import React, { useEffect, useState } from "react";
import { Input, SelectDropdown, Form } from "common/form";
import { IAddress, IBusinessInfo, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/FlowsReducer";
import _ from "lodash";
import { createMerchantSlug, getAPIErrorForTracking } from "utils/helpers";
import { AddressValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import LocalStorage from "classes/LocalStorage";
import { MerchantHandler } from "actions/MerchantHandler";
import { statesArray } from "utils/constants";
import Analytics, { ITracking } from "classes/Analytics";
import { useLoadingBar } from "hooks/useLoadingBar";

const MainBusinessAddress: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();
  const mainAddressInitialValues = useAppSelector(state => state.flows.merchantInvitation["mainAddress"]);
  const businessInformation = useAppSelector(state => state.flows.merchantInvitation["businessInformation"]);
  const landingPage = useAppSelector(state => state.flows.merchantInvitation["landingPage"]);
  const vuid = LocalStorage.get<string>("vuid");
  const invitationId = LocalStorage.get<number>("invitationId");
  const [initialValues, setInitialValues] = useState(null);
  useLoadingBar(initialValues);

  useEffect(() => {
    if (mainAddressInitialValues) {
      setInitialValues(mainAddressInitialValues);
    }
  }, [mainAddressInitialValues]);

  const handleSubmit = async (data: any) => {
    try {
      const merchant = await MerchantHandler().saveBusinessInformation({ ...businessInformation } as IBusinessInfo, { ...data } as IAddress, invitationId, vuid);
      dispatch(actions.updateMerchantInvitation({ mainAddress: data, landingPage: { slug: merchant?.slug || createMerchantSlug(merchant), primary_logo: landingPage.primary_logo || null } }));
      Analytics.track({ experience: "merchant", screen: "merchant_app_main_address", object: "form_submission", action: "successful" } as ITracking, null);
      onNext();
    } catch (error) {
      Analytics.track({ experience: "merchant", screen: "merchant_app_main_address", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
    }
  }

  return <>
    {initialValues && <Form
      id="merchantInvitation_mainBussinessAddress"
      buttonPosition="side right"
      onFormSubmit={handleSubmit}
      title="Main business address"
      submitBtnText="Next"
      validationSchema={Schema({
        ...AddressValidation,
        manageMultipleLocations: SimpleRequireValidation
      })}
      values={initialValues}
      inputFields={[
        [
          <Input type="text" name="address1" id="merchantInvitation_address1" label="Address*" />,
          <Input type="text" name="city" id="merchantInvitation_city" label="City*" />
        ],
        [
          <SelectDropdown
            id="merchantInvitation_state"
            label="State*"
            name="state"
            placeholder="Select"
            selectOptions={statesArray} />,
          <Input type="text" name="postal_code" id="merchantInvitation_postal_code" label="Postal code*" maxLength={5} />
        ],
        [
          <SelectDropdown
            id="merchantInvitation_manageMultipleLocations"
            label="Manage multiple locations?*"
            name="manageMultipleLocations"
            placeholder="Select"
            selectOptions={[{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]}
            tooltip="Managing multiple locations allows you to assign users and applicants to individual locations. This is not recommended for small teams or businesses." />,
        ]
      ]}
      onBackClick={onBack}
    />}
  </>;
};

export default MainBusinessAddress;
