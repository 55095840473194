import { ISettingsView } from "common/interfaces";
import { IPanel } from "common/parts/panel/Panel";
import { ITabContent } from "common/parts/tabs/TabContent";
import { Bucket, CardTick, Clipboard, Global } from "iconsax-react";
import { CSSProperties, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import LandingPageContent from "./content/LandingPageContent";
import useMerchant from "hooks/useMerchant";
import { formatAmount, formatAPICurrency } from "utils/formatters";
import { DEFAULT_MAX_LOAN_AMOUNT, POPUPS } from "utils/constants";
import popUpActions from "reducers/PopUpReducer";
import { useBusinessModel } from "hooks/useBusinessModel";
import SystemHandler from "actions/SystemHandler";

const useExperienceSettings = (): ISettingsView => {
  const dispatch = useAppDispatch();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchant = useMerchant();
  const [panels, setPanels] = useState<Array<IPanel>>([]);
  const selectedBusinessModel = useBusinessModel();
  const [templates, setTemplates] = useState(null);
  const [ready, setReady] = useState(false);
  const logoStyle: CSSProperties = {
    width: "auto",
    height: "auto",
    maxWidth: "90%",
    maxHeight: "100px",
    margin: "auto",
    display: "block",
    objectFit: "contain"
  };
  const [tabContent, setTabContent] = useState<ITabContent>(null);

  useEffect(() => {
    Promise.all([
      SystemHandler.getTemplates(),
    ]).then(responses => {
      setTemplates(responses[0]);
      setReady(true);
    });
  }, []);

  useEffect(() => {
    if (ready && whitelabel?.id && merchant?.id) {
      const panels = [
        {
          title: "Landing Page Logo", customContent: merchant?.primary_logo ? <img src={merchant?.primary_logo} alt={`${merchant?.name} secondary logo`} style={logoStyle} /> : null,
          editAction: () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_LOGO)) }
        },
        {
          title: "Application Settings", lines: [
            { label: `Lender group ${merchant?.lender_group && merchant.lender_group?.name || "Default lender group"}` },
            { label: `Max loan amount ${formatAPICurrency(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT)}` }
          ]
        },
        {
          title: "Experience Settings", lines: [
            { icon: Global, label: `${merchant?.domain}` },
            { icon: Clipboard, label: `${templates.find((temp: any) => { return temp.id === merchant?.industry_template?.id })?.label}` },
            { icon: Bucket, label: `#${whitelabel.primary_color} (primary)` },
            { icon: Bucket, label: `#${whitelabel.secondary_color} (secondary)`, iconColor: "secondaryColor" },
            { icon: CardTick, label: `${merchant?.landing_page_program} ${merchant?.landing_page_program === "DTM" ? "($" + formatAmount(merchant?.landing_page_loan_amount) + " loan amount)" : ""}` },
          ],
          editAction: () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE_SETTINGS)) }
        },
      ];

      if (selectedBusinessModel === "DTM") {
        panels.splice(1, 1);
      }

      setPanels(panels);
    }
  }, [ready, whitelabel, merchant, selectedBusinessModel]);

  useEffect(() => {
    setTabContent({
      sections: [
        { title: `${merchant?.landing_page_program} Landing Page Preview`, body: <LandingPageContent /> },
      ]
    });
  }, [merchant?.landing_page_program]);

  return { panels, tabContent };
}

export default useExperienceSettings;