import React, { useEffect, useRef, useState } from "react";
import Tab, { ITab } from "./Tab";
import styles from "./TabNavigation.module.scss";
import TabContent, { ITabContent } from "./TabContent";
import _debounce from 'lodash/debounce';

export interface ITabNavigation {
  tabs: Array<ITab>;
  contents: Array<ITabContent>;
  onTabChange: (index: number) => void;
  initialActiveTab?: number;
}

const TabNavigation: React.FC<ITabNavigation> = ({ tabs, contents, onTabChange, initialActiveTab }) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [displayShadow, setDisplayShadow] = useState(false);
  const scrollY = useRef(0);

  if (tabs.length !== contents.length) {
    throw new Error("Number of tabs and contents must be the same");
  }

  useEffect(() => {
    onTabChange(activeTab);
  }, [activeTab]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = _debounce(() => {
    const newScrollY = window.scrollY || document.documentElement.scrollTop;
    if (newScrollY > 50) {
      setDisplayShadow(true);
    } else {
      setDisplayShadow(false);
    }
    scrollY.current = newScrollY;
  }, 100);

  return <>
    <div className={`${styles["tab-navigation"]} ${displayShadow ? styles.shadow : ''}`}>
      {tabs.map((tab, index) => {
        return <Tab key={`tab-navigation-tab-${index}`} {...tab} index={index} onClick={(index) => setActiveTab(index)} active={index === activeTab} />
      })}
    </div>
    <div className={styles["tab-content"]}>
      {contents.map((content, index) => {
        if (index !== activeTab) {
          return <React.Fragment key={`tab-content-${index}`}></React.Fragment>;
        }
        return <TabContent key={`tab-content-${index}`} {...content} />;
      })}
    </div>
  </>;
}

export default TabNavigation;