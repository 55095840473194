import Button, { ButtonRef } from "common/Button";
import { IForm, IFormSection } from "common/interfaces";
import { useFormik } from "formik";
import { ReactElement, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { findDifferentKeysInObjects, isSmallScreen, mergeRecursive, objectsAreEqual } from "utils/helpers";
import styles from "./Form.module.scss";
import React from "react";
import { ColorPalleteDropdown, ToolTip } from "common";
import _ from "lodash";
import { FormikError, Input, DigitInput, InputDropdown, SelectDropdown, UploadFile, TextArea } from "common/form";
import { FORM_ELEMENT_TYPES } from "utils/constants";
import { FormRef } from "./Form";
import { useFormDebug } from "./Form.debug.hooks";
import { getChangeHandler } from "utils/changeHandlers";
import { Alert } from "@mui/material";
import { IFormikError } from "./FormikError";

export function useForm(props: IForm, ref: React.ForwardedRef<FormRef>, newStyleOverride: any) {
  const location = useLocation();
  const [agreementAccepted, setAgreementAccepted] = useState(!props.agreement ? true : false);
  /* lastFormikValues and prevFormikValues are used to determine which field changed */
  const [lastFormikValues, setLastFormikValues] = useState(props.values);
  const usesSections = _.first(props.inputFields as Array<IFormSection>)?.title !== undefined;
  const additionalButtonRef = useRef<ButtonRef>(null);

  let inputsCount = 0;
  let numberOfColums = 1;

  if (usesSections) {
    _.each(props.inputFields as Array<IFormSection>, (section: IFormSection) => {
      _.each(section.fields as Array<Array<ReactElement>>, field => {
        numberOfColums = Math.max(numberOfColums, field.length);
      });
    });
  } else {
    _.each(props.inputFields as Array<Array<ReactElement>>, (array: Array<ReactElement>) => {
      numberOfColums = Math.max(numberOfColums, array.length);
      inputsCount += array.length;
    });
  }

  const alerts = () => {
    return <>
      {props.displayErrorBox && <>
        {Object.keys(formik.errors).length > 0 &&
          Object.keys(formik.errors).some(key => formik.touched[key]) && <Alert sx={{
            fontSize: "1.2rem",
            fontWeight: 500
          }} severity="error">
            Please review the highlighted fields in the form and make any necessary corrections.
          </Alert>}
      </>}
    </>;
  }

  const formik = useFormik({
    initialValues: props.values,
    validationSchema: props.validationSchema,
    async onSubmit(values) {
      props.onFormSubmit(values);
    }
  });

  // used for AddressInformation property_status field validation, because we need different validationSchema for 'next' button then 'verify' button
  const formik2 = useFormik({
    initialValues: props.values,
    validationSchema: props.validationSchema2,
    async onSubmit() {
    }
  });

  // formik2 values should mirror formik values
  useEffect(() => {
    formik2.setValues(formik.values);
  }, [formik.values]);

  const { inputChangeDebug } = useFormDebug(formik, location);

  useEffect(() => {
    if (props.overrideValues) {
      const newValues = mergeRecursive(formik.values, props.overrideValues);
      formik.setValues(newValues);
    }
  }, [props.overrideValues])

  const inputErrorStyle = { top: 80 };

  const renderInputs = (fields: Array<Array<ReactElement>>, lastSection?: boolean) => {
    let i = 0;
    return fields.map((line, lineIndex) => {
      let formInnerStyle = styles.formInner;
      // if there is 3rd element "or" between inputs
      if (line.length === 3) { formInnerStyle = [styles.formInnerThird, styles.formInner].join(" "); }
      return React.createElement('div', { className: formInnerStyle, key: `div_line_${lineIndex}` }, line.map((element, index) => {
        const changeHandler = getChangeHandler(element.props.mask, formik);
        i++;
        let elementStyle = styles.formField;
        // the 3rd element "or" between inputs
        if (element.type === "p") { elementStyle = styles.formFieldBetween; }
        const tripleInputErrors: Array<React.ReactElement<IFormikError>> = [];
        return <React.Fragment key={`${element.props.id}_${index}`}>
          <div className={elementStyle}>
            <>
              {element.props.tooltip && <div className={styles.labelContainer}>
                <label>{element.props.label || element.props.name}</label>
                <ToolTip placement={"top"} text={element.props.tooltip} styleOverride={newStyleOverride?.tooltip} onHover={element.props.onTooltipHover} />
              </div>}
              {!element.props.tooltip && <label className={"label" + (element.props.disabled ? " disabled" : "")} htmlFor={element.props.id}>{element.props.label || element.props.name}</label>}

              {element.type === FORM_ELEMENT_TYPES.input && <Input {...element.props} formik={formik}
                onChange={(data: any) => {
                  inputChangeDebug(element.props.name);
                  changeHandler(data);
                  if (element.props.onChange) {
                    element.props.onChange(data);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values[element?.props?.name]}
                styleOverride={newStyleOverride?.input}
                disabled={props.disabledInputFields}
                className={`${element.props?.className} ${formik.touched[element.props.name] && formik.errors[element.props.name] ? ' inputError' : ''}`} />}

              {element.type === FORM_ELEMENT_TYPES.digitInput && <DigitInput {...element.props}
                onChange={(value: string) => {
                  formik.setFieldValue(element.props.name, value);
                }}
                value={formik.values[element.props.name]}
                hasError={formik.errors[element.props.name]} />}

              {element.type === FORM_ELEMENT_TYPES.selectDropdown && <SelectDropdown {...element.props}
                onChange={(value: any) => {
                  formik.setFieldValue(element.props.name, value);
                  if (element.props.onChange) {
                    element.props.onChange(value);
                  }
                }}
                value={formik.values[element.props.name]}
                styleOverride={newStyleOverride?.selectDropdown}
                disabled={element.props.disabled || props.disabledInputFields}
                formik={formik} />}

              {element.type === FORM_ELEMENT_TYPES.colorPalleteDropdown && <ColorPalleteDropdown {...element.props}
                onChange={(value: any) => {
                  formik.setFieldValue(element.props.name, value);
                  if (element.props.onChange) {
                    element.props.onChange(value);
                  }
                }}
                value={formik.values[element.props.name]}
                disabled={props.disabledInputFields}
                formik={formik} />}

              {element.type === FORM_ELEMENT_TYPES.inputDropdown && <InputDropdown {...element.props}
                onChange={(value: any) => {
                  formik.setFieldValue(element.props.name, value);
                  if (element.props.onChange) {
                    element.props.onChange(value);
                  }
                }}
                onInputChange={(value: any) => {
                  formik.setFieldValue(element.props.name, value);
                  if (element.props.onInputChange) {
                    element.props.onInputChange(value);
                  }
                }}
                value={formik.values[element.props.name]}
                styleOverride={newStyleOverride?.selectDropdown}
                disabled={props.disabledInputFields}
                formik={formik} />}

              {element.type === FORM_ELEMENT_TYPES.uploadFile && <UploadFile {...element.props}
                onChange={changeHandler}
                value={formik.values[element.props.name]}
                styleOverride={newStyleOverride?.uploadFile} />}

              {element.type === "p" && <p className={styles.or}>{element.props.children}</p>}
              {element.type === FORM_ELEMENT_TYPES.emptySpace && <div></div>}
              {element.type === FORM_ELEMENT_TYPES.customContent && element}
              {element.props.customErrorMessage && <p className={numberOfColums === 1 ? styles.errorTextOne : styles.errorText}>{element.props.customErrorMessage}</p>}
              {!element.props.customErrorMessage && <FormikError ignoreTouch={element.type === FORM_ELEMENT_TYPES.digitInput} className={numberOfColums === 1 ? styles.errorTextOne : styles.errorText + (element.type === FORM_ELEMENT_TYPES.textArea ? " " + styles.errorTextArea : "")} propertyName={element.props.name} formik={formik} styleOverride={inputErrorStyle} />}
              {/* used only in AddressInformation for property_status field error */}
              {!element.props.customErrorMessage && <FormikError ignoreTouch={element.type === FORM_ELEMENT_TYPES.digitInput} className={numberOfColums === 1 ? styles.errorTextOne : styles.errorText + (element.type === FORM_ELEMENT_TYPES.textArea ? " " + styles.errorTextArea : "")} propertyName={element.props.name} formik={formik2} styleOverride={inputErrorStyle} />}

              {usesSections && props.buttonPosition === "side right" && lastSection && (line.length - 1) === index && <div style={{ textAlign: 'right' }}>
                <Button metadata="B" id={`${props.id}_form_submit`} type="submit" label={props.submitBtnText} disabled={props.buttonDisabled} variant={props.buttonStyle} style={{ width: isSmallScreen() ? '100%' : 'auto', marginTop: 29, ...props.styleOverride?.button ? { ...props.styleOverride.button } : {} }} />
              </div>}

              {element.type === FORM_ELEMENT_TYPES.tripleInput && Array.isArray(element.props?.children) && <div className={styles.tripleInputWrapper}>

                {element.props?.children.map((el, index) => {
                  const changeHandler = getChangeHandler(el.props.mask, formik);

                  if (!el.props.customErrorMessage && formik.errors[el.props.name]?.toString()) {
                    let newError = true;
                    for (let i = 0; i < tripleInputErrors.length; i++) {
                      if (formik.errors[el.props.name]?.toString() === tripleInputErrors[i]?.props.formik.errors[tripleInputErrors[i]?.props.propertyName]?.toString()) {
                        newError = false;
                      }
                    }
                    if (newError) {
                      tripleInputErrors.push(<FormikError ignoreTouch={el.type === FORM_ELEMENT_TYPES.digitInput} className={numberOfColums === 1 ? styles.errorTextOne : styles.errorText + (el.type === FORM_ELEMENT_TYPES.textArea ? " " + styles.errorTextArea : "")} propertyName={el.props.name} formik={formik} styleOverride={inputErrorStyle} />)
                    }
                  }
                  return <div className={styles.tripleInputWithLabel} key={index}>
                    <label className={`${styles.tripleInputLabel}`} htmlFor={el.props.id}>{el.props.label || el.props.name}</label>
                    <Input {...el.props} formik={formik}
                      onChange={(data: any) => {
                        inputChangeDebug(el.props.name);
                        changeHandler(data);
                        if (el.props.onChange) {
                          el.props.onChange(data);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values[el.props.name]}
                      styleOverride={newStyleOverride?.input}
                      disabled={props.disabledInputFields}
                      className={`${el.props?.className} ${formik.touched[el.props.name] && formik.errors[el.props.name] ? ' inputError' : ''}`} />
                  </div>
                })}
              </div>
              }

              {/* triple input errors */}
              {element.type === FORM_ELEMENT_TYPES.tripleInput && <div className={styles.tripleInputErrors}>
                {tripleInputErrors.map((error, index1) => {
                  return <div className={styles.tripleInputError} key={index1}>
                    {error}
                  </div>;
                })}
                {(Number(formik?.values["business_percent_customer_sales"]) + Number(formik?.values["business_percent_business_sales"]) + Number(formik?.values["business_percent_government_sales"]) !== 100) && <p className={numberOfColums === 1 ? styles.errorTextOne : styles.errorText}>The sum of the percentages must be 100</p>}
              </div>}

              {element.type === FORM_ELEMENT_TYPES.textArea && <TextArea {...element.props} formik={formik}
                onChange={(data: any) => {
                  inputChangeDebug(element.props.name);
                  changeHandler(data);
                  if (element.props.onChange) {
                    element.props.onChange(data);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values[element.props.name]}
                styleOverride={newStyleOverride?.input}
                disabled={props.disabledInputFields}
                className={`${element.props?.className} ${formik.touched[element.props.name] && formik.errors[element.props.name] ? ' inputError' : ''}`} />}
            </>
          </div>
          {
            !usesSections && !props.formHelp && props.buttonPosition === "side right" && inputsCount === i && <div className={styles.formField} style={{ marginTop: 28 }}>
              {/* Form helper already has its own button, so don't render if formHelper was provided; inputsCount === i means to render the button on the side of the last input filed; */}
              {renderButtons()}
            </div>
          }
        </React.Fragment >;
      }));
    });
  }

  const renderButtons = () => {
    return <div className={props.buttonPosition === "bottom center" ? styles.submitBtnContainer : styles.formBtnContainer} style={newStyleOverride?.button}>
      {props.allowSkipStep && <Button metadata="C" id={`${props.id}_form_skip`} type="button" label="Skip" variant="secondary" onClick={() => props.onFormSubmit(null)} />}
      {props.additionalButtonLabel && <div className={styles.two_buttons_wrapper}>
        <Button metadata="D" id={`${props.id}_form_additional_button`} type={props.isAdditionalButtonSubmit ? "submit" : "button"} label={props.additionalButtonLabel} variant="secondary" ref={additionalButtonRef} style={{ ...props.styleOverride?.button ? { ...props.styleOverride.button } : {} }}
          onClick={() => {
            if (props.isAdditionalButtonSubmit) {
              formik.handleSubmit();
            } else {
              props.onAdditionalButtonClick(formik.values)
            }
          }}
        />
        <Button metadata="E" id={`${props.id}_form_submit`} type={props.isAdditionalButtonSubmit ? "button" : "submit"} label={props.submitBtnText} disabled={!agreementAccepted} variant="primary" style={{ ...props.styleOverride?.button ? { ...props.styleOverride.button } : {} }}
          onClick={() => {
            if (!props.isAdditionalButtonSubmit) {
              formik.handleSubmit();
            } else {
              props.onAdditionalButtonClick(formik.values)
            }
          }}
        />
      </div>}
      {!usesSections && !props.additionalButtonLabel && <Button metadata="F" id={`${props.id}_form_submit`} type="submit" label={props.submitBtnText} disabled={!agreementAccepted || props.buttonDisabled} variant={props.buttonStyle} style={{ ...props.styleOverride?.button ? { ...props.styleOverride.button } : {} }} />}
      {props.agreement && !agreementAccepted && <><br /></>}
    </div>;
  }

  const reset = () => {
    formik.resetForm();
  }

  const edit = (data: any) => {
    _.forOwn(data, (value, key) => {
      formik.setFieldValue(key, value);
    });
  }

  const isValid = async (): Promise<boolean> => {
    await formik.setTouched(formik.initialTouched);
    const validatedForm = await formik.validateForm();
    const hasErrors = Object.keys(validatedForm).length === 0;
    return Promise.resolve(hasErrors);
  }

  const isValid2 = async (): Promise<boolean> => {
    const touched = {};
    // setting touched for all formik2 fields to true - is used for 'property_status' in AddressInformation
    Object.keys(props.validationSchema2.fields).forEach((key) => { touched[key] = true; })
    await formik2.setTouched(touched, false);
    const validatedForm = await formik2.validateForm();
    const hasErrors = Object.keys(validatedForm).length === 0;
    return Promise.resolve(hasErrors);
  }

  const getValues = () => {
    return formik.values;
  }

  // Expose the child function using the ref
  useImperativeHandle(ref, () => ({ reset, edit, isValid, isValid2, getValues }));

  useEffect(() => {
    if (props.onFormChange) {
      props.onFormChange(formik.values, findDifferentKeysInObjects(lastFormikValues, formik.values));
    }

    if (props.listenToFieldChange && !objectsAreEqual(formik.values, lastFormikValues) && findDifferentKeysInObjects(lastFormikValues, formik.values).includes(props.listenToFieldChange)) {
      props.onListenedFieldChange(formik.values);
    }

    setLastFormikValues(formik.values);
  }, [formik.values]);

  return { formik, agreementAccepted, setAgreementAccepted, usesSections, renderButtons, renderInputs, alerts }
}