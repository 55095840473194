import React, { useEffect, useState } from "react";
import { Input, SelectDropdown, Form } from "common/form";
import { IBusinessInfo, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/FlowsReducer";
import { BusinessValidation, Schema } from "utils/validation/additionalValidation";
import LocalStorage from "classes/LocalStorage";
import SystemHandler from "actions/SystemHandler";
import InvitationHandler from "actions/InvitationHandler";
import appActions from "reducers/AppReducer";
import Analytics, { ITracking } from "classes/Analytics";
import { useLoadingBar } from "hooks/useLoadingBar";

const BusinessInformation: React.FC<IStepper> = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const businessInitialValues = useAppSelector(state => state.flows.merchantInvitation["businessInformation"]);
  const [initialValues, setInitialValues] = useState(null);
  const [annualSalesOptions, setAnnualSalesOptions] = useState([]);
  const [monthlyFinanceOptions, setMonthlyFinanceOptions] = useState([]);
  const [averageTicketOptions, setAverageTicketOptions] = useState([]);
  const [industrySpecialtyOptions, setIndustrySpecialtyOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const invitationId = LocalStorage.get<number>("invitationId");
  const [industry, setIndustry] = useState(null);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  useLoadingBar(initialValues);

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMonthlyFinanceVolume(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty()
    ]).then(responses => {
      setAnnualSalesOptions(responses[0]);
      setMonthlyFinanceOptions(responses[1]);
      setAverageTicketOptions(responses[2]);
      setIndustryOptions(responses[3]);
      setIndustrySpecialtyOptions(responses[4]);
    });
  }, []);

  useEffect(() => {
    if (businessInitialValues) {
      setInitialValues(businessInitialValues);
      if (businessInitialValues.industry) {
        setIndustry(businessInitialValues.industry);
      }
    }
  }, [businessInitialValues]);

  useEffect(() => {
    if (!invitationId) {
      // Since there is no invitation id calls the API to get an invitation Id and Visitor pass
      InvitationHandler.generateMerchantInvitation()
        .then(response => {
          LocalStorage.save("invitationId", response.id);
          LocalStorage.save("vuid", response.visitor_token);

          dispatch(appActions.updateMerchantAppComingFromLandingPage(true));
        });
    }
  }, [invitationId]);

  useEffect(() => {
    if (!industry) {
      setSpecialtyOptions([]);
    } else {
      setSpecialtyOptions(industrySpecialtyOptions.filter(specialty => { return specialty.industry === parseInt(industry) }));
    }
  }, [industry, industrySpecialtyOptions]);

  const handleSubmit = async (data: IBusinessInfo) => {
    dispatch(actions.updateMerchantInvitation({ businessInformation: data }));
    Analytics.track({ experience: "merchant", screen: "merchant_app_business_info", object: "form_submission", action: "successful" } as ITracking, null);
    onNext();
  }

  return <>
    {initialValues && <Form
      id="merchantInvitation_businessInformation"
      buttonPosition="side right"
      onFormSubmit={handleSubmit}
      title="Business information"
      submitBtnText="Next"
      validationSchema={Schema({
        ...BusinessValidation
      })}
      values={initialValues}
      inputFields={[
        [
          <Input type="text" name="name" id="merchantInvitation_name" label="Business name*" />,
          <Input type="text" name="website" id="merchantInvitation_website" label="Website*" />
        ],
        [
          <SelectDropdown
            id="merchantInvitation_annual_sales"
            label="Annual sales"
            name="annual_sales"
            placeholder="Select"
            selectOptions={annualSalesOptions} />,
          <SelectDropdown
            id="merchantInvitation_monthly_financing_volume"
            label="Est. monthly finance volume"
            name="monthly_financing_volume"
            placeholder="Select"
            selectOptions={monthlyFinanceOptions} />,
        ],
        [
          <SelectDropdown
            id="merchantInvitation_average_ticket"
            label="Average ticket"
            name="average_ticket"
            placeholder="Select"
            selectOptions={averageTicketOptions} />,
          <SelectDropdown
            id="merchantInvitation_industry"
            label="Industry"
            name="industry"
            placeholder="Select"
            onChange={(value: any) => { setIndustry(value) }}
            selectOptions={industryOptions} />
        ],
        [
          <SelectDropdown
            id="merchantInvitation_industry_specialty"
            label="Specialty"
            name="industry_specialty"
            placeholder="Select"
            selectOptions={specialtyOptions} />,
        ]
      ]}
    />}
  </>;
};

export default BusinessInformation;
