import { Button, CloseIcon } from "common";
import { SlugTextField } from "common/form";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { Schema, URLFriendlyText } from "utils/validation/additionalValidation";
import { decypherApiErrorResponse, displayMiniFeedback } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { MerchantHandler } from "actions/MerchantHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { Grid } from "@mui/material";

const EditMerchantSlugPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const merchant = useAppSelector(state => state.merchant);
  const [ready, setReady] = useState(false);

  const formik = useFormik({
    initialValues: {
      slug: merchant?.slug
    },
    validationSchema: Schema({
      slug: URLFriendlyText
    }),
    async onSubmit(values) {
      try {
        const currentSlug = merchant?.slug;
        const updatedMerchant = await MerchantHandler().patch(merchant?.slug, { slug: values.slug });
        if (updatedMerchant) {
          if (updatedMerchant?.slug !== currentSlug) {
            Analytics.track({ experience: "portal", screen: "settings_slug", object: "url", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
          }
          displayMiniFeedback("Slug was updated");
          dispatch(popUpActions.closePopup());
        }
      } catch (error) {
        displayMiniFeedback(decypherApiErrorResponse(error), true);
      }
    },
  });

  useEffect(() => {
    if (merchant) {
      formik.setFieldValue("slug", merchant?.slug);
      setReady(true);
    }
  }, [merchant]);

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="landingPageSettingsPopup" color="dark" />
        {ready && <form onSubmit={formik.handleSubmit}>
          <h2>{`${merchant?.name} • Edit slug settings`}</h2>
          <Grid container style={{ marginTop: 30 }}>
            <Grid item>
              <label className="label" style={{ marginBottom: 20, color: "var(--darkTextColor)" }}>Landing page URL</label>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={9}>
              <SlugTextField
                id="landingPageSettingsPopup_slug"
                formik={formik} />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <Button id="landingPageSettingsPopup_submit" type="submit" label="Save" style={{ marginTop: -10 }} />
            </Grid>
          </Grid>
        </form>}
      </>
    </Popup >
  );
};

export default EditMerchantSlugPopup;
