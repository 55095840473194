import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useViewIndex = (views: Array<string>) => {
  const view = useParams()?.view;
  const [index, setIndex] = useState(views.indexOf(view));

  useEffect(() => {
    setIndex(views.indexOf(view));
  }, [view]);

  return index;
}

export default useViewIndex;