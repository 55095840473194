interface IColorRGB {
  r: number,
  g: number,
  b: number
}

interface IColorRGBA {
  r: number,
  g: number,
  b: number,
  a: number
}

/**
 * @description 
 * @param {boolean} darkMode        true => light text and dark background
 */
export const getPrimaryVariationColor = (mainColorHex: string, darkMode: boolean, alpha): string => {
  const mainColorRGB = hexToRgb(mainColorHex);
  const colorOverlay = getOverlayColor(mainColorRGB, darkMode, alpha);
  const mixedColor = mixColors(mainColorRGB, colorOverlay);

  return mixedColor ? rgbToHex(mixedColor.r, mixedColor.g, mixedColor.b) : "";
}

/**
 * @description 
 * @param {number} alpha alpha channel in %  
 */
export const getTranslucentPrimaryOnWhiteColor = (mainColorHex: string, alpha: number): string => {
  const mainColorRGB = hexToRgb(mainColorHex);
  const colorWhite = { r: 255, g: 255, b: 255 };
  const mixedColor = mixColors(colorWhite, addAlphaChannel(mainColorRGB, alpha));

  return mixedColor ? rgbToHex(mixedColor.r, mixedColor.g, mixedColor.b) : "";
}

/**
 * @description 
 * @param {IColorRGB} mainColorRgb mainColor in IColorRGB, returns alpha from 0 to 1
 * @param {number} alpha alpha channel in %
 */
const addAlphaChannel = (mainColorRgb: IColorRGB, alpha: number): IColorRGBA => {
  return { r: mainColorRgb.r, g: mainColorRgb.g, b: mainColorRgb.b, a: alpha / 100 };
}

/**
 * @description 
 * @param {string} mainColorHex mainColor in hex
 * @param {number} alpha alpha channel in %  
 */
export const getPrimaryColorWithAlpha = (mainColorHex: string, alpha: number): string => {
  return "#" + removeHashChar(mainColorHex) + alphaChannelToHex(alpha);
}

function alphaChannelToHex(alpha: number): string {
  return (Math.round(alpha * 255 / 100)).toString(16);
}

/**
 * @description removes the # from the color
 * @param {string} color can contain # in the beginning or not
 */
function removeHashChar(color: string): string {
  if (color.startsWith("#")) { return color.substr(1); }
  return color;
}

/**
 * @description return lighet overlay for light colors, darker overlay for dark colors and special overlay for yellow colors to prevent brownish colors
 * @param {IColorRGB | null}    mainColorRGB    mainColor in hex
 * @param {boolean}             darkMode        true => light text and dark background
 */
function getOverlayColor(mainColorRGB: IColorRGB | null, darkMode: boolean, alpha: number) {
  if (mainColorRGB) {
    const { r, g } = mainColorRGB;
    if (darkMode) {
      if (200 < r && r <= 255 && 100 < g && g <= 255) {
        return { r: 233, g: 102, b: 102, a: alpha };
      } else {
        return { r: 0, g: 0, b: 0, a: alpha };
      }
    } else if (!darkMode) {
      return { r: 255, g: 255, b: 255, a: alpha };
    }
  }
  return { r: 0, g: 0, b: 0, a: alpha };
}

function hexToRgb(hex: string) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
}

function mixColors(colorBase: IColorRGB | null, colorOverlay: IColorRGBA | null) {
  if (colorBase && colorOverlay) {
    return {
      r: colorBase.r - Math.round(colorOverlay.a * (colorBase.r - colorOverlay.r)),
      g: colorBase.g - Math.round(colorOverlay.a * (colorBase.g - colorOverlay.g)),
      b: colorBase.b - Math.round(colorOverlay.a * (colorBase.b - colorOverlay.b)),
    }
  }
  return null;
}

const WCAG_MINIMUM_RATIOS = [
  ['AA Large', 3],
  ['AA', 4.5],
  ['AAA', 7],
]

/**
 * @description returns text color
 * @param {boolean} darkMode        true => light text and dark background
 */
export const getTextColor = (darkMode: boolean): string => {
  if (darkMode) {
    return "#FFFFFF";// 'rgb(255, 255, 255)'
  } else {
    return "#2f2d6c"; // 'rgb(47, 45, 108)' // darkTextColor
  }
}

export const getDarkMode = (primaryColor: string): boolean => {
  const formatColor = (color: string) => color.startsWith("#") ? color : `#${color}`;
  primaryColor = formatColor(primaryColor);
  return isDarkMode(primaryColor);
}

export const isDarkMode = (mainColorHex: string): boolean => {
  const mainColorRGB = hexToRgb(mainColorHex);
  const colorWhiteRGB = { r: 255, g: 255, b: 255 };

  if (mainColorRGB) {
    let ratio = checkContrast(colorWhiteRGB, mainColorRGB);
    let { didPass } = meetsMinimumRequirements(ratio);

    if (didPass) {
      return true;// text will be light
    } else {
      return false;// text will be dark
    }
  }
  return false;
}

function checkContrast(color1: IColorRGB, color2: IColorRGB) {
  let [luminance1, luminance2] = [color1, color2].map(color => {
    return luminance(color);
  });

  return contrastRatio(luminance1, luminance2);
}

function luminance(color: IColorRGB): number {
  const { r, g, b } = color;
  let [lumR, lumG, lumB] = [r, g, b].map(component => {
    let proportion = component / 255;

    return proportion <= 0.03928 ? proportion / 12.92 : Math.pow((proportion + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
}

function contrastRatio(luminance1: number, luminance2: number) {
  let lighterLum = Math.max(luminance1, luminance2);
  let darkerLum = Math.min(luminance1, luminance2);

  return (lighterLum + 0.05) / (darkerLum + 0.05);
}

function meetsMinimumRequirements(ratio: number) {
  let didPass = false;
  let maxLevel = null;

  for (const [level, minRatio] of WCAG_MINIMUM_RATIOS) {
    if (ratio < (minRatio as number)) break;

    didPass = true;
    maxLevel = level;
  }

  return { didPass, maxLevel }
}

export const colorOverlayFavicon = (color: string) => {
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');

  var favicon: any = document.querySelector('link[rel="icon"]');
  var img = document.createElement('img');

  img.onload = function () {
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0);

    context.globalCompositeOperation = 'source-in';
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Only loads second image after the first one was loaded
    let image2 = new Image();
    image2.onload = function () {
      context.globalCompositeOperation = 'source-over';
      context.drawImage(image2, 0, 0);

      var modifiedFavicon = canvas.toDataURL('image/png');
      favicon.href = modifiedFavicon;
    }
    image2.src = '/favDollar.png';
    image2.onerror = handleImageError;
  };
  img.src = favicon.href;

  function handleImageError(event: any) {
    const errorMessage = event.message;
    const imageUrl = event.target.src;
    const errorDetails = `Error loading image: ${errorMessage}\nImage URL: ${imageUrl}`;
    console.error(errorDetails);
  }
}

export interface ColorVariable {
  variable_name: string;
  color_code: string;
}

export const calculateColorVariables = (primaryColor: string, secondaryColor: string): Array<ColorVariable> => {
  const formatColor = (color: string) => color.startsWith("#") ? color : `#${color}`;
  primaryColor = formatColor(primaryColor);
  secondaryColor = formatColor(secondaryColor);
  const darkMode = isDarkMode(primaryColor);
  let variables: Array<ColorVariable> = [
    { variable_name: "--primaryColor", color_code: primaryColor },
    { variable_name: "--primaryTextColor", color_code: darkMode ? primaryColor : "var(--darkTextColor)" },
    { variable_name: "--secondaryColor", color_code: secondaryColor },
    { variable_name: "--primaryColor40", color_code: getPrimaryColorWithAlpha(primaryColor, 40) },
    { variable_name: "--primaryColor20", color_code: getPrimaryColorWithAlpha(primaryColor, 20) },
    { variable_name: "--primaryColor10", color_code: getPrimaryColorWithAlpha(primaryColor, 10) },
    { variable_name: "--secondaryColor40", color_code: getPrimaryColorWithAlpha(secondaryColor, 40) },
    { variable_name: "--primaryVariationColor", color_code: getPrimaryVariationColor(primaryColor, darkMode, 0.3) },
    { variable_name: "--primaryVariationTextColor", color_code: darkMode ? getPrimaryVariationColor(primaryColor, darkMode, 0.3) : "var(--darkTextColor)" },
    { variable_name: "--textColor", color_code: getTextColor(darkMode) },
    { variable_name: "--primaryBlack30Color", color_code: getPrimaryVariationColor(primaryColor, true, 0.3) },
    { variable_name: "--primaryColor40Solid", color_code: getTranslucentPrimaryOnWhiteColor(primaryColor, 40) },
    { variable_name: "--primaryColor30Solid", color_code: getTranslucentPrimaryOnWhiteColor(primaryColor, 30) },
    { variable_name: "--primaryColor20Solid", color_code: getTranslucentPrimaryOnWhiteColor(primaryColor, 20) },
    { variable_name: "--primaryColor10Solid", color_code: getTranslucentPrimaryOnWhiteColor(primaryColor, 10) },
    { variable_name: "--secondaryColor40Solid", color_code: getTranslucentPrimaryOnWhiteColor(secondaryColor, 40) },
    { variable_name: "--scrollColor", color_code: getTranslucentPrimaryOnWhiteColor(primaryColor, 30) },
    { variable_name: "--whiteTextColor", color_code: darkMode ? "var(--whiteColor)" : "var(--darkTextColor)" },
  ];
  return variables;
}