import React, { CSSProperties, ReactNode, forwardRef, useImperativeHandle } from "react";
import styles from "./Button.module.scss";
import { preventTextSelection } from "utils/helpers";

interface IButton {
  id: string;
  label: string;
  onClick?: () => void;
  variant?: "primary" | "secondary";
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  icon?: ReactNode;
  fullWidth?: boolean;
  type?: "button" | "submit";
  small?: boolean;
  /** should equal to "button label"
   *  children are not used in the component itself, but should be passed, if the button is inside of table data for the proper export of the table */
  children?: any;
  metadata?: string;
}

export interface ButtonRef {
  click: () => void;
}

const Button: React.ForwardRefRenderFunction<ButtonRef, IButton> = ({ small, type, fullWidth, id, label, onClick, variant, disabled, style, className, icon, metadata }, ref) => {

  const click = () => {
    if (onClick) {
      onClick();
    }
  }

  // Expose the child function using the ref
  useImperativeHandle(ref, () => ({
    click,
  }));

  return (
    <button
      data-metadata={metadata || ""}
      id={id}
      disabled={disabled}
      type={type || "button"}
      style={{ ...style, ...(fullWidth && { width: "100%" }) }}
      onClick={click}
      className={`${styles.button} ${small ? styles.small : ""} ${variant === "secondary" ? styles.secondary : ""} ${disabled ? styles.disabled : ""} ${className}`}
      onMouseDown={preventTextSelection}>
      <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>{label} {icon || null}</div>
    </button>
  );
};

export default forwardRef(Button);
