import React, { ReactNode } from "react";

interface ICustomContent {
  children: ReactNode;
}

const CustomContent: React.FC<ICustomContent> = ({ children }) => {
  return <>
    {children}
  </>;
}

export default CustomContent;