import { CloseIcon, Scrollable } from "common";
import { Input, SelectDropdown, Form } from "common/form";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import { statesArray } from "utils/constants";
import {
  AddressValidation, BusinessValidation, EmailValidation, NameValidation,
  PhoneValidation, Schema
} from "utils/validation/additionalValidation";
import {
  fillInitialValues, displayMiniFeedback,
  isNotNullNorUndefined
} from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import Analytics, { ITracking } from "classes/Analytics";
import { PartnerHandler } from "actions/PartnerHandler";
import _ from "lodash";
import { usePartnerOptions } from "hooks/usePartnerOptions";
import Popup from "./Popup";
import usePartner from "hooks/usePartner";

const EditPartnerBusinessMainContactPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const user = useAuth()?.user;
  const { partnerSlug } = useParams();
  const partner = usePartner(partnerSlug);
  const [initialValues, setInitialValues] = useState(null);
  const [inputFields, setInputFields] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [buttonToTheRight, setButtonToTheRight] = useState(false);
  const partnerOptions = usePartnerOptions();
  const userType = useMemo(() => user?.user_type, [user]);

  // blank initial values
  const partnerInitialValues = {
    id: "",
    name: "",
    address1: "",
    city: "",
    postal_code: "",
    state: "",
    website: "",
    merchant_number: "",
    type: "",
    main_contact_first_name: "",
    main_contact_last_name: "",
    main_contact_email: "",
    main_contact_phone: "",
    external_id: ""
  };

  useEffect(() => {
    if (partnerOptions.ready && userType === "WHITELABEL") {
      // This is a white label looking at partner account information
      PartnerHandler().get(partnerSlug).then(response => {
        setInitialValues(fillInitialValues({ ...partnerInitialValues }, response));
      });
    } else {
      // This is a partner user seeing their settings
      setInitialValues(fillInitialValues(partnerInitialValues, partner));
    }
  }, [partnerOptions.ready, partner]);

  useEffect(() => {
    if (initialValues && partnerOptions.ready) {
      const newInputFields = [
        <Input name="name" id="editBusinessAndMainContactInformationPopup_name" label="Business name" />,
        userType === "WHITELABEL" ? <Input name="external_id" id="editBusinessAndMainContactInformationPopup_external_id" label="External id" /> : null,
        <Input name="address1" id="editBusinessAndMainContactInformation_address1" label="Address" />,
        <Input name="city" id="editBusinessAndMainContactInformation_city" label="City" />,
        <SelectDropdown selectOptions={statesArray} name="state" id="editBusinessAndMainContactInformation_state" label="State" />,
        <Input name="postal_code" id="editBusinessAndMainContactInformation_postal_code" maxLength={5} label="Postal code" />,
        <Input name="website" id="editBusinessAndMainContactInformation_website" label="Website" />,
        <SelectDropdown selectOptions={partnerOptions.options.numberMerchantsOptions} name="merchant_number" id="editBusinessAndMainContactInformation_merchant_number" label="Number of merchants" />,
        <SelectDropdown selectOptions={partnerOptions.options.partnerTypes} name="type" id="editBusinessAndMainContactInformation_type" label="Type" />,
        <Input name="main_contact_first_name" id="editMainContactInformationPopup_contact_first_name" label="First name" />,
        <Input name="main_contact_last_name" id="editMainContactInformationPopup_contact_last_name" label="Last name" />,
        <Input type="email" name="main_contact_email" id="editMainContactInformationPopup_contact_email" label="Email" />,
        <Input name="main_contact_phone" id="editMainContactInformationPopup_contact_phone" label="Phone" mask="phone" />
      ].filter(field => isNotNullNorUndefined(field));

      if (newInputFields.length % 2 !== 0) {
        setButtonToTheRight(true);
      }
      const finalInputFields = Array.from({ length: Math.ceil(newInputFields.length / 2) }, (_, i) => newInputFields.slice(i * 2, i * 2 + 2));

      let newValidationSchema: any = {
        ...AddressValidation,
        name: BusinessValidation.name,
        main_contact_first_name: NameValidation,
        main_contact_last_name: NameValidation,
        main_contact_email: EmailValidation,
        main_contact_phone: PhoneValidation,
        website: BusinessValidation.website
      };

      setInputFields(finalInputFields);
      setValidationSchema(newValidationSchema);
    }
  }, [initialValues, partnerOptions.ready]);

  const handleSubmit = async (data: any) => {
    const payload = {
      ...data,
      ...data?.merchant_number ? { merchant_number: data.merchant_number } : { merchant_number: null },
      ...data?.type ? { type: data.type } : { type: null }
    };
    const response = await PartnerHandler().patch(partnerSlug || partner?.slug, payload);
    Analytics.track({ experience: "portal", screen: userType === "WHITELABEL" ? "partner_account_info" : "settings_account_info", object: "business_info", action: "updated" } as ITracking, { partner_id: response?.id });
    displayMiniFeedback("Information updated");
    dispatch(popUpActions.closePopup());
  }

  return (
    <Popup isInner={true}>
      <>
        {initialValues && inputFields && validationSchema && <Scrollable>
          <CloseIcon id="editBusinessAndMainContactInformationPopup_closeIcon" color="dark" />
          <Form
            id="editBusinessAndMainContactInformationPopup"
            title={`${userType === "WHITELABEL" ? `${partner?.name} • ` : ''}Edit business information`}
            submitBtnText="Save"
            onFormSubmit={handleSubmit}
            validationSchema={Schema(validationSchema)}
            values={initialValues}
            buttonPosition={buttonToTheRight ? "side right" : "bottom right"}
            inputFields={inputFields}
            isInsidePopup
          />
        </Scrollable>}
      </>
    </Popup>
  );
};

export default EditPartnerBusinessMainContactPopup;