// used in Landing and LocationBorrower
import React, { CSSProperties, useEffect, useState } from "react";
import { Plus } from "react-bootstrap-icons";
import styles from "./LogoHeader.module.scss";
import { Button } from "../";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { isSmallScreen } from "utils/helpers";
import { useImageDimensions } from "hooks/useImageDimensions";

interface ILogoHeader {
  help?: boolean;
  merchantLogoPreview?: string;
  merchantInitials?: string;
  /* you need this for landing page settings, when WL logo is uploaded */
  wlLogoPreview?: string;
  type: "MERCHANT" | "WHITELABEL";
  width?: number;
  preview?: boolean;
}

const LogoHeader: React.FC<ILogoHeader> = ({ help, merchantLogoPreview, merchantInitials, wlLogoPreview, type, width, preview }) => {
  const dispatch = useAppDispatch();
  const { whitelabel, merchant } = useAppSelector(state => state);
  const merchantLogoDimensions = useImageDimensions(merchantLogoPreview || merchant?.primary_logo);
  // set whitelabel logo to a secondary logo or a square logo based on merchant logo aspect ratio
  const [wlLogo, setWlLogo] = useState("");
  const imageStyle: CSSProperties = {
    width: "auto",
    display: "block",
    objectFit: "contain",
  };

  const displaySquareLogo = () => {
    if (merchantLogoDimensions && whitelabel?.square_logo) {
      return merchantLogoDimensions.width <= 1.2 * merchantLogoDimensions.height;
    }
    return false;
  }

  useEffect(() => {
    // set correct WL logo
    if (merchantLogoDimensions && whitelabel?.square_logo) {
      if (displaySquareLogo()) {
        setWlLogo(whitelabel?.square_logo);
      } else {
        setWlLogo(whitelabel?.secondary_logo);
      }
    } else {
      setWlLogo(whitelabel?.secondary_logo);
    }
  }, [merchantLogoDimensions, whitelabel]);

  const calculateLogoHeight = (): CSSProperties => {
    if (preview) {
      return {
        // manual calcuation based on the "var(--squareLogoMaxHeight)" and "var(--logoMaxHeight)" based on the preview width
        height: displaySquareLogo() ? (0.03 * width) + "px" : (0.045 * width) + "px"
      }
    } else {
      return {
        height: displaySquareLogo() ? "var(--squareLogoMaxHeight)" : "var(--logoMaxHeight)"
      }
    }
  }

  const getWLLogo = () => {
    if (wlLogoPreview || wlLogo) {
      return <img src={wlLogoPreview || wlLogo} alt="WhiteLabel Logo" style={{ ...imageStyle, ...calculateLogoHeight(), ...calculateLogoHeight() }} />;
    }
    return null;
  }

  const getMerchantLogo = () => {
    if (merchantLogoPreview || merchant?.primary_logo) {
      return <img src={merchantLogoPreview || merchant?.primary_logo} alt="Merchant Logo" style={{ ...imageStyle, ...calculateLogoHeight(), ...calculateLogoHeight() }} />;
    } else if (merchantInitials) {
      return <p className={styles.initials}>{merchantInitials}</p>
    }
    return null;
  }

  const getLogos = (): any => {
    const wlLogo = getWLLogo();
    const merchantLogo = getMerchantLogo();
    if (type === "WHITELABEL") {
      return wlLogo;
    } else if (type === "MERCHANT" && whitelabel?.merchant_page_layout === "SIDE_BY_SIDE") {
      return <>
        {wlLogo}
        {merchantLogo && <Plus color="var(--darkTextColor)" size={isSmallScreen() ? 20 : 30} />}
        {merchantLogo}
      </>;
    } else if (type === "MERCHANT" && whitelabel?.merchant_page_layout === "POWERED_BY") {
      return merchantLogo;
    }
  }

  const isOneLogo = (): boolean => {
    return type === "WHITELABEL" || type === "MERCHANT" && (whitelabel?.merchant_page_layout === "POWERED_BY" || !(merchantLogoPreview || merchant?.primary_logo));
  }

  return (
    <header className={styles.headerInner + (preview ? " " + styles.borderRadius : "")}>
      {getLogos()}
      {help && <Button
        id="logoHeader_helpButton"
        type="button"
        label="Help"
        onClick={() => {
          dispatch(popUpActions.openPopup(POPUPS.HELP));
        }}
      />}
    </header>
  );
};

export default LogoHeader;