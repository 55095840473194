import React from "react";
import InvitePopup from "./InvitePopup";
import { IFeedback, IFileData, IPopup } from "common/interfaces";
import {
  EmailValidation, NameValidation, Schema, emailMaxChars, nameMaxChars
} from "utils/validation/additionalValidation";
import { Form, Input } from "common/form";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { displayFeedback, displayFullscreenLoading, getAPIErrorForTracking, hideFullscreenLoading } from "utils/helpers";
import { IMerchantInvitation } from "common/interfaces/IInvitation";
import { merchantInvitesTemplate } from "utils/bulkUploadTemplates";
import { POPUPS } from "utils/constants";
import InvitationHandler from "actions/InvitationHandler";
import Analytics, { ITracking } from "classes/Analytics";

const InviteMerchantPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();

  const handleSubmit = (data: IMerchantInvitation): void => {
    displayFullscreenLoading();
    InvitationHandler.sendMerchantInvitation(data)
      .then(() => {
        Analytics.track({ experience: "portal", screen: "invite_merchant", object: "single_invitation", action: "successful" } as ITracking);
        displaySuccessMessage();
        hideFullscreenLoading();
      })
      .catch(error => {
        Analytics.track({ experience: "portal", screen: "invite_merchant", object: "single_invitation", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      });
  }

  const handleBulkInvitation = (data: IFileData): void => {
    displayFullscreenLoading();
    InvitationHandler.bulkUploadMerchantInvites(data.file, data.filename)
      .then(() => {
        Analytics.track({ experience: "portal", screen: "invite_merchant", object: "bulk_invitation", action: "successful" } as ITracking);
        displaySuccessMessage();
        hideFullscreenLoading();
      })
      .catch(error => {
        hideFullscreenLoading();
        Analytics.track({ experience: "portal", screen: "invite_merchant", object: "bulk_invitation", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      });
  }

  const displaySuccessMessage = () => {
    dispatch(popUpActions.closePopup());
    displayFeedback({
      title: `Success!`,
      body: `The contact(s) will get an email with instructions\nto fill out an application.`,
      type: "MERCHANT",
      buttons: [
        {
          id: "back_to_merchants",
          label: "Back",
          action: () => {
            dispatch(popUpActions.closePopup());
          }
        }
      ]
    } as IFeedback);
  }

  const getForm = () => {
    return (
      <Form
        id="inviteMerchantPopup"
        title=""
        submitBtnText="Send"
        onFormSubmit={handleSubmit}
        validationSchema={Schema({
          main_contact_email: EmailValidation,
          main_contact_first_name: NameValidation,
          main_contact_last_name: NameValidation
        })}
        values={{
          main_contact_email: "",
          external_id: "",
          main_contact_first_name: "",
          main_contact_last_name: ""
        }}
        buttonPosition="bottom right"
        inputFields={[
          [
            <Input name="main_contact_first_name" id="inviteMerchantPopup_first_name" label="Contact first name" maxLength={nameMaxChars} />,
            <Input name="main_contact_last_name" id="inviteMerchantPopup_last_name" label="Contact last name" maxLength={nameMaxChars} />,
          ],
          [
            <Input type="email" name="main_contact_email" id="inviteMerchantPopup_contact_email" label="Email" maxLength={emailMaxChars} />,
            <Input name="white_label_external_id" id="inviteMerchantPopup_external_id" label="External ID" maxLength={20} />,
          ]
        ]}
        isInsidePopup
        styleOverride={{
          inputError: {
            top: 78
          }
        }}
      />
    );
  };

  return <InvitePopup
    formTitle="Invite merchant via email"
    bulkUploadTitle="Bulk invite merchants via CSV file"
    disclaimerSend={`By clicking "Send", I affirm that I have permission to email this individual.`}
    onBulkSendClick={handleBulkInvitation}
    disclaimerBulkSend={`By clicking "Upload", I affirm that I have permission to email the individuals contained in the CSV file.`}
    reducerName={POPUPS.INVITE_MERCHANT}
    form={getForm()}
    template={merchantInvitesTemplate}
    onDownloadClick={() => {
      Analytics.track({ experience: "portal", screen: "invite_merchant", object: "bulk_import_file_download_link", action: "clicked" } as ITracking);
    }}
    onCloseClick={() => {
      Analytics.track({ experience: "portal", screen: "invite_merchant", object: "close_modal", action: "clicked" } as ITracking);
    }}
  />;
};

export default InviteMerchantPopup;