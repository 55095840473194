import React, { useEffect } from "react";
import appActions from "reducers/AppReducer";
import borrowerActions from "reducers/BorrowerReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { BorrowerExperience } from "content/flows";
import LocalStorage from "classes/LocalStorage";
import { useParams } from "react-router-dom";
import { MerchantHandler } from "actions/MerchantHandler";
import { InvitationValidator } from "content";
import { displayFullscreenLoading, getQueryString, hideFullscreenLoading, isNullOrUndefinedOrEmpty, tryParseNumber } from "utils/helpers";
import { IAddressInfo, IDTCFinancialInfo, IDTMFinancialInfo, ILoanInformation, IMainContactBorrower, IPersonalInformation, ISSN } from "./interfaces";

const WidgetWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useAppSelector(state => state.app.selectedLocation);
  const { merchantSlug, application_id, vuid } = useParams();
  const isWidget = useAppSelector(state => state.app.isWidget);

  useEffect(() => {
    displayFullscreenLoading();
    LocalStorage.clear(false);

    window.setTimeout(() => {
      //get querystring info
      const querystrings = getQueryString(window.location.href);

      const loanInformation: ILoanInformation = { location_id: LocalStorage.get<any>("selected_location")?.id || location?.id || null, loan_amount: null, loan_purpose: null };
      const personalInformation: IPersonalInformation = { first_name: null, last_name: null, date_of_birth: null, education_level: null };
      const contactInformation: IMainContactBorrower = { email: null, phone_number: null };
      const addressInformation: IAddressInfo = { address1: null, city: null, state: null, postal_code: null, property_status: null };
      const dtcFinancialInformation: IDTCFinancialInfo = { employment_status: null, annual_income: null, pay_frequency: null, credit_rating: null };
      const dtmFinancialInformation: IDTMFinancialInfo = { employment_status: null, annual_income: null, pay_frequency: null, credit_rating: null, company_name: null, supervisor_full_name: null, employment_start_date: null, employment_end_date: null, first_payment_date: null };
      const ssnInformation: ISSN = { ssn: null };

      dispatch(borrowerActions.setLoanInformation({
        ...loanInformation,
        ...(!isNullOrUndefinedOrEmpty(querystrings["loan_amount"]) && { loan_amount: tryParseNumber(querystrings["loan_amount"], false) }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["loan_purpose"]) && { loan_purpose: querystrings["loan_purpose"] })
      }));
      dispatch(borrowerActions.setPersonalInformation({
        ...personalInformation,
        ...(!isNullOrUndefinedOrEmpty(querystrings["first_name"]) && { first_name: querystrings["first_name"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["last_name"]) && { last_name: querystrings["last_name"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["education_level"]) && { education_level: querystrings["education_level"] }),
        // Even if provided date_of_birth is ignored for security reasons
        ...({ date_of_birth: null })
      }));
      dispatch(borrowerActions.setAddressInformation({
        ...addressInformation,
        ...(!isNullOrUndefinedOrEmpty(querystrings["address1"]) && { address1: querystrings["address1"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["city"]) && { city: querystrings["city"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["state"]) && { state: querystrings["state"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["postal_code"]) && { postal_code: querystrings["postal_code"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["property_status"]) && { property_status: querystrings["property_status"] })
      }));
      dispatch(borrowerActions.setContactInformation({
        ...contactInformation,
        ...(!isNullOrUndefinedOrEmpty(querystrings["email"]) && { email: querystrings["email"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["phone_number"]) && { phone_number: querystrings["phone_number"] })
      }));
      dispatch(borrowerActions.setDTCFinancialInformation({
        ...dtcFinancialInformation,
        ...(!isNullOrUndefinedOrEmpty(querystrings["employment_status"]) && { employment_status: querystrings["employment_status"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["pay_frequency"]) && { pay_frequency: querystrings["pay_frequency"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["annual_income"]) && { annual_income: tryParseNumber(querystrings["annual_income"], false) }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["credit_rating"]) && { credit_rating: querystrings["credit_rating"] })
      }));
      dispatch(borrowerActions.setDTMFinancialInformation({
        ...dtmFinancialInformation,
        ...(!isNullOrUndefinedOrEmpty(querystrings["employment_status"]) && { employment_status: querystrings["employment_status"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["pay_frequency"]) && { pay_frequency: querystrings["pay_frequency"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["annual_income"]) && { annual_income: tryParseNumber(querystrings["annual_income"], false) }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["credit_rating"]) && { credit_rating: querystrings["credit_rating"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["company_name"]) && { company_name: querystrings["company_name"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["supervisor_full_name"]) && { supervisor_full_name: querystrings["supervisor_full_name"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["employment_start_date"]) && { employment_start_date: querystrings["employment_start_date"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["employment_end_date"]) && { employment_end_date: querystrings["employment_end_date"] }),
        ...(!isNullOrUndefinedOrEmpty(querystrings["first_payment_date"]) && { first_payment_date: querystrings["first_payment_date"] })
      }));
      // Even if provided ssn is ignored for security reasons
      dispatch(borrowerActions.setSocialSecurity({
        ...ssnInformation
      }));

      /* Confirm external id and prepare for future additional data - Currently, only the external id is supplied,
         but this object is designed to accommodate any extra information required by our clients. */
      if (querystrings["external_id"]) {
        dispatch(borrowerActions.setAdditionalInformation({
          external_id: querystrings["external_id"]
        }));
      }

      if (merchantSlug) {
        window.localStorage.setItem('merchant-slug', merchantSlug);
        MerchantHandler().get(merchantSlug).then(() => {
          dispatch(appActions.setIsWidget(true));
          hideFullscreenLoading();
        });
      } else {
        hideFullscreenLoading();
      }
    }, 1000);
  }, []);

  const getComp = () => {
    if (application_id && vuid) {
      LocalStorage.save('application_id', application_id);
      LocalStorage.save('vuid', vuid);

      return <InvitationValidator type="APPLICATION" errorMessage="Application has expired" />;
    } else {
      return <BorrowerExperience />;
    }
  }

  return <>
    {isWidget && getComp()}
  </>;
}

export default WidgetWrapper;