import React from "react";
import styles from "./FeedbackPopup.module.scss";
import { SVG } from "common";

export const Baloons: React.FC = () => {
  const height = 300;

  return <div
    className={styles.backgroundImage}
    style={{ height: height, marginBottom: 40, marginTop: -10 }}>
    <SVG
      src="/assets/feedback/baloons.svg"
      title="Baloons"
      height={height}
    />
  </div>;
};

export default Baloons;
