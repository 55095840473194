import { AddressValidator } from "common";
import { Form, Input, SelectDropdown } from "common/form";
import { FormRef } from "common/form/Form";
import { IAddEntity, IAddressInfo, IUSPSAddress } from "common/interfaces";
import React, { useRef, useState } from "react";
import { statesArray } from "utils/constants";
import { convertStringNullsToNull, IAddressInfo2IUSPSAddress } from "utils/helpers";
import { AddressValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";

const BusinessMainAddressForm: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const [typedAddress, setTypedAddress] = useState<any>(null);
  const [addressToVerify, setAddressToVerify] = useState<any>(null);
  const [verifyButtonEnabled, setVerifyButtonEnabled] = useState<boolean>(true);

  const formRef = useRef<FormRef>(null);

  const handleAddressVerification = (data: any) => {
    const addressToVerify: IUSPSAddress = IAddressInfo2IUSPSAddress(data);
    setAddressToVerify(addressToVerify);
  }

  const handleNext = (address: IUSPSAddress) => {
    // combine data (primarily manage mulitple locations field) with selected address
    const values = formRef?.current?.getValues();
    const combinedData = { ...values, ...address };
    onNext(combinedData);
  }

  const setAddressIsVerified = (verified: boolean) => {
    setVerifyButtonEnabled(!verified)
  }

  // so you can detect a change to enable / disable verify button
  const handleChange = (data: IAddressInfo) => {
    const typedAddress: IUSPSAddress = IAddressInfo2IUSPSAddress(data);
    convertStringNullsToNull(typedAddress);
    setTypedAddress(typedAddress);
  }

  return <Form
    id="addMerchantPopup_mainBussinessAddress"
    buttonPosition="side right"
    onFormSubmit={handleAddressVerification}
    title="Add merchant • Business main address"
    submitBtnText="Verify"
    validationSchema={Schema({
      ...AddressValidation,
      manageMultipleLocations: SimpleRequireValidation
    })}
    values={initialValues}
    inputFields={[
      [
        <Input type="text" name="address1" id="addMerchantPopup_address1" label="Address*" />,
        <Input type="text" name="city" id="addMerchantPopup_city" label="City*" />
      ],
      [
        <SelectDropdown
          id="addMerchantPopup_state"
          label="State*"
          name="state"
          placeholder="Select"
          selectOptions={statesArray} />,
        <Input type="text" name="postal_code" id="addMerchantPopup_postal_code" label="Postal code*" maxLength={5} />
      ],
      [
        <SelectDropdown
          id="addMerchantPopup_manageMultipleLocations"
          label="Manage multiple locations?*"
          name="manageMultipleLocations"
          placeholder="Select"
          selectOptions={[{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]}
          tooltip="Managing multiple locations allows you to assign users and applicants to individual locations. This is not recommended for small teams or businesses." />,
      ]
    ]}
    isInsidePopup
    endOfFormJSX={addressToVerify && typedAddress && <AddressValidator setAddressIsVerified={setAddressIsVerified} addressToVerify={addressToVerify} typedAddress={typedAddress} handleNext={handleNext} />}
    buttonDisabled={!verifyButtonEnabled}
    onFormChange={handleChange}
    ref={formRef}
  />;
}

export default BusinessMainAddressForm;
