import _ from "lodash";
import store from "reducers/Store";
import appActions from "reducers/AppReducer";
import { useAppSelector } from "reducers/Hooks";
import React from "react";
import Log from "./Log";

/** 
 * @description Available triggers.
 * */
export const EVENTS = {
  USER_UPDATED: "USER_UPDATED",
  USER_ACCOUNT_UPDATED: "USER_ACCOUNT_UPDATED",
  LOCATION_UPDATED: "LOCATION_UPDATED",
  MERCHANT_UPDATED: "MERCHANT_UPDATED",
  PARTNER_UPDATED: "PARTNER_UPDATED",
  NOTIFICATIONS_UPDATED: "NOTIFICATIONS_UPDATED",
  INVITATION_UPDATED: "INVITATION_UPDATED",
  WHITELABEL_UPDATED: "WHITELABEL_UPDATED",
  LOAN_APP_UPDATED: "LOAN_APP_UPDATED",
  TABLE_FILTER_APPLIED: "TABLE_FILTER_APPLIED",
  BULK_LOCATIONS_UPLOAD: "BULK_LOCATIONS_UPLOAD",
  BUSINESS_MODEL_UPDATED: "BUSINESS_MODEL_UPDATED",
  DTM_APPLICATION_FINALIZED: "DTM_APPLICATION_FINALIZED",
  MERCHANT_RETREIVED: "MERCHANT_RETREIVED",
}

type eventTrigger = typeof EVENTS[keyof typeof EVENTS];

/** 
 * @class Observer
 * @description Triggers and listen for events.
 * */
export default class Observer {
  /** 
   * @description Use this hook to listen to events.
   * @param {eventTrigger} event Use one of the EVENTS. import Observer, { EVENTS } from "classes/Observer"; 
   * @returns Observer value.
   * @example import Observer, { EVENTS } from "classes/Observer"; 
   * 
   * const event = Observer.useObserver(EVENTS.USER_CREATED);
   * 
   * useEffect(() => {
   *  // Do whatever you want when the event is triggered.
   * }, [event]);
   * */
  static useObserver = (event: eventTrigger) => {
    const observer = useAppSelector(state => state.app.observers[event]);
    const [value, setValue] = React.useState(observer);

    React.useEffect(() => {
      if (observer === value) return;
      Log.info(`${event} triggered`);
      setValue(observer);
    }, [observer]);

    return value;
  }

  /** 
    * @description Trigger an event.
    * @param {eventTrigger} event Use one of the EVENTS. import Observer, { EVENTS } from "classes/Observer";
    * @example import Observer, { EVENTS } from "classes/Observer";
    * 
    * Observer.trigger(EVENTS.USER_CREATED);
    * */
  static trigger = (event: eventTrigger) => {
    store.dispatch(appActions.triggerEvent(event));
  }

  /** 
   * @description DO NOT USE THIS. This function should only be used by the reducer.
   * @returns List of events.
   * */
  static generateReducerInitialValues = () => {
    const obj: any = {};
    _.forOwn(EVENTS, value => {
      obj[value] = 1;
    });
    return obj;
  }
}