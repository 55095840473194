// used in WL settings - new view
import Landing from "common/Landing";
import React from "react";
import { useAppSelector } from "reducers/Hooks";

const LandingPageContent: React.FC = () => {
  const whitelabel = useAppSelector(state => state.whitelabel);

  return <>
    <div className="logoPreview" style={{ border: "1px solid var(--primaryColor20Solid)" }}>
      <Landing
        id="landingPreview"
        title={`Give your customers the power of choice with ${whitelabel?.name}`}
        subtitle={`Don't let a lack of financing options hold your business back! We provide access to over 20 lenders to help you convert more customers.`}
        hero="/assets/images/whitelabel_default.png"
        type="WHITELABEL"
        preview={true}
        showOnlyAboveFold={false}
        merchantLogoPreview={null}
        merchantInitials={null}
        titleBelowFold="Unlock more sales with our flexible financing solutions"
        feat1="Terms up to 144 months"
        feat2="Rates starting at 5.99%"
        feat3="Loans up to $100,000"
        feat4="Access to 20+ lenders"
        stepsTitle="How it works"
        step1="Your customer fills out a simple, mobile-friendly application"
        step2="A real-time decision is made from 20+ lenders"
        step3="Your customer chooses the offer that best fits their budget"
        step4="Once completed, your customer will be funded directly"
      />
    </div>
  </>;
}

export default LandingPageContent;