import moment, { Moment } from "moment";

export default class EnableCache {
  private static cache = new Map<string, { value: any, expiration: Moment, onExpiration: () => void }>();
  /**
   * @summary Retrieves a value from Cache.
   * @param {string} key - The key to look up in cache.
   * @returns The value, or null if the key was not found.
   */
  static get<T>(key: string): T {
    const unit = EnableCache.cache.get(key);
    if (!unit || unit.expiration < moment()) {
      return null;
    }
    return unit.value as T;
  };

  /**
   * @summary Saves object to cache using the specified key.
   * @param {string} key - The key to use for the item.
   * @param {any} obj - The object to save.
   * @param {number} duration - Expiration in milliseconds. Defaults to 60000.
   * @param {function} onExpiration Run this function when cache expires.
   */
  static save(key: string, obj: any, duration: number = 60000, onExpiration: () => void = () => { }): void {
    EnableCache.cache.set(key, { value: obj, expiration: moment().add(duration, 'millisecond'), onExpiration });
  };

  static remove(key: string): void {
    EnableCache.cache.delete(key);
  }

  /**
   * @summary Remove expired units.
   */
  static checkExpirations(): void {
    EnableCache.cache.forEach((unit, key) => {
      if (unit.expiration < moment()) {
        EnableCache.cache.delete(key);
        if (unit.onExpiration) {
          unit.onExpiration();
        }
      }
    });
  };

  /**
   * @summary Clears all items from the cache.
   */
  static clear(): void {
    EnableCache.cache.clear();
  };
}

window.setInterval(() => {
  // runs every 30 seconds
  EnableCache.checkExpirations();
}, 30000);