import * as React from "react";
import { addYears } from "date-fns";
import styles from "./Calendar.module.scss";
import { DateRangePicker, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "iconsax-react";
import { AppIcon, Button } from "./";
import { preventTextSelection } from "utils/helpers";
import moment from "moment";

export interface IDateRange {
  startDate?: Date;
  endDate?: Date;
}

export const calendarInitialState: IDateRange = {
  startDate: null,
  endDate: null,
}

interface ICalendarRange {
  showCalendarVariation: boolean;
  onChange: (dates: IDateRange) => void;
  className?: any;
  openDateRangeOnMiddle?: boolean;
  title?: string;
  state: IDateRange;
  /** onDateRangeChange is used only for tracking - in comparison to onChange, this is not triggered on "Clear" button */
  onDateRangeChange?: (dates: IDateRange) => void;
}

const CalendarRange = (props: ICalendarRange) => {
  const initialState = props.state;
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [tempState, setTempState] = React.useState<IDateRange>(initialState);

  const middleStyle: React.CSSProperties = props.openDateRangeOnMiddle ? {
    position: "fixed",
    right: "30%",
    top: "10%",
  } : {};

  const renderButtons = () => {
    return <>
      <Button
        id="calendar_close"
        label="Close"
        variant="secondary"
        style={{ color: "var(--primaryVariantColor)", borderColor: "var(--primaryVariantColor)" }}
        small
        onClick={() => {
          setShowCalendar(false);
        }}
      />
      <Button
        id="calendar_clear"
        label="Clear"
        variant="secondary"
        small
        onClick={() => {
          setShowCalendar(false);
          props.onChange(calendarInitialState);
        }}
      />
      <Button
        id="calendar_apply"
        label="Apply"
        small
        onClick={() => {
          setShowCalendar(false);
          props.onChange(tempState);
          if (props.onDateRangeChange) {
            props.onDateRangeChange(tempState);
          }
        }}
      />
    </>;
  }

  return (
    <div className={styles.dateRangeContainer}>
      <div className={styles.dateRangeButton}>
        <div
          className={
            props.className
              ? `${styles.dateRangeBlock} ${props.className}`
              : styles.dateRangeBlock
          }
          onClick={() => setShowCalendar(!showCalendar)}
          onMouseDown={preventTextSelection}>
          <div className={styles.dateIcon}>
            <AppIcon color="var(--secondaryColor)" size={22} icon={Calendar} />
          </div>
          <div className={styles.dateRange}>
            {!props.state.startDate && !props.state.endDate && <>
              <span>All dates</span>
            </>}
            {props.state.startDate && props.state.endDate && <>
              <span>
                {props.state.startDate ? moment(props.state.startDate).format('MM/DD/YYYY') : ""}
              </span>{" "}
              <span>- </span>
              <span>
                {props.state.endDate ? moment(props.state.endDate).format('MM/DD/YYYY') : ""}
              </span>
            </>}
          </div>
        </div>
      </div>

      {showCalendar && (
        props.showCalendarVariation ? (
          <div className={styles.dateRangeModal} style={middleStyle}>
            <h2>{props.title}</h2>
            <DateRange
              onChange={item => {
                setTempState({
                  startDate: item.range1.startDate,
                  endDate: item.range1.endDate
                } as IDateRange);
              }}
              moveRangeOnFirstSelection={false}
              editableDateInputs
              ranges={[{ ...tempState }]}
              direction="vertical"
              showDateDisplay={false}
              weekStartsOn={1}
              minDate={addYears(new Date(), -2)}
              scroll={{ enabled: true }}
            />
            <div className={`${styles.dateRangeBtn}`}>
              {renderButtons()}
            </div>
          </div>
        ) : (
          <div className={styles.dateRangeModal}>
            <DateRangePicker
              onChange={item => {
                setTempState({
                  startDate: item.range1.startDate,
                  endDate: item.range1.endDate
                } as IDateRange);
              }}
              moveRangeOnFirstSelection={false}
              editableDateInputs
              ranges={[{ ...tempState }]}
              direction="vertical"
              showDateDisplay={false}
              weekStartsOn={1}
              minDate={addYears(new Date(), -2)}
              scroll={{ enabled: true }}
            />
            <div className={`${styles.dateRangeBtn} ${styles.dateRangeBtnVariant}`}>
              {renderButtons()}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default CalendarRange;
