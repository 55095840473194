import React from "react";
import Funnel from "./Funnel";

interface IApplicationFunnel {
  data: any;
}

const ApplicationFunnel: React.FC<IApplicationFunnel> = ({ data }) => {
  return <div>
    <Funnel
      type="Submitted"
      total={data && data[0]?.APPLICATION_SUBMITTED?.applications}
      unique={data && data[0]?.APPLICATION_SUBMITTED?.applicants}
      emptyImgSrc={"/assets/dashboard/empty_funnel_submitted.svg"}
      isLoading={data === null}
    />
    <Funnel
      type="Offered"
      total={data && data[1].OFFERED.applications}
      unique={data && data[1].OFFERED.applicants}
      emptyImgSrc={"/assets/dashboard/empty_funnel_offered.svg"}
      isLoading={data === null}
    />
    <Funnel
      type="Clicked"
      total={data && data[2].CLICKED.applications}
      unique={data && data[2].CLICKED.applicants}
      emptyImgSrc={"/assets/dashboard/empty_funnel_clicked.svg"}
      isLoading={data === null}
    />
    <Funnel
      type="Funded"
      total={data && data[3].FUNDED.applications}
      unique={data && data[3].FUNDED.applicants}
      emptyImgSrc={"/assets/dashboard/empty_funnel_funded.svg"}
      isLoading={data === null}
    />
  </div>;
}

export default ApplicationFunnel;