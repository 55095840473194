import styles from "./Container.module.scss";
import { ReactNode } from "react";

interface IContainer {
  children?: ReactNode;
  className?: any;
  style?: any;
}

const Container: React.FC<IContainer> = ({
  children,
  className,
  style,
}) => {
  return (
    <div
      className={`${styles.innerWindowContainer} ${className}`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default Container;
