import React from "react";
import { useAppSelector } from "reducers/Hooks";
import styles from "./FullscreenLoading.module.scss";
import LoadingCircle from "./parts/LoadingCricle";

const FullscreenLoading: React.FC = () => {
  const loading = useAppSelector(state => state.animation.fullScreenLoading);
  return <>
    {loading && <div
      className={styles.loadingContainer}
      style={{ display: loading.isOpen ? 'flex' : 'none' }}>
      <div className={styles.imageContainer}>
        <LoadingCircle />
        <p className={styles.text}>{loading.text || 'Loading'}</p>
      </div>
    </div>}
  </>;
}

export default FullscreenLoading;