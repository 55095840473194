import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    pages: {
      login: {
        title: "Log in",
        forgotPassword: "Forgot password?"
      }
    },
    common: {
      email: "Email",
      password: "Password",
      help: "Help"
    },
    validation: {
      requiredField: "This is a required field",
      validEmail: "Email must be valid",
      passwordMinLenght: "Password must be at least 8 characters",
      exactlyOneFieldRequired: "Exactly one field is required"
    },
    notAvailable: "Not available",
    advertiserDisclosure: `The offers that appear are from companies which {whitelabelName} and its partners receive compensation. This compensation may influence the selection, appearance, and order of appearance of the offers listed below. However, this compensation also facilitates the provision by {whitelabelName} of certain services to you at no charge. The offers shown below do not include all Financial Services companies or all of their available product and service offerings.`,
    offersDisclaimer: "Lenders who do not appear here may not have been able to determine whether you qualified for a personal loan based on the information you provided. No credit decision has been made. You may still qualify for a personal loan from our partners.",
    representativeExample: `Representative Example: If you borrow $5,000 on a 36 month repayment term and at a 10% APR, the monthly repayment will be $161.34. Total repayment will be $5,808.24. Total interest paid will be $808.24.\nAnnual Percentage Rate (APR) Disclosure: The Annual Percentage Rate is the rate at which your loan accrues interest. It is based upon the amount of your loan, the cost of the loan, term of the loan, repayment amounts and timing of payments and payoff. By law, the lender or lending partner must show you the APR before you enter into the loan. States have laws that may limit the APR that the lender or lending partner can charge you. Rates will vary based on your credit worthiness, loan size, amongst other variables, with the lowest rates available to customers with excellent credit. Minimum and maximum loan amounts and APRs may vary according to state law and lender or lending partner. We recommend that you read the lender’s and/or lending partner’s personal loan terms and conditions in full before proceeding for any loan.`,
    partnerGuaranteeDisclosure: "All rates, fees, and terms are presented without guarantee and are subject to change pursuant to each provider's discretion and may not be available in all states or for all types of loans. There is no guarantee you will be approved or qualify for the advertised rates, fees, or terms presented. We do not guarantee acceptance into any particular program or specific terms or conditions with any financial provider; approval standards are established and maintained solely by financial providers. We do not guarantee that the rates offered include the lowest rates available in the market or the rate that will ultimately be charged.  This website does not include all lending companies or all available lending offers that may be available to you.",
  },
  es: {
    pages: {
      login: {
        title: "Log in",
        forgotPassword: "Forgot password?"
      }
    },
    common: {
      email: "Email",
      password: "Password",
      help: "Help"
    },
    validation: {
      requiredField: "This is a required field",
      validEmail: "Email must be valid",
      passwordMinLenght: "Password must be at least 8 characters"
    },
    notAvailable: "Not available",
    advertiserDisclosure: `The offers that appear are from companies which {whitelabelName} and its partners receive compensation. This compensation may influence the selection, appearance, and order of appearance of the offers listed below. However, this compensation also facilitates the provision by {whitelabelName} of certain services to you at no charge. The offers shown below do not include all Financial Services companies or all of their available product and service offerings.`,
    offersDisclaimer: "Lenders who do not appear here may not have been able to determine whether you qualified for a personal loan based on the information you provided. No credit decision has been made. You may still qualify for a personal loan from our partners.",
    representativeExample: `Representative Example: If you borrow $5,000 on a 36 month repayment term and at a 10% APR, the monthly repayment will be $161.34. Total repayment will be $5,808.24. Total interest paid will be $808.24.\nAnnual Percentage Rate (APR) Disclosure: The Annual Percentage Rate is the rate at which your loan accrues interest. It is based upon the amount of your loan, the cost of the loan, term of the loan, repayment amounts and timing of payments and payoff. By law, the lender or lending partner must show you the APR before you enter into the loan. States have laws that may limit the APR that the lender or lending partner can charge you. Rates will vary based on your credit worthiness, loan size, amongst other variables, with the lowest rates available to customers with excellent credit. Minimum and maximum loan amounts and APRs may vary according to state law and lender or lending partner. We recommend that you read the lender’s and/or lending partner’s personal loan terms and conditions in full before proceeding for any loan.`,
    partnerGuaranteeDisclosure: "All rates, fees, and terms are presented without guarantee and are subject to change pursuant to each provider’s discretion and may not be available in all states or for all types of loans. There is no guarantee you will be approved or qualify for the advertised rates, fees, or terms presented. This website does not include all lending companies or all available lending offers that may be available to you.",
  }
});

export default strings;