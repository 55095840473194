import React from "react";
import { SVG } from "common";

const SvgTest: React.FC = () => {
  return (
    <SVG
      src="/assets/images/merchantLandingBanner.svg"
      title="Empty Merchant Activity"
      // height={innerWidth * 0.23}
      width={850}
      height={300}
      fill={"#555555"}
      wrapperStyle={{
        position: 'absolute',
        // bottom: '-50px',
        // right: `${600}px`,
        // width: '100px',
        // height: '100px',
        backgroundColor: "red",
      }}
    />
  );
};

export default SvgTest;
