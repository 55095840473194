import Analytics from "classes/Analytics";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IPageTracker {
  children: any;
}

const PageTracker: React.FC<IPageTracker> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    Analytics.setPage(location.pathname)
  }, [location]);
  return <>
    {children}
  </>;
}

export default PageTracker;