import SVG from "common/SVG";
import React from "react";
import styles from "./ProgressChart.module.scss";
import { formatDashboardRate } from "utils/formatters";

export interface IProgressChart {
  percentage: number;
  title: string;
}

const ProgressChart: React.FC<IProgressChart> = ({ percentage, title }) => {
  const radius = 65;
  const stroke = 12;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - ((percentage || 0) / 100) * circumference;

  if (percentage !== null && percentage !== undefined /* it can be 0 though */) {
    return <div className={styles["progress-chart-wrapper"]}>
      <div style={{ position: 'relative', width: `${radius * 2}px`, height: `${radius * 2}px` }}>
        <svg height={radius * 2} width={radius * 2}>
          <circle
            className={styles["progress-chart-background"]}
            strokeWidth={stroke}
            strokeDashoffset={strokeDashoffset}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            className={styles["progress-chart-foreground"]}
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            strokeDashoffset={strokeDashoffset}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            transform={`rotate(90 ${radius} ${radius})`}
          />
        </svg>
        <div className={styles["progress-chart-percentage"]}>
          {formatDashboardRate(percentage)}%
        </div>
      </div>
      <div className={styles["progress-chart-title"]}>
        {title}
      </div>
    </div>;
  }
  // if percentage is not defined
  return <div className={styles["progress-chart-wrapper"]}>
    <div style={{ position: 'relative', width: `${radius * 2}px`, height: `${radius * 2}px` }}>
      <SVG src={`/assets/dashboard/empty_funnel_offered.svg`} height={"120px"} width={"120px"} />;
    </div>
    <div className={styles["progress-chart-title"]}>
      {title}
    </div>
  </div>;
}

export default ProgressChart;