import styles from "./TableContainer.module.scss";
import { Table, ExportButton, Link, Button } from "common";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { IHeader, IList } from "common/interfaces";
import { useParams } from "react-router-dom";
import { POPUPS } from "utils/constants";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import appActions from "reducers/AppReducer";
import popUpActions from "reducers/PopUpReducer";
import { TableRef } from "common/Table";
import Observer, { EVENTS } from "classes/Observer";
import { Grid } from "@mui/material";
import Analytics, { ITracking } from "classes/Analytics";
import { PartnerHandler } from "actions/PartnerHandler";
import DashboardHandler from "actions/DashboardHandler";
import { formatAPICurrency, formatAPIDate } from "utils/formatters";

const PartnerMerchants: React.FC = () => {
  const dispatch = useAppDispatch();
  const { partnerSlug } = useParams();
  const merchantFilter = useAppSelector(state => state.app.tableFilters.merchant);
  const tableFilterAppliedObserver = Observer.useObserver(EVENTS.TABLE_FILTER_APPLIED);

  const [partner, setPartner] = useState<any>(null);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const tableRef = useRef<TableRef>(null);
  const [preventPagination, setPreventPagination] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(appActions.resetMerchantFilter());
    };
  }, []);

  useEffect(() => {
    if (partnerSlug) {
      PartnerHandler().get(partnerSlug)
        .then(response => {
          setPartner(response);
        });
    }
  }, [partnerSlug]);

  useEffect(() => {
    tableRef.current.reloadData();
  }, [merchantFilter, tableFilterAppliedObserver]);

  const getList = async (next: string): Promise<IList> => {
    setLoadingData(true);
    let merchants = await DashboardHandler.getDashboardMerchants(next, { ...merchantFilter, partner: partnerSlug }, preventPagination);
    merchants.results = merchants.results.map(merchant => {
      return {
        ...merchant,
        name: <Link
          id={`merchant_${merchant?.slug}`}
          href={`/viewMerchant/${merchant?.slug}/accountInformation?bc=1`}
          onClick={() => {
            Analytics.track({ experience: "portal", screen: "partners_merchants", object: "merchant_record", action: "selected" } as ITracking, { merchant_id: merchant?.id });
          }}
          linkText={merchant?.name} />,
        created_at: formatAPIDate(merchant?.created_at),
        last_application_at: formatAPIDate(merchant?.last_application_at),
        requested_total: formatAPICurrency(merchant?.requested_total),
        funded_total: formatAPICurrency(merchant?.funded_total),
        created_by: merchant?.created_by || "Not available"
      };
    });
    setLoadingData(false);
    return Promise.resolve(merchants);
  }

  const merchantsHeaders: Array<IHeader> = [
    { label: "Name", value: "name", size: 12 },
    { label: "Status", value: "status", size: 7 },
    { label: "# Active users", value: "active_user_count", size: 9, align: "center" },
    { label: "Created by", value: "created_by", size: 12 },
    { label: "Created", value: "created_at", size: 8 },
    { label: "Last app.", value: "last_application_at", size: 8 },
    { label: "Submited", value: "submitted_count", size: 7 },
    { label: "Funded", value: "funded_count", size: 6 },
    { label: "Requested", value: "requested_total", size: 9 },
    { label: "Funded", value: "funded_total", size: 9 },
  ];

  useEffect(() => {
    if (preventPagination) {
      tableRef?.current?.reloadData();
    }
  }, [preventPagination]);

  useEffect(() => {
    if (preventPagination) {
      window.DownloadCSV(tableData, merchantsHeaders as Array<any>, "Merchants.csv");
      setPreventPagination(false);
    }
  }, [tableData]);

  const exportAction = () => {
    setPreventPagination(true);
  }

  return <div className={styles.contentContainer}>
    <Container fluid>
      {<div className={styles.titleContainer}>
        <Grid container>
          <Grid item sm={12} md={3} lg={4}>
            {partner && <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{partner?.name} merchants</h2>}
          </Grid>
          <Grid item sm={12} md={9} lg={8} style={{ textAlign: "right" }}>
            <Button
              id="partnerMerchants_invitationStatus"
              label="Invitation status"
              variant="secondary"
              onClick={() => {
                Analytics.track({ experience: "portal", screen: "partners_merchants", object: "invitation_status_button", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup({ name: POPUPS.INVITATION_STATUS, message: "MERCHANTS" }));
              }}
              style={{ marginRight: 20 }} />
            {/* <FilterButton
              id="merchants_filter"
              onClick={() => {
                dispatch(popUpActions.openPopup({ name: POPUPS.TABLE_FILTER, message: { type: "MERCHANTS" } }));
              }}
              type="MERCHANT"
              style={{ marginRight: 20 }} TODO: Fix filter to work on this page
            /> */}
            <ExportButton
              id="partnerMerchants"
              onClick={() => {
                exportAction();
                Analytics.track({ experience: "portal", screen: "partners_merchants", object: "export_button", action: "clicked" } as ITracking);
              }}
              exporting={preventPagination}
              disabled={loadingData}
            />
          </Grid>
        </Grid>
      </div>}
    </Container>
    <Container fluid className={styles.tableContainer}>
      <Table
        id="partner_merchants"
        data={getList}
        headers={merchantsHeaders}
        onUpdate={setTableData}
        tableBodyStyle={{ minWidth: 1200 }}
        ref={tableRef}
        maxHeight={450}
      />
    </Container>
  </div >;
};

export default PartnerMerchants;
