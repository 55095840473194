import React, { ReactElement, useEffect, useMemo } from "react";
import { Stepper, HeaderWL, HelpButton, ReCaptchaDisclaimer } from "common";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./NewUser.module.scss";
import { Done, UserAccount } from "./forms";
import { getFlowStep } from "utils/helpers";
import { IStepper } from "common/interfaces";
import { useNavigate, useLocation } from "react-router-dom";
import Analytics, { ITracking } from "classes/Analytics";

const User: React.FC = () => {
  const [step, setStep] = React.useState(0);
  const STEPS: Array<ReactElement<IStepper>> = useMemo(() => [
    <UserAccount path="UserAccount" screen="create_user_account" />,
    <Done path="Done" screen="create_user_account_done" />
  ], []);

  const navigate = useNavigate();
  const location = useLocation();

  const stepHandler = (moveNext: Boolean) => {
    let newStep = moveNext ? Math.min(step + 1, STEPS.length - 1) : Math.max(step - 1, 0);
    setStep(newStep);
    goTo(STEPS[newStep].props.path);
  };

  useEffect(() => {
    setStep(getFlowStep(STEPS, 2));
    if (step === 0) {
      goTo(STEPS[step].props.path);
    }
  }, []);

  useEffect(() => {
    setStep(getFlowStep(STEPS, 2));
  }, [location]);

  const goTo = (path: string) => {
    if (window.location.pathname.toLowerCase() !== `/newUser/${path}`.toLowerCase()) {
      navigate(`/newUser/${path}`);
    }
  }

  return (
    <>
      <HeaderWL />
      <main className={styles.partnerInvitationContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col>
              <HelpButton type="MERCHANT" onClick={() => {
                Analytics.track({ experience: "portal_login", screen: "create_user_account", object: "help_link", action: "clicked" } as ITracking);
              }} />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer}>
          <Stepper steps={STEPS} step={step} onNext={() => stepHandler(true)} onBack={() => stepHandler(false)} />
        </div>
        <ReCaptchaDisclaimer type="INVITATION_FLOWS" />
      </main>
    </>
  );
};

export default User;
