import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from './ActionConstants';
import { getStatus } from 'utils/helpers';
import { IFilter, IList, IPartnerBusinessInfo } from 'common/interfaces';
import Observer, { EVENTS } from 'classes/Observer';
import ErrorHandler from './ErrorHandler';
import store from 'reducers/Store';
import partnerActions from "reducers/PartnerReducer";
import Log from 'classes/Log';
import ApiCache from 'classes/ApiCache';
import { stripOutNonDigits } from 'utils/formatters';

export const PartnerHandler = (customErrorHandler?: typeof ErrorHandler) => {
  const errorHandler = customErrorHandler || ErrorHandler;

  /**
  * @description Add partner - business information.
  * @param {IPartnerBusinessInfo} data Business info.
  * @returns {Promise<any>} API response.
  * @emits EVENTS.PARTNER_CREATED
  */
  const create = async (data: IPartnerBusinessInfo): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`customer/partner/`, data);
      Observer.trigger(EVENTS.PARTNER_UPDATED);
      store.dispatch(partnerActions.update(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      errorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
  * @description Patch partner.
  * @param {string} slug Slug.
  * @param {any} data Partner data.
  * @returns {Promise<any>} API response.
  * @emits EVENTS.PARTNER_UPDATED
  */
  const patch = async (slug: string, data: any): Promise<any> => {
    try {
      if (data.max_loan_amount) {
        data.max_loan_amount = stripOutNonDigits(data.max_loan_amount);
      }
      if (data.main_contact_phone) {
        data.main_contact_phone = stripOutNonDigits(data.main_contact_phone);
      }
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`customer/partner/${slug}/`, data);
      Observer.trigger(EVENTS.PARTNER_UPDATED);
      store.dispatch(partnerActions.update(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      errorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
  * @description Finalize partner creation.
  * @param {string} slug Slug.
  * @returns {Promise<any>} API response.
  * @emits EVENTS.PARTNER_UPDATED
  */
  const finalize = async (slug: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`customer/partner/${slug}/finalize`);
      return Promise.resolve(response.data);
    } catch (error) {
      errorHandler(error);
      return Promise.reject(error);
    }
  }

  /** 
  * @description Get partner history.
  * @param {string} next Next page.
  * @param {string} slug Partner slug.
  * @returns {Promise<any>} API response.
  */
  const getHistory = async (next: string, slug: string): Promise<any> => {
    try {
      const url = next || `customer/partner/${slug}/history/?offset=0&limit=30`;
      const response = await ApiCache.get(url);
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);

    } catch (error) {
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Decline partner.
  * @param {string} slug Partner slug.
  * @param {string} reason Reason why the partner was declined.
  * @returns {Promise<any>} API response.
  */
  const decline = async (slug: string, reason: string): Promise<any> => {
    const activeStatus = await getStatus("Declined");
    return await patch(slug, { status: activeStatus, reason });
  }

  /**
  * @description Approve partner.
  * @param {string} slug Partner slug.
  * @returns {Promise<any>} API response.
  */
  const approve = async (slug: string): Promise<any> => {
    const activeStatus = await getStatus("Active");
    return await patch(slug, { status: activeStatus });
  }

  /**
  * @description Deactivate partner.
  * @param {string} slug Partner slug.
  * @returns {Promise<any>} API response.
  */
  const deactivate = async (slug: string): Promise<any> => {
    const deactivatedStatus = await getStatus("Deactivated");
    return await patch(slug, { status: deactivatedStatus });
  }

  /**
 * @description Get all of the white label partners.
 * @param {IFilter} filter Partner filter.
 * @returns API response.
 */
  const getAll = async (filter: IFilter): Promise<Array<any>> => {
    try {
      let url = `customer/partner/?offset=0&limit=1000000&simple=true`;
      url = url.Filter(filter);

      const response = await ApiCache.get(url);
      return Promise.resolve(response.results);

    } catch (error) {
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get partner details.
   * @param {string} slug Partner slug.
   * @param {boolean} isAnonymous Should this call ignore the access token?
   * @returns {Promise<any>} API response.
   */
  const get = async (slug: string, isAnonymous: boolean = false): Promise<any> => {
    if (!slug) {
      return Promise.resolve(null);
    }

    try {
      let response: any = null;
      if (!isAnonymous) {
        response = await ENABLE_FINANCING_API_INSTANCE.get(`customer/partner/${slug}`);
      } else {
        response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`customer/partner/${slug}`);
      }
      store.dispatch(partnerActions.update(response.data));
      return Promise.resolve(response.data);

    } catch (error) {
      if (error?.response?.data?.detail === "Not found.") {
        Log.warn('Partner not found');
        return Promise.resolve(null);
      }
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Delete incomplete partner.
  * @param {string} slug Partner slug.
  * @returns {Promise<any>} API response.
  */
  const deleteIncomplete = async (slug: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.delete(`customer/partner/${slug}/`);
      Observer.trigger(EVENTS.PARTNER_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get partner stats.
   * @param {string} slug Partner slug.
   * @param {boolean} isAnonymous Should this call ignore the access token?
   * * @param {IFilter} filter Merchant filter.
   * @returns {Promise<any>} API response.
   */
  const getStats = async (slug: string, isAnonymous: boolean = false, filter?: IFilter): Promise<any> => {
    if (!slug) {
      return Promise.resolve(null);
    }

    try {
      let response: any = null;
      let url = `dashboards/partner-metrics/${slug}/`;
      if (filter) {
        url = url.Filter(filter);
      }

      if (!isAnonymous) {
        response = await ApiCache.get(url, false);
      } else {
        response = await ApiCache.get(url, true);
      }
      store.dispatch(partnerActions.update(response));
      return Promise.resolve(response);

    } catch (error) {
      if (error?.response?.data?.detail === "Not found.") {
        Log.warn('Partner was not found');
        return Promise.resolve(null);
      }
      errorHandler(error);
      return Promise.reject(error.response);
    }
  }

  return {
    create,
    patch,
    getHistory,
    decline,
    approve,
    deactivate,
    getAll,
    get,
    deleteIncomplete,
    finalize,
    getStats,
  }
}