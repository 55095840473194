import styles from "./Settings.module.scss";
import React, { useEffect, useState } from "react";
import { ApplicationMenu, Aside, DashboardHeader, Sidebar } from "common";
import { Notifications, PartnerAccountInformation, PartnerHistory, PartnerMerchants, Users } from ".";
import { partnerDetailsMenu } from "utils/constants";
import { useAuth } from "auth/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { getStatus, handleInnerMenusBackButton } from "utils/helpers";
import { MerchantHandler } from "actions/MerchantHandler";
import Observer, { EVENTS } from "classes/Observer";
import { PartnerHandler } from "actions/PartnerHandler";
import InnerPopups from "content/popups/InnerPopups";
import PartnerAccountInformation2 from "./PartnerAccountInformation2";
import { useFlag } from "hooks/useFlag";
import usePartner from "hooks/usePartner";
import { PartnerTabType } from "common/types";

interface IViewPartner {
  view: string;
}

const ViewPartner: React.FC<IViewPartner> = ({ view }) => {
  const { partnerSlug } = useParams();
  const [viewSettings, setViewSettings] = useState(view || "accountInformation");
  const partner = usePartner();
  const partnerUpdated = Observer.useObserver(EVENTS.PARTNER_UPDATED);
  const navigate = useNavigate();
  const user = useAuth()?.user;
  const [pendingStatus, setPendingStatus] = useState<number | null>(null);
  const [deactivatedStatus, setDeactivatedStatus] = useState<number | null>(null);
  const [detailsMenu, setDetailsMenu] = useState<Array<any>>(null);
  const [merchants, setMerchants] = useState(null);

  const newPartnerViewEnabled = useFlag("new-partner-view");

  useEffect(() => {
    if (partner?.id && partner?.status === pendingStatus) {
      setDetailsMenu(partnerDetailsMenu.filter(item => { return item.id !== "partner_users" && item.id !== "partner_merchants" }));
    } else if (partner?.id && merchants === 0 && partner?.status === deactivatedStatus) {
      setDetailsMenu(partnerDetailsMenu.filter(item => { return item.id !== "partner_merchants" }));
    } else {
      setDetailsMenu(partnerDetailsMenu);
    }
  }, [partner, pendingStatus, deactivatedStatus, merchants]);

  useEffect(() => {
    if (partnerSlug || !partner?.id) {
      MerchantHandler().getAll(null)
        .then(merchantList => {
          setMerchants(merchantList.length);
        });
    }
  }, [partnerSlug]);

  useEffect(() => {
    PartnerHandler().get(partnerSlug || partner?.slug);
  }, [partnerUpdated]);

  useEffect(() => {
    if (user?.user_type === "PARTNER") {
      navigate('/settings/accountInformation');
    }
  }, [user]);

  useEffect(() => {
    getStatus("Pending").then(status => { setPendingStatus(status); });
    getStatus("Deactivated").then(status => { setDeactivatedStatus(status); });
    return handleInnerMenusBackButton(partnerDetailsMenu.map(item => { return item.href }), setViewSettings);
  }, []);

  return <main className={styles.settingsPageContainer}>
    <Sidebar />
    <Aside>
      <DashboardHeader />
      <InnerPopups />
      {newPartnerViewEnabled && <PartnerAccountInformation2 view={view as PartnerTabType} />}

      {newPartnerViewEnabled === false && <>
        {viewSettings === "accountInformation" && <PartnerAccountInformation />}
        {viewSettings === "notifications" && <Notifications />}
        {viewSettings === "merchants" && <PartnerMerchants />}
        {viewSettings === "users" && <Users />}
        {viewSettings === "history" && <PartnerHistory />}
        {partner && detailsMenu && <ApplicationMenu
          onClick={(val: string) => {
            setViewSettings(val);
            if (partnerSlug) {
              navigate(`/viewPartner/${partnerSlug}/${val}`);
            }
          }}
          activeList={viewSettings}
          items={detailsMenu}
        />}
      </>}
    </Aside >
  </main >;
};

export default ViewPartner;
