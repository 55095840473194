// used in merchant and WL landing pages, LandingPagePreview, 3x LandingPageContent - for merchants, parters, WLs settings
import { Container, Row, Col } from "react-bootstrap";
import { LogoHeader, Footer, Link, AppIcon, ReCaptchaDisclaimer, PoweredBy, PreviewBanner, SVG } from ".";
import { Calendar, MoneySend, Activity, Verify, Icon } from "iconsax-react";
import styles from "./LandingPage.module.scss";
import { Grid, Hidden } from "@mui/material";
import { isSmallScreen } from "utils/helpers";
import Analytics, { ITracking } from "classes/Analytics";
import { ILanding, useLandingPage } from "./LandingPage.hooks";
import React from "react";
import useMerchant from "hooks/useMerchant";

const Landing: React.FC<ILanding> = (props) => {
  const landingPageHooks = useLandingPage(props);
  const merchant = useMerchant();
  return <div className={landingPageHooks.mediaClass} style={{ pointerEvents: props.preview ? "none" : "all", ...props.colors && landingPageHooks.customColors, ...landingPageHooks.getPreviewBackgroundWhole() }}>
    <LogoHeader preview={props.preview} type={props.type} merchantLogoPreview={props.merchantLogoPreview} merchantInitials={props.merchantInitials} width={landingPageHooks.actualWidth} />
    <main className={styles.landingPage} ref={landingPageHooks.mainRef}>
      <div className={styles.bannerContainer + (props.preview ? " " + styles.borderRadiusLarge : "")}>
        {props.type === "MERCHANT" && <Hidden smDown><PoweredBy isMobile={false} isPreview={false} width={landingPageHooks.actualWidth} /></Hidden>}
        {props.type === "MERCHANT" && <Hidden smUp><PoweredBy isMobile={true} isPreview={false} width={landingPageHooks.actualWidth} /></Hidden>}
        <span className={styles.topRightCornerLink}
          onClick={() => {
            if (props.type === "WHITELABEL" && landingPageHooks.user) {
              landingPageHooks.navigate(landingPageHooks.upperRightLinkUrlUser);
            } else if (props.type === "WHITELABEL") {
              Analytics.track({ experience: "merchant", screen: "landing_page", object: "login_link", action: "clicked" } as ITracking, null);
              landingPageHooks.navigate(landingPageHooks.upperRightLinkUrl);
            } else if (props.type === "MERCHANT") {
              landingPageHooks.navigate(landingPageHooks.upperRightLinkUrl);
              Analytics.track({ experience: "borrower", screen: "landing_page", object: "received_offers_link", action: "clicked" } as ITracking, null);
            }
          }}
          id={`${props.id}_upper_right_link`}>
          {landingPageHooks.upperRightLinkText}
        </span>
        <div className={styles.content} style={{ backgroundImage: `url(${props.type === "MERCHANT" ? props.hero : ""})`, overflow: "hidden" }}>
          <h1 style={{ color: "var(--textColor)" }}>{landingPageHooks.parseText(props.title, "title")}</h1>
          <p>{landingPageHooks.parseText(props.subtitle, "body")}</p>
          <Link
            id={`${props.id}_get_started`}
            className={styles.buttonPrimaryVariation}
            href={`${props.ctaLink}`}
            onClick={() => landingPageHooks.handleClick("top_cta_button")}
            linkText="Get Started" />
          {props.type === "WHITELABEL" && <div className={styles["wl-hero-image"]}>
            <SVG
              id="wl-hero-image"
              src="/assets/images/wlHero.svg"
              title="Hero"
              width="100%"
              height="100%"
            />
          </div>}
          {!isSmallScreen() && landingPageHooks.whitelabel?.merchant_page_layout === "POWERED_BY" && <div style={{ width: "100%", height: 60, marginBottom: 20 }}></div>}
        </div>
      </div>
      {/* if props.showOnlyAboveFold === undefined, show content */}
      {(!props.preview || props.showOnlyAboveFold === false) &&
        <div className={styles.secondSection} style={{ ...landingPageHooks.getPreviewBackgroundBottom() }}>
          {(!merchant || merchant?.landing_page_program !== "DTM") && <Container>
            <Row>
              <Col>
                <h3 style={{ marginBottom: 20 }}>{props.titleBelowFold}</h3>
                <Grid container columns={4} spacing={4}>
                  {[Calendar, MoneySend, Activity, Verify].map((feat: Icon, index: number) => <Grid sm={2} md={1} item key={`feat_${index}`} style={{ width: "100%" }}>
                    <div className={styles.featureBlock}>
                      <AppIcon color="var(--secondaryColor)" size={60} icon={feat} />
                      <h4>{props[`feat${index + 1}`]}</h4>
                    </div>
                  </Grid>)}
                </Grid>
              </Col>
            </Row>
          </Container>
          }
          <Container className={styles.howItWorkContainer}>
            <Row className={styles.howItWorkInner}>
              <Col className={styles.howItWorkCol}>
                <h3>{props.stepsTitle}</h3>
                <div className={styles.stepsWork}>
                  {[1, 2, 3, 4].map(step => <div key={`step_${step}`} className={styles.step}>
                    <p className={step === 1 ? styles.one : styles.step}>{step}</p>
                    <p>{props[`step${step}`]}</p>
                  </div>)}
                  <Link
                    id={`${props.id}_get_started`}
                    className={styles.buttonPrimary}
                    href={`${props.ctaLink}`}
                    onClick={() => landingPageHooks.handleClick("bottom_cta_button")}
                    linkText="Get Started" />
                </div >
              </Col >
              <Col className={styles.howItWorkImg}></Col>
            </Row >
          </Container >
        </div >
      }
    </main >
    {!props.preview && <>
      <Footer type={props.type}
        onWLLinkClick={() => landingPageHooks.handleClick("white_label_website_link")}
        onPrivacyPolicyClick={() => landingPageHooks.handleClick("privacy_policy_link")}
        onTermsOfServiceClick={() => landingPageHooks.handleClick("terms_service_link")}
        onAdvertiserDisclosureClick={() => landingPageHooks.handleClick("advertiser_disclosure_link")} />
      <ReCaptchaDisclaimer type="LANDING_PAGES" />
    </>}
    {props.preview && <PreviewBanner />}
  </div >;
};

export default Landing;
