import * as Yup from "yup";
import moment from "moment";
import { isNullOrUndefinedOrEmpty } from "utils/helpers";

const INVALID_DATE_MESSAGE = "Invalid date, please use MM/DD/YYYY.";
const INVALID_TODAY_PAST_DATE_MESSAGE = "Invalid date, date must be today or in the past.";
const INVALID_PAST_DATE_MESSAGE = "Invalid date, date must in the past.";
const DATE_REQUIRED_MESSAGE = "Date is required";
const DATE_FORMAT = "MM/DD/YYYY";

const isPastDate = (value: string, isTodayAllowed: boolean): boolean => {
  if (isNullOrUndefinedOrEmpty(value)) { return true; }
  const currentDate = moment();
  const dateToCheck = moment(value);
  if (isTodayAllowed) {
    return dateToCheck.isSameOrBefore(currentDate, 'day');
  } else {
    return dateToCheck.isBefore(currentDate, 'day');
  }
};

const isDateInRange = (value: string, minDate: Date, maxDate: Date): boolean => {
  if (isNullOrUndefinedOrEmpty(value)) { return true; }
  const dateValue = moment(value, DATE_FORMAT, true).toDate();
  return dateValue >= minDate && dateValue <= maxDate;
};

const isUSFormatDate = (value: string): boolean => {
  if (isNullOrUndefinedOrEmpty(value)) { return true; }
  const isValidDate = moment(value, DATE_FORMAT, true).isValid();
  return isValidDate;
};

export const BlankOrDateValidation = Yup.string()
  .test('is-us-date-format', INVALID_DATE_MESSAGE, isUSFormatDate);

export const RequireDateValidation = BlankOrDateValidation
  .required(DATE_REQUIRED_MESSAGE);

export const BlankOrTodayOrPastDateValidation = BlankOrDateValidation
  .test('is-today-or-past', INVALID_TODAY_PAST_DATE_MESSAGE, (value) => isPastDate(value, true));

export const RequireTodayOrPastDateValidation = BlankOrTodayOrPastDateValidation
  .required(DATE_REQUIRED_MESSAGE);

export const BlankOrDateInRangeValidation = (minDate: Date, maxDate: Date) => BlankOrDateValidation
  .test('is-in-range', `Date must be between ${moment(minDate).format('MM/DD/YYYY')} and ${moment(maxDate).format('MM/DD/YYYY')}`, (value) => isDateInRange(value, minDate, maxDate));

export const RequireDateInRangeValidation = (minDate: Date, maxDate: Date) => BlankOrDateInRangeValidation(minDate, maxDate)
  .required(DATE_REQUIRED_MESSAGE);

export const BlankOrPastDateValidation = BlankOrDateValidation
  .test('is-past', INVALID_PAST_DATE_MESSAGE, (value) => isPastDate(value, false));

export const RequirePastDateValidation = BlankOrPastDateValidation
  .required(DATE_REQUIRED_MESSAGE);