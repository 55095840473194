import React from "react";
import styles from "./LoadingBar.module.scss";
import { useAppSelector } from "reducers/Hooks";

interface ILoadingBar {
  styleOverride?: object;
}

const LoadingBar: React.FC<ILoadingBar> = ({ styleOverride }) => {
  const loadingBar = useAppSelector(state => state.animation.loadingBar);

  return <>
    {loadingBar.isFadingIn && <div className={`${styles.progressbarcontainer} fade ${loadingBar.isFadingIn ? 'show' : ''}`} style={styleOverride}>
      <div className={`${styles.progressbar} ${styles.active}`}></div>
    </div>}
  </>;
}

export default LoadingBar;