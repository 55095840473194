import { CloseIcon, Scrollable } from "common";
import { Input, Form } from "common/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { MainContactValidation, Schema } from "utils/validation/additionalValidation";
import {
  decypherApiErrorResponse,
  displayMiniFeedback, objectsAreEqual, parseMainContactInformation,
} from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import { MerchantHandler } from "actions/MerchantHandler";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { PartnerHandler } from "actions/PartnerHandler";
import { useLoadingBar } from "hooks/useLoadingBar";
import useMerchant from "hooks/useMerchant";
import usePartner from "hooks/usePartner";

const EditContactInformationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const { merchantSlug, partnerSlug } = useParams();
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchant = useMerchant();
  const partner = usePartner();

  const [initialValues, setInitialValues] = useState({
    main_contact_first_name: "",
    main_contact_last_name: "",
    main_contact_email: "",
    main_contact_phone: "",
  });
  const [loading, setLoading] = useState<boolean>(true);
  useLoadingBar(!loading);

  useEffect(() => {
    if (user?.user_type === "WHITELABEL") {
      if (merchantSlug) {
        // This is a white label user looking at merchant main contact information
        setInitialValues(parseMainContactInformation(merchant));
        setLoading(false);
      } else if (partnerSlug) {
        // This is a white label user looking at partner main contact information
        setInitialValues(parseMainContactInformation(partner));
        setLoading(false);
      } else {
        // This is a white label user seeing their settings
        setInitialValues(parseMainContactInformation(whitelabel));
        setLoading(false);
      }
    } else if (user?.user_type === "MERCHANT") {
      // This is a merchant user seeing their settings
      setInitialValues(parseMainContactInformation(merchant));
      setLoading(false);
    } else if (user?.user_type === "PARTNER") {
      if (merchantSlug) {
        // This is a partner user looking at merchant main contact information
        setInitialValues(parseMainContactInformation(merchant));
        setLoading(false);
      } else {
        // This is a partner user seeing their settings
        setInitialValues(parseMainContactInformation(partner));
        setLoading(false);
      }
    }
  }, [user, merchant, partner]);

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const handleSubmit = async (data: any) => {
    if (!objectsAreEqual(data, initialValues)) {
      if (user?.user_type === "WHITELABEL") {
        if (merchantSlug) {
          const updatedMerchant = await MerchantHandler(displayErrors).patch(merchantSlug, data);
          Analytics.track({ experience: "portal", screen: "merchants_account_info", object: "main_contact_info", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
        } else if (partnerSlug) {
          const updatedPartner = await PartnerHandler(displayErrors).patch(partnerSlug, data);
          Analytics.track({ experience: "portal", screen: "partners_account_info", object: "main_contact_info", action: "updated" } as ITracking, { partner_id: updatedPartner?.id });
        } else {
          await WhiteLabelHandler.update(whitelabel.slug, data);
        }
      } else if (user?.user_type === "MERCHANT") {
        const updatedMerchant = await MerchantHandler(displayErrors).patch(merchant.slug, data);
        Analytics.track({ experience: "portal", screen: "settings_account_info", object: "main_contact_info", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
      } else if (user?.user_type === "PARTNER") {
        if (merchantSlug) {
          const updatedMerchant = await MerchantHandler(displayErrors).patch(merchantSlug, data);
          Analytics.track({ experience: "portal", screen: "merchants_account_info", object: "main_contact_info", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
        } else {
          const updatedPartner = await PartnerHandler(displayErrors).patch(partner.slug, data);
          Analytics.track({ experience: "portal", screen: "settings_account_info", object: "main_contact_info", action: "updated" } as ITracking, { partner_id: updatedPartner?.id });
        }
      }
      displayMiniFeedback("Main contact information was updated");
    } else {
      displayMiniFeedback("Nothing changed on the main contact information");
    }
    handleClose();
  }

  const displayErrors = (error: any): void => {
    const body = decypherApiErrorResponse(error);
    displayMiniFeedback(body, true);
  }

  return (
    <Popup isInner={true}>
      <>
        {!loading && <Scrollable>
          <CloseIcon id="editMainContactInformation_Popup" color="dark" />
          <Form
            id="editMainContactInformation_Popup"
            title="Edit main contact information"
            submitBtnText="Save"
            onFormSubmit={handleSubmit}
            validationSchema={Schema({
              ...MainContactValidation
            })}
            values={initialValues}
            buttonPosition="bottom right"
            inputFields={[
              [
                <Input name="main_contact_first_name" id="editMainContactInformationPopup_contact_first_name" label="First name" />,
                <Input name="main_contact_last_name" id="editMainContactInformationPopup_contact_last_name" label="Last name" />,
              ],
              [
                <Input type="email" name="main_contact_email" id="editMainContactInformationPopup_contact_email" label="Email" />,
                <Input name="main_contact_phone" id="editMainContactInformationPopup_contact_phone" label="Phone" mask="phone" />,
              ]
            ]}
            isInsidePopup
          />
        </Scrollable>}
      </>
    </Popup>
  );
};

export default EditContactInformationPopup;
