import SVG from "common/SVG";
import React from "react";

const PreviewBanner: React.FC = () => {

  return <SVG
    id="preview_banner"
    src="/assets/images/preview_banner.svg"
    title="Preview banner"
    width="100px"
    height="100px"
    wrapperStyle={{
      zIndex: 1,
      position: 'absolute',
      top: -5,
      right: -2
    }}
  />;
}

export default PreviewBanner;