import React from "react";
import styles from "../NewUser.module.scss";
import { Container, Confetti } from "common";
import { IStepper } from "common/interfaces";
import { useNavigate } from "react-router-dom";

const Done: React.FC<IStepper> = () => {
  const navigate = useNavigate();

  return (
    <Container className={styles.invitationFormInner}>
      <Confetti
        isPopup={false}
        buttonText="Continue to log in"
        buttonAction={() => navigate("/login")}>
        <>
          Amazing news!
          <br />
          You are all set up!
        </>
      </Confetti>
    </Container>
  );
};

export default Done;
