import { Form, Input, SelectDropdown, UploadFile } from "common/form";
import { IAddEntity, IDTMOwner } from "common/interfaces";
import React, { useEffect, useRef, useState } from "react";
import { OWNER_TITLES, statesArray } from "utils/constants";
import { containsOnlyAsterisks, isNullOrUndefined, numberToOrdinalText, removeFieldsWithOnlyAsterisks } from "utils/helpers";
import { AddressValidation, EmailValidation, NameValidation, PhoneValidation, Schema, SimpleRequireValidation, rangeValidation, getSSNValidation } from "utils/validation/additionalValidation";
import { RequireDateValidation, RequirePastDateValidation } from "utils/validation/dateValidation";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { UnderwritingHandler } from "actions/UnderwritingHandler";
import dtmApplicationActions from "reducers/DTMApplicationReducer";
import { FormRef } from "common/form/Form";
import { SVG } from "common";
import TempStore from "classes/TempStore";

// TODO: Add address verification
const OwnerInformation: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const dispatch = useAppDispatch();
  const dtmOwnerPosition = useAppSelector(state => state.dtmApplication.ownerPosition);
  const dtmOwnersNumber = useAppSelector(state => state.dtmApplication.ownersNumber);
  const generateEmptyOwner: IDTMOwner = { first_name: "", last_name: "", dob: "", ssn: "", email: "", phone: "", title: "", ownership_percent: "", drivers_license: "", drivers_license_expiration: "", drivers_license_state: "", address1: "", address2: "", city: "", state: "", zip_code: "", doc_drivers_license: null, drivers_license_dob: "" };
  const [ownerInitialValues, setOwnerInitialValues] = useState<IDTMOwner>(null);
  const formRef = useRef<FormRef>(null);
  const uploadFileRef = useRef(null);
  const applicationId = initialValues?.id;

  useEffect(() => {
    setOwnerInitialValues(null);
    uploadFileRef?.current && uploadFileRef.current.reset();
    UnderwritingHandler().getDTMOwners(applicationId)
      .then((result: Array<IDTMOwner>) => {
        let ownerData: IDTMOwner = { ...generateEmptyOwner };
        if (result.length > 0 && result.length >= dtmOwnerPosition) {
          ownerData = result[dtmOwnerPosition - 1];
        }
        var driversLicense = TempStore.get<File>(`owner${dtmOwnerPosition}`) || ownerData.doc_drivers_license;
        setOwnerInitialValues({ ...ownerData, doc_drivers_license: driversLicense ? "drivers_license.png" : null });
      });
  }, [dtmOwnerPosition]);

  const scrollToTopOfModal = () => {
    const modal = window.document.getElementById('dtmApplicationPopup_modal');
    if (modal) {
      modal.scrollTop = 0;
    }
  }

  const onFileSelection = (file: any) => {
    dispatch(dtmApplicationActions.addDoc({ key: `owner${dtmOwnerPosition}`, doc: file?.name }));
    TempStore.save<File>(`owner${dtmOwnerPosition}`, file);
  }

  const moveNext = async (data: IDTMOwner) => {
    removeFieldsWithOnlyAsterisks(data);
    if (isNullOrUndefined(data?.id)) {
      // create owner
      await UnderwritingHandler().createDTMOwner(applicationId, data, TempStore.get<File>(`owner${dtmOwnerPosition}`));
    } else {
      // update owner
      await UnderwritingHandler().updateDTMOwner(applicationId, data?.id, data, TempStore.get<File>(`owner${dtmOwnerPosition}`));
    }

    // Decide if move to the next owner info or jump back to the pop up next step
    const newOwnerPosition = dtmOwnerPosition + 1;
    if (newOwnerPosition > dtmOwnersNumber) {
      onNext({ ...initialValues });
    } else {
      formRef.current.reset();
      dispatch(dtmApplicationActions.setDtmOwnerPosition(newOwnerPosition));
    }
    scrollToTopOfModal();
  }

  return <>
    {isNullOrUndefined(ownerInitialValues) && <div style={{ paddingTop: 50, paddingBottom: 50 }}>
      <SVG src="/assets/images/loading.svg" width="50" height="50" />
    </div>}
    {!isNullOrUndefined(ownerInitialValues) && <Form
      id="dtm_application_owner_personal_information"
      ref={formRef}
      title={`${numberToOrdinalText(dtmOwnerPosition)} business owner information`}
      submitBtnText="Next"
      displayErrorBox={true}
      onFormSubmit={moveNext}
      validationSchema={Schema({
        first_name: NameValidation,
        last_name: NameValidation,
        dob: RequirePastDateValidation,
        ssn: getSSNValidation(containsOnlyAsterisks(ownerInitialValues?.ssn)),
        email: EmailValidation,
        phone: PhoneValidation,
        title: SimpleRequireValidation,
        ownership_percent: rangeValidation(1, 100),
        drivers_license: SimpleRequireValidation,
        drivers_license_expiration: RequireDateValidation,
        drivers_license_state: SimpleRequireValidation,
        address1: AddressValidation.address1,
        city: AddressValidation.city,
        state: AddressValidation.state,
        zip_code: AddressValidation.postal_code,
        doc_drivers_license: SimpleRequireValidation
      })}
      values={ownerInitialValues}
      buttonPosition="bottom right"
      inputFields={[{
        title: "Personal information",
        fields: [
          [
            <Input name="first_name" id="dtm_application_first_name" label="First name*" />,
            <Input name="last_name" id="dtm_application_last_name" label="Last name*" />,
          ],
          [
            <Input name="dob" id="dtm_application_dob" label="Date of birth*" mask="date" />,
            <Input type="password" name="ssn" id="dtm_application_ssn" label="Social security number (SSN)*" mask="ssn" />,
          ],
          [
            <Input name="email" id="dtm_application_email" label="Email*" />,
            <Input name="phone" id="dtm_application_phone" label="Phone number*" mask="phone" />,
          ],
          [
            <SelectDropdown name="title" id="dtm_application_title" label="Title*" selectOptions={OWNER_TITLES} />,
            <Input name="ownership_percent" id="dtm_application_ownership_percent" label="Business ownership*" inputPrefix="%" />,
          ],
        ]
      },
      {
        title: "Driver's license",
        fields: [
          [
            <Input name="drivers_license" id="dtm_application_drivers_license" label="Driver's license ID number*" />,
            <Input name="drivers_license_expiration" id="dtm_application_drivers_license_expiration" label="Exp date*" mask="date" />,
          ],
          [
            <SelectDropdown name="drivers_license_state" id="dtm_application_drivers_license_state" label="Issuing state or country*" selectOptions={statesArray} />,
            <UploadFile ref={uploadFileRef} filename={ownerInitialValues?.doc_drivers_license} name="doc_drivers_license" id="dtm_application_doc_drivers_license" label="Copy of the driver's license" onFileSelection={onFileSelection} displayLogo={false} />,
          ]
        ]
      },
      {
        title: "Home address",
        fields: [
          [
            <Input name="address1" id="dtm_application_address1" label="Address*" />,
            <Input name="city" id="dtm_application_city" label="City*" />,
          ],
          [
            <SelectDropdown name="state" id="dtm_application_state" label="State*" selectOptions={statesArray} />,
            <Input name="zip_code" id="dtm_application_zip_code" label="Zip code*" maxLength={5} />,
          ],
        ]
      }
      ]}
      isInsidePopup
    />};
  </>;
}

export default OwnerInformation;