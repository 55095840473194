import React from "react";
import styles from "./FeedbackPopup.module.scss";
import { SVG } from "common";

export const Hourglass: React.FC = () => {
  const height = 150;

  return <div
    className={styles.backgroundImage}
    style={{ height: height + 50, marginBottom: -20 }}>
    <SVG
      src="/assets/feedback/hourglass.svg"
      title="Hourglass"
      height={height}
    />
  </div>;
};

export default Hourglass;
