import { IMenuItem } from "common/interfaces";
import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "../";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";

const SidebarMenuItem: React.FC<IMenuItem> = ({ href, icon, label, id, onClick }) => {
  const whitelabel = useAppSelector(state => state.whitelabel);
  const dispatch = useAppDispatch();
  const location = useLocation().pathname.split("/");
  let firstSlug = "";
  if (location && location[1]) {
    firstSlug = "/" + location[1];
  }

  return <li style={{ textShadow: whitelabel?.dark_mode ? "0px 2px 4px rgba(50, 15, 122, 0.25)" : undefined }}>
    <Link
      id={id}
      href={href}
      onClick={onClick}
      beforeClickAction={() => {
        dispatch(popUpActions.closePopup());
      }}
      className={href.includes(firstSlug) ? "activeMenu" : "notActive"}>
      <div>
        {React.createElement(icon, { color: "var(--whiteTextColor)" }, null)}
      </div>
      <div>
        <p className="menu">{label}</p>
      </div>
    </Link>
  </li>;
};

export default SidebarMenuItem;
