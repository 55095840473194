import styles from "./TimeZoneMessage.module.scss";
import moment from "moment";

interface ITimeZoneMessage {
  timeZone?: string;
}

const TimeZoneMessage: React.FC<ITimeZoneMessage> = ({ timeZone }) => {

  let notation = `Dates and metrics are based on the UTC time zone (currently ${moment.utc(moment()).format('h:mm A')})`;
  if (timeZone) {
    notation = `All times displayed here are in ${timeZone} time zone (currently ${moment().format('h:mm A')})`;
  }

  return (
    <p className={styles.timeZoneNotation}>{notation}</p>
  );
};

export default TimeZoneMessage;
