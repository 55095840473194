import styles from "./ApplicationMenu.module.scss";
import { IApplicationMenu } from "common/interfaces";
import React from "react";

const ApplicationMenu: React.FC<IApplicationMenu> = ({
  activeList,
  onClick,
  items,
}) => {
  return (
    <div className={styles.menuContainer}>
      <ul>
        {items.map((item, index) => {
          return (
            <li
              id={`application-menu-item-${index}`}
              key={`application-menu-item-${index}`}
              className={
                activeList === item.href ? styles.active : styles.listContainer
              }
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                }
                onClick(item.href);
              }}
            >
              {React.createElement(item.icon, { size: 22, color: activeList === item.href ? "var(--secondaryColor)" : "var(--primaryTextColor)" })}
              <p>{item.label}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ApplicationMenu;
