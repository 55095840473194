// this isn't used anywhere on 18/07/2023

import { Button, CloseIcon } from "common";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";

const NewApplicationPopup: React.FC<IPopup> = () => {
  return (
    <Popup isInner={true} >
      <>
        <CloseIcon id="newApplicationPopup" color="dark" />
        <h2>New application</h2>
        <Button id="newApplicationPopup_sendLink" label="Send a link" type="button" />
        <Button id="newApplicationPopup_onThisDevice" label="On this device" type="button" />
      </>
    </Popup>
  );
};

export default NewApplicationPopup;
