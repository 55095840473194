import { CloseIcon, Scrollable } from "common";
import { Input, SelectDropdown, Form, EmptySpace } from "common/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { statesArray } from "utils/constants";
import { EstimatedSalesValidation, Schema } from "utils/validation/additionalValidation";
import { objectsAreEqual, fillInitialValues, displayMiniFeedback, isForPartnerGlobal } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import { MerchantHandler } from "actions/MerchantHandler";
import SystemHandler from "actions/SystemHandler";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { PartnerHandler } from "actions/PartnerHandler";
import { useLoadingBar } from "hooks/useLoadingBar";
import useMerchant from "hooks/useMerchant";
import usePartner from "hooks/usePartner";

const EditEstimatedSalesPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const { merchantSlug, partnerSlug } = useParams();
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchant = useMerchant();
  const partner = usePartner();

  const [annualSalesOptions, setAnnualSalesOptions] = useState([]);
  const [monthlyFinanceOptions, setMonthlyFinanceOptions] = useState([]);
  const [averageTicketOptions, setAverageTicketOptions] = useState([]);
  const [numberMerchantsOptions, setNumberMerchantOptions] = useState([]);
  const [partnerTypeOptions, setPartnerTypeOptions] = useState([]);

  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState<boolean>(true);
  useLoadingBar(!loading);

  const whiteLabelInitialValues = {
  };
  let merchantInitialValues = {
    annual_sales: "",
    monthly_financing_volume: "",
    average_ticket: "",
  };
  let partnerInitialValues = {
  }

  useEffect(() => {
    if (!initialValues) {
      if (user?.user_type === "WHITELABEL" && merchantSlug) {
        // This is a white label user looking at merchant account information
        setInitialValues(fillInitialValues({ ...merchantInitialValues, external_id: "" }, merchant));
      } else if (user?.user_type === "PARTNER" && merchantSlug) {
        // This is a partner user looking at merchant account information
        setInitialValues(fillInitialValues({ ...merchantInitialValues, external_id: "" }, merchant));
      } else if (user?.user_type === "WHITELABEL" && partnerSlug) {
        // This is a white label user looking at partner account information
        setInitialValues(fillInitialValues({ ...partnerInitialValues, external_id: "" }, partner));
      } else if (user?.user_type === "WHITELABEL") {
        // This is a white label user seeing their settings
        setInitialValues(fillInitialValues(whiteLabelInitialValues, whitelabel));
      } else if (user?.user_type === "MERCHANT") {
        // This is a merchant user seeing their settings
        setInitialValues(fillInitialValues(merchantInitialValues, merchant));
      } else if (user?.user_type === "PARTNER") {
        // This is a partner user seeing their settings
        setInitialValues(fillInitialValues(partnerInitialValues, partner));
      }
    } else {
      setInitialValues(null);
    }
  }, [user, merchant, partner]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMonthlyFinanceVolume(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getNumberOfMerchants(),
      SystemHandler.getPartnerTypes()
    ]).then(responses => {
      setAnnualSalesOptions(responses[0]);
      setMonthlyFinanceOptions(responses[1]);
      setAverageTicketOptions(responses[2]);
      setNumberMerchantOptions(responses[3]);
      setPartnerTypeOptions(responses[4]);
    });
  }, []);

  useEffect(() => {
    if (initialValues && averageTicketOptions.length > 0 && annualSalesOptions.length > 0 &&
      monthlyFinanceOptions.length > 0 && numberMerchantsOptions.length > 0 &&
      partnerTypeOptions.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [initialValues, averageTicketOptions,
    annualSalesOptions, monthlyFinanceOptions, numberMerchantsOptions, partnerTypeOptions])

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const isForPartner = () => {
    return isForPartnerGlobal(user, partnerSlug, merchantSlug);
  }

  const handleSubmit = async (data: any) => {
    let payload = data;// for whitelabel
    if (user?.user_type === "MERCHANT" || (user?.user_type === "WHITELABEL" && merchantSlug) || (user?.user_type === "PARTNER" && merchantSlug)) {
      payload = {
        ...data,
        annual_sales: parseInt(data.annual_sales),
        average_ticket: parseInt(data.average_ticket),
        monthly_financing_volume: parseInt(data.monthly_financing_volume),
      }
    } else if (isForPartner()) {
      payload = {
        ...payload,
        merchant_number: parseInt(data.merchant_number),
        type: parseInt(data.type)
      }
    }


    if (!objectsAreEqual(payload, initialValues)) {
      if (user?.user_type === "MERCHANT" || (user?.user_type === "WHITELABEL" && merchantSlug) || (user?.user_type === "PARTNER" && merchantSlug)) {
        const response = await MerchantHandler().patch(merchantSlug || merchant?.slug, data);
        if (response) {
          Analytics.track({ experience: "portal", screen: user?.user_type === "WHITELABEL" && merchantSlug ? "merchants_account_info" : "settings_account_info", object: "business_info", action: "updated" } as ITracking, { merchant_id: response?.id });
        }
      } else if (isForPartner()) {
        const response = await PartnerHandler().patch(partnerSlug || partner?.slug, data);
        Analytics.track({ experience: "portal", screen: user?.user_type === "WHITELABEL" && partnerSlug ? "partners_account_info" : "settings_account_info", object: "business_info", action: "updated" } as ITracking, { partner_id: response?.id });
      } else if (user?.user_type === "WHITELABEL") {
        await WhiteLabelHandler.update(whitelabel?.slug, data);
      }
      displayMiniFeedback("Business information updated");

    } else {
      displayMiniFeedback("Nothing changed on the business information");
    }
    handleClose();
  }

  let inputFields = [
    [
      <Input name="postal_code" id="editEstimatedSales_postal_code" maxLength={5} label="Postal code" />,
      <EmptySpace />
    ],
  ];
  if (user?.user_type === "MERCHANT" || (user?.user_type === "WHITELABEL" && merchantSlug) || (user?.user_type === "PARTNER" && merchantSlug)) {
    const externalIdField = user?.user_type === "MERCHANT" ? <EmptySpace /> : <Input name="external_id" id="editEstimatedSalesPopup_external_id" label="External id" />;
    inputFields = [
      [
        <SelectDropdown selectOptions={averageTicketOptions} name="average_ticket" id="editEstimatedSales_average_ticket" label="Average ticket" />,
        <SelectDropdown selectOptions={monthlyFinanceOptions} name="monthly_financing_volume" id="editEstimatedSales_monthly_financing_volume" label="Est. monthly finance volume" />,
      ],
      [
        <SelectDropdown selectOptions={annualSalesOptions} name="annual_sales" id="editEstimatedSales_annual_sales" label="Annual sales" />,
        <EmptySpace />,
      ]
    ]
  } else if (isForPartner()) {
    const externalIdField = user?.user_type === "PARTNER" ? <EmptySpace /> : <Input name="external_id" id="editEstimatedSalesPopup_external_id" label="External id" />;
    inputFields = [
      [
        <Input name="name" id="editEstimatedSalesPopup_name" label="Business name" />,
        externalIdField
      ],
      [
        <SelectDropdown selectOptions={statesArray} name="state" id="editEstimatedSales_state" label="Business state" />,
        <Input name="postal_code" id="editEstimatedSales_postal_code" maxLength={5} label="Postal code" />,
      ],
      [
        <Input name="website" id="editEstimatedSales_website" label="Website" />,
        <SelectDropdown selectOptions={numberMerchantsOptions} name="merchant_number" id="editEstimatedSales_merchant_number" label="No. of merchants in network" />,
      ],
      [
        <SelectDropdown selectOptions={partnerTypeOptions} name="type" id="editEstimatedSales_type" label="Partner type" />,
        <EmptySpace />
      ]
    ]
  }

  let validationSchema = Schema({
    ...EstimatedSalesValidation
  });

  return (
    <Popup isInner={true}>
      <>
        {!loading && <Scrollable>
          <CloseIcon id="editEstimatedSalesPopup_closeIcon" color="dark" />
          <Form
            id="editEstimatedSalesPopup"
            title="Edit estimated sales"
            submitBtnText="Save"
            onFormSubmit={handleSubmit}
            validationSchema={validationSchema}
            values={initialValues}
            buttonPosition="bottom right"
            inputFields={inputFields}
            isInsidePopup
          />
        </Scrollable>}
      </>
    </Popup>
  );
};

export default EditEstimatedSalesPopup;
