import styles from "./BasicInfoPopup.module.scss";
import { useAppSelector } from "reducers/Hooks";
import Popup from "./Popup";
import { IPopup, ISupport } from "common/interfaces";
import { CloseIcon } from "common";

const HelpPopup: React.FC<IPopup> = () => {
  const message = useAppSelector(state => state.popUp.messagePassing);
  const support = useAppSelector(state => state.app.support);
  const whitelabel = useAppSelector(state => state.whitelabel);

  const getInfo = (): ISupport => {
    switch (message) {
      case "BORROWER":
        return support?.borrower as ISupport;
      case "MERCHANT":
        return support?.merchant as ISupport;
      default:
        return support?.whitelabel as ISupport;
    }
  }

  return (
    <Popup>
      {support && <>
        {/* <div className={styles.popupContainer}> */}
        <div className={styles.popupHeader}>
          <div>
            <img src={whitelabel?.primary_logo || "/assets/images/Help.png"} alt="Help" />
            <h3></h3>
          </div>
          <CloseIcon id="helpPopup" color="light" />
        </div>
        <div className={styles.popupInfo}>
          <div className={styles.contactInfo}>
            <p className="menu">Contact name</p>
            <p className="text">{getInfo()?.name}</p>
          </div>
          <div className={styles.contactInfo}>
            <p className="menu">Email</p>
            <p className="text">{getInfo()?.email}</p>
          </div>
          {getInfo()?.phone && <div className={styles.contactInfo}>
            <p className="menu">Phone</p>
            <p className="text">{getInfo()?.phone}</p>
          </div>}
        </div>
        {/* </div> */}
      </>
      }
    </Popup>
  );
};

export default HelpPopup;
