import React, { useEffect, useState } from "react";
import useViewIndex from "../useViewIndex";
import useBusinessAccount from "./useBusinessAccount";
import useYourSettings from "./useYourSettings";
import { ISettingsView } from "common/interfaces";
import { useNavigate } from "react-router-dom";
import SettingsView from "../SettingsView";

const PartnerSettingsView: React.FC = () => {
  const navigate = useNavigate();
  const views = ["accountInformation", "yourSettings"];
  const activeView = useViewIndex(views);
  const businessAccount = useBusinessAccount();
  const yourSettings = useYourSettings();
  const [activeTab, setActiveTab] = useState(0);
  const settingsArray: ISettingsView[] = [ businessAccount, yourSettings ];
  
  useEffect(() => {
    navigate(`/settings/${views[activeTab]}`, { replace: true });
  }, [activeTab]);

  return <>
    {businessAccount && yourSettings && <SettingsView
      panels={settingsArray[activeTab].panels}
      tabNavigation={{
        tabs: [
          { title: "Business account" },
          { title: "Your settings" }
        ],
        contents: [
          businessAccount?.tabContent,
          yourSettings?.tabContent
        ],
        onTabChange: (index: number) => setActiveTab(index),
        initialActiveTab: activeView
      }} />}
  </>;
}

export default PartnerSettingsView;