import ProtectedRoute from "auth/ProtectedRoute";
import { Settings } from "content/portals";
import { Route } from "react-router-dom";

const SettingsRoutes = () => {
  return <>
    <Route path="/settings/:view" element={<ProtectedRoute> <Settings /> </ProtectedRoute>} />
  </>;
};

export default SettingsRoutes;