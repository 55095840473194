import React from "react";
import { Icon } from "iconsax-react";
import AppIcon from "common/AppIcon";
import styles from "./PanelLine.module.scss";

export interface IPanelLine {
  label: string;
  icon?: Icon;
  iconColor?: string;
  textColor?: string;
  id?: string;
}

const PanelLine: React.FC<IPanelLine> = ({ label, icon, iconColor, textColor, id }) => {
  return <div className={styles.line}>
    {icon && <div id={id ? id + "_icon" : undefined} className={styles["line-icon"]}><AppIcon icon={icon} color={`var(--${textColor || iconColor || "primaryVariationColor"})`} /></div>}
    <div id={id ? id + "_label" : undefined} className={styles["line-label"]} style={{ ...(textColor && { color: `var(--${textColor})` }) }}>{label}</div>
  </div>;
}

export default PanelLine;