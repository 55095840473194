import React, { useEffect, useState } from "react";
import { SelectDropdown } from "./form";
import SystemHandler from "actions/SystemHandler";
import { displayMiniFeedback } from "utils/helpers";
import styles from "./ColorPalleteDrowdown.module.scss";

interface IColorPalleteDropdown {
  id: string;
  name: string;
  label: string;
  placeholder?: string;
  formik?: any;
  disabled?: boolean;
  value?: any;
  onColorChange: (colors: Array<string>) => void;
  onChange: (colorTheme: string) => void;
}

const ColorPalleteDropdown: React.FC<IColorPalleteDropdown> = (props) => {
  const [colors, setColors] = useState<Array<any>>(null);

  useEffect(() => {
    SystemHandler.getColorTheme()
      .then(response => {
        setColors(response?.results?.map((item: any) => { return { value: item?.id, colors: item?.colors?.map(color => `#${color}`) }; }));
      })
      .catch(error => {
        displayMiniFeedback("Error getting color themes", true);
      });
  }, []);

  return <>
    {colors && <SelectDropdown {...props} id={props.id || "color_pallete_dropdown"}
      selectOptions={
        colors.map((color: any) => {
          return {
            value: color.value,
            label: <div className={styles["color-pallete-wrapper"]}>
              <div className={`${styles["color-pallete-item"]} ${styles["first"]}`} style={{ backgroundColor: color.colors[0] }}></div>
              <div className={styles["color-pallete-item"]} style={{ backgroundColor: color.colors[1] }}></div>
              <div className={`${styles["color-pallete-item"]} ${styles["last"]}`} style={{ backgroundColor: color.colors[2] }}></div>
            </div>
          }
        })
      }
      onChange={(value: any) => { 
        props.onColorChange(colors.find(color => color.value === value)?.colors);
        props.onChange(value);
      }}
      optionFullFill={true} />}
  </>;
}

export default ColorPalleteDropdown;