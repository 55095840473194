import styles from "./Agreements.module.scss";
import React from "react";
import { useAppSelector } from "reducers/Hooks";
import strings from "localization/Strings";
import { Disclaimer } from "common";

const AdvertiserDisclosure: React.FC = () => {
  const whitelabelName = useAppSelector(state => state.whitelabel?.name);

  return <Disclaimer title="Advertiser Disclosure">
    <div className={styles.agreement}>
      <p>{strings.advertiserDisclosure.replaceAll("{whitelabelName}", whitelabelName)}</p>
    </div>
  </Disclaimer>;
};

export default AdvertiserDisclosure;
