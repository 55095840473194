import React from "react";
import PinInput from "react-pin-input";

interface IDigitInput {
  value?: string;
  onChange?: (data: string) => void;
  disabled?: boolean;
  name: string; // used by Form
  id: string; // used by Form
  label: string; // used by Form
  hasError?: boolean;
}

const DigitInput: React.FC<IDigitInput> = ({ value, onChange, disabled, hasError }) => {
  return <div style={{ marginTop: 10 }}>
    <PinInput
      length={4}
      secret
      secretDelay={1000}
      initialValue={value}
      type="numeric"
      inputMode="number"
      regexCriteria={/^[0-9]*$/}
      onChange={(value, index) => { onChange(value) }}
      onComplete={(value, index) => { onChange(value) }}
      style={{
        display: "flex",
        justifyContent: "space-between"
      }}
      inputStyle={{
        width: 70,
        paddingInline: "2rem",
        height: 45,
        border: `0.1rem solid ${hasError ? "#ff3d3d" : "var(--darkTextColor)"}`,
        borderRadius: "1rem",
        fontWeight: 500,
        fontSize: "1.5rem",
        lineHeight: "2rem",
        color: "var(--darkTextColor)"
      }}
      disabled={disabled || false}
    />
  </div>;
}

export default DigitInput;