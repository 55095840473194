import WhiteLabelHandler from "actions/WhiteLabelHandler";
import { useAuth } from "auth/useAuth";
import { Form, Input, SelectDropdown } from "common/form";
import { IAddEntity } from "common/interfaces";
import React, { useEffect, useState } from "react";
import { Schema, SimpleRequireValidation, MaxLoanAmountValidation } from "utils/validation/additionalValidation";

const LenderGroupSelectionForm: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const [lenderGroups, setLenderGroups] = useState(null);
  const [newInitialValues, setNewInitialValues] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const user = useAuth()?.user;

  useEffect(() => {
    WhiteLabelHandler.getLenderGroups()
      .then(groups => {
        setLenderGroups(groups.map((group: any) => { return { label: group.name, value: group.id } }));
      });
  }, []);

  useEffect(() => {
    if (lenderGroups) {
      let valSch: any = { max_loan_amount: MaxLoanAmountValidation };
      let initVals: any = { max_loan_amount: initialValues?.max_loan_amount || "" };
      if (lenderGroups && lenderGroups?.length > 1) {
        valSch["lender_group"] = SimpleRequireValidation;
        initVals = { ...initVals, lender_group: initialValues?.lender_group || "" }
      }
      setNewInitialValues(initVals);
      setValidationSchema(valSch);
    }
  }, [lenderGroups]);

  return <>
    {user?.user_type === "PARTNER" && <></>}
    {user?.user_type !== "PARTNER" && lenderGroups && validationSchema && newInitialValues && <Form
      id="addMerchantPopup_lender_group"
      title="Add merchant • Program settings"
      submitBtnText="Next"
      onFormSubmit={(data: any) => { onNext(data); }}
      validationSchema={Schema(validationSchema)}
      values={newInitialValues}
      buttonPosition="bottom right"
      inputFields={lenderGroups && lenderGroups.length > 1 ? [
        [
          <SelectDropdown selectOptions={lenderGroups} name="lender_group" id="addMerchantPopup_lender_group" label="Lender groups" />,
          <Input name="max_loan_amount" id="addMerchantPopup_max_loan_amount" mask="amount" label="Max loan amount" tooltip="Max loan amount must be between $1,000 and $100,000" inputPrefix="$" />
        ]
      ] : [
        [
          <Input name="default_lender_group" readonly id="editLenderGroupPopup_lender_group" label="New lender group" placeholder={lenderGroups.length === 0 ? "Default lender group" : lenderGroups[0].label} />,
          <Input name="max_loan_amount" id="addMerchantPopup_max_loan_amount" mask="amount" label="Max loan amount" tooltip="Max loan amount must be between $1,000 and $100,000" inputPrefix="$" />
        ]
      ]}
      isInsidePopup
    />}
  </>;
}

export default LenderGroupSelectionForm;