import { useEffect, useState } from 'react';

export const useKeyDown = (condition: boolean) => {
  const [keyDownEvent, setKeyDownEvent] = useState<KeyboardEvent>(null);

  useEffect(() => {
    if (!condition && keyDownEvent) {
      setKeyDownEvent(null);
    }

    const listener = event => {
      if (condition) {
        setKeyDownEvent(event);
      } else {
        setKeyDownEvent(null);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [condition]);

  return keyDownEvent;
};