import React, { useEffect, useRef } from "react";
import Panel, { IPanel } from "./Panel";
import styles from "./Panels.module.scss";
import { useAppDispatch } from "reducers/Hooks";
import appActions from "reducers/AppReducer";
import { isNotNullNorUndefined } from "utils/helpers";

export interface IPanels {
  panels: Array<IPanel>;
}

const Panels: React.FC<IPanels> = ({ panels }) => {
  const dispatch = useAppDispatch();
  const gradientColors = ["whiteColor", "primaryColor10Solid", "primaryColor20Solid", "primaryColor30Solid", "primaryColor40Solid"];
  const panelsRef = useRef<HTMLDivElement>();

  if (panels.length > 5) {
    throw new Error("Need more gradient colors to handle more than 5 panels");
  }

  useEffect(() => {
    if (isNotNullNorUndefined(panelsRef?.current)) {
      dispatch(appActions.setSidePanelsHeight(panelsRef.current.clientHeight));
    }
  }, [panelsRef?.current?.clientHeight]);

  return <div className={styles["panels-wrapper"]}>
    <div ref={panelsRef} className={styles.panels} style={{
      backgroundColor: `var(--${panels.length === 0 ? 'whiteColor' : (panels[panels.length - 1]?.panelColor || gradientColors[panels.length - 1])})`
    }}>
      {panels.map((panel, index) => {
        return <Panel key={`side_panel_${index}`} {...panel} colorVariable={gradientColors[index]} last={index === panels.length - 1} />;
      })}
    </div>
  </div>;
}

export default Panels;