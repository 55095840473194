// this isn't used anywhere on 18/07/2023

import { Button, CloseIcon, LocationSelector, SearchBar } from "common";
import React, { useEffect, useRef, useState } from "react";
import styles from "./TableWIthSearchInputPopup.module.scss";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IMerchantLocation, IPopup } from "common/interfaces";
import Popup from "./Popup";
import { useParams } from "react-router-dom";
import popUpActions from "reducers/PopUpReducer";
import { SearchBarRef } from "common/SearchBar";
import Log from "classes/Log";
import { MerchantHandler } from "actions/MerchantHandler";
import LoanApplicationHandler from "actions/LoanApplicationHandler";

const EditLocationAssociationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const message = useAppSelector(state => state.popUp.messagePassing);
  const [newLocation, setNewLocation] = React.useState<string>(null);
  const { applicantId } = useParams();
  const [keyword, setKeyword] = useState<string>("");
  const searchBarRef = useRef<SearchBarRef>(null);

  useEffect(() => {
    if (message) {
      MerchantHandler().get(message.application.merchant?.slug);
    }
  }, [message]);

  const handleSelect = (location: IMerchantLocation) => {
    if (location) {
      setNewLocation(location.id);
    }
  }

  const saveChange = () => {
    if (newLocation) {
      searchBarRef.current.clearSearch();
      LoanApplicationHandler.updateLocationAssociation(newLocation, applicantId)
        .then(response => {
          Log.debug(response);
          dispatch(popUpActions.closePopup());
        });
    }
  }

  return (
    <Popup isInner={true} >
      <>
        <CloseIcon id="editLocationAssociationPopup" color="dark" />
        <h2>Application {applicantId} • Edit location association</h2>
        <div className={styles.searchContainer}>
          <SearchBar
            id="editLocationAssociation_search"
            placeholder={"Search location"}
            onSearch={setKeyword}
            ref={searchBarRef} />
        </div>
        <LocationSelector
          id="editLocationAssociation_selector"
          onSelection={handleSelect}
          keyword={keyword}
          selectedLocationId={newLocation}
        />
        <div style={{ textAlign: "right" }}>
          <Button
            id="save_location_association"
            label="Save"
            onClick={saveChange}
          />
        </div>
      </>
    </Popup>
  );
};

export default EditLocationAssociationPopup;
