import { useAuth } from "auth/useAuth";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "reducers/Hooks";
import styles from "./LandingPage.module.scss";
import Analytics, { ITracking } from "classes/Analytics";
import { calculateColorVariables } from "utils/colors";
import React from "react";
import { debounce } from "lodash";

export interface ILanding {
  id: string;
  ctaLink?: string;
  title?: string;
  subtitle?: string;
  hero: string;
  titleBelowFold?: string;
  feat1?: string;
  feat2?: string;
  feat3?: string;
  feat4?: string;
  stepsTitle?: string;
  step1?: string;
  step2?: string;
  step3?: string;
  step4?: string;
  type: "MERCHANT" | "WHITELABEL";
  preview?: boolean;
  // used in some previews
  showOnlyAboveFold?: boolean;
  /* overrides the innerWidth - in case of a landing page preview, where it is shown inside of a popup for example */
  merchantLogoPreview?: string;
  merchantInitials?: string;
  onCtaLinkClick?: () => void;
  colors?: Array<string>;
}

export function useLandingPage(props: ILanding) {
  const navigate = useNavigate();
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);
  const [mediaClass, setMediaClass] = useState("");
  const mainRef = useRef(null);
  const [actualWidth, setActualWidth] = useState(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setActualWidth(entry.contentRect.width);
      }
    });

    if (mainRef.current) {
      resizeObserver.observe(mainRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [mainRef.current]);

  useEffect(() => {
    if (actualWidth) {
      const breakpoints = [
        { width: 380, style: styles.max380 },
        { width: 480, style: styles.max480 },
        { width: 600, style: styles.max600 },
        { width: 750, style: styles.max750 },
        { width: 900, style: styles.max900 },
        { width: 990, style: styles.max990 },
        { width: 1150, style: styles.max1150 },
        { width: 1300, style: styles.max1300 },
        { width: 1400, style: styles.max1400 },
      ];
      // Find the appropriate style based on the actualWidth
      const foundBreakpoint = breakpoints.find(breakpoint => actualWidth <= breakpoint.width);
      // Set the media class to the found style or default to an empty string if none is found
      setMediaClass(foundBreakpoint ? foundBreakpoint.style : "");
    }
  }, [actualWidth]);

  let upperRightLinkUrl = "";
  let upperRightLinkUrlUser = "";
  let upperRightLinkText = "";

  if (props.type === "WHITELABEL") {
    upperRightLinkText = "Already have an account? Log in";
    upperRightLinkUrl = "/login";
    upperRightLinkUrlUser = "/dashboard";
  } else if (props.type === "MERCHANT") {
    upperRightLinkText = "Already received offers? Click here";
    upperRightLinkUrl = "/verifyDOBandSSN";
  }

  let customColors: any = null;
  if (props.colors?.length > 0 && props.colors[0]) {
    customColors = {};
    const variables = calculateColorVariables(props.colors[0], props.colors[2]);
    variables.forEach(item => {
      customColors[item.variable_name] = item.color_code;
    });
  }

  const parseText = (text: string, type: 'title' | 'body') => {
    if (!text) { return type === "body" ? `Don't let a lack of financing options hold you back! We provide access to over 20 lenders to help with all of your financing needs.` : `Unlock your possibilities with ${whitelabel?.name}`; }

    const parts = text.replace(/{{white-label}}/g, whitelabel?.name).split('|');
    return parts.map((part, index) => <React.Fragment key={`${type}_${index}`}>
      {part}{index < parts.length - 1 && <br />}
    </React.Fragment>);
  };

  const handleClick = (object: "top_cta_button" | "white_label_website_link" | "privacy_policy_link" | "bottom_cta_button" | "terms_service_link" | "advertiser_disclosure_link") => {
    Analytics.track({ experience: props.type === "WHITELABEL" ? "merchant" : "borrower", screen: "landing_page", object, action: "clicked" } as ITracking);
    if (object.indexOf("cta") > -1 && props.onCtaLinkClick) {
      props.onCtaLinkClick();
    }
  }

  const getPreviewBackgroundWhole = (): CSSProperties => {
    if (props.preview && props.showOnlyAboveFold === false) {
      return {
        backgroundColor: "white",
        borderRadius: "2.5rem",
      }
    } else {
      return {}
    }
  };

  const getPreviewBackgroundBottom = (): CSSProperties => {
    if (props.preview && props.showOnlyAboveFold === false) {
      return {
        backgroundColor: "white",
        borderBottomLeftRadius: "2.5rem",
        borderBottomRightRadius: "2.5rem"
      }
    } else {
      return {}
    }
  };

  return {
    navigate, user, whitelabel, mediaClass, actualWidth, upperRightLinkUrl, upperRightLinkUrlUser, upperRightLinkText,
    mainRef, parseText, customColors, handleClick, getPreviewBackgroundWhole, getPreviewBackgroundBottom
  }
};