import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

const counterSlice = createSlice({
  name: "dtmApplication",
  initialState: {
    ownersNumber: 1,
    ownerPosition: 1,
    ownerIDsToKeep: [],
    docs: {
      doc_voided_check: null,
      doc_refund_policy: null,
      doc_incorporation_articles: null,
      doc_ein_letter: null,
      doc_monthly_statement_1: null,
      doc_monthly_statement_2: null,
      doc_monthly_statement_3: null,
      doc_year_profit_and_loss: null,
      doc_year_balance_sheet: null,
      doc_proof_of_insurance: null,
      doc_business_license: null,
      doc_professional_license: null,
      doc_sales_tax_permit: null,
    }
  },
  reducers: {
    setDtmOwnerPosition: (state, action: PayloadAction<number>) => {
      state.ownerPosition = action.payload;
    },
    clearOwnerIDsToKeep: (state) => {
      state.ownerIDsToKeep = [];
    },
    keepOwnerID: (state, action: PayloadAction<string>) => {
      let ownerIDsToKeep = [...state.ownerIDsToKeep];
      ownerIDsToKeep.push(action.payload);
      state.ownerIDsToKeep = ownerIDsToKeep;
    },
    addDoc: (state, action: PayloadAction<{ key: string, doc: string }>) => {
      let newDocs = {
        ...state.docs,
        [action.payload.key]: action.payload.doc
      };
      state.docs = Object.assign({}, newDocs);
    },
    setOwnersNumber: (state, action: PayloadAction<number>) => {
      state.ownersNumber = action.payload;
    }
  }
});

export const dtmApplicationReducer = counterSlice.reducer;
export default counterSlice.actions;
