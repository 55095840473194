import SystemHandler from 'actions/SystemHandler';
import { useEffect, useState } from 'react';
import _ from "lodash";

/**
 * Contain all of the options and statuses related with merchants.
 * @param industry Merchant industry.
 * @returns Options containing all of the select options, and ready flag (must be used to know when all of the asyncronous called are done).
 */
export const useMerchantOptions = (industry: number) => {
  const [industryOptions, setIndustryOptions] = useState(null);
  const [specialtyOptions, setSpecialtyOptions] = useState(null);
  const [annualSales, setAnnualSales] = useState(null);
  const [averageTicket, setAverageTicket] = useState(null);
  const [merchantStatuses, setMerchantStatuses] = useState(null);
  const [monthlyFinanceVolume, setMonthlyFinanceVolume] = useState(null);
  const [filteredSpecialtyOptions, setFilteredSpecialtyOptions] = useState(null);
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty(),
      SystemHandler.getAnnualSales(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getMerchantStatuses(),
      SystemHandler.getMonthlyFinanceVolume()
    ]).then(responses => {
      setIndustryOptions(_.sortBy(responses[0], ["label"]));
      setSpecialtyOptions(_.sortBy(responses[1], ["label"]));
      setAnnualSales(_.sortBy(responses[2], ["label"]));
      setAverageTicket(_.sortBy(responses[3], ["label"]));
      setMerchantStatuses(_.sortBy(responses[4], ["label"]));
      setMonthlyFinanceVolume(_.sortBy(responses[5], ["label"]));
      setOptionsLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (optionsLoaded) {
      setFilteredSpecialtyOptions(industry > 0 ? _.filter(specialtyOptions, option => option.industry === industry) : []);
      setReady(true);
    }
  }, [optionsLoaded, industry]);

  const updateIndustry = (newIndustry: number) => {
    setFilteredSpecialtyOptions(_.filter(specialtyOptions, option => option.industry === newIndustry));
  }

  return {
    options: {
      industryOptions,
      specialtyOptions: filteredSpecialtyOptions,
      annualSales,
      averageTicket,
      merchantStatuses,
      monthlyFinanceVolume,
    },
    ready,
    updateIndustry
  };
};