import React from "react";

export interface IFormikError {
  propertyName: string;
  formik: any;
  className?: string;
  styleOverride?: object;
  ignoreTouch?: boolean;
}

const FormikError: React.FC<IFormikError> = ({ propertyName, formik, className, styleOverride, ignoreTouch }) => {
  return <>
    {(formik.touched[propertyName] || ignoreTouch) && formik.errors[propertyName] && (
      <p className={className || "errorText"} style={styleOverride}>{formik.errors[propertyName].toString()}</p>
    )}
  </>;
}
export default FormikError;