import { CloseIcon } from "common";
import { Input, Form, SelectDropdown } from "common/form";
import React from "react";
import { useAppDispatch } from "reducers/Hooks";
import { IMerchantLocation, IPopup } from "common/interfaces";
import { statesArray } from "utils/constants";
import { LocationValidation2, Schema } from "utils/validation/additionalValidation";
import { displayMiniFeedback } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useAuth } from "auth/useAuth";
import Log from "classes/Log";
import { useParams } from "react-router-dom";
import LocationsHandler from "actions/LocationsHandler";
import { MerchantHandler } from "actions/MerchantHandler";
import NewPopup from "./NewPopup";
import Analytics, { ITracking } from "classes/Analytics";

const AddNewLocationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const { merchantSlug } = useParams();
  const user = useAuth()?.user;

  const handleSubmit = async (data: IMerchantLocation) => {
    try {
      const merchant = await MerchantHandler().get(merchantSlug || (user?.user_type === "MERCHANT" && user?.customer?.slug));
      await LocationsHandler.create(data, (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") ? merchant?.slug : null);
      await MerchantHandler().get(merchant?.slug || merchantSlug); // Need a second call to update redux store with new locations.
      Analytics.track({ experience: "portal", screen: user?.user_type === "WHITELABEL" ? "merchants_locations" : "settings_locations", object: "add_location", action: "successful" } as ITracking, { merchant_id: merchant?.id });
      displayMiniFeedback(`${data.name} was added successfully!`);
      handleClose();

    } catch (error) {
      Log.error(error);
    }
  }

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  return <NewPopup style={{ maxWidth: 850 }}>
    <>
      <CloseIcon id="addNewLocationPopup" color="dark" />
      <Form
        id="addNewLocationPopup"
        title="Add new location"
        submitBtnText="Done"
        onFormSubmit={handleSubmit}
        validationSchema={Schema({
          ...LocationValidation2
        })}
        values={{
          name: "",
          address1: "",
          city: "",
          state: "",
          postal_code: ""
        }}
        buttonPosition="side right"
        inputFields={[
          [
            <Input name="name" id="addNewLocationPopup_location_name" label="Location name" />,
            <Input name="address1" id="addNewLocationPopup_address1" label="Address" />
          ],
          [
            <Input name="city" id="addNewLocationPopup_city" label="City" />,
            <SelectDropdown selectOptions={statesArray} name="state" id="addNewLocationPopup_state" label="State" />
          ],
          [
            <Input name="postal_code" id="addNewLocationPopup_postal_code" maxLength={5} label="Postal code" />,
          ]
        ]}
        isInsidePopup
      />
    </>
  </NewPopup>;
};

export default AddNewLocationPopup;
