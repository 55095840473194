import { MerchantHandler } from "actions/MerchantHandler";
import { PartnerHandler } from "actions/PartnerHandler";
import LocalStorage from "classes/LocalStorage";
import { IUser } from "common/interfaces";
import { useState } from "react";

export function useAuthStorage(defaultValue: IUser): [IUser, (value: IUser) => void] {
  const [storedValue, setStoredValue] = useState((): IUser => {
    try {
      const value = LocalStorage.get<IUser>("user");
      if (value) {
        if (value?.user_type === "MERCHANT" && value?.customer?.slug) {
          MerchantHandler().get(value.customer?.slug);
        } else if (value?.user_type === "PARTNER" && value?.customer?.slug) {
          PartnerHandler().get(value.customer?.slug);
        }
        return value;
      } else {
        LocalStorage.save("user", defaultValue);
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  function setValue(value: IUser) {
    try {
      if (!value) {
        LocalStorage.remove("user");
      } else {
        LocalStorage.save("user", value);
        setStoredValue(value);
      }
    } catch (err) { }
    setStoredValue(value);
  };

  return [storedValue, setValue];
};
