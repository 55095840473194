import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import styles from "./SearchBar.module.scss";
import { Add } from "iconsax-react";

export interface ISearchBar {
  id: string;
  placeholder: string;
  onSearch: (keyword: string) => void;
  styleOverride?: object;
  disabled?: boolean;
  onClearClick?: () => void;
}

export interface SearchBarRef {
  clearSearch: () => void;
}

const SearchBar: React.ForwardRefRenderFunction<SearchBarRef, ISearchBar> = (props, ref) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      props.onSearch(searchTerm);
    }, 750);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm, props.onSearch]);

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const resetSearch = () => {
    setSearchTerm("");
    if (props.onClearClick) {
      props.onClearClick();
    }
  }

  const clearSearch = () => {
    setSearchTerm("");
    if (props.onClearClick) {
      props.onClearClick();
    }
  };

  // Expose the child function using the ref
  useImperativeHandle(ref, () => ({
    clearSearch,
  }));

  return <div style={{ position: "relative" }}>
    <input
      className={styles.search}
      id={`${props.id}_search_bar`}
      name={`${props.id}_search_bar`}
      placeholder={props.placeholder || "Search"}
      style={props.styleOverride || {}}
      onChange={handleInputChange}
      value={searchTerm}
      disabled={props.disabled || false}
    />
    <Add 
      style={{ 
        position: "absolute", 
        right: 8, 
        top: 9, 
        rotate: "45deg", 
        cursor: "pointer", 
        fill: "currentcolor", 
        color: "var(--darkTextColor)" 
      }} 
      size={28} 
      onClick={resetSearch} />
  </div>;
};

export default forwardRef(SearchBar);
