import BorrowerHandler from "actions/BorrowerHandler";
import LocalStorage from "classes/LocalStorage";
import React, { useEffect, useState } from "react";
import { BorrowerExperience, MerchantInvitation } from "./flows";
import { displayFullscreenLoading, hideFullscreenLoading } from "utils/helpers";
import InvitationHandler from "actions/InvitationHandler";
import { VerifyDOB } from "content";

interface IInvitationValidator {
  type: "APPLICATION" | "MERCHANT",
  errorMessage?: string;
}

const InvitationValidator: React.FC<IInvitationValidator> = ({ type, errorMessage }) => {
  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const invitationId = LocalStorage.get<number>("invitationId");
  const [ready, setReady] = useState(false);
  // only used for type: "APPLICATION"
  const [applicationHasDOB, setApplicationHasDOB] = useState(false);

  const getElement = () => {
    if (applicationHasDOB) {
      return <VerifyDOB path="VerifyDOB" screen="application_verify_dob" />;
    } else {
      return <BorrowerExperience />
    }
  }

  useEffect(() => {
    displayFullscreenLoading();
    if (type === "APPLICATION") {
      BorrowerHandler.getInvitation(applicationId, vuid)
        .then(response => {
          setApplicationHasDOB(response.applicationHasDOB);
          hideFullscreenLoading();
          if (response.invitationStatus === "INVALID") {
            window.location.href = `${getMerchantSlug()}?m=${errorMessage || "Your invitation has expired, kindly request a new one to apply."}`;
          } else if (response.applicationHasDOB) {
            setReady(true);
          } else {
            LocalStorage.remove("application_locked");
            LocalStorage.remove("selected_location");
            LocalStorage.remove("equal_housing");
            LocalStorage.remove("no_offers");
            setReady(true);
          }
        });
    } else {
      InvitationHandler.getMerchantInvitation(invitationId, vuid)
        .then(response => {
          hideFullscreenLoading();
          if (response.invitationStatus === "INVALID") {
            window.location.href = `/?m=${errorMessage || "Your invitation has expired, kindly request a new one to continue."}`;
          } else {
            setReady(true);
          }
        });
    }
  }, []);

  const getMerchantSlug = () => {
    const path = window.location.pathname;
    if (path) {
      const parts = path.split('/');
      if (parts.length > 1) {
        return `/${parts[1]}`;
      }
    }
    return "/";
  }

  return <>
    {ready && type === "APPLICATION" && getElement()}
    {ready && type === "MERCHANT" && <MerchantInvitation />}
  </>;
}

export default InvitationValidator;