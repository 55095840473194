import React from "react";
import styles from "./FeedbackPopup.module.scss";
import { SVG } from "common";
import { useInnerDimensions } from "hooks/useInnerDimensions";
import { useMediaQuery } from "@mui/material";

export const Spaceship: React.FC = () => {
  const height = 350;
  const [innerWidth] = useInnerDimensions();
  const max700 = useMediaQuery('only screen and (max-width: 700px)');

  return <div
    className={styles.backgroundImage}
    style={{ height: height, marginBottom: -100, marginTop: -10 }}>
    <SVG
      src="/assets/feedback/spaceship.svg"
      title="Spaceship"
      height={height}
      wrapperStyle={{ position: 'absolute', left: `${max700 ? innerWidth / 2 - 160 : 200}px` }}
    />
  </div>;
};

export default Spaceship;
