import styles from "./ViewApplicant.module.scss";
import { Sidebar, DashboardHeader, Aside } from "common";
import React, { useEffect, useState } from "react";
import { ApplicationDetails2, History, Offers } from "content/portals";
import { viewApplicantMenu } from "utils/constants";
import { handleInnerMenusBackButton } from "utils/helpers";
import InnerPopups from "content/popups/InnerPopups";

interface IViewApplicant {
  view: string;
}

const ViewApplicant: React.FC<IViewApplicant> = ({ view }) => {
  const [viewApplication, setViewApplication] = useState(view || "application");

  useEffect(() => {
    return handleInnerMenusBackButton(viewApplicantMenu.map(item => { return item.href }), setViewApplication);
  }, []);

  return <main className={styles.viewApplicantPageContainer}>
    <Sidebar />
    <Aside>
      <DashboardHeader />
      <InnerPopups />
      {viewApplication === "application" && <ApplicationDetails2 />}
      {viewApplication === "offers" && <Offers />}
      {viewApplication === "history" && <History />}
    </Aside>
  </main>;
};

export default ViewApplicant;
