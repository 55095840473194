import { UnderwritingHandler } from 'actions/UnderwritingHandler';
import { useAuth } from 'auth/useAuth';
import { useEffect, useState } from 'react';
import useMerchant from './useMerchant';
import { UW_APP_STATUSES } from 'utils/constants';
import { isNullOrUndefined, isNullOrUndefinedOrEmpty } from 'utils/helpers';
import DTMAppStatusEnum from 'common/enums/DTMAppStatusEnum';
import Observer, { EVENTS } from 'classes/Observer';

/**
 * A custom React hook that manages the state of underwriting applications for merchants.
 * It checks the eligibility of the current user and merchant and fetches the application status,
 * updating the component state based on whether the application was found, is pending, or is under review.
 *
 * @returns {Array} A tuple containing:
 *   - {DTMAppStatusEnum} appStatus - The current status of the underwriting application.
 *   - {boolean} verified - Indicates if the merchant has been verified as eligible.
 *   - {boolean} declined - Indicates if the merchant has a declined application.
 *   - {string} buttonLabel - Text for the button based on the current status of the application.
 */

const useUnderwritingApplications = (): [DTMAppStatusEnum, boolean, boolean, string] => {
  const user = useAuth()?.user;
  const merchant = useMerchant();
  const [appStatus, setAppStatus] = useState<DTMAppStatusEnum>(DTMAppStatusEnum.NOT_ELIGIBLE);
  const [verified, setVerified] = useState<boolean>(false);
  const [declined, setDeclined] = useState<boolean>(false);
  const [buttonLabel, setButtonLabel] = useState<string>("");
  const [reloadKey, setReloadKey] = useState<number>(1);
  const dtmAppObserver = Observer.useObserver(EVENTS.DTM_APPLICATION_FINALIZED);

  useEffect(() => {
    if (!isNullOrUndefinedOrEmpty(user?.user_type) && !isNullOrUndefinedOrEmpty(merchant?.id) && (!verified || (reloadKey !== dtmAppObserver))) {
      const isMerchantAndEligible = user?.user_type === "MERCHANT" &&
        merchant?.whitelabel?.direct_to_merchant &&
        !merchant?.programs.includes("DTM");

      if (isMerchantAndEligible) {
        UnderwritingHandler().getDTMApplication()
          .then(application => {
            if (isNullOrUndefined(application)) {
              setAppStatus(DTMAppStatusEnum.NOT_FOUND);
              setButtonLabel("Join our DTM program");
            } else {
              const isAppPending = application?.underwriting_status === UW_APP_STATUSES.PENDING;
              setAppStatus(isAppPending ? DTMAppStatusEnum.PENDING : DTMAppStatusEnum.UNDER_REVIEW);
              setButtonLabel(isAppPending ? "Continue DTM application" : "");
            }
            setVerified(true);
            setReloadKey(dtmAppObserver);
          });
      } else {
        setAppStatus(DTMAppStatusEnum.NOT_ELIGIBLE);
        setVerified(true);
        setReloadKey(dtmAppObserver);
        setButtonLabel("");
      }
    }
  }, [user, merchant, verified, dtmAppObserver]);

  useEffect(() => {
    if (user?.user_type === "MERCHANT") {
      UnderwritingHandler().getDeclinedDTMApplications()
        .then(response => {
          if (response?.length > 0) {
            setDeclined(true);
          }
        })
    }
  }, [user]);

  return [appStatus, verified, declined, buttonLabel];
};

export default useUnderwritingApplications;