import React, { ReactNode } from "react";
import debounce from "lodash/debounce";

interface IInfiniteScroll {
  id: string;
  children: ReactNode;
  onScrollEnd: () => void;
  styleOverride?: object; 
}

const InfiniteScroll: React.FC<IInfiniteScroll> = ({ children, onScrollEnd, styleOverride, id }) => {

  const onScroll = (e: any) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
      const fn = debounce(onScrollEnd, 1000);
      fn();
    }
  }

  const style: React.CSSProperties = {
    overflowX: "hidden", 
    overflowY: "auto",
    ...styleOverride
  }

  return <div onScroll={onScroll} style={style} id={id}>
    {children}
  </div>;
};

export default InfiniteScroll;
