import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { REGEX_CHECKS } from "utils/constants";
import styles from "./PasswordRequirements.module.scss";
import { useAppSelector } from "reducers/Hooks";

interface IPasswordRequirements {
  password: string;
}

const PasswordRequirements: React.FC<IPasswordRequirements> = ({ password }) => {

  const [id, setId] = useState<string>("");
  const requirements = useAppSelector(state => state.app.passwordRequirements);

  const requiredLengthPassed = requirements.requiredLength === 0 || password.length >= requirements.requiredLength;
  const requireDigitPassed = !requirements.requireDigit || REGEX_CHECKS.requireDigit.test(password);
  const requireLowercasePassed = !requirements.requireLowercase || REGEX_CHECKS.requireLowercase.test(password);
  const requireUppercasePassed = !requirements.requireUppercase || REGEX_CHECKS.requireUppercase.test(password);
  const requireNonAlphanumericPassed = !requirements.requireNonAlphanumeric || REGEX_CHECKS.requireNonAlphanumeric.test(password);

  useEffect(() => {
    setId(uuidv4());
  }, []);

  const checked = `url("/assets/images/checked.png")`;
  const unchecked = `url("/assets/images/unchecked.png")`;

  return (
    <div id={id}>
      <div className={styles.passwordMinRequirements}>
        <p>Password minimum requirements:</p>
        <ul>
          <li style={{ backgroundImage: requiredLengthPassed ? checked : unchecked }}>At least 8 characters</li>
          <li style={{ backgroundImage: requireLowercasePassed && requireUppercasePassed ? checked : unchecked }}>At least one uppercase and lowercase letter</li>
          <li style={{ backgroundImage: requireDigitPassed ? checked : unchecked }}>At least one number</li>
          <li style={{ backgroundImage: requireNonAlphanumericPassed ? checked : unchecked }}>At least one special character</li>
        </ul>
      </div>
    </div>
  );
}

export default PasswordRequirements;