import React from "react";
import _ from "lodash";
import { Autocomplete, TextField } from "@mui/material";

interface IInputDropdown {
  id: string;
  label?: string;
  selectOptions: Array<any>;
  hiddenSelectOptions?: Array<any>;
  formik?: any;
  onChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
  value?: any;
  name?: string;
  tooltip?: string;
  disabled?: boolean;
  placeholder?: string;
  /* If true, the Autocomplete is free solo, meaning that the user input is not bound to provided options. */
  freeSolo?: boolean;
  onBlur?: () => void;
}

const InputDropdown: React.FC<IInputDropdown> = ({
  selectOptions,
  hiddenSelectOptions,
  onChange,
  onInputChange,
  value,
  name,
  id,
  formik,
  disabled,
  placeholder,
  freeSolo,
}) => {
  const handleChange = (event: any, newValue: any) => {
    /* populate form fields or call callback onChange from AddressInformation */
    /* newValue is what you typed in */
    onChange(newValue?.label);
  }

  const handleInputChange = (event: any, newValue: any) => {
    /* have to return value for the formik instead of option */
    /* newValue is actual option object with key and value */
    onInputChange(newValue);
  }

  return <>
    <Autocomplete
      className="test"
      // works differently than SelectDropdown - value is expected to be option instead of option's value property
      value={freeSolo ? value : _.find(selectOptions, item => { return item.label === value })?.label || null}
      disablePortal
      id={`${id}_selectDropdown`}
      options={selectOptions}
      filterOptions={hiddenSelectOptions ? (selectOptions) => selectOptions.filter(option => !hiddenSelectOptions.includes(option)) : undefined}// if hiddenSelectOptions is null, use default filterOptions
      onChange={handleChange}
      onInputChange={handleInputChange}
      disabled={disabled}
      // you have to use isOptionEqualToValue if value isn't the exact same option object as you selected; because we are showing label as a value, we have to also compare to a label
      isOptionEqualToValue={(option, value) => option.label == value}
      freeSolo={freeSolo}
      /* TODO: check renderOption */
      sx={{
        marginTop: "10px",
        paddingRight: "0px",

        '& .MuiOutlinedInput-notchedOutline': {
          border: "unset",
          padding: "0px",
        },

        '& .MuiInputBase-input': {
          paddingRight: "0px",
        },

        '& .MuiAutocomplete-input': {
          border: "unset",
          margin: "0px",
          height: "45px",
          padding: "0px 0px 0px 20px !important",
          fontFamily: "var(--fontDMSans);",
          fontWeight: "400",
        },

        '& .MuiAutocomplete-input ::placeholder': {
          color: "var(--darkTextColor)",
          backgroundColor: "var(--darkTextColor)",
        },

        '& .MuiAutocomplete-inputRoot': {
          padding: "0px",
        },

        '& .MuiTextField-root': {
          height: "45px",
          border: "0.1rem solid var(--darkTextColor)",
          borderRadius: "0.5rem",
        },

        "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
          color: "var(--darkTextColor)",
          fontSize: "15px",
          fontFamily: "var(--fontDMSans);",
          fontWeight: "500",
        },

        "& + .MuiAutocomplete-popper": {
          boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
        },

        '& .MuiAutocomplete-clearIndicator': {
          marginTop: "2px",
          color: "var(--darkTextColor)",
        },
      }}
      renderInput={(params) => <TextField
        {...params}
        placeholder={placeholder || "Select"}
      />}
    />
  </>;
};

export default InputDropdown;
