import { useAuth } from "auth/useAuth";
import { ISettingsView } from "common/interfaces";
import { IPanel } from "common/parts/panel/Panel";
import { IPanelLine } from "common/parts/panel/PanelLine";
import { ITabContent } from "common/parts/tabs/TabContent";
import { Login, ProfileCircle, Sms, UserOctagon } from "iconsax-react";
import { useEffect, useState } from "react";
import { getRole } from "utils/helpers";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import Observer, { EVENTS } from "classes/Observer";
import NotificationsTable from "../shared/NoticationsTable";
import { formatAPIDate } from "utils/formatters";

const useYourSettings = (): ISettingsView => {
  const dispatch = useAppDispatch();
  const user = useAuth()?.user;
  const [panels, setPanels] = useState<Array<IPanel>>([]);
  const observer = Observer.useObserver(EVENTS.USER_ACCOUNT_UPDATED);

  useEffect(() => {
    if (user) {
      loadUserInfo();
    }
  }, [user]);

  useEffect(() => {
    loadUserInfo();
  }, [observer]);

  const loadUserInfo = () => {
    let lines: Array<IPanelLine> = [
      { icon: ProfileCircle, label: `${user.first_name} ${user.last_name}` },
      { icon: Sms, label: user.email },
      { icon: UserOctagon, label: `${getRole(user.group)} role` }
    ];
    if (user?.last_login) {
      lines.push({ icon: Login, label: `Last login ${formatAPIDate(user?.last_login, true)}` });
    }
    setPanels([
      {
        title: "Your profile", lines: lines, editAction: () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_USER_INFORMATION)) }, actions: [
          { label: "Update password", id: "your-profile-btn", color: "primary", onClick: () => { dispatch(popUpActions.openPopup(POPUPS.UPDATE_PASSWORD)) }, visible: true, moveToBottom: true }
        ]
      }
    ]);
  }

  const tabContent: ITabContent = {
    sections: [
      {
        title: "Your Notifications",
        body: <NotificationsTable />,
        actions: null
      }
    ]
  }

  return { panels, tabContent };
}

export default useYourSettings;