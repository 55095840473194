import { URLHandler } from "common";
import { InvitationValidator } from "content";
import { MerchantInvitation } from "content/flows";
import ReviewInformation from "content/flows/merchantInvitation/ReviewInformation";
import { ReactNode } from "react";
import { Route } from "react-router-dom";

const MerchantInvitationRoutes = () => {

  const validateInvitation = () => {
    return <URLHandler
      requireParams={["invitationId"]}
      errorMessage="The link to your invitation has expired or is not valid. Please contact your referrer for a reinvite."
      redirectUrl={"/"}
      requireVisitorPass>
      {<InvitationValidator type="MERCHANT" />}
    </URLHandler>;
  }

  const getElement = (comp: ReactNode) => {
    return <URLHandler
      requireParams={["invitationId"]}
      errorMessage="The link to your invitation has expired or is not valid. Please contact your referrer for a reinvite"
      redirectUrl={"/"}
      requireVisitorPass>
      {comp}
    </URLHandler>;
  }

  return <>
    <Route path="/merchantInvitation/businessInformation" element={<MerchantInvitation />} />
    <Route path="/merchantInvitation/mainBusinessAddress" element={<MerchantInvitation />} />
    <Route path="/merchantInvitation/mainContactInformation" element={<MerchantInvitation />} />
    <Route path="/merchantInvitation/additionalLocations" element={<MerchantInvitation />} />
    <Route path="/merchantInvitation/landingPage" element={<MerchantInvitation />} />
    <Route path="/merchantInvitation/thankYou" element={<MerchantInvitation />} />
    <Route path="/merchantInvitation/:invitationId" element={validateInvitation()} />
    <Route path="/reviewInformation/:invitationId" element={getElement(<ReviewInformation />)} />
  </>;
};

export default MerchantInvitationRoutes;