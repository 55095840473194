import _ from "lodash";
import Log from './Log';
import store from "reducers/Store";
import LocalStorage from "./LocalStorage";
import { IUser } from "common/interfaces";
import { gaKey } from 'utils/scripts';
import LogRocket from 'logrocket';
import { isNullOrUndefinedOrEmpty } from "utils/helpers";

export default class Analytics {
  /** 
   * @description Track analytics.
   * @param {ITracking} eventInfo Additional tracking information.
   * @param {any} metadata Metadata. Information like white_label_id, merchant_id, user_id, user_role, and url are provided automatically. Just send the additional metadata you want to track.
   * @param {string} eventName Event name. OPTIONAL. If not provided the event name will be {object}_{action}.
   * */
  static track(eventInfo: ITracking, metadata: any = null, eventName: string = null): void {
    if (isNullOrUndefinedOrEmpty(eventInfo.medium)) {
      eventInfo.medium = "ui";
    }
    validateTrackingInfo(eventInfo); // this helps the developer to know that something is missing if any.
    if (isNullOrUndefinedOrEmpty(eventName)) {
      eventName = `${eventInfo.object}_${eventInfo.action}`;
    }
    try {
      let combinedMetadata = {
        ...this.getAdditionalMetadata(),
        ...eventInfo,
        ...(metadata || {})
      };
      Log.analytics(eventName, combinedMetadata);
      if (window.analytics?.track) {
        window.analytics.track(eventName, combinedMetadata);
      }
      if (combinedMetadata.email) {
        // For GA is better not to log identifiable properties such as email, phone numbers, addresses, and ssn.
        // So far we are only sending email to Segment, so I am removing it from the oject before sending the data to GA.
        delete combinedMetadata.email;
      }
      if (window.gtag) {
        window.gtag('event', eventName, {
          ...combinedMetadata
        });
      }
    } catch (error) {
      Log.error(error);
    }
  }

  /** 
   * @description Set identifier.
   * @param {string} userId User identification.
   * @param {any} data Additional user data.
   * */
  static setIdentity(userId: string, data: any = null): void {
    try {
      if (window.analytics?.identify) {
        if (userId === null) {
          window.analytics.identify(null);
        } else {
          window.analytics.identify(data?.id, data);
        }
      }
      if (window.gtag) {
        window.gtag('config', gaKey, { user_id: data?.id });
      }
      if (LogRocket) {
        const userIdentification = userId || data?.email || data?.id;
        if (userIdentification) {
          LogRocket.identify(`${userIdentification}`, {
            ...(data?.first_name ? { first_name: data?.first_name } : {}),
            ...(data?.last_name ? { last_name: data?.last_name } : {}),
            ...(data?.email ? { email: data?.email } : {}),
            ...(data?.user_type ? { user_type: data?.user_type } : {}),
          });
        }
      }
    } catch (error) {
      Log.error(error);
    }
  }

  /** 
   * @description Set page.
   * @param {string} path Location path.
   * */
  static setPage(path: string): void {
    try {
      if (window.analytics?.page) {
        window.analytics.page({
          url: path
        });
      }
      if (window.gtag) {
        window.gtag('event', 'page_view', {
          'page_path': path,
          'page_title': document.title,
          'page_location': window.location.href
        });
      }
    } catch (error) {
      Log.error(error);
    }
  }

  static getAdditionalMetadata = (): any => {
    const whitelabel = store.getState().whitelabel;
    const merchant = store.getState().merchant;
    const user = LocalStorage.get<IUser>("user");
    const metadata = {
      white_label_id: whitelabel?.id || null,
      ...((user?.user_type === "MERCHANT" || window.location.pathname.toLowerCase().startsWith('/viewmerchant')) && { merchant_id: merchant?.id }),
      ...(user?.id && { user_id: user.id }),
      ...(user?.group && { user_role: user.group }),
      origin: window.location.pathname
    };
    return metadata;
  }
}

const validateTrackingInfo = (info: ITracking): void => {
  let errors = [];
  _.forOwn(info, (value, key) => {
    if (!value) {
      errors.push(`${key} is required`);
    }
  });
  if (errors.length > 0) {
    throw new Error(`${errors.join('; ')}.`);
  }
}

export interface ITracking {
  medium: string,
  experience: string,
  screen: string,
  object: string,
  action: string
}