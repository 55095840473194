export const merchantLocationsTemplate = [
  ["location_name", "address1", "city", "state", "postal_code"],
  [
    "Name your location here",
    "Address here",
    "City here",
    "State here. Use two letters.",
    "Postal code (5 digits) here",
  ],
  [
    "Name your location here",
    "Address here",
    "City here",
    "State here. Use two letters.",
    "Postal code (5 digits) here",
  ],
];

export const merchantLocationsTemplate2 = [
  ["name", "address1", "city", "state", "postal_code"],
  [
    "Name your location here",
    "Address here",
    "City here",
    "State here. Use two letters.",
    "Postal code (5 digits) here",
  ],
  [
    "Name your location here",
    "Address here",
    "City here",
    "State here. Use two letters.",
    "Postal code (5 digits) here",
  ],
];

export const merchantInvitesTemplate = [
  ["main_contact_first_name", "main_contact_last_name", "main_contact_email", "external_id"],
  [
    "Contact first name here",
    "Contact last name here",
    "Email here",
    "External id here. This is an identification you might already have for the merchant. Optional.",
  ],
  [
    "Contact first name here",
    "Contact last name here",
    "Email here",
    "External id here. This is an identification you might already have for the merchant. Optional.",
  ],
];

export const applicationInvitesTemplate = [
  ["email", "phone_number"],
  [
    "Email here. Only one of the fields is required. Email or phone number.",
    "Phone number. Only one of the fields is required. Email or phone number.",
  ],
  [
    "Email here. Only one of the fields is required. Email or phone number.",
    "Phone number. Only one of the fields is required. Email or phone number.",
  ],
];

export const applicationInvitesOnlyEmailTemplate = [
  ["email"],
  [
    "Email here",
  ],
  [
    "Email here",
  ],
];