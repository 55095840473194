import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "reducers/Hooks";
import appActions from "reducers/AppReducer";

const LocationTracker: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appActions.setLocation(location.pathname));
  }, [location]);

  return null;
};

export default LocationTracker;
