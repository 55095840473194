import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getQueryString } from "utils/helpers";
import Lock from "content/popups/feedback/Lock";
import styles from "./Deactivated.module.scss";
import LocalStorage from "classes/LocalStorage";
import Popup from "./popups/Popup";

const Deactivated: React.FC = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const params = getQueryString(`${window.location.pathname}${window.location.search}`);
    const prevMessageMerchant = LocalStorage.get<string>("deactivated-merchant");
    const prevMessagePartner = LocalStorage.get<string>("deactivated-partner");
    if (params?.m || prevMessageMerchant) {
      setMessage(params.m || prevMessageMerchant);
      if (params.m) {
        LocalStorage.save("deactivated-partner", params.m);
      }
      navigate(window.location.pathname, { replace: true });
    } else if (params?.p || prevMessagePartner) {
      setMessage(params.p || prevMessagePartner);
      if (params.p) {
        LocalStorage.save("deactivated-partner", params.p);
      }
      navigate(window.location.pathname, { replace: true });
    }
  }, []);

  return <div className={styles.fullscreen}>
    <Popup>
      <>
        <p className={styles.title}>
          Account deactivated
        </p>
        <p className={styles.body}>
          {message}
        </p>
        <div className={styles.imageSvg}>
          <Lock />
        </div>
      </>
    </Popup>
  </div>;
}

export default Deactivated;