import { AppIcon, Button } from "common";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "reducers/Hooks";
import { IBusinessInfo, IPopup } from "common/interfaces";
import {
  decypherApiErrorResponse, displayMiniFeedback
} from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import BusinessInformationForm from "./optimizedAddNewMerchant/BusinessInformationForm";
import BusinessMainAddressForm from "./optimizedAddNewMerchant/BusinessMainAddressForm";
import { Add, ArrowLeft } from "iconsax-react";
import Log from "classes/Log";
import NewPopup from "./NewPopup";
import styles from "./AddEntityPopup.module.scss";
import { v4 as uuidv4 } from 'uuid';

const merchantInitialState = {
  businessInformation: { external_id: "", name: "", website: "", annual_sales: "", monthly_financing_volume: "", average_ticket: "", industry: "", industry_specialty: "" },
  mainAddress: { address1: "", city: "", postal_code: "", state: "", manageMultipleLocations: "" }
};

const OptimizedAddMerchantPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);
  const [merchant, setMerchant] = useState<any>(merchantInitialState);
  const [merchantSlug, setMerchantSlug] = useState<any>(null);
  const [originalMerchantSlug, setOriginalMerchantSlug] = useState<string>(null);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);
  
  useEffect(() => {
    setMerchantSlug(null);
    setOriginalMerchantSlug(null);
    setMerchant(merchantInitialState);
    setStep(0);
  }, []);

  useEffect(() => {
    setMerchant({
      ...merchant,
      landingPage: {
        ...merchant?.landingPage,
        slug: merchantSlug
      }
    });
  }, [merchantSlug]);

  const saveData = useCallback((data: any, type: string) => {
    setMerchant(prev => ({
      ...prev,
      [type]: data
    }));
  }, []);

  const displayErrors = useCallback((error: any): void => {
    Log.error(error);
    const body = decypherApiErrorResponse(error);
    displayMiniFeedback(body, true);
  }, []);

  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleBackButton = useCallback(() => {
    if (step === 0) {
      dispatch(popUpActions.closePopup());
    } else {
      setStep(step => step - 1);
    }
  }, [step, dispatch]);

  const patch = useCallback((data: any, nextStep: number) => {
    wait(2000).then(() => {
      console.log('2 seconds of interaction with API simulated');
      setStep(nextStep);
    });
  }, [merchantSlug, displayErrors, step]);

  const renderStep = () => {
    const components = [
      <BusinessInformationForm initialValues={merchant?.businessInformation}
        onNext={(data: IBusinessInfo) => {
          saveData(data, 'businessInformation');
          if (merchantSlug) {
            patch(data, 1);
          } else {
            wait(2000).then(() => {
              console.log('2 seconds of interaction with API simulated');
              var newSLug = uuidv4();
              setMerchantSlug(newSLug);
              setOriginalMerchantSlug(newSLug);
              setStep(1);
            });
          }
        }} />,
      <BusinessMainAddressForm initialValues={merchant?.mainAddress}
        onNext={(data: any) => {
          saveData(data, 'mainAddress');
          patch(data, data.manageMultipleLocations === "yes" ? 2 : 3);
        }} />
    ];

    return components[step] || null;
  }

  const closeForm = useCallback(() => {
    setMerchantSlug(null);
    setMerchant(merchantInitialState);
    setStep(0);
    setDisplayConfirmation(false);
    dispatch(popUpActions.closePopup());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    if (merchantSlug) {
      wait(2000).then(() => {
        console.log('2 seconds of interaction with API simulated');
        closeForm();
      });
    } else {
      closeForm();
    }
  }, [merchantSlug, originalMerchantSlug, displayErrors, step, closeForm]);

  return (
    <NewPopup style={{ maxWidth: "100rem" }}>
      <>
        {displayConfirmation && (
          <div>
            <p className={styles.confirmation}>
              Closing the form without finalizing the new merchant<br />will result in losing your progress
            </p>
            <div className={styles.buttons}>
              <Button
                id="addMerchant_confirmLoss"
                label="Confirm loss"
                variant="secondary"
                onClick={handleClose}
              />
              <Button
                id="addMerchant_stayForm"
                label="Stay on form"
                onClick={() => {
                  setDisplayConfirmation(false);
                }}
              />
            </div>
          </div>
        )}
        <div style={{ display: displayConfirmation ? "none" : "block" }}>
          {step > 0 && (
            <AppIcon
              size={26}
              color="var(--darkTextColor)"
              clickTrigger={{ id: "addMerchant_back", onClick: handleBackButton }}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 20,
                left: 20,
                width: 40,
                height: 26
              }}
              icon={ArrowLeft}
            />
          )}
          <AppIcon
            clickTrigger={{
              id: "addMerchantPopup",
              onClick: () => {
                setDisplayConfirmation(true);
              }
            }}
            color="var(--darkTextColor)"
            size={35}
            className={styles.crossIcon}
            icon={Add}
          />
          {step <= 1 ? renderStep() : <>
            <p>This is the end of this test. You can close this pop up.</p>
          </>}
        </div>
      </>
    </NewPopup>
  );
};

export default OptimizedAddMerchantPopup;