import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { InfoCircle } from "iconsax-react";
import ResizeDetection from "./ResizeDetection";
import styles from "./ToolTip.module.scss";

interface IToolTip {
  text: string;
  textToolTip?: string;
  placement?: any;
  styleOverride?: object;
  onHover?: () => void;
}

const ToolTip: React.FC<IToolTip> = ({
  text,
  textToolTip,
  placement,
  styleOverride,
  onHover,
}: IToolTip) => {
  const handleTooltipHover = () => {
    if (onHover) {
      onHover();
    }
  }

  return <>
    <OverlayTrigger
      delay={500}
      rootClose={true}
      overlay={(props) => (
        <Tooltip {...props} id="tooltip-top">
          {text?.split('\n').map((str, id) => <p key={id} className="info" style={{ textAlign: "center", color: "var(--darkTextColor)" }}>{str}</p>)}
        </Tooltip>
      )}
      placement={placement || "right"}
      onEnter={handleTooltipHover}
    >
      {textToolTip ? (
        <p className={styles.tooltipText}>{textToolTip}</p>
      ) : (
        <InfoCircle style={styleOverride} size={20} color="var(--darkTextColor)" />
      )}
    </OverlayTrigger>
  </>;
};

export default ToolTip;
