import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, HeaderWL } from "common";
import { Input, Form } from "common/form";
import loginStyles from "./Login.module.scss";
import LocalStorage from "classes/LocalStorage";
import { displayFeedback, displayFullscreenLoading, hideFullscreenLoading } from "utils/helpers";
import { IFeedback } from "common/interfaces";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import AuthHandler from "actions/AuthHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { PasswordValidation, Schema } from "utils/validation/additionalValidation";

const ResetPassword: React.FC = () => {
  const [success, setSuccess] = React.useState<boolean>(false);
  const [passwordMatch, setPasswordMatch] = React.useState<boolean>(true);
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [isValidCheck, setIsValidCheck] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = LocalStorage.get<string>("token");

  useEffect(() => {
    if (token) {
      AuthHandler.validateResetToken(token)
      .then(response => {
        if (response) {
          setIsValidCheck(true);
        } else {
          displayFeedback({
            title: "Link expired",
            body: "This reset password link is expired. Please request a new one.",
            type: "BLANK",
            buttons: [
              { label: "Ok", action: () => { navigate('/forgotPassword'); dispatch(popUpActions.closePopup()) } }
            ]
          } as IFeedback);
        }
      });
    }
  }, [token]);

  const handleSubmit = async (data: any) => {
    setPasswordMatch(data.password === data.confirm_password);
    setDirty(true);
    if (data.password === data.confirm_password) {
      try {
        displayFullscreenLoading("Resetting your password");

        await AuthHandler.confirmPasswordReset(token, data.password);
        Analytics.track({ experience: "portal_login", screen: "reset_password_finish", object: "reset_password_attempt", action: "successful" } as ITracking);
        setSuccess(true);

        hideFullscreenLoading();
        LocalStorage.remove("token");

      } catch (error) {
        hideFullscreenLoading();
        return Promise.reject();
      }
    }
  }

  const onKeyUp = () => {
    setDirty(false);
  }

  return <>
    <HeaderWL />
    {!success && isValidCheck && <Form
      id="resetPassword"
      buttonPosition="bottom center"
      onFormSubmit={handleSubmit}
      title="Reset your password"
      submitBtnText="Submit"
      validationSchema={Schema({
        password: PasswordValidation,
        confirm_password: PasswordValidation
      })}
      values={{
        password: "",
        confirm_password: ""
      }}
      inputFields={[
        [
          <Input type="password" name="password" id="resetPassword_password" label="Create new password" onKeyUp={onKeyUp} />
        ],
        [
          <Input type="password" name="confirm_password" id="resetPassword_confirm_password" label="Confirm your password" onKeyUp={onKeyUp} customErrorMessage={dirty && !passwordMatch ? "Passwords don't match" : null} />
        ]
      ]}
      styleOverride={{
        formContainer: { maxWidth: "45rem" },
        pageContainer: { paddingTop: "12rem" }
      }}
      passwordRequirementsCheckField="password"
    />}
    {success && <Container className={loginStyles.innerContainer} style={{ marginTop: "12rem", textAlign: "center" }}>
      <div className={loginStyles.msgContainer}>
        <h1 style={{ marginBottom: "3rem" }}>Congratulations! Your password has been reset.</h1>
        <Button
          id="resetPassword_login"
          label="Go to login"
          type="button"
          onClick={() => {
            navigate("/login");
          }}
        />
      </div>
    </Container>}
  </>;
};

export default ResetPassword;
