import React, { useEffect, useState } from "react";
import { Input, Form } from "common/form";
import { IInvitedUser, IStepper } from "common/interfaces";
import { EmailValidation, PasswordValidation, PersonalInfoValidation, Schema } from "utils/validation/additionalValidation";
import { Link } from "common";
import LocalStorage from "classes/LocalStorage";
import Log from "classes/Log";
import InvitationHandler from "actions/InvitationHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { useLoadingBar } from "hooks/useLoadingBar";

const UserAccount: React.FC<IStepper> = ({ onNext }) => {
  const [initialValues, setInitialValues] = useState<IInvitedUser>(null);
  const vuid = LocalStorage.get<string>("vuid");
  const invitationId = LocalStorage.get<number>("invitationId");
  useLoadingBar(initialValues);

  useEffect(() => {
    InvitationHandler.getInvitedUserInformation(invitationId, vuid)
      .then(response => {
        if (response.invitationStatus === "INVALID") {
          window.location.href = `/?m=Your invitation has expired; please request a new one to complete your account setup.`;
        } else {
          setInitialValues({
            ...response,
            password: "",
            confirm_password: ""
          });
        }
      });
  }, [vuid, invitationId]);

  const handleSubmit = async (data: IInvitedUser) => {
    InvitationHandler.saveInvitedUserInformation(invitationId, vuid, data)
      .then(response => {
        Analytics.track({ experience: "portal_login", screen: "create_user_account", object: "form_submission", action: "successful" } as ITracking, { ...response, invitation_id: invitationId, user_id: response.id });
        onNext();
      })
      .catch(error => {
        Log.error(error);
      });
  }

  return <>
    {initialValues && <>
      <Form
        id="newUser_userAccount"
        buttonPosition="bottom center"
        onFormSubmit={handleSubmit}
        title="Create your user account"
        submitBtnText="Next"
        validationSchema={Schema({
          first_name: PersonalInfoValidation.first_name,
          last_name: PersonalInfoValidation.last_name,
          email: EmailValidation,
          password: PasswordValidation,
          confirm_password: PasswordValidation
        })}
        values={initialValues}
        inputFields={[
          [
            <Input type="text" name="first_name" id="newUser_first_name" label="First name" />,
            <Input type="text" name="last_name" id="newUser_last_name" label="Last name" />,
          ],
          [
            <Input type="email" name="email" id="newUser_email" label="Email" />,
          ],
          [
            <Input type="password" name="password" id="newUser_password" label="Password" />,
            <Input type="password" name="confirm_password" id="newUser_confirm_password" label="Confirm password" />,

          ]
        ]}
        passwordRequirementsCheckField="password"
        agreement={<p style={{ display: "inline", paddingLeft: "5px" }}>
          I have read and agree to the <Link openNewTab id="terms_and_services_new_user" href="/termsConditions" linkText="Terms of Service" /> and <Link openNewTab id="privacy_policy_new_user" href="/privacyPolicy" linkText="Privacy Policy" />*.
        </p>}
      />
    </>}
  </>;
}

export default UserAccount;
