import SystemHandler from "actions/SystemHandler";
import { Form, Input, SelectDropdown } from "common/form";
import { IAddEntity, IBusinessInfo } from "common/interfaces";
import React, { useEffect, useState } from "react";
import { BusinessValidation, Schema } from "utils/validation/additionalValidation";

const BusinessInformationForm: React.FC<IAddEntity> = ({ onNext, initialValues }) => {

  const [annualSalesOptions, setAnnualSalesOptions] = useState([]);
  const [monthlyFinanceOptions, setMonthlyFinanceOptions] = useState([]);
  const [averageTicketOptions, setAverageTicketOptions] = useState([]);
  const [industrySpecialtyOptions, setIndustrySpecialtyOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);

  const [industry, setIndustry] = useState(null);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMonthlyFinanceVolume(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty()
    ]).then(responses => {
      setAnnualSalesOptions(responses[0]);
      setMonthlyFinanceOptions(responses[1]);
      setAverageTicketOptions(responses[2]);
      setIndustryOptions(responses[3]);
      setIndustrySpecialtyOptions(responses[4]);
    });

    if (initialValues.industry) {
      setIndustry(initialValues.industry);
    }
  }, []);

  useEffect(() => {
    if (!industry) {
      setSpecialtyOptions([]);
    } else {
      setSpecialtyOptions(industrySpecialtyOptions.filter(specialty => { return specialty.industry === parseInt(industry) }));
    }
  }, [industry]);

  return <Form
    id="addMerchantPopup_businessInformation"
    title="Add merchant • Business information"
    submitBtnText="Next"
    onFormSubmit={(data: IBusinessInfo) => { onNext(data); }}
    validationSchema={Schema({
      ...BusinessValidation,
    })}
    values={initialValues}
    buttonPosition="bottom right"
    inputFields={[
      [
        <Input name="name" id="addMerchantPopup_name" label="Business name*" />,
        <Input name="website" id="addMerchantPopup_website" label="Website*" />,
      ],
      [
        <Input name="external_id" id="addMerchantPopup_external_id" label="External ID" />,
        <SelectDropdown selectOptions={annualSalesOptions} name="annual_sales" id="addMerchantPopup_annual_sales" label="Annual sales" />,
      ],
      [
        <SelectDropdown selectOptions={monthlyFinanceOptions} name="monthly_financing_volume" id="addMerchantPopup_monthly_financing_volume" label="Est. monthly finance volume" />,
        <SelectDropdown selectOptions={averageTicketOptions} name="average_ticket" id="addMerchantPopup_average_ticket" label="Average ticket" />,
      ],
      [
        <SelectDropdown selectOptions={industryOptions} name="industry" id="addMerchantPopup_industry" label="Industry" onChange={(value: any) => { setIndustry(value) }} />,
        <SelectDropdown selectOptions={specialtyOptions} name="industry_specialty" id="addMerchantPopup_industry_specialty" label="Specialty" />
      ]
    ]}
    isInsidePopup
  />;
}

export default BusinessInformationForm;