import React from "react";
import StatsCard, { IStatsCard } from "./StatsCard";
import { Grid } from "@mui/material";
import { sanitizeString } from "utils/helpers";

interface IStatsCardRow {
  items: Array<IStatsCard>;
}

const StatsCardRow: React.FC<IStatsCardRow> = ({ items }) => {
  return <Grid container spacing={4} style={{ gap: "1%", marginLeft: "0" }}>
    {items.map((item, index) => {
      return <Grid style={{ minWidth: "250px", flexBasis: "25%", maxWidth: "24%", paddingLeft: "0", gap: "1%" }} key={`stats-card-${sanitizeString(item.description)}-${index}`} item xs={6} sm={4} md={4} lg={3} xl={2}>
        <StatsCard {...item} />
      </Grid>;
    })}
  </Grid>;
}

export default StatsCardRow;