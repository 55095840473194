import React, { useEffect, useState } from "react";
import _ from "lodash";
import TopbarTabs from "common/parts/TopbarTabs";
import { PartnerTabType } from "common/types";
import PartnerAccountTab from "./PartnerAccountTab";
import PartnerUsersTab from "./PartnerUsersTab";
import PartnerExperienceSettingsTab from "./PartnerExperienceSettingsTab";
import { useNavigate } from "react-router-dom";
import usePartner from "hooks/usePartner";

interface IPartnerAccountInformation2 {
  view: PartnerTabType;
}

const PartnerAccountInformation2: React.FC<IPartnerAccountInformation2> = ({ view }) => {
  const tabs: PartnerTabType[] = ["accountInformation", "users"/*, "experienceSettings"*/];

  const [activeTab, setActiveTab] = useState(view ? tabs.indexOf(view) : 0);
  const navigate = useNavigate();
  const partner = usePartner();

  const handleTabClick = (index) => {
    setActiveTab(index);
  }

  useEffect(() => {
    if (partner?.slug) {
      navigate(`/viewPartner/${partner?.slug}/${getPartnerInfoCardVariant()}`);
    }
  }, [activeTab]);

  const getPartnerInfoCardVariant = (): PartnerTabType => {
    return tabs[activeTab];
  }

  // partnerAccountTab will be used in the future

  return <>

    <TopbarTabs
      // items={[{ label: "Partner account" }, { label: "Users" }, { label: "Experience settings" }]}
      items={[{ label: "Partner account" }, { label: "Users" }]}
      activeTab={activeTab}
      onClick={handleTabClick}
    />

    {getPartnerInfoCardVariant() === "accountInformation" && <PartnerAccountTab variant={getPartnerInfoCardVariant()} />}
    {getPartnerInfoCardVariant() === "users" && <PartnerUsersTab variant={getPartnerInfoCardVariant()} />}
    {/* {getPartnerInfoCardVariant() === "experienceSettings" && <PartnerExperienceSettingsTab variant={getPartnerInfoCardVariant()} />} */}
  </>;
};

export default PartnerAccountInformation2;
