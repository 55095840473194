import Observer from "./Observer";

export default class TempStore {
  private static store: Map<string, any> = new Map();
  /**
   * @summary Retrieves a value from temp store.
   * @param {string} key - The key to look up in store.
   * @returns The value, or null if the key was not found.
   */
  static get<T>(key: string): T | undefined {
    return TempStore.store.get(key) as T;
  };

  /**
   * @summary Saves object to store using the specified key.
   * @param {string} key - The key to use for the item.
   * * @param {string} key - The key to use for the item.
   * @param {T} value - The value to save.
   */
  static save<T>(key: string, value: T, eventToTrigger?: string): void {
    TempStore.store.set(key, value);
    Observer.trigger(eventToTrigger);
  };

  static remove(key: string): void {
    TempStore.store.delete(key);
  }

  /**
   * @summary Clears all items from the store.
   */
  static clear(): void {
    TempStore.store.clear();
  };
}