import { CloseIcon, Scrollable } from "common";
import { Input, SelectDropdown, Form, EmptySpace } from "common/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { statesArray } from "utils/constants";
import { AddressValidation, BusinessValidation, Schema } from "utils/validation/additionalValidation";
import { objectsAreEqual, fillInitialValues, displayMiniFeedback, isForPartnerGlobal } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import { MerchantHandler } from "actions/MerchantHandler";
import SystemHandler from "actions/SystemHandler";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { PartnerHandler } from "actions/PartnerHandler";
import { useLoadingBar } from "hooks/useLoadingBar";
import useMerchant from "hooks/useMerchant";
import usePartner from "hooks/usePartner";

const EditBusinessInformationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const { merchantSlug, partnerSlug } = useParams();
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchant = useMerchant();
  const partner = usePartner();

  const [annualSalesOptions, setAnnualSalesOptions] = useState([]);
  const [monthlyFinanceOptions, setMonthlyFinanceOptions] = useState([]);
  const [averageTicketOptions, setAverageTicketOptions] = useState([]);
  const [industrySpecialtyOptions, setIndustrySpecialtyOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [industry, setIndustry] = useState(null);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [numberMerchantsOptions, setNumberMerchantOptions] = useState([]);
  const [partnerTypeOptions, setPartnerTypeOptions] = useState([]);

  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState<boolean>(true);
  useLoadingBar(!loading);

  const whiteLabelInitialValues = {
    id: "",
    name: "",
    address1: "",
    city: "",
    postal_code: "",
    state: "",
  };
  let merchantInitialValues = {
    id: "",
    name: "",
    address1: "",
    city: "",
    postal_code: "",
    website: "",
    annual_sales: "",
    state: "",
    monthly_financing_volume: "",
    average_ticket: "",
    industry: "",
    industry_specialty: "",
  };
  let partnerInitialValues = {
    id: "",
    name: "",
    address1: "",
    city: "",
    state: "",
    postal_code: "",
    website: "",
    merchant_number: "",
    type: ""
  }

  useEffect(() => {
    if (!initialValues) {
      if (user?.user_type === "WHITELABEL" && merchantSlug) {
        // This is a white label user looking at merchant account information
        setInitialValues(fillInitialValues({ ...merchantInitialValues, external_id: "" }, merchant));
        setIndustry(merchant.industry);
      } else if (user?.user_type === "PARTNER" && merchantSlug) {
        // This is a partner user looking at merchant account information
        setInitialValues(fillInitialValues({ ...merchantInitialValues, external_id: "" }, merchant));
        setIndustry(merchant.industry);
      } else if (user?.user_type === "WHITELABEL" && partnerSlug) {
        // This is a white label user looking at partner account information
        setInitialValues(fillInitialValues({ ...partnerInitialValues, external_id: "" }, partner));
      } else if (user?.user_type === "WHITELABEL") {
        // This is a white label user seeing their settings
        setInitialValues(fillInitialValues(whiteLabelInitialValues, whitelabel));
      } else if (user?.user_type === "MERCHANT") {
        // This is a merchant user seeing their settings
        setInitialValues(fillInitialValues(merchantInitialValues, merchant));
        setIndustry(merchant.industry);
      } else if (user?.user_type === "PARTNER") {
        // This is a partner user seeing their settings
        setInitialValues(fillInitialValues(partnerInitialValues, partner));
      }
    } else {
      setInitialValues(null);
    }
  }, [user, merchant, partner]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMonthlyFinanceVolume(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty(),
      SystemHandler.getNumberOfMerchants(),
      SystemHandler.getPartnerTypes()
    ]).then(responses => {
      setAnnualSalesOptions(responses[0]);
      setMonthlyFinanceOptions(responses[1]);
      setAverageTicketOptions(responses[2]);
      setIndustryOptions(responses[3]);
      setIndustrySpecialtyOptions(responses[4]);
      setNumberMerchantOptions(responses[5]);
      setPartnerTypeOptions(responses[6]);
    });
  }, []);

  useEffect(() => {
    if (!industry) {
      setSpecialtyOptions([]);
    } else if (industry) {
      setSpecialtyOptions(industrySpecialtyOptions.filter(specialty => { return specialty.industry === parseInt(industry) }));
    }
  }, [industry, industrySpecialtyOptions]);

  useEffect(() => {
    if (initialValues && industryOptions.length > 0 && averageTicketOptions.length > 0 &&
      industrySpecialtyOptions.length > 0 && annualSalesOptions.length > 0 &&
      monthlyFinanceOptions.length > 0 && numberMerchantsOptions.length > 0 &&
      partnerTypeOptions.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [initialValues, industryOptions, averageTicketOptions, industrySpecialtyOptions,
    annualSalesOptions, monthlyFinanceOptions, numberMerchantsOptions, partnerTypeOptions])

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const isForPartner = () => {
    return isForPartnerGlobal(user, partnerSlug, merchantSlug);
  }

  const handleSubmit = async (data: any) => {
    let payload = data;// for whitelabel
    if (user?.user_type === "MERCHANT" || (user?.user_type === "WHITELABEL" && merchantSlug) || (user?.user_type === "PARTNER" && merchantSlug)) {
      payload = {
        ...data,
        annual_sales: parseInt(data.annual_sales),
        average_ticket: parseInt(data.average_ticket),
        monthly_financing_volume: parseInt(data.monthly_financing_volume),
        industry: parseInt(data.industry),
        industry_specialty: parseInt(data.industry_specialty),
      }
    } else if (isForPartner()) {
      payload = {
        ...payload,
        merchant_number: parseInt(data.merchant_number),
        type: parseInt(data.type)
      }
    }

    if (!objectsAreEqual(payload, initialValues)) {
      if (user?.user_type === "MERCHANT" || (user?.user_type === "WHITELABEL" && merchantSlug) || (user?.user_type === "PARTNER" && merchantSlug)) {
        const response = await MerchantHandler().patch(merchantSlug || merchant?.slug, data);
        if (response) {
          Analytics.track({ experience: "portal", screen: user?.user_type === "WHITELABEL" && merchantSlug ? "merchants_account_info" : "settings_account_info", object: "business_info", action: "updated" } as ITracking, { merchant_id: response?.id });
        }
      } else if (isForPartner()) {
        const response = await PartnerHandler().patch(partnerSlug || partner?.slug, data);
        Analytics.track({ experience: "portal", screen: user?.user_type === "WHITELABEL" && partnerSlug ? "partners_account_info" : "settings_account_info", object: "business_info", action: "updated" } as ITracking, { partner_id: response?.id });
      } else if (user?.user_type === "WHITELABEL") {
        await WhiteLabelHandler.update(whitelabel?.slug, data);
      }
      displayMiniFeedback("Business information updated");

    } else {
      displayMiniFeedback("Nothing changed on the business information");
    }
    handleClose();
  }

  let inputFields = [
    [
      <Input name="name" id="editBusinessInformationPopup_name" label="Business name" />,
      <Input name="address1" id="editBusinessInformation_address1" label="Business address" />,
    ],
    [
      <Input name="city" id="editBusinessInformation_city" label="Business city" />,
      <SelectDropdown selectOptions={statesArray} name="state" id="editBusinessInformation_state" label="Business state" />,
    ],
    [
      <Input name="postal_code" id="editBusinessInformation_postal_code" maxLength={5} label="Postal code" />,
      <EmptySpace />
    ],
  ];
  if (user?.user_type === "MERCHANT" || (user?.user_type === "WHITELABEL" && merchantSlug) || (user?.user_type === "PARTNER" && merchantSlug)) {
    const externalIdField = user?.user_type === "MERCHANT" ? <EmptySpace /> : <Input name="external_id" id="editBusinessInformationPopup_external_id" label="External id" />;
    inputFields = [
      [
        <Input name="name" id="editBusinessInformationPopup_name" label="Business name" />,
        externalIdField
      ],
      [
        <Input name="address1" id="editBusinessInformation_address1" label="Business address" />,
        <Input name="city" id="editBusinessInformation_city" label="Business city" />,
      ],
      [
        <SelectDropdown selectOptions={statesArray} name="state" id="editBusinessInformation_state" label="Business state" />,
        <Input name="postal_code" id="editBusinessInformation_postal_code" maxLength={5} label="Postal code" />,
      ],
      [
        <Input name="website" id="editBusinessInformation_website" label="Website" />,
        <SelectDropdown selectOptions={annualSalesOptions} name="annual_sales" id="editBusinessInformation_annual_sales" label="Annual sales" />,
      ],
      [
        <SelectDropdown selectOptions={monthlyFinanceOptions} name="monthly_financing_volume" id="editBusinessInformation_monthly_financing_volume" label="Est. monthly finance volume" />,
        <SelectDropdown selectOptions={averageTicketOptions} name="average_ticket" id="editBusinessInformation_average_ticket" label="Average ticket" />,
      ],
      [
        <SelectDropdown selectOptions={industryOptions} name="industry" id="editBusinessInformation_industry" label="Industry" onChange={(value: any) => { setIndustry(value) }} />,
        <SelectDropdown selectOptions={specialtyOptions} name="industry_specialty" id="editBusinessInformation_industry_specialty" label="Specialty" />,
      ]
    ]
  } else if (isForPartner()) {
    const externalIdField = user?.user_type === "PARTNER" ? <EmptySpace /> : <Input name="external_id" id="editBusinessInformationPopup_external_id" label="External id" />;
    inputFields = [
      [
        <Input name="name" id="editBusinessInformationPopup_name" label="Business name" />,
        externalIdField
      ],
      [
        <Input name="address1" id="editBusinessInformation_address1" label="Business address" />,
        <Input name="city" id="editBusinessInformation_city" label="Business city" />,
      ],
      [
        <SelectDropdown selectOptions={statesArray} name="state" id="editBusinessInformation_state" label="Business state" />,
        <Input name="postal_code" id="editBusinessInformation_postal_code" maxLength={5} label="Postal code" />,
      ],
      [
        <Input name="website" id="editBusinessInformation_website" label="Website" />,
        <SelectDropdown selectOptions={numberMerchantsOptions} name="merchant_number" id="editBusinessInformation_merchant_number" label="No. of merchants in network" />,
      ],
      [
        <SelectDropdown selectOptions={partnerTypeOptions} name="type" id="editBusinessInformation_type" label="Partner type" />,
        <EmptySpace />
      ]
    ]
  }

  let validationSchema = Schema({
    ...AddressValidation,
    name: BusinessValidation.name
  });
  if (user?.user_type === "MERCHANT" || user?.user_type === "PARTNER" ||
    (user?.user_type === "WHITELABEL" && (merchantSlug || partnerSlug))) {
    validationSchema = Schema({
      ...AddressValidation,
      ...BusinessValidation
    });
  }

  return (
    <Popup isInner={true}>
      <>
        {!loading && <Scrollable>
          <CloseIcon id="editBusinessInformationPopup_closeIcon" color="dark" />
          <Form
            id="editBusinessInformationPopup"
            title="Edit business information"
            submitBtnText="Save"
            onFormSubmit={handleSubmit}
            validationSchema={validationSchema}
            values={initialValues}
            buttonPosition="bottom right"
            inputFields={inputFields}
            isInsidePopup
          />
        </Scrollable>}
      </>
    </Popup>
  );
};

export default EditBusinessInformationPopup;
