import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./useAuth";

const ProtectedRoute = ({ children }) => {
  const user = useAuth()?.user;
  const location = useLocation();

  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" replace state={{ pathname: location?.pathname }} />;
  }
  return children;
};

export default ProtectedRoute;
