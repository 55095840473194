import styles from "./LoadingCircle.module.scss";

const LoadingCircle: React.FC = () => {


  return (
    <>
      <div className={styles.loader} >
      </div>
    </>
  );
}

export default LoadingCircle;