import { IPanel } from "common/parts/panel/Panel";
import { IPanelLine } from "common/parts/panel/PanelLine";
import { ITabContent } from "common/parts/tabs/TabContent";
import { Call, ProfileCircle, Sms, Location, Global, Shop, Home, UserEdit, UserCirlceAdd, Calendar } from "iconsax-react";
import Analytics, { ITracking } from "classes/Analytics";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { useEffect, useState } from "react";
import { concatenateAddress } from "utils/helpers";
import { IAddress, ISettingsView } from "common/interfaces";
import { formatAPIDate, formatAPIPhone } from "utils/formatters";
import UsersTable from "../shared/UsersTable";
import { usePartnerOptions } from "hooks/usePartnerOptions";

const useBusinessAccount = (): ISettingsView => {
  const dispatch = useAppDispatch();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const partner = useAppSelector(state => state.partner);
  const [addressContact, setAddressContact] = useState<Array<IPanelLine>>(null);
  const [associations, setAssociations] = useState<Array<IPanelLine>>(null);
  const partnerOptions = usePartnerOptions();
  const [panels, setPanels] = useState<Array<IPanel>>([]);

  useEffect(() => {
    if (partner?.id && partnerOptions.ready) {
      setAddressContact([
        { icon: Location, label: concatenateAddress({ ...partner } as IAddress) },
        { icon: Global, label: partner?.website },
        { icon: Shop, label: partner?.merchant_number ? `${partnerOptions.options.numberMerchantsOptions.find((numMerchant: any) => numMerchant.id === partner?.merchant_number)?.label} (#merchants)` : "-" },
        { icon: UserCirlceAdd, label: partner?.type ? `${partnerOptions.options.partnerTypes.find((partType: any) => partType.id === partner?.type)?.label}` : "-" },
        { icon: ProfileCircle, label: partner?.main_contact_first_name ? `${partner.main_contact_first_name} ${partner.main_contact_last_name} (main contact)` : "-" },
        { icon: Call, label: formatAPIPhone(partner.main_contact_phone) },
        { icon: Sms, label: partner.main_contact_email || "-" }
      ]);
      setAssociations([
        { icon: Home, label: `Created by ${whitelabel?.name}` },
        { icon: UserEdit, label: `Creator ${partner?.created_by?.first_name} ${partner?.created_by?.last_name}` },
        { icon: Calendar, label: formatAPIDate(partner?.created_at) },
      ]);
    }
  }, [partner, partnerOptions.ready]);

  useEffect(() => {
    if (addressContact && associations) {
      setPanels([
        { title: partner?.name, lines: addressContact, editAction: () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_PARTNER_BUSINESS_AND_MAIN_CONTACT)) } },
        { title: "Associations", lines: associations },
      ]);
    }
  }, [addressContact, associations]);

  const tabContent: ITabContent = {
    sections: [
      {
        title: "Users",
        body: <UsersTable />,
        actions: [
          {
            label: "Invite user", visible: true, color: "primary", id: "invite-user-btn", onClick: () => {
              Analytics.track({ experience: "portal", screen: "settings", object: "invite_user_button", action: "clicked" } as ITracking);
              dispatch(popUpActions.openPopup(POPUPS.INVITE_USER));
            }
          }
        ]
      }
    ]
  }
  return { panels, tabContent };
}

export default useBusinessAccount;