// not used anywhere - 4th Sep 2024
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { useAuth } from "auth/useAuth";
import merchantActions from "reducers/MerchantReducer";
import Landing from "./Landing";
import { isForMerchantGlobal } from "utils/helpers";
import useMerchant from "hooks/useMerchant";

interface ILandingPagePreview {
  preview_logo?: string;
  forceMerchant?: boolean;
  // show name if the logo is missing
  businessName?: string;
  colors?: Array<string>;
  title?: string;
  subtitle?: string;
  hero?: string;
  showOnlyAboveFold?: boolean;
}

const LandingPagePreview: React.FC<ILandingPagePreview> = ({ preview_logo, forceMerchant, businessName,
  colors, title, subtitle, hero, showOnlyAboveFold }) => {
  const dispatch = useAppDispatch();
  const { merchantSlug } = useParams();
  const user = useAuth()?.user;
  const merchant = useMerchant();
  const whitelabel = useAppSelector(state => state.whitelabel);

  const [reload, setReload] = useState<number>(1);

  useEffect(() => {
    if (!merchantSlug) {
      if (user?.user_type === "WHITELABEL" && !merchantSlug) {
        dispatch(merchantActions.clear());
      }
    }
  }, [merchantSlug]);

  useEffect(() => {
    setReload(reload + 1);
  }, [window.location.href])

  const isForMerchant = () => {
    return forceMerchant || isForMerchantGlobal(user, merchantSlug);
  }

  // TODO: put all those landing page texts in one place
  const merchantFeatures = {
    titleBelowFold: "See how much you qualify for in just a few minutes with no impact to your credit score.",
    feat1: "Terms up to 144 months",
    feat2: "Rates starting at 5.99%",
    feat3: "Loans up to $100,000",
    feat4: "Access to 20+ lenders",
    stepsTitle: "Apply for your loan in 4 easy steps!",
    step1: "Fill out a simple, mobile friendly application",
    step2: "View your available loan offers",
    step3: "Choose the offer that best fits your budget",
    step4: "Finalize your application with your chosen lender",
  };

  const whitelabelFeatures = {
    titleBelowFold: "Unlock more sales with our flexible financing solutions",
    feat1: "Terms up to 144 months",
    feat2: "Rates starting at 5.99%",
    feat3: "Loans up to $100,000",
    feat4: "Access to 20+ lenders",
    stepsTitle: "How it works",
    step1: "Your customer fills out a simple, mobile-friendly application",
    step2: "A real-time decision is made from 20+ lenders",
    step3: "Your customer chooses the offer that best fits their budget",
    step4: "Once completed, your customer will be funded directly",
  };

  return <React.Fragment key={`landingPagePreview_${reload}`}>
    <div className="logoPreview">
      <Landing
        id="landingPreview"
        title={title || `${isForMerchant() ? `Unlock your possibilities with` : `Give your customers the power of choice with`} ${whitelabel?.name}`}
        subtitle={subtitle || `${isForMerchant() ? `Don't let a lack of financing options hold you
          back! We provide access to over 20 lenders to help
          with all of your financing needs.` : `Don't let a lack of financing options hold your business back!&nbsp;We provide access to over 20 lenders to help
          you convert more customers.`}`}
        hero={hero}
        {...(isForMerchant ? merchantFeatures : whitelabelFeatures)}
        type={isForMerchant() ? "MERCHANT" : "WHITELABEL"}
        preview={true}
        showOnlyAboveFold={showOnlyAboveFold}
        merchantLogoPreview={isForMerchant() ? (preview_logo || merchant?.primary_logo) : null}
        merchantInitials={businessName}
        colors={colors || null}
      />
    </div>
  </React.Fragment>;
}

export default LandingPagePreview;