import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, HeaderWL } from "common";
import styles from "./Login.module.scss";

const AccountDeactivation: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderWL />
      <main className={styles.loginPageContainer}>
        <Container className={styles.innerContainer}>
          <div className={styles.msgContainer}>
            <h1>
              Unfortunately, your account has been deactivated. Please reach out
              to support for more details.
            </h1>
            <Button
              id="accountDeactivation_back"
              label="Back to login"
              type="button"
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>
        </Container>
      </main>
    </>
  );
};

export default AccountDeactivation;
