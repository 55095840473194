import React, { useEffect, useState } from "react";
import styles from "./TabContent.module.scss";
import Button from "common/Button";
import { isNotNullNorUndefined, sanitizeString } from "utils/helpers";
import { IAction } from "common/interfaces";
import { useAppSelector } from "reducers/Hooks";

interface IContentSection {
  title: string;
  body: any;
  actions?: Array<IAction>;
}

export interface ITabContent {
  sections: Array<IContentSection>;
}

const TabContent: React.FC<ITabContent> = ({ sections }) => {
  const sidePanelsHeight = useAppSelector(state => state.app.sidePanelsHeight);
  const [minHeight, setMinHeight] = useState(0);
  
  useEffect(() => {
    if (sidePanelsHeight > 0 && minHeight !== sidePanelsHeight) {
      setMinHeight(sidePanelsHeight);
    }
  }, [sidePanelsHeight]);


  return <div style={{ minHeight: minHeight }}>
    {sections.map((section, index) => {
      return <React.Fragment key={`tab-content-section-${index}`}>
        <div className={styles["actions-parent"]}>
          <div className={styles["actions-child-left"]}>
            <h2 className={styles["tab-content-section-title"]}>{section.title}</h2>
          </div>
          {isNotNullNorUndefined(section?.actions) && <div className={styles["actions-child-right"]}>
            {section.actions.map((action, actionIndex) => {
              return <React.Fragment key={`${sanitizeString(section.title)}_action_${sanitizeString(action.label)}_${actionIndex}`}>
                {action.visible && <Button
                  variant={action.color}
                  type="button"
                  id={action.id}
                  label={action.label}
                  onClick={action.onClick}
                />}
              </React.Fragment>;
            })}
          </div>}
        </div>
        <div className={styles["tab-content-body"]}>{section.body}</div>
      </React.Fragment>;
    })}
  </div>;
}

export default TabContent;