import styles from "./SaveReportPopup.module.scss";
import { Button, CloseIcon } from "common";
import { Input } from "common/form";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";

const SaveReportPopup: React.FC<IPopup> = () => {
  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="saveReportPopup" color="dark" />
        <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Save report as</h2>
        <div className={styles.nameFieldContainer}>
          <div>
            <label className="label">Name</label>
            <Input id="saveReportPopup_name" type="text" name="name" />
          </div>
          <div className={styles.btnContainer}>
            <Button id="saveReportPopup_save" type="button" label="Save" />
          </div>
        </div>
        <div className={styles.reportInfo}>
          <ul>
            <li className="textMedium">
              Report type: <span>Program Performance</span>
            </li>
            <li className="textMedium">
              Fields:
              <br />
              <span>
                Program Name, # Apps Submitted, # Apps Offered, # Apps Clicked, #
                Loans Funded, Amt Requested, Amt Funded
              </span>
            </li>
            <li className="textMedium">
              Filters:
              <br />
              <span>N/A</span>
            </li>
          </ul>
        </div>
      </>
    </Popup>
  );
};

export default SaveReportPopup;
