import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from 'actions/ActionConstants';
import { AxiosPromise } from 'axios';
import Hasher from './Hasher';

export default class RequestHandler {
  private static pendingRequests: Map<string, AxiosPromise<any>> = new Map();

  static async makeRequest(endpoint: string, method: string = 'GET', data: any = null, isAnonymous: boolean = false): Promise<any> {
    const requestKey = `${method}-${endpoint}-${data ? Hasher.convert(JSON.stringify(data)) : ""}`;

    if (this.pendingRequests.has(requestKey)) {
      // If a request is already in progress, return a promise that resolves when the first request completes
      return this.pendingRequests.get(requestKey) as Promise<any>;
    }

    // Create a new promise for the request
    const requestPromise: AxiosPromise<any> = (isAnonymous ? ENABLE_FINANCING_API_INSTANCE_NO_TOKEN : ENABLE_FINANCING_API_INSTANCE).request({
      url: endpoint,
      method,
      data
    });

    // Store the promise in the map of pending requests
    this.pendingRequests.set(requestKey, requestPromise);

    try {
      // Wait for the request to complete and get the response data
      const response = await requestPromise;

      // Remove the completed request from the map of pending requests
      this.pendingRequests.delete(requestKey);

      // Return the response data
      return response;
    } catch (error) {
      // Remove the failed request from the map of pending requests
      this.pendingRequests.delete(requestKey);

      // Rethrow the error
      throw error;
    }
  }
}
