import Analytics, { ITracking } from "classes/Analytics";
import React from "react";
import { useNavigate } from "react-router-dom";
import { preventTextSelection } from "utils/helpers";

interface ILink {
  id: string;
  href: string;
  linkText?: string;
  openNewTab?: boolean;
  className?: string;
  rel?: string;
  children?: any;
  styleOverride?: any;
  disabled?: boolean;
  beforeClickAction?: () => void;
  onClick?: () => void;
}

const Link: React.FC<ILink> = ({ href, linkText, className, openNewTab, rel, children, id, styleOverride, disabled, beforeClickAction, onClick }) => {
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    if (onClick) {
      onClick();
    }

    // Analytics.track(TRACKING_EVENTS.LINK_CLICK, { href });
    if (href === "/forgotPassword") {
      Analytics.track( { experience: "portal_login", screen: "login", object: "forgot_password_link", action: "clicked" } as ITracking);
    }

    if (beforeClickAction) {
      beforeClickAction();
    }
    if (href?.substring(0, 1) === "#") {
      return;
    }
    if (href?.toLowerCase().startsWith("mailto") || href?.toLowerCase().startsWith("tel:")) {
      window.open(href);
      return;
    }
    if (!openNewTab) {
      e.preventDefault();
      if (href?.indexOf('http') > -1) {
        window.open(href);
      } else {
        if (href === window.location.pathname) {
          return;
        }
        return navigate(href);
      }
    }
  }

  return <a
    id={`${id}_link`}
    href={href}
    className={className || "menu"}
    target={openNewTab ? "_blank" : "_self"}
    rel={rel}
    onClick={disabled ? () => { } : handleClick}
    onMouseDown={preventTextSelection}
    style={styleOverride}>
    {linkText}
    {children}
  </a>;
};

export default Link;
