import styles from "./InfoCard.module.scss";
import { IInfoCard } from "common/interfaces";
import React from "react";

const InfoCard: React.FC<IInfoCard> = ({
  id,
  value,
  label,
  prefix,
  postfix,
  empty,
  disabled
}) => {
  return (
    <>
      {!empty && <div className={styles.infoCardWrapper}>
        <div id={`info-card-${id}`} className={styles.infoCard + (disabled ? " " + styles.disabled : "")}>
          <h1 className={styles.value}>{prefix}{value}{postfix}</h1>
          <p className={styles.label}>{label}</p>
        </div>
      </div>
      }
      {empty && <div className={styles.empty} />}
    </>
  );
};

export default InfoCard;
