import { IAddEntity } from "common/interfaces";
import React from "react";
import EditMerchantLandingPageTemplate from "content/EditMerchantLandingPageTemplate";

const LandingPageSettingsForm: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const handleSubmit = async (data: any, logo: any) => {
    onNext({ formValues: data, file: logo })
  }

  return <>
    <EditMerchantLandingPageTemplate
      onSubmit={handleSubmit}
      slug={initialValues?.slug}
      primary_logo={initialValues?.primary_logo}
      color_theme={initialValues?.color_theme}
      industry_template={initialValues?.industry_template}
      title="Add merchant • Landing page settings" 
    />
  </>;
}

export default LandingPageSettingsForm;