import styles from "./Settings.module.scss";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { Button, Grid, InfoContainer } from "common";
import BusinessInformationCard from "./accountInformation/BusinessInformationCard";
import UserAccountCard from "./accountInformation/UserAccountCard";
import MainContactCard from "./accountInformation/MainContactCard";
import { IAddress, IFeedback } from "common/interfaces";
import { askForConfirmation, displayFeedback, displayMiniFeedback, getAllStatuses } from "utils/helpers";
import Observer, { EVENTS } from "classes/Observer";
import SystemHandler from "actions/SystemHandler";
import _ from "lodash";
import { PartnerHandler } from "actions/PartnerHandler";
import { useAuth } from "auth/useAuth";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { UserOctagon } from "iconsax-react";
import { formatFullname } from "utils/formatters";

const PartnerAccountInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const partner = useAppSelector(state => state.partner);
  const whitelabel = useAppSelector(state => state.whitelabel);
  const { partnerSlug } = useParams();
  const observer = Observer.useObserver(EVENTS.PARTNER_UPDATED);
  const user = useAuth()?.user;
  const [partnerStatuses, setPartnerStatuses] = useState([]);

  useEffect(() => {
    getAllStatuses()
      .then(response => {
        setPartnerStatuses(response); // TODO: check if partner statuses are different
      });
  }, []);

  useEffect(() => {
    if (partnerSlug && !partner) {
      PartnerHandler().get(partnerSlug);
      SystemHandler.getSupportInfo();
    }

  }, [partnerSlug, observer]);

  const getStatusId = (status: "Active" | "Pending" | "Deactivated" | "Declined"): number => {
    return _.find(partnerStatuses, item => { return item.name === status })?.id;
  }

  const getPartnerSlug = () => {
    return partnerSlug || partner?.slug;
  }

  const handleDeactivate = () => {
    PartnerHandler().deactivate(partnerSlug)
      .then(() => {
        PartnerHandler().get(getPartnerSlug());
        displayMiniFeedback("Partner deactivated");
        dispatch(popUpActions.closePopup());
      });
  }

  const handleActivation = () => {
    PartnerHandler().approve(partnerSlug)
      .then(() => {
        PartnerHandler().get(getPartnerSlug());
        displayFeedback({
          title: `Success!`,
          body: `You have successfully activated ${partner?.name}!`,
          type: "PARTNER"
        } as IFeedback);
      });
  }

  const handleApprove = () => {
    PartnerHandler().approve(partnerSlug)
      .then(() => {
        PartnerHandler().get(getPartnerSlug());
        displayFeedback({
          title: `You have successfully approved ${partner?.name}!`,
          body: `Their account will now become active and the main contact will get an email to create a user account.`,
          type: "PARTNER"
        } as IFeedback);
      });
  }

  const handleDecline = (reason: string): void => {
    PartnerHandler().decline(partnerSlug, reason)
      .then(() => {
        PartnerHandler().get(getPartnerSlug());
        displayMiniFeedback(`You have successfully declined ${partner?.name}!`);
        dispatch(popUpActions.closePopup());
      });
  }

  return <>
    {partner && partner?.status === getStatusId("Pending") && user?.user_type === "WHITELABEL" && <div className={styles.approval}>
      <p className={styles.approvalText}>This partner requires approval</p>
      <Button id="partnerAccountInformation_btn_approve" label="Approve" style={{ padding: "1rem" }} onClick={handleApprove} />
      <Button id="partnerAccountInformation_btn_deny" label="Deny" style={{ padding: "1rem" }} variant="secondary" onClick={() => {
        dispatch(popUpActions.openPopup({
          name: POPUPS.DECLINE_MERCHANT,
          message: {
            partner,
            onSend: handleDecline
          }
        })); // TODO: refactor decline merchant popup to be reused by partner
      }} />
    </div>}
    {partner && <div className={styles.contentContainer}>
      <Row>
        <Col md={6} lg={6} className={styles.accountInformationTitle}>
          {user?.user_type === "WHITELABEL" && <div style={{ color: "var(--primaryVariationTextColor)" }}>{partner?.name} <span style={{ color: "var(--primaryColor", fontSize: "1.6rem", fontWeight: "700" }}>{partner?.status === getStatusId("Deactivated") ? "(deactivated)" : (partner?.status === getStatusId("Declined") ? "(declined)" : (partner?.status === getStatusId("Pending") ? "(pending)" : ""))}</span> account information</div>}
        </Col>
        <Col md={6} lg={6}>
          {partner && partner?.status !== getStatusId("Pending") && user?.user_type === "WHITELABEL" && <Grid item>
            <div style={{
              display: "flex",
              justifyContent: "end",
            }}>
              <Button
                id="partnerAccountInformation_deactivate_partner"
                type="button"
                label={partner?.status === getStatusId("Deactivated") || partner?.status === getStatusId("Declined") ? "Activate" : "Deactivate"}
                onClick={() => {
                  if (partner?.status === getStatusId("Deactivated") || partner?.status === getStatusId("Declined")) {
                    askForConfirmation(`Are you sure you want to activate ${partner?.name}?`,
                      { text: 'Yes, I do', action: () => { dispatch(popUpActions.closePopup()); handleActivation() } },
                      { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                  } else {
                    askForConfirmation(`Are you sure you want to deactivate ${partner?.name}?`,
                      { text: 'Yes, deactivate', action: () => { handleDeactivate() } },
                      { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                  }
                }}
                variant="secondary"
                style={{ width: "auto" }}
              />
            </div>
          </Grid>}
        </Col>
      </Row>
      <Grid horizontal>
        <Grid>
          <Grid item>
            <BusinessInformationCard
              id="partner_business_information"
              externalId={partner?.external_id}
              businessName={partner?.name}
              mainAddress={{
                address1: partner?.address1,
                address2: partner?.address2 || "",
                city: partner?.city,
                state: partner?.state,
                postal_code: partner?.postal_code
              } as IAddress}
              website={partner?.website}
              merchantNumber={partner?.merchant_number}
              partnerType={partner?.type}
              type="PARTNER"
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item>
            <MainContactCard
              cardTitle="Main contact information"
              firstName={partner?.main_contact_first_name}
              lastName={partner?.main_contact_last_name}
              email={partner?.main_contact_email}
              phone={partner?.main_contact_phone}
            />
          </Grid>
          {user?.user_type === "WHITELABEL" && <Grid item>
            <InfoContainer
              id="association_card"
              title="Associations"
              rows={[
                { label: "Created by (account)", value: whitelabel?.name }, // TODO: get account account
                { label: "Created by (user)", value: formatFullname(partner?.created_by?.first_name, partner?.created_by?.last_name) }
                // { label: "Created by (user)", value: `${partner?.created_by?.first_name} ${partner?.created_by?.last_name}`, onClick: () => {
                //   dispatch(popUpActions.openPopup({
                //     name: POPUPS.EDIT_USER_ASSOCIATION,
                //     message: {
                //       type: "PARTNER",
                //       partner
                //     }
                //   }));
                // }}
              ]}
              icon={UserOctagon}
            />
          </Grid>}
          {user && user?.user_type === "PARTNER" && <Grid item>
            <UserAccountCard />
          </Grid>}
        </Grid>
      </Grid>
    </div>}
  </>;
};

export default PartnerAccountInformation;
