import { Link } from "common";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Footer.module.scss";
import { useAppSelector } from "reducers/Hooks";
import { Hidden } from "@mui/material";
import PoweredByEnable from "./PoweredByEnable";
import Analytics, { ITracking } from "classes/Analytics";

interface IFooter {
  type: "MERCHANT" | "WHITELABEL";
  onWLLinkClick?: () => void;
  onTermsOfServiceClick?: () => void;
  onPrivacyPolicyClick?: () => void;
  onAdvertiserDisclosureClick?: () => void;
}

const Footer: React.FC<IFooter> = ({ type, onWLLinkClick, onTermsOfServiceClick, 
  onPrivacyPolicyClick, onAdvertiserDisclosureClick }) => {
  const whitelabel = useAppSelector(state => state.whitelabel);

  return (
    <div className={styles.footerContainer}>
      <Container>
        <Row className={styles.row}>
          <Col sm={12} md={12} lg={12} className={styles.footerCol1}>
            <p>
              Not all applicants will prequalify for loan offers. All rates,
              fees, and terms are presented without guarantee and are subject to
              change pursuant to each provider’s discretion and may not be
              available in all states or for all types of loans. There is no
              guarantee you will be approved or qualify for the advertised
              rates, fees, or terms presented.
            </p>
            <ul>
              <li></li>
              <li>
                <Link
                  id="whitelabel website"
                  href={whitelabel?.website}
                  openNewTab
                  linkText={whitelabel?.name + " • "}
                  onClick={onWLLinkClick}
                />
              </li>
              <li>
                <Link
                  id="footer_termsConditions"
                  href="/termsConditions"
                  openNewTab
                  linkText="Terms of Service • "
                  onClick={onTermsOfServiceClick}
                />
              </li>
              <li>
                <Link
                  id="footer_privacyPolicy"
                  href="/privacyPolicy"
                  openNewTab
                  linkText="Privacy Policy • "
                  onClick={onPrivacyPolicyClick}
                />
              </li>
              <li>
                <Link
                  id="footer_advertiserDisclosure"
                  href="/advertiserDisclosure"
                  openNewTab
                  linkText="Advertiser Disclosure"
                  onClick={onAdvertiserDisclosureClick}
                />
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Hidden mdDown>
        <PoweredByEnable darkMode={true} />
      </Hidden>
    </div>
  );
};

export default Footer;
