import React, { ReactNode } from "react";
import styles from "./Scrollable.module.scss";

interface IScrollable {
  children?: ReactNode;
  className?: string;
  styleOverride?: object;
}

const Scrollable: React.FC<IScrollable> = ({ children, className, styleOverride }) => {
  return (
    <div className={[styles.scrollable, className].join(" ")} style={styleOverride}>
      {children}
    </div>
  );
}
export default Scrollable;