import React from "react";
import styles from "./FeedbackPopup.module.scss";
import { SVG } from "common";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useInnerDimensions } from "hooks/useInnerDimensions";

export const Unplugged: React.FC = () => {
  const height = 150;
  const max600 = useMediaQuery('only screen and (max-width: 600px)');
  const maxHeight500 = useMediaQuery('only screen and (max-height: 500px)');

  const [innerWidth] = useInnerDimensions();

  return <div
    className={styles.backgroundImage}
    style={{
      marginLeft: -50,
      marginRight: -50,
      height: height + (maxHeight500 ? 0 : 50),
      marginTop: 20
    }}>
    <SVG
      src="/assets/feedback/leftPlug.svg"
      title="Left plug"
      height={height + 110}
      // wrapperStyle={{ position: 'absolute', left: 5, top: 130 }}
      // wrapperStyle={{ position: 'absolute', left: -50, top: 130 }}
      wrapperStyle={{ position: 'absolute', left: `${Math.min(0, innerWidth / 2 - 350)}px`, top: `${maxHeight500 ? 70 : max600 ? 110 : 110}px` }}
    />
    <SVG
      src="/assets/feedback/rightPlug.svg"
      title="Right plug"
      height={height + 100}
      // wrapperStyle={{ position: 'absolute', right: 15, top: 100 }}
      wrapperStyle={{ position: 'absolute', right: `${Math.min(0, innerWidth / 2 - 350)}px`, top: `${maxHeight500 ? 40 : max600 ? 80 : 80}px` }}
    />
  </div>;
};

export default Unplugged;
