import styles from "./Settings.module.scss";
import { Sidebar, DashboardHeader, Aside } from "common";
import React from "react";
import { useFlag } from "hooks/useFlag";
import MerchantSettingsView from "./settings/merchant/MerchantSettingsView";
import InnerPopups from "content/popups/InnerPopups";

const MerchantSettings: React.FC = () => {
  return <main className={styles.settingsPageContainer}>
    <Sidebar />
    <Aside>
      <DashboardHeader />
      <InnerPopups />
      {<MerchantSettingsView />}
    </Aside>
  </main>;
};

export default MerchantSettings;
