import { InfoContainer } from "common";
import { IAddress } from "common/interfaces";
import React, { useEffect, useState } from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch } from "reducers/Hooks";
import { POPUPS } from "utils/constants";
import _ from "lodash";
import { handleNull } from "utils/helpers";
import { useAuth } from "auth/useAuth";
import SystemHandler from "actions/SystemHandler";
import { Shop, Home, Icon } from "iconsax-react";
import { useLocation } from "react-router-dom";

interface IBusinessInformationCard {
  id: string;
  externalId?: string;
  businessName: string;
  mainAddress: IAddress;
  type: "MERCHANT" | "WHITELABEL" | "PARTNER";
  cardTitle?: string;
  disabled?: boolean;
  website?: string;
  annualSales?: number | null;
  estMonthlyFinanceVolume?: number | null;
  averageTicket?: number | null;
  industry?: number | null;
  specialty?: number | null;
  merchantNumber?: number | null;
  partnerType?: number | null;
}

const BusinessInformationCard: React.FC<IBusinessInformationCard> = ({ id, cardTitle, businessName, mainAddress,
  disabled, type, website, annualSales, estMonthlyFinanceVolume, averageTicket, industry, specialty, externalId,
  merchantNumber, partnerType }) => {
  const dispatch = useAppDispatch();
  const user = useAuth()?.user;

  const [annualSalesOptions, setAnnualSalesOptions] = useState([]);
  const [estMonthlyFinanceVolumeOptions, setEstMonthlyFinanceVolumeOptions] = useState([]);
  const [averageTicketOptions, setAverageTicketOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [merchantDetailsLabels, setMerchantDetailsLabels] = useState<any>(null);
  const [partnerDetailsLabels, setPartnerDetailsLabels] = useState<any>(null);
  const [numberMerchantsOptions, setNumberMerchantsOptions] = useState([]);
  const [partnerTypeOptions, setPartnerTypeOptions] = useState([]);
  const [rows, setRows] = useState([]);
  const location = useLocation();
  const [businessInformationIcon, setBusinessInformationIcon] = useState<Icon>(Shop);

  useEffect(() => {
    if (location.pathname.includes("settings")) {
      setBusinessInformationIcon(Home);
    }
  }, [location])

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMonthlyFinanceVolume(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty(),
      SystemHandler.getNumberOfMerchants(),
      SystemHandler.getPartnerTypes()
    ]).then(responses => {
      setAnnualSalesOptions(responses[0]);
      setEstMonthlyFinanceVolumeOptions(responses[1]);
      setAverageTicketOptions(responses[2]);
      setIndustryOptions(responses[3]);
      setSpecialtyOptions(responses[4]);
      setNumberMerchantsOptions(responses[5]);
      setPartnerTypeOptions(responses[6]);
    });
  }, []);

  useEffect(() => {
    if (type === "MERCHANT" && annualSalesOptions?.length > 0 && estMonthlyFinanceVolumeOptions?.length > 0 &&
      averageTicketOptions?.length > 0 && industryOptions?.length > 0 && specialtyOptions?.length > 0) {
      setMerchantDetailsLabels({
        annual_sales: _.find(annualSalesOptions, option => { return option.id === annualSales })?.label || annualSales,
        monthly_financing_volume: _.find(estMonthlyFinanceVolumeOptions, option => { return option.id === estMonthlyFinanceVolume })?.label || estMonthlyFinanceVolume,
        average_ticket: _.find(averageTicketOptions, option => { return option.id === averageTicket })?.label || averageTicket,
        industry: _.find(industryOptions, option => { return option.id === industry })?.label || industry,
        industry_specialty: _.find(specialtyOptions, option => { return option.id === specialty })?.label || handleNull(specialty)
      });
    } else if (type === "PARTNER" && numberMerchantsOptions?.length > 0 && partnerTypeOptions?.length > 0) {
      setPartnerDetailsLabels({
        merchant_number: _.find(numberMerchantsOptions, option => { return option.id === merchantNumber })?.label || merchantNumber,
        type: _.find(partnerTypeOptions, option => { return option.id === partnerType })?.label || partnerType
      })
    }
  }, [annualSalesOptions, estMonthlyFinanceVolumeOptions, averageTicketOptions,
    industryOptions, specialtyOptions, type, annualSales, estMonthlyFinanceVolume,
    averageTicket, industry, specialty, numberMerchantsOptions, merchantNumber,
    partnerTypeOptions, partnerType]);

  useEffect(() => {
    setNewRows();
  }, [id, cardTitle, businessName, mainAddress, disabled, type, website,
    merchantDetailsLabels, partnerDetailsLabels]);

  const setNewRows = () => {
    let newRows = [];
    if (type === "WHITELABEL" || type === "PARTNER") {
      newRows = [
        { label: "Business name", value: handleNull(businessName) },
        { label: "Main address", value: handleNull(`${mainAddress.address1} ${mainAddress?.address2 || ""}`) },
        { label: "City", value: handleNull(mainAddress?.city) },
        { label: "State", value: handleNull(mainAddress?.state) },
        { label: "Postal code", value: handleNull(mainAddress?.postal_code) },
      ];
    } else if (type === "MERCHANT") {
      newRows = [
        { label: "Business", value: handleNull(businessName), id: "merchant-business-name" },
        { label: "Address", value: handleNull(`${mainAddress.address1 || ""} ${mainAddress?.address2 || ""}`) },
        { label: "City", value: handleNull(mainAddress?.city) },
        { label: "State", value: handleNull(mainAddress?.state) },
        { label: "Postal code", value: handleNull(mainAddress?.postal_code) },
        { label: "Website", value: handleNull(website) },
        { label: "Annual sales", value: handleNull(merchantDetailsLabels?.annual_sales) },
        { label: "Est monthly finance volume", value: handleNull(merchantDetailsLabels?.monthly_financing_volume) },
        { label: "Average ticket", value: handleNull(merchantDetailsLabels?.average_ticket) },
        { label: "Industry", value: handleNull(merchantDetailsLabels?.industry) },
        { label: "Specialty", value: handleNull(merchantDetailsLabels?.industry_specialty) }
      ];
    }
    if (type === "PARTNER") {
      newRows = [
        ...newRows,
        { label: "Website", value: handleNull(website) },
        { label: "No. of merchants in network", value: handleNull(partnerDetailsLabels?.merchant_number) },
        { label: "Partner type", value: handleNull(partnerDetailsLabels?.type) },
      ];
    }
    if (user?.user_type === "WHITELABEL" && (type === "MERCHANT" || type === "PARTNER")) {
      newRows = [
        { label: "External id", value: handleNull(externalId) },
        ...newRows
      ]
    }
    setRows(newRows);
  }

  return <InfoContainer
    id={`${id}_business_information`}
    title={cardTitle || "Business information"}
    rows={rows}
    onClick={() => dispatch(popUpActions.openPopup(POPUPS.EDIT_BUSINESS))}
    disabled={disabled}
    icon={businessInformationIcon}
  />;
}

export default BusinessInformationCard;