import { CloseIcon } from "common";
import { Input, Form } from "common/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup, ISupport } from "common/interfaces";
import { EmailValidation, NameValidation, PhoneValidation, Schema } from "utils/validation/additionalValidation";
import { displayMiniFeedback } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import NewPopup from "./NewPopup";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import Analytics, { ITracking } from "classes/Analytics";
import EnableCache from "classes/EnableCache";

const EditSupportPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const message = useAppSelector(state => state.popUp.messagePassing);
  const support = useAppSelector(state => state.app.support);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (message && support) {
      const info = message === "MERCHANT" ? support.merchant : support.borrower;
      setInitialValues({
        name: info?.name,
        phone: info?.phone,
        email: info?.email
      });
    }
  }, [message, support]);

  useEffect(() => {
    return () => {
      setInitialValues(null);
    };
  }, []);

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const handleSubmit = async (data: ISupport) => {
    await WhiteLabelHandler.updateSupport(message === "MERCHANT" ? "merchant-support" : "borrower-support", data);
    Analytics.track({ experience: "portal", screen: "settings_account_info", object: message === "MERCHANT" ? "merchant_help_contact" : "borrower_help_contact", action: "updated" } as ITracking);
    EnableCache.remove('support/');
    displayMiniFeedback("Support information updated");
    handleClose();
  }

  return <>
    {initialValues && <NewPopup style={{ width: 800 }}>
      <>
        <CloseIcon id="editSupportPopup" color="dark" />
        <Form
          id="editSupportPopup"
          title={`Edit ${message === "MERCHANT" ? "merchant" : "borrower"} support`}
          submitBtnText="Save"
          onFormSubmit={handleSubmit}
          validationSchema={Schema({
            name: NameValidation,
            email: EmailValidation,
            phone: PhoneValidation
          })}
          values={initialValues}
          buttonPosition="side right"
          inputFields={[
            [
              <Input name="name" id="editSupportPopup_name" label="Name" />,
              <Input name="phone" id="editSupportPopup_phone" label="Phone" mask="phone" />
            ],
            [
              <Input name="email" id="editSupportPopup_email" label="Email" type="email" />,
            ]
          ]}
          isInsidePopup
        />
      </>
    </NewPopup>}
  </>;
};

export default EditSupportPopup;
