import { Table } from "common";
import React, { useEffect, useRef, useState } from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch } from "reducers/Hooks";
import { POPUPS } from "utils/constants";
import { askForConfirmation, displayMiniFeedback, getAllStatuses } from "utils/helpers";
import { IHighlight, IList } from "common/interfaces";
import { TableRef } from "common/Table";
import Observer, { EVENTS } from "classes/Observer";
import LocationsHandler from "actions/LocationsHandler";
import UserHandler from "actions/UserHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { useAuth } from "auth/useAuth";
import useMerchant from "hooks/useMerchant";

const LocationsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const merchant = useMerchant();
  const auth = useAuth();
  const [statuses, setStatuses] = useState([]);
  const observer = Observer.useObserver(EVENTS.LOCATION_UPDATED);
  const tableRef = useRef<TableRef>(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    getAllStatuses()
      .then(response => {
        setStatuses(response);
      });
  }, []);

  useEffect(() => {
    setReady(merchant?.id && statuses?.length > 0);
  }, [merchant, statuses]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [observer]);

  const update = (location: any, status: "Active" | "Deactivated") => {
    const locationDeactivated = {
      name: location.name || location.location_name,
      status: statuses.find(locStatus => { return locStatus.label.Equals(status) })?.value
    };

    LocationsHandler.update(locationDeactivated, location.id)
      .then(() => {
        Analytics.track({ experience: "portal", screen: "settings_locations", object: "location", action: status === "Active" ? "activated" : "deactivated" } as ITracking);
        displayMiniFeedback(`${location.name} is now ${status.toLowerCase()}`);
        dispatch(popUpActions.closePopup());
      });
  };

  const makeLocationPrimary = (item: any) => {
    const payload = {
      primary_location: item.id,
    }
    if (auth?.user?.id) {
      UserHandler.updateMerchantUserForWL(merchant?.id, auth.user.id, payload)
        .then(() => {
          Analytics.track({ experience: "portal", screen: "settings_locations", object: "primary_location", action: "updated" } as ITracking);
          UserHandler.getProfile()
            .then(response => {
              auth.setUser(response);
              dispatch(popUpActions.closePopup());
              Observer.trigger(EVENTS.LOCATION_UPDATED);
            });
        });
    }
  };

  const getData = async (next: string): Promise<IList> => {
    let list = await LocationsHandler.getAll(next, merchant?.slug, null, null, null, true);
    list.results = list.results.map(location => {
      return {
        ...location,
        highlight: { highlighted: location.id === auth?.user?.primary_location && auth?.user?.user_type === "MERCHANT", property: "name", chip: "primary" } as IHighlight
      };
    });
    return Promise.resolve(list);
  }

  return <>
    {!ready && <></>}
    {ready && <Table
      data={getData}
      maxHeight={450}
      headers={[
        { label: "Location name", value: "name", size: 9, sortBy: "location__name" },
        { label: "Address", value: "address1", size: 8 },
        { label: "City", value: "city", size: 7 },
        { label: "State", value: "state", size: 4 },
        { label: "Postal code", value: "postal_code", size: 5 },
        { label: "Status", value: "status", size: 5 },
        { label: "Actions", value: "actions", size: 7, preventSorting: true, align: "left" }
      ]}
      tableBodyStyle={{ minWidth: 1200 }}
      ref={tableRef}
      id="merchant_settings_locations"
      // partner admin has to be able to edit declined merchants
      action={[
        {
          label: "Edit",
          value: (item) => {
            dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_LOCATION, message: item }));
            return Promise.resolve();
          },
          visible: (item: any) => {
            if (!item.status.Equals("Deactivated")) {
              return true;
            }
          }
        },
        {
          label: "Activate",
          value: (item) => {
            askForConfirmation(`Are you sure you want to activate ${item?.name}?`,
              { text: 'Yes, I do', action: () => { update(item, "Active") } },
              { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
            return Promise.resolve();
          },
          visible: (item: any) => {
            if (item.status.Equals("Deactivated")) {
              return true;
            }
          }
        },
        {
          label: "Deactivate",
          value: (item) => {
            askForConfirmation(`Are you sure you want to deactivate ${item?.name}?`,
              { text: 'Yes, deactivate', action: () => { update(item, "Deactivated") } },
              { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
            return Promise.resolve();
          },
          visible: (item: any) => {
            if (!item.status.Equals("Deactivated")) {
              return true;
            }
          }
        },
        {
          label: "Make primary",
          value: (item) => {
            askForConfirmation(`Are you sure you want to make ${item?.name} the primary location?`,
              { text: 'Yes, make primary', action: () => { makeLocationPrimary(item) } },
              { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
            return Promise.resolve();
          },
          visible: (item: any) => {
            if (item.id !== auth?.user?.primary_location && !item.status.Equals("Deactivated")) {
              return true;
            }
          }
        }
      ]}
      minDropdownWidth={125} // based on "Make primary"
    />}
  </>;
};

export default LocationsTable;
