import { Add } from "iconsax-react";
import AppIcon from "./AppIcon";
import styles from "./CloseIcon.module.scss";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";

interface ICloseIcon {
  id: string;
  color: "dark" | "light";
  onClose?: () => void;
}

const CloseIcon: React.FC<ICloseIcon> = ({ id, color, onClose }) => {
  const dispatch = useAppDispatch();

  return <AppIcon
    clickTrigger={{
      id: `${id}_close`, onClick: () => {
        if (onClose) {
          onClose();
        }
        dispatch(popUpActions.closePopup());
      }
    }}
    color={`${color === "dark" ? "var(--darkTextColor)" : "var(--whiteTextColor)"}`}
    size={35}
    className={styles.crossIcon}
    icon={Add}
  />;
}
export default CloseIcon;