import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IWhiteLabel } from 'common/interfaces';
import { calculateColorVariables, getDarkMode } from 'utils/colors';

const initialState: IWhiteLabel = {
  id: '',
  name: '',
  domain: '',
  website: '',
  slug: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal_code: '',
  phone: '',
  main_contact_first_name: '',
  main_contact_last_name: '',
  main_contact_email: '',
  main_contact_phone: '',
  primary_logo: '',
  secondary_logo: '',
  square_logo: '',
  primary_color: '',
  secondary_color: '',
  display_merchant_logo: false,
  display_partner_logo: false,
  display_powered_by_enable: false,
  auto_approve_merchant: false,
  merchant_page_layout: 'SIDE_BY_SIDE',
  business_lending_enabled: false,
  business_lending_url: '',
  created_by: 0,
  direct_to_merchant: false,
  dark_mode: true,
  programs: ["DTC"],
};

const counterSlice = createSlice({
  name: 'whiteLabel',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<{ whiteLabelData: any, setColors: boolean }>) => {
      state = Object.assign(state, action.payload.whiteLabelData);
      if (state && action.payload.setColors) {
        const style = document.documentElement.style;
        const variables = calculateColorVariables(state["primary_color"], state["secondary_color"]);
        const darkMode = getDarkMode(state["primary_color"]);
        state = Object.assign(state, { dark_mode: darkMode });
        variables.forEach(item => {
          style.setProperty(item.variable_name, item.color_code);
        });
      }
    }
  }
})

export const whiteLabelReducer = counterSlice.reducer;
export default counterSlice.actions;
