import { Form, Input, SelectDropdown, TextArea } from "common/form";
import TripleInput from "common/form/TripleInput";
import { IAddEntity } from "common/interfaces";
import useMerchant from "hooks/useMerchant";
import { useMerchantOptions } from "hooks/useMerchantOptions";
import React from "react";
import { AnnualSalesValidation, PercentValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";

const SalesInformation: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const merchant = useMerchant();
  const merchantOptions = useMerchantOptions(merchant?.industry || -1);

  const handleSubmit = (data: any) => {
    // check the percentage sum
    if (Number(data?.business_percent_customer_sales) + Number(data?.business_percent_business_sales) + Number(data?.business_percent_government_sales) === 100) {
      onNext(data);
    }
  }
  return <>
    <Form
      id="dtm_application_sales_information"
      title="Business sales information"
      submitBtnText="Next"
      onFormSubmit={handleSubmit}
      validationSchema={Schema({
        sales_industry: SimpleRequireValidation,
        sales_last_year_annual_sales: AnnualSalesValidation,
        sales_avg_ticket: SimpleRequireValidation,
        sales_estimated_monthly_volume: SimpleRequireValidation,
        sales_refund_policy: SimpleRequireValidation,
        business_description: SimpleRequireValidation,
        business_percent_customer_sales: PercentValidation,
        business_percent_business_sales: PercentValidation,
        business_percent_government_sales: PercentValidation
      })}
      values={initialValues}
      buttonPosition="side right"
      inputFields={[
        [
          <SelectDropdown selectOptions={merchantOptions.options.industryOptions} name="sales_industry" id="dtm_application_sales_industry" label="Industry*" />,
          <Input name="sales_last_year_annual_sales" id="dtm_application_sales_last_year_annual_sales" label="Annual sales last year* " inputPrefix="$" mask="amount" />,
        ],
        [
          <Input name="sales_avg_ticket" id="dtm_application_sales_avg_ticket" label="Average ticket price*" mask="amount" />,
          <Input name="sales_estimated_monthly_volume" id="dtm_application_sales_estimated_monthly_volume" label="Est. monthly finance volume*" mask="amount" />,
        ],
        [
          <TextArea name="sales_refund_policy" id="dtm_application_sales_refund_policy" label="What is your Refund Policy?*" />,
          <TextArea name="business_description" id="dtm_application_business_description" label="Brief description of your product/service*" />,
        ],
        [
          <TripleInput
            label="% of sale for each type of customer*">
            <Input name="business_percent_customer_sales" id="dtm_application_business_percent_customer_sales" label="Consumer" mask="percent" maxLength={3} />
            <Input name="business_percent_business_sales" id="dtm_application_business_percent_business_sales" label="Business" mask="percent" maxLength={3} />
            <Input name="business_percent_government_sales" id="dtm_application_business_percent_government_sales" label="Government" mask="percent" maxLength={3} />
          </TripleInput>
        ],
      ]}
      isInsidePopup
    />;
  </>;
}

export default SalesInformation;