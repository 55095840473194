import styles from "./LandingPageSettingsPopup.module.scss";
import { Button, CloseIcon, LandingPagePreview, Scrollable } from "common";
import { FormikError } from "common/form";
import { InfoCircle } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { LandingPageValidation, Schema } from "utils/validation/additionalValidation";
import { displayMiniFeedback, getStatus, isForMerchantGlobal } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { useParams } from "react-router-dom";
import { MerchantHandler } from "actions/MerchantHandler";
import { useAuth } from "auth/useAuth";
import useMerchant from "hooks/useMerchant";

const LandingPageLogoSettingsPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const { merchantSlug } = useParams();
  const [toolTip, setToolTip] = useState(false);
  const [uploadedFile, setUploadedFile] = React.useState<any>("");
  const merchant = useMerchant();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const user = useAuth()?.user;

  const [isMerchantDeactivated, setIsMerchantDeactivated] = useState(false);

  useEffect(() => {
    if (merchant) {
      getStatus("Deactivated").then(response => setIsMerchantDeactivated(merchant?.status === response));
    }
  }, [merchant]);

  useEffect(() => {
    return () => {
      setUploadedFile("");
      formik.setFieldValue("primary_logo", "");
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      primary_logo: "",
    },
    validationSchema: Schema({
      primary_logo: LandingPageValidation.primary_logo
    }),
    async onSubmit(values) {
      if (isForMerchant()) {
        await MerchantHandler().patchWithLogo(uploadedFile, merchant?.slug, merchant?.slug);
      }
      displayMiniFeedback("Logo was updated");
      dispatch(popUpActions.closePopup());
    },
  });

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="landingPageSettingsPopup" color="dark" />
        <form onSubmit={formik.handleSubmit}>
          <h2>Landing page settings</h2>
          <div className={styles.inviteFormContainer}>
            <div className={styles.inviteFormRow}>
              <div
                className={`${styles.formField} ${styles.uploadLogoContainer}`}>
                <div className={styles.labelContainer}>
                  <p>Logo</p>
                  <InfoCircle size={20} color="var(--darkTextColor)"
                    onMouseEnter={() => setToolTip(true)}
                    onMouseLeave={() => setToolTip(false)}
                  />
                  {toolTip && (
                    <div className={`${styles.pop} ${styles.pop2}`}>
                      <p className="info">
                        Image criteria for logos:
                        <ul>
                          <li>At least 100px wide</li>
                          <li>Transparent or white background</li>
                          <li>PNG or SVG</li>
                        </ul>
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.uploadContainer}>
                  <input
                    type="file"
                    id="landingPageSettingsPopup_actual-btn"
                    name="primary_logo"
                    hidden
                    onChange={(e: any) => {
                      formik.setFieldValue("primary_logo", e.target.files[0].name);
                      setUploadedFile(e.target.files[0]);
                    }}
                  />
                  <label htmlFor="landingPageSettingsPopup_actual-btn">Upload</label>
                  <span id={`fileChosen ${styles.choosenFile}`}>
                    {formik.values.primary_logo}
                  </span>
                </div>
                <FormikError formik={formik} propertyName="primary_logo" />
              </div>
              <div>
                <div className={styles.uploadedLogo}>
                  {uploadedFile && <img src={URL.createObjectURL(uploadedFile)} alt="Logo" />}
                  {!uploadedFile && isForMerchant() && merchant?.primary_logo && <img src={merchant?.primary_logo} style={{ width: "auto", margin: "auto", maxHeight: "10rem", maxWidth: "25rem" }} alt={merchant?.name} />}
                  {!uploadedFile && !isForMerchant() && <img src={whitelabel?.secondary_logo} style={{ width: "auto", margin: "auto", maxHeight: "10rem", maxWidth: "25rem" }} alt={whitelabel?.name} />}
                  {!uploadedFile && isForMerchant() && !isMerchantDeactivated && !merchant?.primary_logo && <p className="textMedium">Please upload a logo.</p>}
                  {!uploadedFile && isForMerchant() && isMerchantDeactivated && !merchant?.primary_logo && <p className="textMedium">Logo not set.</p>}
                </div>
              </div>
            </div>
            <div className={styles.btnContainer}>
              <Button id="landingPageSettingsPopup_submit" type="submit" label="Save" />
            </div>
            {uploadedFile &&
              <Scrollable
                styleOverride={{ maxHeight: 200 }}>
                <LandingPagePreview preview_logo={URL.createObjectURL(uploadedFile)} />
              </Scrollable>
            }
          </div>
        </form>
      </>
    </Popup >
  );
};

export default LandingPageLogoSettingsPopup;
