import ProtectedRoute from "auth/ProtectedRoute";
import { Applications, ViewApplicant } from "content/portals";
import { Route } from "react-router-dom";

const ViewApplicationRoutes = () => {
  return <>
    <Route path="/applications" element={<ProtectedRoute> <Applications /> </ProtectedRoute>} />
    <Route path="/viewApplicant/:applicantId/application" element={<ProtectedRoute> <ViewApplicant view="application" /> </ProtectedRoute>} />
    <Route path="/viewApplicant/:applicantId/offers" element={<ProtectedRoute> <ViewApplicant view="offers" /> </ProtectedRoute>} />
    <Route path="/viewApplicant/:applicantId/history" element={<ProtectedRoute> <ViewApplicant view="history" /> </ProtectedRoute>} />
  </>;
};

export default ViewApplicationRoutes;