import React, { ReactNode } from "react";

interface ITripleInput {
  label: string;
  children: Array<ReactNode>;
}

const TripleInput: React.FC<ITripleInput> = ({ children }) => {
  if (children.length !== 3) {
    return <></>;
  }

  return <>
    {children}
  </>;
}

export default TripleInput;