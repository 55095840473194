import styles from "./Dashboard.module.scss";
import {
  Button, CalendarRange, Table, Sidebar,
  DashboardHeader, Aside,
  ButtonDropdown, TimeZoneMessage, ComponentErrorWrapper, StatsCardRow, ApplicationProgressTracker, Link
} from "common";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CardTick, DiscountShape, ReceiptEdit, Shop, ShopAdd, TickCircle } from "iconsax-react";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import _ from "lodash";
import moment from "moment";
import { IDateRange } from "common/Calendar";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAmount, formatAPICurrency, formatAPIDate } from "utils/formatters";
import { useDashboard } from "./Dashboard.hooks";
import { ApplicationActivityChart, CombinedActivityChart, ProgressChartRow } from "common/charts";
import InnerPopups from "content/popups/InnerPopups";
import { IStatsCard } from "common/StatsCard";
import LocalStorage from "classes/LocalStorage";
import { calculateDashboardRate } from "utils/math";
import { useFlag } from "hooks/useFlag";

const Dashboard2: React.FC = () => {
  const {
    user, dispatch, enablePartner, filter, setFilter, isAmount, setIsAmount,
    merchantsActivity, applicationActivity, applicationsActivityHasError, updateApplicationActivity,
    financeActivity, merchantsStats, activityFunnel, parseLocationLeaderboard, merchantFilter,
    tableRef, leaderboardType, setLeaderboardType, getListUsers, partnerFilter, leaderboardBtnOptions,
    whitelabelOrPartnerMetrics, merchant, getMerchants, getPartners,
    usersTableHasError, locationsTableHasError, merchantsTableHasError, partnersTableHasError
  } = useDashboard();

  const optimizedFormBtnEnabled = useFlag("display-optimized-testing-form");

  const handleLeaderboardTypeChange = (value: any) => {
    setLeaderboardType(value);
    if (user?.user_type === "MERCHANT") {
      LocalStorage.save('merchant_leaderboard_type', value);
    } else if (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") {
      LocalStorage.save('wl_leaderboard_type', value);
    }
  }

  let statCardItems: Array<IStatsCard> = [
    { icon: ReceiptEdit, description: "Requested", stats: formatAPICurrency(financeActivity?.requested_total, false), color: "primary" },
    { icon: TickCircle, description: "Approved", stats: formatAPICurrency(financeActivity?.total_approved, false), color: "primary" },
    { icon: CardTick, description: "Funded", stats: formatAPICurrency(financeActivity?.total_funded, false), color: "primary" },
  ];
  if (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") {
    statCardItems = [...statCardItems,
    { icon: Shop, description: "Added mer.", stats: merchantsStats?.added, color: "primary" },
    { icon: ShopAdd, description: "Submitting mer.", stats: merchantsStats?.submiting, color: "primary" },
    ];
  } else if (user?.user_type === "MERCHANT") {
    statCardItems = [...statCardItems,
    { icon: DiscountShape, description: "Approval rate", stats: formatAmount(merchant?.approval_rate * 100 || 0, true), color: "secondary" },
    ];
  };

  const trackOnLoadingRows = () => {
    Analytics.track({ experience: "portal", screen: "dashboard", object: "table_load_more_rows", action: "initiated" } as ITracking);
  };
  const trackOnSort = (field_sorted: string) => {
    Analytics.track({ experience: "portal", screen: "dashboard", object: "table_sort", action: "successful" } as ITracking, { field_sorted });
  }

  const maxHeightTableWL = 267;
  const maxHeightTableMerchant = 513;
  const minTableWidth = 300;

  return (
    <main className={styles.dashboardPageContainer}>
      <Sidebar />
      <Aside>
        <DashboardHeader />
        <InnerPopups />
        <div className={styles.contentContainer}>
          <Container fluid className={styles.filterContainer}>
            <Row>
              <Col md={12} lg={6} className={styles.filterColOne}>
                {user?.user_type === "WHITELABEL" &&
                  <>
                    <ButtonDropdown label="Invite" id="whitelabelDashboard_invite"
                      options={[
                        {
                          label: "User", action: () => {
                            Analytics.track({ experience: "portal", screen: "dashboard", object: "invite_user_button", action: "clicked" } as ITracking);
                            dispatch(popUpActions.openPopup(POPUPS.INVITE_USER))
                          }
                        },
                        {
                          label: "Merchant", action: () => {
                            Analytics.track({ experience: "portal", screen: "dashboard", object: "invite_merchant_button", action: "clicked" } as ITracking);
                            dispatch(popUpActions.openPopup(POPUPS.INVITE_MERCHANT))
                          }
                        }
                      ]}
                    />
                    <Button id="whitelabelDashboard_addMerchant" type="button" label="Add merchant"
                      onClick={() => {
                        Analytics.track({ experience: "portal", screen: "dashboard", object: "add_merchant_button", action: "clicked" } as ITracking);
                        dispatch(popUpActions.openPopup(POPUPS.ADD_MERCHANT));
                      }}
                    />
                    {optimizedFormBtnEnabled && <Button id="whitelabelDashboard_optimizedAddMerchant" type="button" label="Optimized Testing Form"
                      onClick={() => {
                        Analytics.track({ experience: "portal", screen: "dashboard", object: "add_merchant_button", action: "clicked" } as ITracking);
                        dispatch(popUpActions.openPopup(POPUPS.OPTIMIZED_ADD_MERCHANT));
                      }}
                    />}
                    {enablePartner && <Button id="whitelabelDashboard_addPartner" type="button" label="Add partner"
                      onClick={() => {
                        Analytics.track({ experience: "portal", screen: "dashboard", object: "add_partner_button", action: "clicked" } as ITracking);
                        dispatch(popUpActions.openPopup(POPUPS.ADD_PARTNER));
                      }}
                    />}
                  </>
                }
                {user?.user_type === "MERCHANT" && <Button id="dashboard_newApplication" type="button" label="Invite applicant"
                  onClick={() => {
                    Analytics.track({ experience: "portal", screen: "dashboard", object: "invite_applicant_button", action: "clicked" } as ITracking);
                    dispatch(popUpActions.openPopup(POPUPS.INVITE_APPLICANT));
                  }}
                />}
                {user?.user_type === "MERCHANT" && <Button id="dashboard_inviteUser" type="button" label="Invite user"
                  onClick={() => {
                    dispatch(popUpActions.openPopup(POPUPS.INVITE_USER));
                  }}
                />}
                {user?.user_type === "PARTNER" && <Button id="dashboard_addMerchant" type="button" label="Add merchant"
                  onClick={() => {
                    dispatch(popUpActions.openPopup(POPUPS.ADD_MERCHANT))
                  }}
                />}
              </Col>
              <Col md={12} lg={6} className={styles.filterColTwo}>
                <CalendarRange
                  showCalendarVariation={false}
                  onChange={(dateRange: IDateRange) => { setFilter({ ...filter, date_from: dateRange.startDate, date_to: dateRange.endDate }) }}
                  state={{ startDate: filter.date_from, endDate: filter.date_to }}
                  onDateRangeChange={(dateRange: IDateRange) => {
                    Analytics.track({ experience: "portal", screen: "dashboard", object: "date_range", action: "updated" } as ITracking, { days_in_range: moment(dateRange.endDate).diff(dateRange.startDate, 'days') + 1 });
                  }}
                />
              </Col>
            </Row>

            <Row className={styles.dashboardRow}>
              <StatsCardRow items={statCardItems} />
            </Row>

            <Row className={styles.dashboardRow}>
              <Col>
                <div className={styles.dashboardPadding}>
                  {activityFunnel && <ApplicationProgressTracker
                    requested={{ count: activityFunnel[0]?.APPLICATION_SUBMITTED?.applicants, total: activityFunnel[0]?.APPLICATION_SUBMITTED?.applications }}
                    offered={{ count: activityFunnel[1]?.OFFERED?.applicants, total: activityFunnel[1]?.OFFERED?.applications }}
                    clicked={{ count: activityFunnel[2]?.CLICKED?.applicants, total: activityFunnel[2]?.CLICKED?.applications }}
                    funded={{ count: activityFunnel[3]?.FUNDED?.applicants, total: activityFunnel[3]?.FUNDED?.applications }} />
                  }
                </div>
                <div className={styles.dashboardRow}>
                  {(user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") && <CombinedActivityChart
                    applicationActivityChart={{
                      data: applicationActivity,
                      onSwitchChange: (isAmount: boolean) => setIsAmount(isAmount),
                      isAmount: isAmount,
                      wrapped: true
                    }}
                    merchantActivityChart={{
                      data: merchantsActivity,
                      wrapped: true
                    }} />
                  }
                  {user?.user_type === "MERCHANT" && <ComponentErrorWrapper
                    id="applications_activity_chart"
                    hasError={applicationsActivityHasError}
                    onRetry={updateApplicationActivity}
                    bottomPadding="2rem">
                    <ApplicationActivityChart wrapped={false} data={applicationActivity} onSwitchChange={(isAmount: boolean) => setIsAmount(isAmount)} isAmount={isAmount} />
                  </ComponentErrorWrapper>
                  }
                </div>
              </Col>

              <Col>
                {(user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") && <>
                  <div>
                    {(user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") &&
                      <ProgressChartRow title="Rates for the last 7 days" charts={[
                        { percentage: calculateDashboardRate(whitelabelOrPartnerMetrics?.conversion_rate), title: "Conversion rate" },
                        { percentage: calculateDashboardRate(whitelabelOrPartnerMetrics?.approval_rate), title: "Approval rate" },
                        { percentage: calculateDashboardRate(whitelabelOrPartnerMetrics?.active_merchants_rate), title: "Active merchants" }
                      ]} />
                    }
                  </div>
                  <div className={styles.dashboardRow}>
                    {leaderboardType === "users" && <ComponentErrorWrapper
                      id="dashboard_table_users"
                      hasError={usersTableHasError}
                      onRetry={() => {
                        tableRef?.current?.reloadData();
                      }}
                      bottomPadding="2rem"
                    >
                      <Table
                        id="dashboard_table_users"
                        data={getListUsers}
                        tableBodyStyle={{ minWidth: minTableWidth }}
                        headers={[
                          { label: "Users", value: "name", size: 15, sortBy: "first_name", onChange: handleLeaderboardTypeChange, options: leaderboardBtnOptions },
                          { label: "Added M", value: "merchants_added_count", size: 7 },
                          { label: "$ Funded", value: "funded_total", size: 9 },
                        ]}
                        ref={tableRef}
                        maxHeight={maxHeightTableWL}
                        onLoadingRows={trackOnLoadingRows}
                        onSort={trackOnSort}
                        smallHeader={true}
                      />
                    </ComponentErrorWrapper>
                    }

                    {leaderboardType === "merchants" && <ComponentErrorWrapper
                      id="dashboard_table_merchants"
                      hasError={merchantsTableHasError}
                      onRetry={() => {
                        tableRef?.current?.reloadData();
                      }}
                      bottomPadding="2rem"
                    >
                      <Table
                        id="dashboard_table_merchants"
                        data={getMerchants}
                        tableBodyStyle={{ minWidth: minTableWidth }}
                        headers={[
                          { label: "Merchants", value: "name", size: 15, onChange: handleLeaderboardTypeChange, options: leaderboardBtnOptions },
                          { label: "# Submited", value: "submitted_count", size: 7 },
                          { label: "$ Funded", value: "funded_total", size: 9 },
                        ]}
                        ref={tableRef}
                        maxHeight={maxHeightTableWL}
                        onLoadingRows={trackOnLoadingRows}
                        onSort={trackOnSort}
                        smallHeader={true}
                      />
                    </ComponentErrorWrapper>
                    }

                    {leaderboardType === "partners" && <ComponentErrorWrapper
                      id="dashboard_table_partners"
                      hasError={partnersTableHasError}
                      onRetry={() => {
                        tableRef?.current?.reloadData();
                      }}
                      bottomPadding="2rem"
                    >
                      <Table
                        id="dashboard_table_partners"
                        data={getPartners}
                        tableBodyStyle={{ minWidth: minTableWidth }}
                        headers={[
                          { label: "Partners", value: "name", size: 15, onChange: handleLeaderboardTypeChange, options: leaderboardBtnOptions },
                          { label: "Active M", value: "active_merchants", size: 7 },
                          { label: "$ Funded", value: "funded_total", size: 9 },
                        ]}
                        ref={tableRef}
                        maxHeight={maxHeightTableWL}
                        onLoadingRows={trackOnLoadingRows}
                        onSort={trackOnSort}
                        smallHeader={true}
                      />
                    </ComponentErrorWrapper>
                    }

                    {leaderboardType === "locations" && <ComponentErrorWrapper
                      id="dashboard_table_locationLeaderboard"
                      hasError={locationsTableHasError}
                      onRetry={() => {
                        tableRef?.current?.reloadData();
                      }}
                      bottomPadding="2rem"
                    >
                      <Table
                        id="dashboard_table_locationLeaderboard"
                        data={parseLocationLeaderboard}
                        tableBodyStyle={{ minWidth: minTableWidth }}
                        maxHeight={maxHeightTableWL}
                        headers={[
                          { label: "Locations", value: "name", size: 15, sortBy: "name", onChange: handleLeaderboardTypeChange, options: leaderboardBtnOptions },
                          { label: "# Submitted", value: "requested_count", size: 7 },
                          { label: "$ Funded", value: "funded_total", size: 9 },
                        ]}
                        ref={tableRef}
                        onLoadingRows={trackOnLoadingRows}
                        onSort={trackOnSort}
                        smallHeader={true}
                      />
                    </ComponentErrorWrapper>
                    }

                  </div>
                </>}
                {user?.user_type === "MERCHANT" && <>
                  {leaderboardType === "locations" && <ComponentErrorWrapper
                    id="dashboard_table_locationLeaderboard"
                    hasError={locationsTableHasError}
                    onRetry={() => {
                      tableRef?.current?.reloadData();
                    }}
                    bottomPadding="2rem"
                  >
                    <Table
                      id="dashboard_table_locationLeaderboard"
                      data={parseLocationLeaderboard}
                      tableWrapperStyle={{ maxHeight: "562px", overflowY: "hidden" }}
                      maxHeight={maxHeightTableMerchant}
                      tableBodyStyle={{ minWidth: minTableWidth }}
                      headers={[
                        { label: "Locations", value: "name", size: 15, sortBy: "name", onChange: handleLeaderboardTypeChange, options: leaderboardBtnOptions },
                        { label: "# Submitted", value: "requested_count", size: 7 },
                        { label: "$ Funded", value: "funded_total", size: 9 },
                      ]}
                      ref={tableRef}
                      onLoadingRows={trackOnLoadingRows}
                      onSort={trackOnSort}
                      smallHeader={true}
                    />
                  </ComponentErrorWrapper>
                  }
                  {leaderboardType === "users" && <ComponentErrorWrapper
                    id="dashboard_table_users"
                    hasError={usersTableHasError}
                    onRetry={() => {
                      tableRef?.current?.reloadData();
                    }}
                    bottomPadding="2rem"
                  >
                    <Table
                      id="dashboard_table_users"
                      data={getListUsers}
                      maxHeight={maxHeightTableMerchant}
                      tableBodyStyle={{ minWidth: minTableWidth }}
                      headers={[
                        { label: "Users", value: "name", size: 15, sortBy: "first_name", onChange: handleLeaderboardTypeChange, options: leaderboardBtnOptions },
                        { label: "# Submitted", value: "requested_count", size: 7 },
                        { label: "$ Funded", value: "funded_total", size: 9 },
                      ]}
                      ref={tableRef}
                      onLoadingRows={trackOnLoadingRows}
                      onSort={trackOnSort}
                      smallHeader={true}
                    />
                  </ComponentErrorWrapper>
                  }

                </>}


              </Col>
            </Row>




          </Container>
          <TimeZoneMessage />
        </div>
      </Aside>
    </main>
  );
};

export default Dashboard2;
