import { IPanel } from "common/parts/panel/Panel";
import { IPanelLine } from "common/parts/panel/PanelLine";
import { ITabContent } from "common/parts/tabs/TabContent";
import { Call, ProfileCircle, Sms, Location } from "iconsax-react";
import Analytics, { ITracking } from "classes/Analytics";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { useEffect, useState } from "react";
import { concatenateAddress } from "utils/helpers";
import { IAddress, ISettingsView } from "common/interfaces";
import { formatAPIPhone } from "utils/formatters";
import UsersTable from "../shared/UsersTable";

const useBusinessAccount = (): ISettingsView => {
  const dispatch = useAppDispatch();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const support = useAppSelector(state => state.app.support);
  const [addressContact, setAddressContact] = useState<Array<IPanelLine>>(null);
  const [merchantContact, setMerchantContact] = useState<Array<IPanelLine>>(null);
  const [borrowerContact, setBorrowerContact] = useState<Array<IPanelLine>>(null);
  const [panels, setPanels] = useState<Array<IPanel>>([]);
  
  useEffect(() => {
    if (whitelabel?.id && support) {
      setAddressContact([
        { icon: Location, label: concatenateAddress({ ...whitelabel } as IAddress) },
        { icon: ProfileCircle, label: `${whitelabel.main_contact_first_name} ${whitelabel.main_contact_last_name} (main contact)` },
        { icon: Sms, label: whitelabel.main_contact_email },
        { icon: Call, label: formatAPIPhone(whitelabel.main_contact_phone) }
      ]);
      setMerchantContact([
        { icon: ProfileCircle, label: support?.merchant?.name },
        { icon: Sms, label: support?.merchant?.email },
        { icon: Call, label: support?.merchant?.phone }
      ]);
      setBorrowerContact([
        { icon: ProfileCircle, label: support?.borrower?.name },
        { icon: Sms, label: support?.borrower?.email },
        { icon: Call, label: support?.borrower?.phone },
      ]);
    }
  }, [whitelabel, support]);

  useEffect(() => {
    if (addressContact && merchantContact && borrowerContact) {
      setPanels([
        { title: whitelabel?.name, lines: addressContact, editAction: () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_WHITELABEL_BUSINESS_AND_MAIN_CONTACT)) } },
        { title: "Merchant Help Contact", lines: merchantContact, editAction: () => { dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_SUPPORT, message: "MERCHANT" })); } },
        { title: "Borrower Help Contact", lines: borrowerContact, editAction: () => { dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_SUPPORT, message: "BORROWER" })); } }
      ]);
    }
  }, [addressContact, merchantContact, borrowerContact]);

  const tabContent: ITabContent = {
    sections: [
      {
        title: "Users",
        body: <UsersTable />,
        actions: [
          {
            label: "Invite user", visible: true, id: "invite-user-btn", color: "primary", onClick: () => {
              Analytics.track({ experience: "portal", screen: "settings", object: "invite_user_button", action: "clicked" } as ITracking);
              dispatch(popUpActions.openPopup(POPUPS.INVITE_USER));
            }
          }
        ]
      }
    ]
  }
  return { panels, tabContent };
}

export default useBusinessAccount;