import ProtectedRoute from "auth/ProtectedRoute";
import { Merchants, ViewMerchant, Partners, ViewPartner } from "content/portals";
import { Route } from "react-router-dom";

const WhiteLabelRoutes = () => {
  return <>
    <Route path="/merchants" element={<ProtectedRoute><Merchants /></ProtectedRoute>} />
    <Route path="/partners" element={<ProtectedRoute><Partners /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/usersLocations" element={<ProtectedRoute><ViewMerchant view="usersLocations" /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/accountInformation" element={<ProtectedRoute><ViewMerchant view="accountInformation" /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/experienceSettings" element={<ProtectedRoute><ViewMerchant view="experienceSettings" /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/landingPageSettings" element={<ProtectedRoute><ViewMerchant view="landingPageSettings" /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/notifications" element={<ProtectedRoute><ViewMerchant view="notifications" /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/locations" element={<ProtectedRoute><ViewMerchant view="locations" /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/applications" element={<ProtectedRoute><ViewMerchant view="applications" /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/users" element={<ProtectedRoute><ViewMerchant view="users" /></ProtectedRoute>} />
    <Route path="/viewMerchant/:merchantSlug/history" element={<ProtectedRoute><ViewMerchant view="history" /></ProtectedRoute>} />
    <Route path="/merchants/users" element={<ProtectedRoute><ViewMerchant view="users" /></ProtectedRoute>} />
    <Route path="/viewPartner/:partnerSlug/accountInformation" element={<ProtectedRoute><ViewPartner view="accountInformation" /></ProtectedRoute>} />
    <Route path="/viewPartner/:partnerSlug/notifications" element={<ProtectedRoute><ViewPartner view="notifications" /></ProtectedRoute>} />
    <Route path="/viewPartner/:partnerSlug/merchants" element={<ProtectedRoute><ViewPartner view="merchants" /></ProtectedRoute>} />
    <Route path="/viewPartner/:partnerSlug/users" element={<ProtectedRoute><ViewPartner view="users" /></ProtectedRoute>} />
    <Route path="/viewPartner/:partnerSlug/history" element={<ProtectedRoute><ViewPartner view="history" /></ProtectedRoute>} />
    <Route path="/partners/users" element={<ProtectedRoute><ViewPartner view="users" /></ProtectedRoute>} />
  </>;
};

export default WhiteLabelRoutes;