import styles from "./TopbarTabs.module.scss";
import { ITopbarTabs } from "common/interfaces";
import React from "react";
import { Col, Row } from "react-bootstrap";

const TopbarTabs: React.FC<ITopbarTabs> = ({
  items,
  activeTab,
  onClick,
}) => {
  return (
    <div className={styles.topbarWrapper}>
      <Row className={styles.row}>
        {items.map((item, index) => {
          return (
            <Col
              md={12 / items.length} lg={12 / items.length}
              id={`topbar-tabs-item-${index}`}
              key={`topbar-tabs-item-${index}`}
              className={activeTab === index ? styles.topbarTab : (styles.topbarTab + " " + styles.topbarTabActive)}
              onClick={() => {
                if (onClick) {
                  onClick(index);
                }
              }}
            >
              <p>{item.label}</p>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default TopbarTabs;
