import styles from "./Dashboard.module.scss";
import {
  Button, CalendarRange, Table, Sidebar,
  DashboardHeader, OneColorCard, Aside,
  ButtonDropdown, TimeZoneMessage, ComponentErrorWrapper, ApplicationFunnel
} from "common";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CardCoin, MoneySend, Shop } from "iconsax-react";
import popUpActions from "reducers/PopUpReducer";
import { isUndefinedNullOrZero } from "utils/helpers";
import { POPUPS } from "utils/constants";
import _ from "lodash";
import moment from "moment";
import { IDateRange } from "common/Calendar";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAPICurrency } from "utils/formatters";
import { useDashboard } from "./Dashboard.hooks";
import { ApplicationActivityChart, MerchantActivityChart } from "common/charts";
import InnerPopups from "content/popups/InnerPopups";

const Dashboard: React.FC = () => {
  const {
    user, dispatch, enablePartner, filter, setFilter, isAmount, setIsAmount, merchantsActivityHasError,
    updateMerchantsActivity, merchantsActivity, applicationActivity, applicationsActivityHasError, updateApplicationActivity,
    financeActivity, financeActivityHasError, updateFinanceActivity, merchantsStatsHasError, merchantsStats,
    updateActivityFunnel, activityFunnelHasError, activityFunnel, locationsTableHasError, parseLocationLeaderboard,
    locationLeaderboardTableRef
  } = useDashboard();

  return (
    <main className={styles.dashboardPageContainer}>
      <Sidebar />
      <Aside>
        <DashboardHeader />
        <InnerPopups />
        <div className={styles.contentContainer}>
          <Container fluid className={styles.filterContainer}>
            <Row>
              <Col md={12} lg={6} className={styles.filterColOne}>
                {user?.user_type === "WHITELABEL" &&
                  <>
                    <ButtonDropdown label="Invite" id="whitelabelDashboard_invite"
                      options={[
                        {
                          label: "User", action: () => {
                            Analytics.track({ experience: "portal", screen: "dashboard", object: "invite_user_button", action: "clicked" } as ITracking);
                            dispatch(popUpActions.openPopup(POPUPS.INVITE_USER))
                          }
                        },
                        {
                          label: "Merchant", action: () => {
                            Analytics.track({ experience: "portal", screen: "dashboard", object: "invite_merchant_button", action: "clicked" } as ITracking);
                            dispatch(popUpActions.openPopup(POPUPS.INVITE_MERCHANT))
                          }
                        }
                      ]}
                    />
                    <Button id="whitelabelDashboard_addMerchant" type="button" label="Add merchant"
                      onClick={() => {
                        Analytics.track({ experience: "portal", screen: "dashboard", object: "add_merchant_button", action: "clicked" } as ITracking);
                        dispatch(popUpActions.openPopup(POPUPS.ADD_MERCHANT));
                      }}
                    />
                    {enablePartner && <Button id="whitelabelDashboard_addPartner" type="button" label="Add partner"
                      onClick={() => {
                        Analytics.track({ experience: "portal", screen: "dashboard", object: "add_partner_button", action: "clicked" } as ITracking);
                        dispatch(popUpActions.openPopup(POPUPS.ADD_PARTNER));
                      }}
                    />}
                  </>
                }
                {user?.user_type === "MERCHANT" && <Button id="dashboard_newApplication" type="button" label="Invite applicant"
                  onClick={() => {
                    Analytics.track({ experience: "portal", screen: "dashboard", object: "invite_applicant_button", action: "clicked" } as ITracking);
                    dispatch(popUpActions.openPopup(POPUPS.INVITE_APPLICANT));
                  }}
                />}
                {user?.user_type === "MERCHANT" && <Button id="dashboard_inviteUser" type="button" label="Invite user"
                  onClick={() => {
                    dispatch(popUpActions.openPopup(POPUPS.INVITE_USER));
                  }}
                />}
                {user?.user_type === "PARTNER" && <Button id="dashboard_addMerchant" type="button" label="Add merchant"
                  onClick={() => {
                    dispatch(popUpActions.openPopup(POPUPS.ADD_MERCHANT))
                  }}
                />}
              </Col>
              <Col md={12} lg={6} className={styles.filterColTwo}>
                <CalendarRange
                  showCalendarVariation={false}
                  onChange={(dateRange: IDateRange) => { setFilter({ ...filter, date_from: dateRange.startDate, date_to: dateRange.endDate }) }}
                  state={{ startDate: filter.date_from, endDate: filter.date_to }}
                  onDateRangeChange={(dateRange: IDateRange) => {
                    Analytics.track({ experience: "portal", screen: "dashboard", object: "date_range", action: "updated" } as ITracking, { days_in_range: moment(dateRange.endDate).diff(dateRange.startDate, 'days') + 1 });
                  }}
                />
              </Col>
            </Row>
          </Container>
          <Container fluid className={styles.infoSectionContainer}>
            <Row className={styles.infoSectionRow}>
              <Col md={12} lg={6} className={styles.infoSectionColOne}>
                {(user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") &&
                  <ComponentErrorWrapper
                    id="merchants_activity_chart"
                    hasError={merchantsActivityHasError}
                    onRetry={updateMerchantsActivity}
                    bottomPadding="2rem">
                    <MerchantActivityChart wrapped={false} data={merchantsActivity} />
                  </ComponentErrorWrapper>
                }
                <ComponentErrorWrapper
                  id="applications_activity_chart"
                  hasError={applicationsActivityHasError}
                  onRetry={updateApplicationActivity}
                  bottomPadding="2rem">
                  <ApplicationActivityChart wrapped={false} data={applicationActivity} onSwitchChange={(isAmount: boolean) => setIsAmount(isAmount)} isAmount={isAmount} />
                </ComponentErrorWrapper>
                {user?.user_type === "MERCHANT" &&
                  <ComponentErrorWrapper
                    id="finance_activity_card"
                    hasError={financeActivityHasError}
                    onRetry={updateFinanceActivity}
                    bottomPadding="2rem">
                    <OneColorCard
                      title="Finance Activity"
                      icon={CardCoin}
                      metricsCol1={[{ name: "Requested", value: formatAPICurrency(financeActivity?.requested_total) }]}
                      metricsCol2={[{ name: "Funded", value: formatAPICurrency(financeActivity?.total_funded) }]}
                      isEmpty={isUndefinedNullOrZero(financeActivity?.requested_total) && isUndefinedNullOrZero(financeActivity?.total_funded)}
                    />
                  </ComponentErrorWrapper>
                }
              </Col>
              <Col md={12} lg={6} className={styles.infoSectionColTwo}>
                <ComponentErrorWrapper
                  id="finance_activity_card"
                  hasError={merchantsStatsHasError || financeActivityHasError}
                  onRetry={() => {
                    if (merchantsActivityHasError) {
                      updateMerchantsActivity();
                    }
                    if (financeActivityHasError) {
                      updateFinanceActivity();
                    }
                  }}
                  bottomPadding="2rem">
                  <div className={styles.activityContainer}>
                    {(user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") &&
                      <>
                        <OneColorCard
                          title="Merchants"
                          icon={Shop}
                          metricsCol1={[{ name: "Added", value: merchantsStats?.added }, { name: "Submitting", value: merchantsStats?.submiting }]}
                          isEmpty={isUndefinedNullOrZero(merchantsStats?.added) && isUndefinedNullOrZero(merchantsStats?.submiting)}
                        />
                        <OneColorCard
                          title="Finance Activity"
                          icon={MoneySend}
                          metricsCol1={[{ name: "Requested", value: formatAPICurrency(financeActivity?.requested_total) }, { name: "Funded", value: formatAPICurrency(financeActivity?.total_funded) }]}
                          isEmpty={isUndefinedNullOrZero(financeActivity?.requested_total) && isUndefinedNullOrZero(financeActivity?.total_funded)}
                        />
                      </>
                    }
                  </div>
                </ComponentErrorWrapper>
                <ComponentErrorWrapper
                  id="activity_funnel"
                  onRetry={updateActivityFunnel}
                  hasError={activityFunnelHasError}>
                  <ApplicationFunnel data={activityFunnel} />
                </ComponentErrorWrapper>
              </Col>
            </Row>
          </Container>
          <>
            {(user?.user_type === "MERCHANT" && !location) && <Container fluid className={styles.leaderBoardContainer}>
              <Row className={styles.leaderBoardRow}>
                <Col sm={12} md={12} lg={12}>
                  <ComponentErrorWrapper
                    id="location_leaderboard"
                    hasError={locationsTableHasError}
                    onRetry={() => {
                      locationLeaderboardTableRef?.current?.reloadData();
                    }}
                    bottomPadding="2rem"
                    topPadding="3rem">
                    <>
                      <h2>Location Leaderboard</h2>
                      <Table
                        id="dashboard_locationLeaderboard"
                        data={parseLocationLeaderboard}
                        tableBodyStyle={{ minWidth: 500, height: 270 }}
                        headerWrapperStyle={{ minWidth: 500 }}
                        headers={[
                          { label: "Location", value: "name", size: 15, sortBy: "location__name" },
                          { label: "# Submitted", value: "requested_count", size: 10 },
                          { label: "$ Requested", value: "requested_total", size: 10 },
                        ]}
                        ref={locationLeaderboardTableRef}
                        maxHeight={270}
                      />
                    </>
                  </ComponentErrorWrapper>
                </Col>
              </Row>
            </Container>}
          </>
          <TimeZoneMessage />
        </div>
      </Aside>
    </main>
  );
};

export default Dashboard;
