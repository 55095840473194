import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'flow',
  initialState: {
    merchantInvitation: null,
    isRevision: false
  },
  reducers: {
    updateMerchantInvitation: (state, action: PayloadAction<any>) => {
      state.merchantInvitation = {
        ...state.merchantInvitation,
        ...action.payload
      }
    },
    clearMerchantInvitation: (state) => {
      state.merchantInvitation = null;
    },
    setRevision: (state, action: PayloadAction<boolean>) => {
      state.isRevision = action.payload;
    }
  }
})

export const flowsReducer = counterSlice.reducer;
export default counterSlice.actions;
