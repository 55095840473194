export default class Log {

  /** 
   * @description Logs to the console log with colors.
   * @param {string} backgroundColor CSS for background color.
   * @param {string} color CSS for letters color.
   * @example import Log from "classes/Log";
   * 
   * Log.highlight("red", "white", merchant);
   * Log.highlight("green", "yellow", whitelabel);
   * 
   * */
  static highlight(backgroundColor: string, color: string, ...args: any[]): void {
    console.log(`%cattention`, `color: ${color || "black"}; background-color: ${backgroundColor || "yellow"}; padding: 5px; border-radius: 5px;`, args);
  }

  static debug(...args: any[]): void {
    if (window.EnableDebug) {
      console.log(...args);
    }
  }

  static analytics(eventName: string, metadata: any): void {
    if (window.EnableDebug) {
      console.log(`%c${eventName}`, `color: white; background-color: #8900AF; padding: 5px; border-radius: 5px;`, metadata);
    }
  }

  static info(...args: any[]): void {
    console.info(...args);
  }

  static warn(...args: any[]): void {
    console.warn(...args);
  }

  static error(...args: any[]): void {
    console.error(...args);
  }
}
