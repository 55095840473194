import { URLHandler } from "common";
import { AccountDeactivation, ForgotPassword, Login, ResetPassword } from "content";
import { Route } from "react-router-dom";

const AuthRoutes = () => {
  return <>
    <Route path="/login" element={<Login />} />
    <Route path="/forgotPassword" element={<ForgotPassword />} />
    <Route path="/accountDeactivation" element={<AccountDeactivation />} />
    <Route path="/resetPassword" element={
      <URLHandler
        requireParams={["token"]}
        errorMessage="Please check your email for a link to reset your password."
        redirectUrl="/forgotPassword">
        <ResetPassword />
      </URLHandler>
    } />
  </>;
};

export default AuthRoutes;