import React from "react";
import { Eye, EyeSlash } from "iconsax-react";
import styles from "./ShowSensitiveInfo.module.scss";
import { preventTextSelection } from "utils/helpers";

interface IShowSensitiveInfo {
  id: string;
  onChange: (status: boolean) => void;
}

const ShowSensitiveInfo: React.FC<IShowSensitiveInfo> = ({ onChange, id }) => {
  const [showInfo, setShowInfo] = React.useState(true);

  const changeStatus = (status: boolean | ((prevState: boolean) => boolean)) => {
    setShowInfo(status);
    onChange(showInfo);
  }

  return <div id={`${id}_eyeButton`} className={styles.eyeButton} onClick={() => changeStatus(!showInfo)} onMouseDown={preventTextSelection}>
    {!showInfo && <Eye />}
    {showInfo && <EyeSlash />}
  </div>;
}

export default ShowSensitiveInfo;