import { getUSPSAddress } from "actions/USPSActions";
import { Button } from "common";
import { IUSPSAddress } from "common/interfaces";
import React, { useEffect, useState } from "react";
import { convertStringNullsToNull } from "utils/helpers";
import { addressesAreEqual, concatenateUSPSAddress } from "utils/validation/addressValidation";
import styles from "./AddressValidator.module.scss";

interface IAddressValidator {
  addressToVerify: IUSPSAddress;
  typedAddress: IUSPSAddress;
  handleNext: (address: IUSPSAddress) => void;
  setAddressIsVerified: (verified: boolean) => void;
}

const AddressValidator: React.FC<IAddressValidator> = ({ addressToVerify, typedAddress, handleNext, setAddressIsVerified }) => {
  const [addresses, setAddresses] = useState<{ typedAddress?: IUSPSAddress, suggestedAddress?: IUSPSAddress }>(null);
  const [selectedAddress, setSelectedAddress] = useState('suggestedAddress');
  const [lastVerifiedAddress, setLastVerifiedAddress] = useState<IUSPSAddress>(null);

  useEffect(() => {
    if (addressToVerify) {
      handleAddressVerification(addressToVerify);
    }
  }, [addressToVerify])

  useEffect(() => {
    if (addressesAreEqual(typedAddress, lastVerifiedAddress)) {
      setAddressIsVerified(true);
    } else {
      setAddressIsVerified(false);
    }
  }, [typedAddress, lastVerifiedAddress])

  const handleAddressVerification = async (addressToVerify: IUSPSAddress) => {
    convertStringNullsToNull(addressToVerify);

    try {
      const suggestedAddress = await getUSPSAddress(addressToVerify);

      if (suggestedAddress) {
        setLastVerifiedAddress(addressToVerify);
        const addressesEqual = addressesAreEqual(addressToVerify, suggestedAddress);

        if (addressesEqual) {
          setAddresses({ suggestedAddress });
        } else {
          setAddresses({ typedAddress, suggestedAddress });
        }
      } else {
        setAddresses({ typedAddress });
      }
    } catch (error) {
      setAddresses({ typedAddress });
      console.log("error", error);
    }
  }

  const getAddress = (address: IUSPSAddress, name: string): JSX.Element => {
    if (name === "suggestedAddress") {
      return <div className={styles.contactInfo}>
        <input id={`address_${name}`} type="radio" value={name} name={"address"} onChange={e => setSelectedAddress(e.target.value)} defaultChecked={true} />
        <p className="menu">{concatenateUSPSAddress(address)}</p>
        {/* for UI testing */}
        <input id={`hidden_${name}`} type="hidden" value={address.Address1} />
      </div>;
    } else {
      if (!addresses.suggestedAddress && selectedAddress !== name) {
        setSelectedAddress(name);
      }

      return (
        <div className={styles.contactInfo}>
          <input id={`address_${name}`} type="radio" value={name} name={"address"} onChange={e => setSelectedAddress(e.target.value)} defaultChecked={addresses.suggestedAddress ? false : true} />
          <p className="menu">Your entry:</p>
          <p>{concatenateUSPSAddress(address)}</p>
          {/* for UI testing */}
          <input id={`hidden_${name}`} type="hidden" value={address.Address1} />
        </div>);
    }
  }

  return !addresses ? <></> : (
    <>
      <div className={styles.addressSelector} >
        <h3>Suggested verified addresses based on your entry:</h3>
        {addresses["typedAddress"] && getAddress(addresses.typedAddress, "typedAddress")}
        {addresses["suggestedAddress"] && getAddress(addresses.suggestedAddress, "suggestedAddress")}
      </div>
      <div className={styles.buttonRight}>
        <Button
          className={styles.buttonRight}
          id="addressSelectorPopup_confirm"
          type="button"
          label="Next"
          onClick={() => handleNext(addresses[selectedAddress])}
        />
      </div>
    </>
  );
}

export default AddressValidator;