import { Form, Input, SelectDropdown } from "common/form";
import { IAddEntity } from "common/interfaces";
import React from "react";
import { YES_NO } from "utils/constants";
import { containsOnlyAsterisks } from "utils/helpers";
import { BusinessCheckingRoutingValidation, BusinessNameValidation, getBusinessCheckingAccountValidation, NameValidation, PhoneValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";

const BankInformation: React.FC<IAddEntity> = ({ onNext, initialValues }) => {

  return <>
    <Form
      id="dtm_application_bank_information"
      title="Business bank information"
      submitBtnText="Next"
      onFormSubmit={(data: any) => { onNext(data); }}
      validationSchema={Schema({
        bank_billing_contact_name: NameValidation,
        bank_billing_contact_phone: PhoneValidation,
        bank_name: BusinessNameValidation,
        bank_state: SimpleRequireValidation,
        bank_account_number: getBusinessCheckingAccountValidation(containsOnlyAsterisks(initialValues?.bank_account_number)),
        bank_routing_number: BusinessCheckingRoutingValidation,
        bank_phone_number: PhoneValidation,
        bank_fees_withdrawn_from_account: SimpleRequireValidation,
      })}
      values={initialValues}
      buttonPosition="bottom right"
      inputFields={[
        [
          <Input name="bank_billing_contact_name" id="dtm_application_bank_billing_contact_name" label="Business billing contact name*" />,
          <Input name="bank_billing_contact_phone" id="dtm_application_bank_billing_contact_phone" label="Business billing contact phone number*" mask="phone" />,
        ],
        [
          <Input name="bank_name" id="dtm_application_bank_name" label="Business bank name*" />,
          <Input name="bank_state" id="dtm_application_bank_state" label="Branch city/state*" />,
        ],
        [
          <Input name="bank_account_number" id="dtm_application_bank_account_number" label="Business checking account number*" />,
          <Input name="bank_routing_number" id="dtm_application_bank_routing_number" label="Business checking routing number*" maxLength={9} />,
        ],
        [
          <Input name="bank_phone_number" id="dtm_application_bank_phone_number" label="Bank phone number*" mask="phone" />,
          <SelectDropdown name="bank_fees_withdrawn_from_account" id="dtm_application_bank_fees_withdrawn_from_account" label="Will fees be withdrawn from this account?*" selectOptions={YES_NO} />,
        ],
      ]}
      isInsidePopup
    />;
  </>;
}

export default BankInformation;