import { useEffect } from "react";

/**
 * useFormDebug is a custom hook for debugging form states and behaviors in React.
 * It provides various utilities to monitor and log the form's current state, changes,
 * and interactions. This can be especially useful during development or for troubleshooting
 * complex form behaviors.
 */
export function useFormDebug(formik: any, location: any) {
  useEffect(() => {
    if (window.EnableDebug && location?.pathname.includes("borrowerExperience")) {
        console.log("DEBUG formik.touched", formik.touched);
      }
  }, [formik.touched]);

  useEffect(() => {
    if (window.EnableDebug && location?.pathname.includes("borrowerExperience")) {
        console.log("formik.errors0", formik.errors);
      }
    
    if (window.EnableDebug && formik.errors) {
      console.log(formik.errors);
    }
  }, [formik.errors]);

  const inputChangeDebug = (name: string) => {
    if (window.EnableDebug && location?.pathname.includes("borrowerExperience")) {
      console.log("DEBUG: Input onChange", name);
    }
  }

  return { inputChangeDebug };
}
