import React from "react";
import styles from "./Tab.module.scss";

export interface ITab {
  title: string;
  active?: boolean;
  index?: number;
  onClick?: (index: number) => void;
}

const Tab: React.FC<ITab> = ({ title, active, index, onClick }) => {
  return <div id={`tab-${index}`} className={`${styles.tab} ${active ? styles.active : ''}`} onClick={() => onClick(index)}>
    <p className={styles["tab-title"]}>{title}</p>
  </div>;
}

export default Tab;