import styles from "./TableContainer.module.scss";
import { Table, TimeZoneMessage } from "common";
import React from "react";
import { Container } from "react-bootstrap";
import { IHeader, IList } from "common/interfaces";
import { useParams } from "react-router-dom";
import { PartnerHandler } from "actions/PartnerHandler";
import { formatAPIDate } from "utils/formatters";
import usePartner from "hooks/usePartner";

const PartnerHistory: React.FC = () => {
  const { partnerSlug } = useParams();
  const partner = usePartner();

  const getHistory = async (next: string): Promise<any> => {
    let history: IList = await PartnerHandler().getHistory(next, partnerSlug);
    history.results = history.results.map(result => {
      return {
        ...result,
        date_time: formatAPIDate(result.date_time, true, true)
      }
    });
    return history;
  }

  const historyHeaders: Array<IHeader> = [
    { label: "Event", value: "event", size: 10, preventSorting: true },
    { label: "Date / Time", value: "date_time", size: 9 },
    { label: "Account", value: "account", size: 13, preventSorting: true },
    { label: "User", value: "user", size: 13, preventSorting: true },
  ];

  return <div className={styles.contentContainer}>
    <Container fluid>
      {partner && <h2 className="menu" style={{ color: "var(--primaryVariationTextColor)" }}>{partner?.name} history</h2>}
    </Container>
    <Container fluid className={styles.tableContainer}>
      <Table
        id="partner_history"
        data={getHistory}
        headers={historyHeaders}
        maxHeight={450}
        tableBodyStyle={{ minWidth: 1000 }}
      />
    </Container>
    <TimeZoneMessage timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone} />
  </div >;
};

export default PartnerHistory;
