import { Confetti, Container } from "common";
import styles from "../MerchantInvitation.module.scss";
import { useEffect, useState } from "react";
import { IStepper } from "common/interfaces";
import LocalStorage from "classes/LocalStorage";
import { MerchantHandler } from "actions/MerchantHandler";
import actions from "reducers/FlowsReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import appActions from "reducers/AppReducer";

const ThankYou: React.FC<IStepper> = () => {
  const dispatch = useAppDispatch();
  const vuid = LocalStorage.get<string>("vuid");
  const invitationId = LocalStorage.get<number>("invitationId");
  const isRevision = useAppSelector(state => state.flows.isRevision);
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchantComingFromLandingPage = useAppSelector(state => state.app.merchantAppFromLandingPage);

  const [copies, setCopies] = useState({ wasRevision: isRevision, wasMerchantComingFromLandingPage: merchantComingFromLandingPage });

  useEffect(() => {
    if (!invitationId && !vuid) {
      window.location.href = window.location.origin;
      return;
    } else {
      MerchantHandler().finalizeMerchantFlow(invitationId, vuid, isRevision)
      .then(() => {
        setCopies({ wasRevision: isRevision, wasMerchantComingFromLandingPage: merchantComingFromLandingPage });
        LocalStorage.clear(true);
        dispatch(actions.setRevision(false));
        dispatch(appActions.updateMerchantAppComingFromLandingPage(false));
      });
    }
  }, []);

  const getMessage = () => {
    if (copies.wasRevision || copies.wasMerchantComingFromLandingPage || !whitelabel.auto_approve_merchant) {
      return <>
        Thank you! <br />Your information was submitted and we will reach out to finalize your account shortly.
      </>
    } else {
      return <>
        Great news - your account was approved!<br />We just sent the main contact you listed an email with instructions to set up their user credentials.
      </>
    }
  }

  return (
    <Container>
      <div className={styles.invitationFormInner} style={{ marginTop: -50 }}>
        <Confetti isPopup={false}>
          {getMessage()}
        </Confetti>
      </div>
    </Container>
  );
};

export default ThankYou;
