import React from "react";
import styles from "./StatsCard.module.scss";
import { Icon } from "iconsax-react";
import { sanitizeString } from "utils/helpers";

export interface IStatsCard {
  icon: Icon;
  stats: string;
  description: string;
  color: "primary" | "secondary"
}

const StatsCard: React.FC<IStatsCard> = ({ icon, stats, description, color }) => {

  return <div className={styles["stats-card-wrapper"]}>
    <div className={styles["stats-card"]}>
      <div className={`${styles["stats-card-icon"]} ${color === "primary" ? styles.primary : styles.secondary}`}>
        {React.createElement(icon, { color: "var(--whiteTextColor)", size: 24 })}
      </div>
      <div className={styles.body}>
        <div className={styles["stats-card-main-info"]} id={`stats-card-${sanitizeString(description.toLowerCase())}`}>
          {stats}
        </div>
        <div className={styles["stats-card-description"]}>
          {description}
        </div>
      </div>
    </div>
  </div>;
}

export default StatsCard;