import React, { ReactNode, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

interface IResizeDetection {
  children?: ReactNode;
  onOrientationChange: () => void;
}

const ResizeDetection: React.FC<IResizeDetection> = ({ children, onOrientationChange }) => {
  const [key, setKey] = useState(uuidv4());

  useEffect(() => {
    const handleResize = () => {
      setKey(key);
    };

    const handleOrientationChange = () => {
      onOrientationChange();
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);


  return <React.Fragment key={key}>
    {children}
  </React.Fragment>;
}

export default ResizeDetection;