import { useEffect, useState } from 'react';
import { IImageDimensions } from 'common/interfaces';

export const useImageDimensions = (src: string | null) => {
  const [dimensions, setDimensions] = useState<IImageDimensions>(null);
  let img = null;

  useEffect(() => {
    if (src) {
      img = new Image();
      img.src = src;
      img.onload = function () {
        setDimensions({
          width: img.width,
          height: img.height
        });
      }
    }
  }, [src]);

  return dimensions;
};