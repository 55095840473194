import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { stripOutNonDigits } from "utils/formatters";
import styles from "./TextArea.module.scss";

interface ITextArea {
  id: string;
  /** 
   * @description This will set the Form component label for this Input. Outside of the Form component, this property will be ignored. 
   * */
  label?: string;
  name: string;
  className?: string;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  value?: any;
  maxLength?: number;
  styleOverride?: object;
  inputPrefix?: string;
  /** 
   * @description This will display a custom error message.
   * Outside of the Form component, this property will be ignored.
   * */
  customErrorMessage?: string;
  /** 
   * @description This will add the InfoCircle icon at the input Label with some Tooltip text. Outside of the Form component, this property will be ignored. 
   * */
  tooltip?: string;
  onTooltipHover?: () => void;
  formik?: any;
  onKeyUp?: any;
  disabled?: boolean;
  readonly?: boolean;
}

const TextArea: React.FC<ITextArea> = ({
  className,
  id,
  name,
  onChange,
  onBlur,
  placeholder,
  value,
  maxLength,
  styleOverride,
  formik,
  inputPrefix,
  onKeyUp,
  disabled,
  readonly,
}) => {
  const inputRef = useRef(null);
  const [hasError, setHasError] = useState(false);

  let autocomplete: "on" | "off" = "off";
  if (window.EnableDebug) {
    autocomplete = "on";
  }

  useEffect(() => {
    if (value) {
      setHelperText(value);
    }
  }, []);

  useEffect(() => {
    setHasError(formik?.touched[name] && formik?.errors[name]);
  }, [formik]);

  const inputPrefixStyle: React.CSSProperties = {
    left: "10px",
    top: "58%",
    transform: "translateY(-50%)",
    position: 'absolute',
    fontSize: "1.5rem",
    fontWeight: 500,
    color: "var(--darkTextColor)",
  };

  const handleChange = (e: any) => {
    setHelperText(e.target.value);
    onChange(e);
  };

  const setHelperText = (text: string) => {
    let dateDigits: string = stripOutNonDigits(text);
    if (dateDigits.length > 8) {
      dateDigits = dateDigits.substring(0, 8);
    }
  };

  if (readonly) {
    styleOverride = {
      ...styleOverride,
      backgroundColor: "#f3f3f3",
      fontStyle: "italic",
      color: "#a3a3a3"
    }
  }

  const helperTextStyle: CSSProperties = {
    color: "var(--darkTextColor)",
    position: "absolute",
    bottom: -20,
    left: 5,
    fontSize: "1.3rem"
  }

  return <>
    <div style={{ position: "relative" }}>
      <span style={inputPrefixStyle}>{inputPrefix}</span>
      <textarea
        className={`${styles.textarea} ${className || ""} ${hasError ? "inputError" : ""}`}
        name={name}
        id={`${id}_input`}
        value={value || ""}
        style={styleOverride}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyUp={(e: any) => {
          if (onKeyUp) {
            onKeyUp(e);
          }
        }}
        disabled={disabled}
        ref={inputRef}
        autoComplete={autocomplete}
        readOnly={readonly || false}
        title={readonly ? "This is a readonly field" : null}
        rows={2}
      // cols={30}
      />
      {readonly && <span style={helperTextStyle}>Changes not allowed on this field</span>}
    </div>
  </>;
};

export default TextArea;
