// this isn't used anywhere on 18/07/2023

import { Button, CloseIcon } from "common";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";

const ExportReportPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  return (
    <Popup isInner={true} >
      <>
        <CloseIcon id="exportReportPopup" color="dark" />
        <h2>Export</h2>
        {/* <Button id="exportReportPopup_exportPDF" label="Export PDF" type="button" maxWidth="15rem" />
            <Button id="exportReportPopup_exportExcel" label="Export Excel" type="button" maxWidth="15rem" /> */}
        <Button id="exportReportPopup_exportCSV" label="Export CSV" type="button" />
      </>
    </Popup>
  );
};

export default ExportReportPopup;
