import BorrowerHandler from "actions/BorrowerHandler";
import LocalStorage from "classes/LocalStorage";
import { IApplicationVerification } from "common/interfaces";
import { useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { convertDateFormatSlashToDash } from "utils/formatters";
import { saveLoanApplicationToRedux } from "utils/helpers";

/**
 * useGetLoanApplication - Used inide of borrower experience to get the application data and save it to redux
 * with the help of application_id, vuid, and dob (date of birth) from local storage if user refreshes the page
 *
 * @returns {any} Returns application data
 */
export const useGetLoanApplication = () => {
    const vuid = LocalStorage.get<string>("vuid");
    const applicationId = LocalStorage.get<number>("application_id");
    const [applicationData, setApplicationData] = useState(null);

    const borrower = useAppSelector(state => state.borrower);
    const [dobLS, setDobLS] = useState(LocalStorage.get<string>("dob"));

    useEffect(() => {
        if (vuid && applicationId) {
            if (borrower?.personalInformation?.date_of_birth || dobLS) {
                getApplicationData();
            }
            if (borrower && !borrower?.personalInformation?.date_of_birth && !dobLS) {
                getApplicationDataWithoutDOB();
            }
        }
    }, [vuid, applicationId, borrower?.personalInformation?.date_of_birth, dobLS])

    const getApplicationData = async () => {
        const response = await BorrowerHandler.getApplicationData({
            date_of_birth: convertDateFormatSlashToDash(borrower?.personalInformation?.date_of_birth || dobLS) ?? "",
        } as IApplicationVerification, applicationId, vuid);
        if (response) {
            setApplicationData(response);
        }
    }

    const getApplicationDataWithoutDOB = async () => {
        const response = await BorrowerHandler.getApplicationDataWithoutDOB(applicationId, vuid);
        if (response) {
            if (response?.date_of_birth === null) {
                // application has no DOB yet
                setApplicationData(response);
            } else {
                // probably shouldn't even get to here
                LocalStorage.save("dob", response?.date_of_birth);
                setDobLS(LocalStorage.get<string>("dob"));
            }
        }
    }

    useEffect(() => {
        // populate borrower flow redux
        if (applicationData) {
            saveLoanApplicationToRedux(applicationData);
        }
    }, [applicationData]);

    return applicationData;
};

export default useGetLoanApplication;
