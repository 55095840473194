import styles from "./LandingPageSettingsPopup.module.scss";
import { Button, CloseIcon, Padding } from "common";
import { FormikError } from "common/form";
import { InfoCircle } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { Schema } from "utils/validation/additionalValidation";
import { decypherApiErrorResponse, displayMiniFeedback } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { MerchantHandler } from "actions/MerchantHandler";
import Analytics, { ITracking } from "classes/Analytics";

const EditLogoPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const [toolTip, setToolTip] = useState(false);
  const [uploadedFile, setUploadedFile] = React.useState<any>("");
  const merchant = useAppSelector(state => state.merchant);
  const [ready, setReady] = useState(false);

  const formik = useFormik({
    initialValues: { primary_logo: "" },
    validationSchema: Schema({}),
    async onSubmit() {
      try {
        const currentLogo = merchant?.primary_logo;
        const updatedMerchant = await MerchantHandler().patchWithLogo(uploadedFile, merchant?.slug, merchant?.slug);
        if (updatedMerchant) {
          if (uploadedFile?.name !== currentLogo) {
            Analytics.track({ experience: "portal", screen: "settings_logo", object: "logo", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
          }
          displayMiniFeedback("Logo was updated");
          dispatch(popUpActions.closePopup());
        }
      } catch (error) {
        displayMiniFeedback(decypherApiErrorResponse(error), true);
      }
    }
  });

  useEffect(() => {
    if (merchant) {
      formik.setFieldValue("primary_logo", merchant?.primary_logo || null);
      setReady(true);
    }
  }, [merchant]);

  return (
    <Popup isInner={true}>
      <>
        <Padding top="40px" left="0px" right="0px" bottom="40px">
          <CloseIcon id="landingPageSettingsPopup" color="dark" />
          {ready && <form onSubmit={formik.handleSubmit}>
            <h2>{`${merchant?.name} • Edit logo settings`}</h2>
            <div className={styles.inviteFormContainer}>
              <div className={styles.inviteFormRow}>
                <div
                  className={`${styles.formField} ${styles.uploadLogoContainer}`}>
                  <div className={styles.labelContainer}>
                    <p>Logo</p>
                    <InfoCircle size={20} color="var(--darkTextColor)"
                      onMouseEnter={() => setToolTip(true)}
                      onMouseLeave={() => setToolTip(false)}
                    />
                    {toolTip && (
                      <div className={`${styles.pop} ${styles.pop2}`} style={{ left: 80 }}>
                        <p className="info">
                          Image criteria for logos:
                          <ul>
                            <li>At least 100px wide</li>
                            <li>Transparent or white background</li>
                            <li>PNG or SVG</li>
                          </ul>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.uploadContainer}>
                    <input
                      type="file"
                      id="landingPageSettingsPopup_actual-btn"
                      name="primary_logo"
                      hidden
                      onChange={(e: any) => {
                        formik.setFieldValue("primary_logo", e.target.files[0].name);
                        setUploadedFile(e.target.files[0]);
                      }}
                    />
                    <label htmlFor="landingPageSettingsPopup_actual-btn">Upload</label>
                    <span id={`fileChosen ${styles.choosenFile}`} style={{
                      width: 330,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "unset"
                    }} title={formik.values.primary_logo}>
                      {formik.values.primary_logo}
                    </span>
                  </div>
                  <FormikError formik={formik} propertyName="primary_logo" />
                </div>
                <div className={styles.btnContainer}>
                  <Button id="landingPageSettingsPopup_submit" type="submit" label="Save" style={{ marginTop: 30 }} />
                </div>
              </div>
            </div>
          </form>}
        </Padding>
      </>
    </Popup >
  );
};

export default EditLogoPopup;
