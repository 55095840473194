import { useEffect, useState } from 'react';

export const useInnerDimensions = () => {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
            setInnerWidth(window.innerWidth);
            setInnerHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    return [innerWidth, innerHeight];
};