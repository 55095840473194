import { AppIcon, Container, SVG } from "common";
import React from "react";
import styles from "./OneColorCard.module.scss";

interface IMetrics {
  name: string;
  value: string;
}

interface IOneColorCard {
  title: string;
  icon: any;
  metricsCol1: IMetrics[];
  metricsCol2?: IMetrics[];
  isEmpty?: boolean;
}

const OneColorCard: React.FC<IOneColorCard> = ({ title, metricsCol1, metricsCol2, icon, isEmpty }) => {
  const emptyClassName = isEmpty ? styles.noData : "";

  return (
    <Container className={styles.oneColorCardContainer}>
      <AppIcon color="var(--secondaryColor)" icon={icon} />
      <h4>{title}</h4>
      {!isEmpty && <div className={styles.activityCount}>
        <>
          <div className={styles.metricsCol}>
            {metricsCol1.map((data: any, i: any) => (
              <div className={styles.activity} id={`OneColorCard_metrics1_${i}`} key={`metrics1_${i}`}>
                <p className={emptyClassName}>{data.name}</p>
                {!isEmpty && <h3>{data.value}</h3>}
              </div>
            ))}
          </div>
          <div className={styles.metricsCol}>
            {metricsCol2 && metricsCol2.map((data: any, i: any) => (
              <div className={styles.activity} id={`OneColorCard_metrics2_${i}`} key={`metrics2_${i}`}>
                <p className={emptyClassName}>{data.name}</p>
                {!isEmpty && <h4>{data.value}</h4>}
              </div>
            ))}
          </div>
        </>
      </div>}
      {isEmpty && <div className={styles.emptyImg}>
        <SVG
          src="/assets/dashboard/empty_application_activity.svg"
          title={`Empty ${title}`}
          height={100}
        />
      </div>}
    </Container>
  );
};

export default OneColorCard;
