import React from "react";
import styles from "./ViewApplicant.module.scss";
import { Table, TimeZoneMessage } from "common";
import { useParams } from "react-router-dom";
import { IList } from "common/interfaces";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { formatAPIDate } from "utils/formatters";

const History: React.FC = () => {
  const { applicantId } = useParams();

  const getHistory = async (next: string): Promise<IList> => {
    let history = await LoanApplicationHandler.getHistory(next, applicantId);
    history.results = history.results.map(result => {
      return {
        ...result,
        dateTime: formatAPIDate(result.date_time, true, true),
        date_time: new Date(result.date_time)
      }
    });
    return history;
  }

  return <div className={styles.contentContainer}>
    <h2 className={styles.pageTitle}>
      Application {applicantId} history
    </h2>
    <div className={styles.tableContainer}>
      <Table
        id="merchantViewApplicant_history"
        data={getHistory}
        tableBodyStyle={{ minWidth: 1100 }}
        headerWrapperStyle={{ minWidth: 1100 }}
        headers={[
          { label: "Event", value: "event", preventSorting: true },
          { label: "Date/Time", value: "dateTime", sortBy: "date_time" },
          { label: "Account", value: "account", preventSorting: true },
          { label: "User", value: "user", preventSorting: true },
        ]}
        maxHeight={450}
      />
    </div>
    <TimeZoneMessage timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone} />
  </div>;
}

export default History;