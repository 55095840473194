import React from "react";
import styles from "./FeedbackPopup.module.scss";
import { SVG } from "common";

export const Cancel: React.FC = () => {
  const height = 150;

  return <div
    className={styles.backgroundImage}
    style={{ height: height + 50, marginBottom: -20 }}>
    <SVG
      src="/assets/feedback/cancel.svg"
      title="Cancel"
      height={height}
    />
  </div>;
};

export default Cancel;
