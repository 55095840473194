import { decrypt, encrypt } from "utils/helpers";
import Hasher from "./Hasher";
import Environment from "./Environment";
import store from "reducers/Store";
import appActions from "reducers/AppReducer";

export default class LocalStorage {
  static readonly ENABLE_ENCRYPTION: boolean = Environment.isProduction;

  /**
   * @summary Retrieves a value from localStorage and decrypts it or client storage (widget).
   * @param {string} key - The key to look up in localStorage.
   * @returns The decrypted value, or null if the key was not found.
   */
  static get<T>(key: string): T {
    if (store.getState().app.isWidget) {
      return store.getState().app.clientStorage[key] as T || null;
    } else {
      let storedUnit: any = null;
      if (this.ENABLE_ENCRYPTION) {
        const hash = Hasher.convert(key);
        storedUnit = window.localStorage.getItem(hash);
      } else {
        storedUnit = window.localStorage.getItem(key);
      }
      if (!storedUnit) {
        return null;
      }
      let value: any = null;
      if (this.ENABLE_ENCRYPTION) {
        value = decrypt(storedUnit);
      } else {
        value = storedUnit;
      }
      // if you encrypt and decrypt null, you get "null" instead of null
      if (value === "null") {
        return null;
      }
      if (["[", "{"].includes(value.substring(0, 1))) {
        return JSON.parse(value) as T;
      }
  
      // Boolean act weird when converted. This is a fail safe.
      if (value.toLowerCase() === "true") {
        return true as T;
      } else if (value.toLowerCase() === "false") {
        return false as T;
      }
  
      return value as unknown as T;
    }
  };

  /**
   * @summary Encrypts an object and saves it to localStorage using the specified key.
   * @param {string} key - The key to use for the item in localStorage.
   * @param {any} obj - The object to encrypt and save.
   */
  static save(key: string, obj: any): void {
    if (store.getState().app.isWidget) {
      store.dispatch(appActions.saveToClientStorage({ key, value: obj }));
    } else {
      if (this.ENABLE_ENCRYPTION) {
        const hash = Hasher.convert(key);
        const encryption: string = encrypt(obj);
        window.localStorage.setItem(hash, encryption);
      } else {
        if (typeof obj !== "string") {
          window.localStorage.setItem(key, JSON.stringify(obj));
        } else {
          window.localStorage.setItem(key, obj);
        }
      }
    }
  };

  /**
   * @summary Removes an item from localStorage using the specified key.
   * @param {string} key - The key of the item to remove from localStorage.
   */
  static remove(key: string): void {
    if (store.getState().app.isWidget) {
      store.dispatch(appActions.removeFromClientStorage(key));
    } else {
      if (this.ENABLE_ENCRYPTION) {
        const hash = Hasher.convert(key);
        window.localStorage.removeItem(hash);
      } else {
        window.localStorage.removeItem(key);
      }
    }
  };

  /**
   * @summary Clear local storage.
   * @param {boolean} cleanRedux - If Redux needs to be cleaned too.
   */
  static clear(cleanRedux: boolean): void {
    window.localStorage.clear();
    if (cleanRedux) {
      store.dispatch(appActions.cleanClientStorage());
    }
  };
}
