import React from "react";
import { Button, Container, HeaderWL } from "common";
import { Input, Form } from "common/form";
import styles from "./Login.module.scss";
import { useNavigate } from "react-router-dom";
import { displayFullscreenLoading, hideFullscreenLoading } from "utils/helpers";
import { EmailValidation, Schema } from "utils/validation/additionalValidation";
import AuthHandler from "actions/AuthHandler";

const ForgotPassword: React.FC = () => {
  const [success, setSuccess] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    try {
      displayFullscreenLoading("Processing");

      setEmail(data.email);
      await AuthHandler.resetPassword(data.email);
      setSuccess(true);

      hideFullscreenLoading();

    } catch (error) {
      hideFullscreenLoading();
      return Promise.reject();
    }
  }

  return <>
    <HeaderWL />
    {!success && <Form
      id="forgotPassword"
      buttonPosition="bottom center"
      onFormSubmit={handleSubmit}
      title="Password reset"
      submitBtnText="Send password reset link"
      validationSchema={Schema({
        email: EmailValidation
      })}
      values={{
        email: ""
      }}
      inputFields={[
        [
          <Input type="email" name="email" id="forgotPassword_email" label="Email" />,
        ]
      ]}
      styleOverride={{
        formContainer: { maxWidth: "45rem" },
        pageContainer: { paddingTop: "12rem" }
      }}
      onBackClick={() => {
        navigate("/login");
      }} />}
    {success && <Container className={styles.innerContainer} style={{ marginTop: "12rem", textAlign: "center" }}>
      <div className={styles.msgContainer}>
        <h1 style={{ marginBottom: "3rem" }}>
          A link to reset your password has been sent to&nbsp;
          {email}
        </h1>
        <Button
          id="forgotPassword_back"
          label="Back to login"
          type="button"
          onClick={() => {
            navigate("/login");
          }}
        />
      </div>
    </Container>}
  </>;
};

export default ForgotPassword;
