import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HeaderWL, WhiteFooter, ToolTip, Container as InternalContainer, HelpButton, AppIcon, OfferCard } from "common";
import styles from "./OfferInfo.module.scss";
import { ArrowLeft } from "iconsax-react";
import { useAppSelector } from "reducers/Hooks";
import { IOfferCard } from "common/interfaces";
import { scrollTop } from "utils/helpers";
import strings from "localization/Strings";
import Analytics, { ITracking } from "classes/Analytics";
import useGetLoanApplication from "hooks/useGetLoanApplication";

const OfferInfo: React.FC = () => {
  const navigate = useNavigate();
  const offerInfo: IOfferCard = useAppSelector(state => state.borrower.offerInfo);
  const whitelabel = useAppSelector(state => state.whitelabel);
  const applicationData = useGetLoanApplication();

  useEffect(() => {
    scrollTop();
  }, [offerInfo]);

  const handleSelectOffer = () => {
    Analytics.track({ experience: "borrower", screen: "application_offers_info_disclaimer", object: "offer", action: "selected" } as ITracking, null);
  }

  const handleAdvertiserDisclosureHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_offers_info_disclaimer", object: "advertiser_discl_link", action: "hover" } as ITracking, null);
  }

  const handleHelpButtonClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_offers_info_disclaimer", object: "help_link", action: "clicked" } as ITracking, null);
  }

  const handlePartnerGuaranteeDisclosureHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_offers_info_disclaimer", object: "partner_guarantee_discl_link", action: "hover" } as ITracking, null);
  }

  const handleRepresentativeExampleHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_offers_info_disclaimer", object: "rep_example_discl_link", action: "hover" } as ITracking, null);
  }

  const handlePrivacyPolicyClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_offers_info_disclaimer", object: "privacy_policy_link", action: "clicked" } as ITracking, null);
  }

  const handleTermsOfServiceClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_offers_info_disclaimer", object: "terms_service_link", action: "clicked" } as ITracking, null);
  }

  return (
    <>
      <HeaderWL instructions="Fill out the information and we'll match you with offers from our network of lenders!" />
      <main className={styles.offerInfoPageContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" onClick={handleHelpButtonClick} />
            </Col>
            <Col className={styles.helpCol2}>
              <ToolTip
                textToolTip="Advertiser Disclosure"
                text={strings.advertiserDisclosure.replaceAll("{whitelabelName}", whitelabel?.name)}
                placement="bottom"
                onHover={handleAdvertiserDisclosureHover}
              />
            </Col>
          </Row>
        </Container>
        <InternalContainer className={styles.offerInfoInner}>
          <div className={styles.backIcon}>
            <AppIcon
              clickTrigger={{
                id: `offerInfo_arrowLeft`,
                onClick: () => {
                  Analytics.track({ experience: "borrower", screen: "application_offers_info_disclaimer", object: "back_arrow", action: "clicked" } as ITracking, null);
                  navigate(-1);
                }
              }}
              size={26}
              color="var(--darkTextColor)"
              className={styles.backIcon}
              icon={ArrowLeft}
            />
          </div>
          <OfferCard
            {...offerInfo}
            offerInfoVariant
            onSelect={handleSelectOffer}
            status={applicationData?.status}
          />
          <div className={styles.moreInfoContainer}>
            <h1>More information</h1>
            <p className="info" dangerouslySetInnerHTML={{ __html: offerInfo?.more_information }}>
              {/* {offerInfo.more_information} */}
            </p>
            <h1>Disclaimer</h1>
            <p className="info" dangerouslySetInnerHTML={{ __html: offerInfo?.disclaimer }}>
              {/* {offerInfo.disclaimer} */}
            </p>
          </div>
        </InternalContainer>
      </main>
      <WhiteFooter onPartnerGuaranteeDisclosureHover={handlePartnerGuaranteeDisclosureHover} onPrivacyPolicyClick={handlePrivacyPolicyClick} onRepresentativeExampleHover={handleRepresentativeExampleHover} onTermsOfServiceClick={handleTermsOfServiceClick} />
    </>
  );
};

export default OfferInfo;

