import SystemHandler from 'actions/SystemHandler';
import { useEffect, useState } from 'react';
import _ from "lodash";

/**
 * Contain all of the options related with partners.
 * @returns Options containing all of the select options, and ready flag (must be used to know when all of the asyncronous called are done).
 */
export const usePartnerOptions = () => {
  const [types, setTypes] = useState(null);
  const [numberMerchantsOptions, setNumberMerchantOptions] = useState(null);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([
      SystemHandler.getPartnerTypes(),
      SystemHandler.getNumberOfMerchants()
    ]).then(responses => {
      setTypes(_.sortBy(responses[0], ["label"]));
      setNumberMerchantOptions(_.sortBy(responses[1], ["label"]));
      setReady(true);
    });
  }, []);

  return { 
    options: {
      partnerTypes: types, 
      numberMerchantsOptions
    },
    ready
  };
};