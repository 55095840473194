import { ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from './ActionConstants';

export default class GuestHandler {

  /** 
   * @description Unsubscribes guest from a notification.
   * @param {string} wlId white label id on the email link.
   * @param {string} vuid vuid on the unsubcribe email link.
   * @param {string} notificationCodename notification codename on the unsubcribe email link.
   * @returns Api response.
   * */
  static unsubscribe = async (wlId: string, vuid: string, notificationCodename: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`notifications/visitor-unsubscribe/?vuid=${vuid}&whitelabel_id=${wlId}&codename=${notificationCodename}`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 
   * @description Subscribes guest on a notification.
   * @param {string} wlId white label id on the email link.
   * @param {string} vuid vuid on the unsubcribe email link.
   * @param {string} notificationCodename notification codename on the unsubcribe email link.
   * @returns Api response.
   * */
  static subscribe = async (wlId: string, vuid: string, notificationCodename: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`notifications/visitor-subscribe/?vuid=${vuid}&whitelabel_id=${wlId}&codename=${notificationCodename}`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }


}