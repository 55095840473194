import OptimizedForm from "common/form/OptimizedForm";

const Test: React.FC = () => {

  const fields = [
    { label: 'Name', name: 'name', type: 'text' },
    { label: 'Phone Number', name: 'phoneNumber', type: 'text' },
    { label: 'Email', name: 'email', type: 'email' },
    { label: 'Gender', name: 'gender', type: 'select', options: ['Male', 'Female', 'Other'] }
  ];

  const handleSubmit = (data: any) => {
    console.log(data);
  }

  return <>
    <OptimizedForm fields={fields} onSubmit={handleSubmit} title="Add merchant • Business information" />;
  </>;
}

export default Test;