import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import styles from "./HeaderWL.module.scss";
import { Button } from "..";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import LocalStorage from "classes/LocalStorage";
import { useLocation } from "react-router-dom";

interface IHeaderWL {
  instructions?: string;
}

const HeaderWL: React.FC<IHeaderWL> = ({ instructions }) => {
  let showEqualHousingLogo: boolean = LocalStorage.get<boolean>("equal_housing");
  const location = useLocation();

  // constrain equal housing logo only to borrower specific sites
  if (!location.pathname.toLowerCase().includes("borrowerexperience") &&
    !(location.pathname.toLowerCase().includes("offers") && !location.pathname.includes("viewApplicant")) &&// because url part "offers" are in borrower experience flow and in merchant dashboard
    !location.pathname.toLowerCase().includes("offerinfo") &&
    !location.pathname.toLowerCase().includes("offerselected")) {
    showEqualHousingLogo = false;
  }

  const dispatch = useAppDispatch();
  const whitelabel = useAppSelector(state => state.whitelabel);

  return (
    <div className={styles.headerContainer}>
      <Container fluid className={styles.headerInner}>
        <Row>
          <Col className={styles.headerInnerCol}>
            <div className={styles.headerLogos}>
              <div>
                {whitelabel?.primary_logo && <img src={whitelabel.primary_logo} alt="WhiteLabel Logo" />}
              </div>
            </div>
            <div>
              <div className={styles.instructionsWrapper}>
                <p>{instructions}</p>
                {showEqualHousingLogo && <img src="/assets/images/equal-housing-logo-white-250.png" alt="Equal housing logo" style={{ height: 45, width: "auto" }} title="Equal Housing Opportunity" />}
              </div>
              {/* TODO: this button should be used in borrower experience on mobile, but is currently not - fix it */}
              <Button
                id="header_helpButton"
                type="button"
                label="Help"
                onClick={() => {
                  dispatch(popUpActions.openPopup(POPUPS.HELP));
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderWL;
