import { ISupport } from "common/interfaces";
import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from "./ActionConstants";
import ErrorHandler from "./ErrorHandler";
import store from "reducers/Store";
import whiteLabelActions from "reducers/WhiteLabelReducer";
import SystemHandler from "./SystemHandler";
import Observer, { EVENTS } from "classes/Observer";
import ApiCache from "classes/ApiCache";

export default class WhiteLabelHandler {
  /**
   * @description Get white label settings
   * @param {string} whitelabel White label slug.
   * @param {boolean} setColors Set white label colors.
   * @returns {Promise<any>} White label settings.
   */
  static get = async (whitelabel: string, setColors: boolean): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`customer/whitelabel/${whitelabel}`);
      store.dispatch(whiteLabelActions.set({ whiteLabelData: response.data, setColors }));
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Update support information.
  * @param {"merchant-support" | "borrower-support" | "whitelabel-support"} key Support key.
  * @param {ISupport} data Support data.
  * @returns {Promise<any>} API response.
  */
  static updateSupport = async (key: "merchant-support" | "borrower-support" | "whitelabel-support", data: ISupport): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`support/${key}/`, data);
      await SystemHandler.getSupportInfo();
      Observer.trigger(EVENTS.WHITELABEL_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Update whitelabel details.
  * @param {string} slug Whitelabel slug.
  * @param {any} data Whitelabel data.
  * @returns {Promise<any>} API response.
  */
  static update = async (slug: string, data: any): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`customer/whitelabel/${slug}/`, data);
      store.dispatch(whiteLabelActions.set({ whiteLabelData: response.data, setColors: false }));
      Observer.trigger(EVENTS.WHITELABEL_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
 * @description Get lender groups.
 * @returns {Promise<any>} API response.
 */
  static getLenderGroups = async (): Promise<any> => {
    try {
      const response = await ApiCache.get(`customer/lender-groups`);
      return Promise.resolve(response?.results);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }
}