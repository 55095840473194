import { IPanel } from "common/parts/panel/Panel";
import Panels from "common/parts/panel/Panels";
import TabNavigation, { ITabNavigation } from "common/parts/tabs/TabNavigation";
import React, { useEffect } from "react";
import { useAppSelector } from "reducers/Hooks";

interface ISettingsView {
  tabNavigation: ITabNavigation;
  panels: Array<IPanel>;
}

const SettingsView: React.FC<ISettingsView> = ({ tabNavigation, panels }) => {
  const isPopupOpened = useAppSelector(state => state.popUp.showInnerPopup || state.popUp.showPopup);

  useEffect(() => {
    document.body.style.overflowY = 'scroll';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    if (isPopupOpened) {
      document.body.style.overflowY = 'auto';
    }
  }, [isPopupOpened]);

  return <div style={{ width: "100%" }}>
    {tabNavigation && <TabNavigation {...tabNavigation} />}
    {panels && <Panels panels={panels} />}
  </div>;
}

export default SettingsView;