import React, { useEffect } from "react";
import styles from "../MerchantInvitation.module.scss";
import { AppIcon, Button, Container } from "common";
import { Input, FormikError, SelectDropdown } from "common/form";
import { ArrowLeft } from "react-bootstrap-icons";
import { useFormik } from "formik";
import { decypherApiErrorResponse, displayMiniFeedback, generateCsvFile, getAPIErrorForTracking } from 'utils/helpers';
import { ILocation, IStepper } from "common/interfaces";
import { useAppSelector } from "reducers/Hooks";
import _ from "lodash";
import { Edit, Trash } from "iconsax-react";
import { LocationValidation, Schema } from "utils/validation/additionalValidation";
import LocalStorage from "classes/LocalStorage";
import { merchantLocationsTemplate } from "utils/bulkUploadTemplates";
import Observer, { EVENTS } from "classes/Observer";
import LocationsHandler from "actions/LocationsHandler";
import { statesArray } from "utils/constants";
import Analytics, { ITracking } from "classes/Analytics";
import { formatLocationDisplay } from "utils/formatters";

const AdditionalLocations: React.FC<IStepper> = ({ onNext, onBack }) => {
  const mainAddress = useAppSelector(state => state.flows.merchantInvitation["mainAddress"]);
  const isRevision = useAppSelector(state => state.flows.isRevision);
  const observer = Observer.useObserver(EVENTS.LOCATION_UPDATED);
  const bulkUploadObserver = Observer.useObserver(EVENTS.BULK_LOCATIONS_UPLOAD);

  const [uploadedFile, setUploadedFile] = React.useState<Blob>(null);
  const [filename, setFilename] = React.useState<string>("");
  const [locations, setLocations] = React.useState<Array<ILocation>>([]);

  const vuid = LocalStorage.get<string>("vuid");
  const invitationId = LocalStorage.get<number>("invitationId");

  useEffect(() => {
    retrieveLocations();
  }, [observer]);

  useEffect(() => {
    retrieveLocations();
  }, [bulkUploadObserver])

  useEffect(() => {
    if (isRevision) {
      retrieveLocations();
    }
  }, [isRevision]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      location_name: "",
      address1: "",
      city: "",
      state: "",
      postal_code: "",
    },
    validationSchema: Schema({
      ...LocationValidation
    }),
    async onSubmit(values: ILocation) {
      LocationsHandler.createWithInvitation(values, invitationId, vuid)
        .then(() => {
          formik.resetForm();
          Analytics.track({ experience: "merchant", screen: "merchant_app_add_locations", object: "edit_location", action: "successful" } as ITracking, null);
        })
        .catch(error => {
          Analytics.track({ experience: "merchant", screen: "merchant_app_add_locations", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error), number_locations: locations?.length });
        });
    }
  });

  const editLocation = (location: ILocation) => {
    formik.setFieldValue("location_name", location.location_name);
    formik.setFieldValue("address1", location.address1);
    formik.setFieldValue("city", location.city);
    formik.setFieldValue("state", location.state);
    formik.setFieldValue("postal_code", location.postal_code);

    LocationsHandler.deleteWithInvitation(location.id, invitationId, vuid);
  }

  const removeLocation = (location: ILocation) => {
    LocationsHandler.deleteWithInvitation(location.id, invitationId, vuid)
      .then(() => {
        Analytics.track({ experience: "merchant", screen: "merchant_app_add_locations", object: "delete_location", action: "successful" } as ITracking, null);
      });
  }

  const uploadFile = () => {
    LocationsHandler.bulkUploadWithInvitation(uploadedFile, filename, invitationId, vuid)
      .then(() => {
        Analytics.track({ experience: "merchant", screen: "merchant_app_add_locations", object: "bulk_upload", action: "successful" } as ITracking, null);
      })
      .catch(error => {
        Analytics.track({ experience: "merchant", screen: "merchant_app_add_locations", object: "bulk_upload", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error), number_locations: locations?.length });
      });
  }

  useEffect(() => {
    if (mainAddress?.manageMultipleLocations === "no") {
      onNext();
    } else {
      retrieveLocations();
    }
  }, []);

  const retrieveLocations = () => {
    LocationsHandler.getAllWithInvitation(invitationId, vuid, true)
      .then(response => {
        setLocations(response);
      });
  }

  const doneAdding = () => {
    Analytics.track({ experience: "merchant", screen: "merchant_app_add_locations", object: "form_submission", action: "successful" } as ITracking, { number_locations: locations?.length });
    onNext();
  }

  return (<main className={styles.pageContainer}>
    <Container className={styles.innerContainer} style={{ marginTop: -50 }}>
      <div className={styles.invitationFormInner}>
        <ArrowLeft size={26} color="var(--darkTextColor)" onClick={onBack} style={{ cursor: "pointer" }} />
        <form onSubmit={formik.handleSubmit}>
          <h1>Add additional locations (optional)</h1>
          <div className={styles.formBlock}>
            <div className={styles.formInner}>
              <div className={styles.formField}>
                <label>Location name*</label>
                <Input
                  type="text"
                  id="merchantInvitation_location_name"
                  name="location_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location_name}
                />
                <FormikError propertyName="location_name" formik={formik} />
              </div>
              <div className={styles.formField}>
                <label>Address*</label>
                <Input
                  type="text"
                  id="merchantInvitation_address1"
                  name="address1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address1}
                />
                <FormikError propertyName="address1" formik={formik} />
              </div>
            </div>
            <div className={styles.formInner}>
              <div className={styles.formField}>
                <label>City*</label>
                <Input
                  id="merchantInvitation_city"
                  type="text"
                  name="city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
                <FormikError propertyName="city" formik={formik} />
              </div>
              <div className={styles.formField}>
                <label>State*</label>
                <SelectDropdown
                  id="merchantInvitation_state"
                  name="state"
                  selectOptions={statesArray}
                  onChange={(value: any) => {
                    formik.setFieldValue("state", value);
                  }}
                  value={formik.values.state}
                />
                <FormikError propertyName="state" formik={formik} />
              </div>
            </div>
            <div className={styles.formInner}>
              <div className={styles.formField}>
                <label>Postal code*</label>
                <Input
                  id="merchantInvitation_postal_code"
                  type="text"
                  name="postal_code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postal_code}
                  maxLength={5}
                />
                <FormikError propertyName="postal_code" formik={formik} />
              </div>
              <div
                className={`${styles.formBtnContainer} ${styles.finalBtnContainer} ${styles.nextBtnContainer}`}
              >
                <Button
                  id="merchantInvitation_addLocation"
                  type="submit"
                  label="Add Location"
                  variant="secondary"
                />
                <Button
                  id="merchantInvitation_addLocation_doneAdding"
                  type="button"
                  label="Done Adding"
                  onClick={doneAdding}
                />
              </div>
            </div>
          </div>
          <p className="textMedium" style={{ marginTop: 30 }}>
            <img src="/assets/images/star.png" alt="Location" style={{ width: 15, height: 15, marginRight: 7 }} />
            {formatLocationDisplay(_.find(locations, item => { return item.location_name === "main_location" || item.id === "main_location" || item.location_name?.toLowerCase() === "main location" }))}
          </p>
          {_.filter(locations, item => { return item.location_name !== "main_location" && item.id !== "main_location" && item.location_name?.toLowerCase() !== "main location" }).map((location, i) => {
            return <div key={`${location.id}_${i}`} className={`${styles.locationContainer} ${styles.editLocationContainer}`}>
              <p className={`textMedium`}>
                {formatLocationDisplay(location)}
              </p>
              <AppIcon clickTrigger={{ id: `additionalLocations_editLocation_${i}`, onClick: () => editLocation(location) }} size={20} color="var(--primaryVariationTextColor)" icon={Edit} />
              <AppIcon clickTrigger={{ id: `additionalLocations_deleteLocation_${i}`, onClick: () => removeLocation(location) }} size={20} color="var(--primaryVariationTextColor)" icon={Trash} />
            </div>;
          })}
          <div className={styles.orContainer}>
            <hr />
            <h2>or</h2>
            <hr />
          </div>
          <div className={styles.bulkCsvContainer}>
            <div className={styles.formBlock}>
              <div className={styles.formInner}>
                <div
                  className={`${styles.formField} ${styles.uploadLogoContainer}`}
                >
                  <div className={styles.labelcsvContainer}>
                    <p>CSV file</p>
                    <a href="#" target="_blank" className="menu" onClick={(e) => {
                      e.preventDefault();
                      generateCsvFile(merchantLocationsTemplate);
                      Analytics.track({ experience: "merchant", screen: "merchant_app_add_locations", object: "bulk_import_file_download_link", action: "clicked" } as ITracking, null);
                    }}>Download blank CSV</a>
                  </div>
                  <div className={styles.uploadContainer}>
                    <input
                      type="file"
                      name="csv"
                      id="actual-btn"
                      hidden
                      onChange={(e: any) => {
                        setFilename(e.target.files[0].name);
                        setUploadedFile(e.target.files[0]);
                      }}
                    />
                    <label htmlFor="actual-btn">Upload</label>
                    <span id={`fileChosen ${styles.choosenFile}`}>
                      {filename}
                    </span>
                  </div>
                </div>
                <div className={`${styles.formBtnContainer} ${styles.nextBtnContainer}`}>
                  <Button
                    id="merchantInvitation_bulkUpload"
                    type="button"
                    onClick={uploadFile}
                    label="Bulk upload"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Container>
  </main>
  );
};

export default AdditionalLocations;
