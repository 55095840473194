import styles from "./MerchantTabs.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { CalendarRange, ExportButton, InfoCard, Link, Table } from "common";
import { useParams } from "react-router-dom";
import { useAuth } from "auth/useAuth";
import { PartnerTabType } from "common/types";
import { IFilter, IHeader, IList } from "common/interfaces";
import { TableRef } from "common/Table";
import Observer, { EVENTS } from "classes/Observer";
import { formatAmount, formatAPICurrency, formatAPIDate } from "utils/formatters";
import Analytics, { ITracking } from "classes/Analytics";
import { Col, Container, Row } from "react-bootstrap";
import appActions from "reducers/AppReducer";
import { Grid } from "@mui/material";
import { IDateRange } from "common/Calendar";
import moment from "moment";
import { PartnerHandler } from "actions/PartnerHandler";
import PartnerInformationCard from "./PartnerInformationCard";
import DashboardHandler from "actions/DashboardHandler";
import { getStatus } from "utils/helpers";
import usePartner from "hooks/usePartner";

interface IPartnerAccountTab {
  variant: PartnerTabType;
}

const PartnerAccountTab: React.FC<IPartnerAccountTab> = ({ variant }) => {
  const user = useAuth()?.user;
  const { partnerSlug } = useParams();
  const partner = usePartner(partnerSlug);
  const dispatch = useAppDispatch();

  const [tableData, setTableData] = useState<Array<any>>([]);
  const tableRef = useRef<TableRef>(null);
  const [preventPagination, setPreventPagination] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [isPartnerDeactivated, setIsPartnerDeactivated] = useState(false);
  const merchantFilter = useAppSelector(state => state.app.tableFilters.merchant);
  const tableFilterAppliedObserver = Observer.useObserver(EVENTS.TABLE_FILTER_APPLIED);
  const [merchantsLoaded, setMerchantsLoaded] = useState<IList>(null);
  const businessModelUpdated = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);

  const [filter, setFilter] = useState<IFilter>({
    date_from: moment('01/01/2023', 'MM/DD/YYYY').toDate(),
    date_to: moment().toDate(),
  });

  useEffect(() => {
    if (partner) {
      getStatus("Deactivated")
        .then(response => {
          setIsPartnerDeactivated(partner?.status === response);
        });
    }
  }, [partner]);

  useEffect(() => {
    return () => {
      dispatch(appActions.resetMerchantFilter());
    };
  }, []);

  useEffect(() => {
    PartnerHandler().getStats(partnerSlug, false, filter)
  }, [partnerSlug, filter, businessModelUpdated]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [filter, merchantFilter, tableFilterAppliedObserver, businessModelUpdated]);

  useEffect(() => {
    if (preventPagination) {
      tableRef?.current?.reloadData();
    }
  }, [preventPagination]);

  useEffect(() => {
    if (preventPagination) {
      window.DownloadCSV(tableData, merchantsHeaders as Array<any>, "Merchants.csv");
      setPreventPagination(false);
    }
  }, [tableData]);

  const exportAction = () => {
    setPreventPagination(true);
  }

  const getList = async (next: string): Promise<IList> => {
    setLoadingData(true);
    let merchants = await DashboardHandler.getDashboardMerchants(next, { ...merchantFilter, partner: partnerSlug }, preventPagination);
    merchants.results = merchants.results.map(merchant => {
      return {
        ...merchant,
        name: <Link
          id={`merchant_${merchant?.slug}`}
          href={`/viewMerchant/${merchant?.slug}/accountInformation?bc=1`}
          onClick={() => {
            Analytics.track({ experience: "portal", screen: "partners_merchants", object: "merchant_record", action: "selected" } as ITracking, { merchant_id: merchant?.id });
          }}
          linkText={merchant?.name} />,
        created_at: formatAPIDate(merchant?.created_at),
        last_application_at: formatAPIDate(merchant?.last_application_at),
        requested_total: formatAPICurrency(merchant?.requested_total),
        funded_total: formatAPICurrency(merchant?.funded_total),
        created_by: merchant?.created_by || "Not available"
      };
    });
    setLoadingData(false);
    setMerchantsLoaded(merchants);
    return Promise.resolve(merchants);
  }

  const merchantsHeaders: Array<IHeader> = [
    { label: "Name", value: "name", size: 12 },
    // { label: "# Active users", value: "active_user_count", size: 9, align: "center" },
    // { label: "Created by", value: "created_by", size: 12 },
    // { label: "Created", value: "created_at", size: 8 },
    { label: "Last app.", value: "last_application_at", size: 7 },
    // { label: "Submited", value: "submitted_count", size: 7 },
    // { label: "Funded", value: "funded_count", size: 6 },
    { label: "Requested", value: "requested_total", size: 9 },
    { label: "Funded", value: "funded_total", size: 9 },
    { label: "Status", value: "status", size: 7 },
  ];

  return <>
    <PartnerInformationCard variant={variant} />

    {partner && <div className={styles.contentContainer}>

      <Row className={styles.titleCalendar}>
        <Col md={12} lg={6} className={styles.titleContainer}>
          <h2>Partner {partner?.name}</h2>
        </Col>
        <Col md={12} lg={6} className={styles.calendarRange}>
          <CalendarRange
            showCalendarVariation={false}
            onChange={(dateRange: IDateRange) => { setFilter({ ...filter, date_from: dateRange.startDate, date_to: dateRange.endDate }) }}
            state={{ startDate: filter.date_from, endDate: filter.date_to }}
            onDateRangeChange={(dateRange: IDateRange) => {
              Analytics.track({ experience: "portal", screen: "dashboard", object: "date_range", action: "updated" } as ITracking, { days_in_range: moment(dateRange.endDate).diff(dateRange.startDate, 'days') + 1 });
            }}
          />
        </Col>
      </Row>

      <div className={styles.infoCards}>
        <InfoCard id="submitted-count" value={partner?.submitted_count} label="# Submitted" disabled={isPartnerDeactivated} />
        <InfoCard id="approved-count" value={partner?.approved_count} label="# Approved" disabled={isPartnerDeactivated} />
        <InfoCard id="funded-count" value={partner?.funded_count} label="# Funded" disabled={isPartnerDeactivated} />
        <InfoCard id="approval-rate" value={formatAmount(partner?.approval_rate * 100 || 0) + "%"} label="Approval Rate" disabled={isPartnerDeactivated} />
        <InfoCard id="submitted-amount" value={partner?.enrolled_merchants} label="Enrolled Merch." disabled={isPartnerDeactivated} />
        <InfoCard id="funded-amount" value={partner?.active_merchants} label="Active Merch." disabled={isPartnerDeactivated} />
        <InfoCard id="funded-amount" value={"$" + formatAmount(partner?.funded_total_amount)} label="Funded" disabled={isPartnerDeactivated} />
        <InfoCard id="conversion-rate" value={formatAmount(partner?.conversion_rate * 100 || 0) + "%"} label="Conversion rate" disabled={isPartnerDeactivated} />
        {/* those 3 empty Info Cards are used to position last InfoCard to the left */}
        <InfoCard id="empty" value="" label="" empty={true} disabled={isPartnerDeactivated} />
        <InfoCard id="empty" value="" label="" empty={true} disabled={isPartnerDeactivated} />
        <InfoCard id="empty" value="" label="" empty={true} disabled={isPartnerDeactivated} />
      </div>

      {/* MERCHANTS */}
      <Container fluid>
        {<div className={styles.titleContainer}>
          <Grid container>
            <Grid item sm={12} md={3} lg={4}>
              {partner && <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Merchants</h2>}
            </Grid>
            <Grid item sm={12} md={9} lg={8} style={{ textAlign: "right" }}>
              <ExportButton
                id="partnerMerchants"
                onClick={() => {
                  exportAction();
                  Analytics.track({ experience: "portal", screen: "partners_merchants", object: "export_button", action: "clicked" } as ITracking);
                }}
                exporting={preventPagination}
                disabled={loadingData || merchantsLoaded?.count === 0}
              />
            </Grid>
          </Grid>
        </div>}
      </Container>
      <Container fluid className={styles.tableContainer}>
        <Table
          id="partner_merchants"
          data={getList}
          headers={merchantsHeaders}
          onUpdate={setTableData}
          tableBodyStyle={{ minWidth: 700 }}
          ref={tableRef}
          maxHeight={450}
          disabled={isPartnerDeactivated}
        />
      </Container>

    </div>}

  </>;
};

export default PartnerAccountTab;
