import strings from "localization/Strings";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, ToolTip } from "../";
import styles from "./WhiteFooter.module.scss";
import PoweredByEnable from "./PoweredByEnable";
import { useAppSelector } from "reducers/Hooks";

interface IWhiteFooter {
  onlyTermsPrivacy?: boolean;
  onPartnerGuaranteeDisclosureHover?: () => void;
  onRepresentativeExampleHover?: () => void;
  onTermsOfServiceClick?: () => void;
  onPrivacyPolicyClick?: () => void;
}

const WhiteFooter: React.FC<IWhiteFooter> = ({ onlyTermsPrivacy, onPartnerGuaranteeDisclosureHover, onRepresentativeExampleHover, onTermsOfServiceClick, onPrivacyPolicyClick }) => {
  const whitelabel = useAppSelector(state => state.whitelabel);
  return (
    <div className={styles.borrowerFooter}>
      <Container fluid>
        <Row>
          <Col sm={12} md={12} lg={12} className={styles.footerCol1}>
            <ul>
              {!onlyTermsPrivacy && <li>
                <ToolTip
                  textToolTip="Representative Example & APR Disclosure •"
                  text={strings.representativeExample}
                  placement="top"
                  onHover={onRepresentativeExampleHover}
                />
              </li>}
              {!onlyTermsPrivacy && <li>
                <ToolTip
                  textToolTip="Partner Guarantee Disclosure •"
                  text={strings.partnerGuaranteeDisclosure}
                  placement="top"
                  onHover={onPartnerGuaranteeDisclosureHover}
                />
              </li>}
              <li>
                <Link id="whiteFooter_termsConditions" href="/termsConditions" openNewTab linkText="Terms of Service •" onClick={onTermsOfServiceClick} />
              </li>
              <li>
                <Link id="whiteFooter_privacyPolicy" href="/privacyPolicy" openNewTab linkText="Privacy Policy" onClick={onPrivacyPolicyClick} />
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      {whitelabel?.display_powered_by_enable && <PoweredByEnable darkMode={false} />}
    </div>
  );
};

export default WhiteFooter;
