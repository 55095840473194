import React from "react";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import styles from "./HelpButton.module.scss";
import { POPUPS } from "utils/constants";

interface IHelpButton {
  type: "MERCHANT" | "BORROWER";
  onClick?: () => void;
}

const HelpButton: React.FC<IHelpButton> = ({ type, onClick }) => {
  const dispatch = useAppDispatch();
  const { openPopup } = popUpActions;

  return <button
    id="helpButton"
    className={styles.helpButton}
    onClick={() => {
      if (onClick) {
        onClick();
      }
      dispatch(openPopup({ name: POPUPS.HELP, message: type }))
    }}>
    Help
  </button>;
};

export default HelpButton;
