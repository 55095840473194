import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import styles from "./BackdropOverlay.module.scss";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { displayMiniFeedback } from "utils/helpers";

const BackdropOverlay: React.FC = () => {
  const dispatch = useAppDispatch();
  const popupOpened = useAppSelector(state => state.popUp.showPopup);
  const addMerchantPopupOpened = useAppSelector(state => {
    const index = state.popUp.fullscreen.names.findIndex(item => { return item === POPUPS.ADD_MERCHANT });
    return state.popUp.fullscreen.isOpen[index];
  });
  const addPartnerPopupOpened = useAppSelector(state => {
    const index = state.popUp.fullscreen.names.findIndex(item => { return item === POPUPS.ADD_PARTNER });
    return state.popUp.fullscreen.isOpen[index];
  });
  const unsubscribePopupOpened = useAppSelector(state => {
    const index = state.popUp.fullscreen.names.findIndex(item => { return item === POPUPS.UNSUBSCRIBE });
    return state.popUp.fullscreen.isOpen[index];
  });

  const handleClose = () => {
    if (addMerchantPopupOpened || addPartnerPopupOpened) {
      // Prevent user from clicking the blur background by mistake
      displayMiniFeedback(`Closing the form without finalizing the new ${addMerchantPopupOpened ? "merchant" : "partner"} will result in losing your progress`, true, 60000);
    } else if (unsubscribePopupOpened) {
    }
    else {
      dispatch(popUpActions.closePopup());
    }
  }

  return <div className={styles.backDropOverlay} style={{ display: popupOpened ? 'flex' : 'none', zIndex: 9 }} onClick={handleClose} />;
};

export default BackdropOverlay;