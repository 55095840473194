import { AppIcon, BulkUploadSection } from "common";
import { Form, Input, SelectDropdown } from "common/form";
import { IAddEntity, IFileData, ILocation } from "common/interfaces";
import React, { useEffect, useRef, useState } from "react";
import { statesArray } from "utils/constants";
import { LocationValidation2, Schema } from "utils/validation/additionalValidation";
import { merchantLocationsTemplate } from "utils/bulkUploadTemplates";
import { FormRef } from "common/form/Form";
import { decypherApiErrorResponse, displayMiniFeedback, getAPIErrorForTracking } from "utils/helpers";
import { Edit, Trash } from "iconsax-react";
import styles from "./AdditionalLocationsForm.module.scss";
import LocationsHandler from "actions/LocationsHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { formatLocationDisplay } from "utils/formatters";

interface IAdditionalLocationsForm extends IAddEntity {
  slug: string;
  mainAddress: any
}
const AdditionalLocationsForm: React.FC<IAdditionalLocationsForm> = ({ onNext, initialValues, slug, mainAddress }) => {
  const [locations, setLocations] = useState<Array<ILocation>>([]);
  const formRef = useRef<FormRef>(null);

  useEffect(() => {
    LocationsHandler.getAll(null, slug, { key: "name", asc: true }, 50000)
      .then(response => {
        setLocations(response.results || []);
      });
  }, [initialValues]);

  const handleAddLocation = async (data: any): Promise<void> => {
    const isFormValid = await formRef.current.isValid();
    if (isFormValid) {
      try {
        await LocationsHandler.create(data, slug);
        Analytics.track({ experience: "portal", screen: "add_merchant_add_locations", object: "edit_location", action: "successful" } as ITracking);
        await updateList();
        Analytics.track({ experience: "portal", screen: "add_merchant_add_locations", object: "form_submission", action: "successful" } as ITracking, { number_of_locations: locations.length });
        formRef.current.reset();
      } catch (error) {
        Analytics.track({ experience: "portal", screen: "add_merchant_add_locations", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error), number_of_locations: locations.length });
      }
    }
  };

  const handleBulkUpload = async (fileData: IFileData): Promise<void> => {
    try {
      await LocationsHandler.bulkUpload(fileData.file, slug);
      Analytics.track({ experience: "portal", screen: "add_merchant_add_locations", object: "bulk_upload", action: "successful" } as ITracking);
      await updateList();
    } catch (error) {
      Analytics.track({ experience: "portal", screen: "add_merchant_add_locations", object: "bulk_upload", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
    }
  }

  const editLocation = async (location: any): Promise<void> => {
    formRef.current.edit(location);
    await LocationsHandler.delete(location.id);
    await updateList();
  }

  const removeLocation = async (location: any): Promise<void> => {
    await LocationsHandler.delete(location.id);
    Analytics.track({ experience: "portal", screen: "add_merchant_add_locations", object: "delete_location", action: "successful" } as ITracking);
    await updateList();
  }

  const updateList = async () => {
    const response = await LocationsHandler.getAll(null, slug, { key: "name", asc: true }, 50000, null, true);
    setLocations(response.results);
  }

  return <>
    <Form
      id="addMerchantPopup_additionalLocations"
      buttonPosition="side right"
      onFormSubmit={(data: any) => { handleAddLocation(data); }}
      title="Add merchant • Add additional locations (optional)"
      submitBtnText="Done adding"
      validationSchema={Schema({
        ...LocationValidation2
      })}
      values={initialValues}
      inputFields={[
        [
          <Input name="name" id="addNewLocationPopup_location_name" label="Location name*" />,
          <Input name="address1" id="addNewLocationPopup_address1" label="Address*" />
        ],
        [
          <Input name="city" id="addNewLocationPopup_city" label="City*" />,
          <SelectDropdown selectOptions={statesArray} name="state" id="addNewLocationPopup_state" label="State*" />
        ],
        [
          <Input name="postal_code" id="addNewLocationPopup_postal_code" maxLength={5} label="Zip code*" />,
        ]
      ]}
      isInsidePopup
      styleOverride={{
        pageContainer: {
          marginBottom: "3.5rem",
        },
      }}
      additionalButtonLabel="Add location"
      onAdditionalButtonClick={() => { onNext({ name: "", address1: "", city: "", postal_code: "", state: "" }) }}
      ref={formRef}
      isAdditionalButtonSubmit
    />
    <p className="textMedium" style={{ display: "flex" }}>
      <img src="/assets/images/star.png" alt="Location" style={{ width: 15, height: 15, marginRight: 7 }} />
      {formatLocationDisplay({
        id: "main_location",
        location_name: "Main location",
        ...mainAddress
      })}
    </p>
    {locations.map((location, i) => {
      return <div key={`${location.id}_${location.location_name}_${i}`} className={`${styles.locationContainer} ${styles.editLocationContainer}`}>
        <p className="textMedium">
          {formatLocationDisplay(location)}
        </p>
        <AppIcon clickTrigger={{ id: `additionalLocations_editLocation_${i}`, onClick: () => editLocation(location) }} size={20} color="var(--primaryVariationTextColor)" icon={Edit} />
        <AppIcon clickTrigger={{ id: `additionalLocations_deleteLocation_${i}`, onClick: () => removeLocation(location) }} size={20} color="var(--primaryVariationTextColor)" icon={Trash} />
      </div>;
    })}
    <BulkUploadSection
      onSend={handleBulkUpload}
      template={merchantLocationsTemplate}
      title={" Bulk upload locations via CSV file"}
      onDownloadClick={() => {
        Analytics.track({ experience: "portal", screen: "add_merchant_add_locations", object: "bulk_import_file_download_link", action: "clicked" } as ITracking);
      }} />
  </>;
}

export default AdditionalLocationsForm;