import ErrorHandler from "./ErrorHandler";
import { IFilter, IList } from "common/interfaces";
import moment from "moment";
import { DASHBOARD_SECTIONS } from "utils/constants";
import ApiCache from "classes/ApiCache";
import { ENABLE_FINANCING_API_INSTANCE } from "./ActionConstants";

export default class DashboardHandler {
  /**
 * @description Get user leaderboard.
 * @param {string} next Used to get the next page.
 * @param {IFilter} filter Filter.
 * @returns {Promise<IList>} Leaderboard.
 */
  static getUserLeaderboard = async (next: string, filter: IFilter): Promise<IList> => {
    try {
      let url = next || `dashboards/user-leaderboard/?offset=0&limit=30`;
      url = url.Filter(filter);

      const response = await ApiCache.get(url);
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get location leaderboard.
   * @param {string} next Used to get the next page.
   * @param {IFilter} filter filter.
   * @returns {Promise<IList>} Leaderboard.
   */
  static getLocationLeaderboard = async (next: string, filter: IFilter): Promise<IList> => {
    try {
      let url = next || `dashboards/location-leaderboard/?offset=0&limit=30`;
      url = url.Filter(filter);

      const response = await ApiCache.get(url);
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);

    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get merchant dasboard information.
   * @param {string} section Use one of the constants DASHBOARD_SECTIONS.
   * @param {IFilter} filter filter.
   * @param {"amount" | "count"} mode Chart mode. Only applies for application activity.
   * @returns {Promise<any>} Result.
   */
  static getInfo = async (section: string, filter: IFilter, mode?: "amount" | "count"): Promise<any> => {
    try {
      const newFilter = Object.assign({}, filter);
      if (section === DASHBOARD_SECTIONS.APPLICATION_ACTIVITY) {
        // force filter to always show last 7 days
        newFilter.date_from = moment().add(-1, "week").toDate();
        newFilter.date_to = moment().toDate();
      }

      let url = section;
      url = url.Filter(newFilter).AddQuerystring("mode", mode);

      const response = await ApiCache.get(url);
      return Promise.resolve(response);

    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get dashboard merchants.
   * @param {string} next Next link.
   * @param {IFilter} filter Merchant filter.
   * @param {boolean} preventPagination Prevent breaking the result in different pages.
   * @returns {Promise<IList>} API response.
   */
  static getDashboardMerchants = async (next: string, filter: IFilter, preventPagination: boolean = false): Promise<IList> => {
    try {
      let url = next || `dashboards/merchant-list/?offset=0&limit=30`;
      url = url.Filter(filter);
      if (preventPagination) {
        url = url.AddQuerystring("offset", "0").AddQuerystring("limit", "1000000");
      }

      const response = await ApiCache.get(url);
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get dashboard partners.
   * @param {string} next Next link.
   * @param {IFilter} filter Merchant filter.
   * @param {boolean} preventPagination Prevent breaking the result in different pages.
   * @returns {Promise<IList>} API response.
   */
  static getDashboardPartners = async (next: string, filter: IFilter, preventPagination: boolean = false): Promise<IList> => {
    try {
      let url = next || `dashboards/partner-list/?offset=0&limit=30`;
      url = url.Filter(filter);
      if (preventPagination) {
        url = url.AddQuerystring("offset", "0").AddQuerystring("limit", "1000000");
      }

      const response = await ENABLE_FINANCING_API_INSTANCE.get(url);
      return Promise.resolve({
        ...response.data,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get merchant stats.
   * @param {IFilter} filter filter.
   * @returns {Promise<any>} API response.
   */
  static getMerchantsStats = async (filter: IFilter): Promise<any> => {
    try {
      let url = `dashboards/merchant-stats/`;
      url = url.Filter(filter);

      const response = await ApiCache.get(url);
      return Promise.resolve(response);

    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get merchants activity.
   * @param {IFilter} filter filter.
   * @returns {Promise<any>} Response.
   */
  static getMerchantsActivity = async (filter: IFilter): Promise<any> => {
    try {
      const newFilter = Object.assign({}, filter);
      // force filter to always show last 7 days
      newFilter.date_from = moment().add(-1, "week").toDate();
      newFilter.date_to = moment().toDate();

      let url = `dashboards/merchant-activity/`;
      url = url.Filter(newFilter);

      const response = await ApiCache.get(url);
      return Promise.resolve(response);

    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
 * @description Get whitelabel rates.
 * @param {IFilter} filter filter.
 * @returns {Promise<any>} Response.
 */
  static getWhitelabelRates = async (filter: IFilter): Promise<any> => {
    try {
      const newFilter = Object.assign({}, filter);
      // force filter to always show last 7 days
      newFilter.date_from = moment().add(-1, "week").toDate();
      newFilter.date_to = moment().toDate();

      let url = `/dashboards/whitelabel-metrics/`;
      url = url.Filter(newFilter);

      const response = await ApiCache.get(url);
      return Promise.resolve(response);

    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  // prepared for the new partner view
  /**
 * @description Get partner rates.
 * @param {string} slug Partner slug.
 * @param {IFilter} filter filter.
 * @returns {Promise<any>} Response.
 */
  // static getPartnerRates = async (slug: string, filter: IFilter): Promise<any> => {
  //   try {
  //     const newFilter = Object.assign({}, filter);
  //     // force filter to always show last 7 days
  //     newFilter.date_from = moment().add(-1, "week").toDate();
  //     newFilter.date_to = moment().toDate();

  //     let url = `/dashboards/partner-metrics/${slug}`;
  //     url = url.Filter(newFilter);

  //     const response = await ApiCache.get(url);
  //     return Promise.resolve(response);

  //   } catch (error) {
  //     return Promise.reject(error.response);
  //   }
  // }
}