export const calculateDashboardRate = (rate: any): number => {
  if (rate === undefined || rate === null || rate === 0 || isNaN(rate)) {
    return 0;
  }
  const percentage = rate * 100;
  if (percentage >= 10) {
    return parseInt(percentage.toFixed(0));
  } else if (percentage < 10) {
    return parseFloat(percentage.toFixed(1));
  }

  return 0;
}