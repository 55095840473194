import React, { useEffect, useState } from "react";
import { DOMAIN_PREFIX } from "utils/constants";
import Input from "./Input";

interface ISlugTextField {
  id: string;
  /**
   * @default slug
   */
  name?: string;
  formik: any;
}

const SlugTextField: React.FC<ISlugTextField> = ({ id, name, formik }) => {

  const boxStyle = {
    color: "var(--darkTextColor)",
    lineHeight: "1.8rem",
    fontSize: "1.5rem",
    marginTop: "0.5rem",
    marginRight: 2,
    fontWeight: 500
  };

  const errorStyle = {
    color: "#ff3d3d",
    fontSize: "1.2rem",
    fontWeight: 500,
    width: "100%",
    left: 0, 
    top: 0, 
    maxWidth: 400
  } as React.CSSProperties;

  return <>
    <div style={{ display: "flex" }}>
      <div style={boxStyle}>{DOMAIN_PREFIX}</div>
      <Input
        id={`${id}_clientSlug`}
        type="text"
        name={name || "slug"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          formik.setFieldValue(name || "slug", e.target.value);
        }}
        onBlur={formik.handleBlur}
        value={formik.values[name || "slug"]}
        styleOverride={{ marginTop: -10, paddingInline: "0.5rem" }} />
    </div>
    {formik.touched[name || "slug"] && formik.errors[name || "slug"] && <p style={{ ...errorStyle }}>{formik.errors[name || "slug"].toString()}</p>}
  </>;
}

export default SlugTextField;