import React, { ReactElement } from "react";

interface IStepperComp {
  steps: Array<ReactElement>;
  step: number;
  onNext: () => void;
  onBack: () => void;
}

const Stepper: React.FC<IStepperComp> = ({ steps, step, onNext, onBack }) => {
  return <div>
    {React.createElement(steps[step].type, { onNext, onBack })}
  </div>;
};

export default Stepper;
