import React, { ReactElement } from "react";
import styles from "./StatusLabel.module.scss";

interface IStatusLabel {
  label: string | ReactElement;
}

const StatusLabel: React.FC<IStatusLabel> = ({ label }) => {
  if (typeof label === "string") {
    return <>
      <span className={`${styles.status} ${styles[label?.toString().toLowerCase()]}`}>
        {label?.toString().Capitalize()}
      </span>
    </>;
  } else {
    return label;
  }
}

export default StatusLabel;