import { AppIcon, Container, SVG } from "common";
import React from "react";
import styles from "./Funnel.module.scss";
import { MoneySend, Pointer, Profile, ProfileTick } from "iconsax-react";
import { Skeleton } from "@mui/material";

interface IFunnel {
  type: "Submitted" | "Offered" | "Clicked" | "Funded";
  total?: number;
  unique?: number;
  emptyImgSrc?: any;
  isLoading: boolean;
}

const Funnel: React.FC<IFunnel> = ({
  type,
  total,
  unique,
  emptyImgSrc,
  isLoading
}) => {

  const getIcon = () => {
    switch (type) {
      case "Submitted":
        return Profile;
      case "Offered":
        return ProfileTick;
      case "Clicked":
        return Pointer;
      case "Funded":
        return MoneySend;
      default:
        return Profile;
    }
  }

  return (
    <Container className={styles.submittedCount} style={{ maxHeight: "92px", height: "92px" }}>
      <div className={styles.submittedColOne}>
        {isLoading && <>
          <Skeleton width={100} height="100%" />
          <Skeleton width={100} height="100%" />
        </>}
        <>
          <div className={styles.countContainer} style={{ opacity: total === 0 && unique === 0 ? 0 : 1 }}>
            <h1>{total}</h1>
            <p className="infoMedium">Total applications</p>
          </div>
          <div className={styles.countContainer} style={{ opacity: total === 0 && unique === 0 ? 0 : 1 }}>
            <h1>{unique}</h1>
            <p className="infoMedium">Unique applicants</p>
          </div>
        </>
        <div className={total === 0 && unique === 0 ? styles.fadeInSvg : styles.fadeOutSvg} style={{
          position: "absolute",
          opacity: total === 0 && unique === 0 ? 1 : 0
        }}>
          <SVG
            src={emptyImgSrc}
            title={"Empty Application Funnel" + type}
            height={60}
            width={60}
          />
        </div>
      </div>
      <div className={styles.submittedCol}>
        <div className={styles.logoContainer}>
          <AppIcon color="var(--secondaryColor)" size={25} icon={getIcon()} />
          <h2>{type}</h2>
        </div>
      </div>
    </Container>
  );
};

export default Funnel;
