import { Button, SVG } from "common";
import { IAddEntity } from "common/interfaces";
import { useAppDispatch } from "reducers/Hooks";
import styles from "./DTMApplication.module.scss";
import popUpActions from "reducers/PopUpReducer";
import { useAuth } from "auth/useAuth";
import { useEffect } from "react";
import TempStore from "classes/TempStore";

const EndDTMApplication: React.FC<IAddEntity> = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  useEffect(() => {
    TempStore.clear();
  }, []);

  return <>
    <div className={styles.step1}>
      <h1>Thank you for submitting your information!</h1>
      <div className={styles.svgWrapper}>
        <SVG
          src={"/assets/images/annual_amount_success.svg"}
          title={`Annual amount icon`}
          height={150}
        />
      </div>
      <p style={{ textAlign: "left" }}>Here's what happens next:</p>
      <ul style={{ listStyleType: "decimal" }}>
        <li>Our underwriting team reviews your submission to ensure nothing's missing</li>
        <li>We determine which lenders you qualify for based on a number of unique factors</li>
        <li>Once prequalified for a lender, you're sent the lenders agreement to sign</li>
      </ul>

      <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
        <Button
          id="dtm_application_take_me_to_dashboard"
          label="Take me to my dashboard"
          style={{ width: 270 }}
          onClick={() => { dispatch(popUpActions.closePopup()) }} />
      </div>
    </div>
  </>;
}

export default EndDTMApplication;