import React, { ReactElement, forwardRef } from "react";
import { Container, Button, PasswordRequirements, AppIcon, ToolTip } from "common";
import styles from "./Form.module.scss";
import { isSmallScreen, mergeRecursive } from "utils/helpers";
import { ArrowLeft } from "iconsax-react";
import _ from "lodash";
import { IForm, IFormSection } from "common/interfaces";
import Hasher from "classes/Hasher";
import { useForm } from "./Form.hooks";

export interface FormRef {
  reset: () => void;
  edit: (data: any) => void;
  isValid: () => Promise<boolean>;
  getValues?: () => any;
}

const Form: React.ForwardRefRenderFunction<FormRef, IForm> = (props, ref) => {

  let newStyleOverride: any = props.styleOverride;
  if (props.isInsidePopup) {
    newStyleOverride = mergeRecursive({
      pageContainer: { backgroundColor: "white", padding: "10px" },
      innerContainer: { padding: "0rem", boxShadow: "none" }
    }, props.styleOverride);
  }

  const { alerts, usesSections, formik, renderInputs, renderButtons, agreementAccepted, setAgreementAccepted } = useForm(props, ref, newStyleOverride);

  return <main className={styles.pageContainer} style={newStyleOverride?.pageContainer}>
    <Container className={styles.innerContainer} style={{ textAlign: usesSections ? 'left' : 'inherit', ...newStyleOverride?.innerContainer }}>
      <form className={styles.formContainer} onSubmit={formik.handleSubmit} id={`${props.id}_form`} style={newStyleOverride?.formContainer}>
        <>
          {props.onBackClick && <div className={styles.backIcon}>
            <AppIcon size={26} color="var(--darkTextColor)" clickTrigger={{ id: "form_back", onClick: props.onBackClick }} className={styles.backIcon} icon={ArrowLeft} />
          </div>}
          {props.title && <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{props.title}</h2>}
          {props.preface && props.preface.map((p: string, prefaceIndex: number) => {
            return <p key={`preface_${prefaceIndex}`} className={styles.preface}>
              {p}&nbsp;{props.prefaceInfo && prefaceIndex === (props.preface.length - 1) && <ToolTip placement={"top"} text={props.prefaceInfo} />}
            </p>
          })}
          {usesSections && <>
            {(props.inputFields as Array<IFormSection>).map((section: IFormSection, index: number) => {
              const sectionHash = Hasher.convert(`${section.title}-${index}`);
              return <React.Fragment key={sectionHash}>
                <h3 style={{ textAlign: usesSections ? 'left' : 'inherit', color: "var(--primaryTextColor)", marginBottom: -20, marginTop: 20 }}>
                  {section.title}
                </h3>
                <div className={styles.formBlock}>
                  {renderInputs(section.fields as Array<Array<ReactElement>>, (props.inputFields.length - 1) === index)}
                </div>
              </React.Fragment>;
            })}
            {props.buttonPosition !== "side right" && <div style={{ textAlign: props.buttonPosition === "bottom right" ? 'right' : 'center' }}>
              <Button metadata="A" id={`${props.id}_form_submit`} type="submit" label={props.submitBtnText} disabled={props.buttonDisabled} variant={props.buttonStyle} style={{ width: isSmallScreen() ? '100%' : 'auto', ...props.styleOverride?.button ? { ...props.styleOverride.button } : {} }} />
            </div>}
          </>}
          {!usesSections && <>
            <div className={styles.formBlock}>
              {renderInputs(props.inputFields as Array<Array<ReactElement>>)}
            </div>
          </>}
          {props.formHelp && <div className={styles.formHelp} style={{ justifyContent: isSmallScreen() ? "center" : "space-between" }}>
            {/* Form helper is a string, so adds the styleOverride to it. */}
            {typeof props.formHelp === typeof "" && <p style={newStyleOverride?.formHelp}>{props.formHelp.toString()}</p>}
            {/* Form helper is a react component, don't need to add styleOverride. */}
            {typeof props.formHelp !== typeof "" && <>{props.formHelp}</>}
            {/* When there is a form helper string and the button is set to "side right" displays the button at the side of the form helper content. */}
            {props.buttonPosition === "side right" && typeof props.formHelp === typeof "" && renderButtons()}
          </div>}
          {props.passwordRequirementsCheckField && <PasswordRequirements password={formik.values[props.passwordRequirementsCheckField]} />}
          {props.agreement && <div>
            <input type="checkbox" id="terms-checkbox" checked={agreementAccepted} onChange={(e) => setAgreementAccepted(e.target.checked)} />
            {props.agreement}
          </div >}
          {props.beforeButtonsJSX}
          {/* Render the buttons at the bottom of the form.*/}
          {(props.buttonPosition === "bottom center" || props.buttonPosition === "bottom right") && <>
            {props.disclaimer && <p style={{ color: "var(--darkTextColor)", fontSize: "1.2rem" }}>{props.disclaimer}</p>}
            {renderButtons()}
          </>}
        </>
        {props.endOfFormJSX}
        {props.disclaimer && props.buttonPosition !== "bottom center" && props.buttonPosition !== "bottom right" && <p style={{ color: "var(--darkTextColor)", fontSize: "1.2rem" }}>{props.disclaimer}</p>}
      </form >
      {alerts()}
    </Container >
  </main >;
};

export default forwardRef(Form);
