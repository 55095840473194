import styles from "./DeclineMerchantCancelLoan.module.scss";
import { Button, CloseIcon } from "common";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";

const DeclineMerchantPopup: React.FC<IPopup> = () => {
  const message = useAppSelector(state => state.popUp.messagePassing);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    return () => {
      setReason("");
    };
  }, []);

  const handleSend = () => {
    if (!reason?.trim()) {
      setError("Message is required");
    } else {
      message?.onSend(reason, message?.merchant);
    }
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="declineMerchantPopup" color="dark" />
        <p className={`${styles.p1} big`}>{`Decline ${message?.merchant?.name}`}</p>
        <p className={`${styles.p2} menu`}>
          You can send them a message below letting them know what was done wrong
          and needs to be fixed.
        </p>
        <div className={styles.msgTextarea}>
          <label className="label">Your message</label>
          <textarea
            id="message"
            name="message"
            className="textMedium"
            style={{
              padding: 10,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "var(--primaryVariationTextColor)"
            }}
            rows={2}
            cols={50}
            onChange={(e) => { setError(""); setReason(e.target.value); }}
            value={reason}
            maxLength={99} />
          {error && <p className={styles.errorText}>{error}</p>}
        </div>
        <div className={styles.btnContainer}>
          <Button id="sendBtn" label="Send decline" onClick={handleSend} />
        </div>
      </>
    </Popup >
  );
};

export default DeclineMerchantPopup;
