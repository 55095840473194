// this isn't used anywhere on 18/07/2023

import { CloseIcon, SearchBar, Table } from "common";
import React, { useEffect } from "react";
import styles from "./TableWIthSearchInputPopup.module.scss";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { useParams } from "react-router-dom";
import LoanApplicationHandler from "actions/LoanApplicationHandler";

// TODO: not MVP since we don't support partners at this moment
const EditAccountAssociationPopup: React.FC<IPopup> = () => {
  const [tableData, setTableData] = React.useState<any[]>([]);
  const { applicantId } = useParams();

  useEffect(() => {
    if (applicantId) {
      LoanApplicationHandler.get(applicantId)
        .then(response => {
          setTableData(response.merchant?.locations.map(location => {
            return {
              id: location.id,
              name: location.name,
              address: `${location.address1}, ${location.city} ${location.state}`
            }
          }));
        })
        .catch(error => {
          // TODO: handle error
        });
    }
  }, [applicantId]);

  const handleSelect = () => {

  }

  return (
    <Popup isInner={true} >
      <>
        <CloseIcon id="editAccountAssociationInformationPopup" color="dark" />
        <h2>Application 1234567890 • Edit account association</h2>
        <div className={styles.searchContainer}>
          <SearchBar
            id="editAccountAssociation_search"
            placeholder={"Search users"}
            onSearch={(filteredData) => {
              //setSortedTableData(filteredData);
            }} />
        </div>
        <div className={styles.tableDataContainer} style={{ overflowX: "auto" }}>
          <Table
            id="editAccountAssociation_accountAssociation"
            //data={sortedTableData}
            data={(next: string) => null} // TODO: integrate this when we start supporting partners
            headers={[
              { label: "Name", value: "name", size: 10 },
              { label: "City", value: "city", size: 10 },
              { label: "Account type", value: "accountType", size: 8 },
              { label: "Actions", value: "actions", size: 8, preventSorting: true }
            ]}
            action={() => { }}
            maxHeight={250}
          />
        </div>
      </>
    </Popup>
  );
};

export default EditAccountAssociationPopup;
