import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IMiniFeedback } from 'common/interfaces';

const counterSlice = createSlice({
  name: 'animation',
  initialState: {
    fullScreenLoading: {
      isOpen: true,
      text: "Loading",
    },
    loadingBar: {
      isFadingIn: false,
      isFadingOut: false,
      text: "Please wait"
    },
    miniFeedback: {
      open: false,
      isError: false,
      message: null,
      closeTimer: 6000
    }
  },
  reducers: {
    fadeInLoadingBar: (state, action?: PayloadAction<string>) => {
      state.loadingBar.isFadingIn = true;
      state.loadingBar.isFadingOut = false;
      state.loadingBar.text = action?.payload || "Please wait"
    },
    fadeOutLoadingBar: (state) => {
      state.loadingBar.isFadingOut = true;
      state.loadingBar.isFadingIn = false;
    },
    displayMiniFeedback: (state, action?: PayloadAction<IMiniFeedback>) => {
      state.miniFeedback.open = true;
      state.miniFeedback.isError = action.payload.isError;
      state.miniFeedback.message = action.payload.message;
      state.miniFeedback.closeTimer = action.payload.closeTimer || 6000;
    },
    hideMiniFeedback: (state) => {
      state.miniFeedback.open = false;
    },
    activateLoading: (state, action?: PayloadAction<string>) => {
      state.fullScreenLoading.isOpen = true;
      state.fullScreenLoading.text = action.payload || "Loading";
    },
    deactivateLoading: (state) => {
      state.fullScreenLoading.isOpen = false;
      state.fullScreenLoading.text = "Loading";
    },
  }
})

export const animationReducer = counterSlice.reducer;
export default counterSlice.actions;
