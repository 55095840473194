import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import styles from "./Form.module.scss";
import SVG from "common/SVG";
import { truncateString } from "utils/formatters";

interface IUploadFile {
  /** 
   * @summary Component id. 
   **/
  id: string;
  /** 
   * @summary Display label. 
   **/
  label: string;
  /** 
   * @summary Input name. 
   **/
  name: string;
  /** 
   * @summary Optional - Tooltip. 
   **/
  tooltip?: any;
  /** 
   * @summary Optional - Style. 
   **/
  styleOverride?: object;
  /** 
   * @summary Optional - Returns the selected file. 
   **/
  onFileSelection?: (file: any, inputId: string) => void;
  /** 
   * @summary Optional - Input file onChange event. 
   **/
  onChange?: (e: any) => void;
  /** 
   * @summary If true displays the logo after the image is selected. 
   **/
  displayLogo: boolean;
  /** 
   * @summary Pre-loads the component with this filename. 
   **/
  filename?: string;
}

export interface UploadFileRef {
  reset: () => void;
}

const UploadFile: React.ForwardRefRenderFunction<UploadFileRef, IUploadFile> = ({ id, name, displayLogo, onFileSelection, onChange, filename }, ref) => {
  const [selectedFilename, setSelectedFilename] = React.useState<string>(truncateString(filename, 22) ?? null);
  const [uploadedFile, setUploadedFile] = React.useState<Blob>(null);
  const [imageURL, setImageURL] = React.useState<string>(null);
  const [loading, setLoading] = React.useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (uploadedFile) {
      if (displayLogo) {
        setImageURL(URL.createObjectURL(uploadedFile));
      }
      onFileSelection(uploadedFile, name);
    }
    setLoading(false);
  }, [uploadedFile])

  const reset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return <div className={`${styles.formField} ${styles.uploadLogoContainer}`}>

    <div className={styles.uploadContainer}>
      <input
        type="file"
        id={id}
        name={name}
        hidden
        onChange={(e: any) => {
          setLoading(true);
          window.setTimeout(() => {
            if (e.target.files && e.target.files[0]) {
              setSelectedFilename(truncateString(e.target.files[0].name, 22));
              setUploadedFile(e.target.files[0]);
              if (onChange) onChange(e);
            } else {
              setLoading(false);
            }
          }, 100);
        }}
      />
      <label htmlFor={id}>Select File</label>
      <span className={`fileChosen ${styles.choosenFile}`}>
        {loading && <SVG src="/assets/images/uploading.svg" width="20" height="20" />}
        {!loading && <>{selectedFilename}</>}
      </span>
    </div>
    {displayLogo && imageURL && <div className={styles.logoContainer}>
      <img
        src={imageURL}
        alt="logoContainer"
        width={100}
        height={100}
        style={{
          height: "180px",
          objectFit: "cover",
          borderRadius: "6px",
        }}
      />
    </div>}
  </div>;
};

export default forwardRef(UploadFile);
