import React, { useEffect } from "react";
import InvitePopup from "./InvitePopup";
import { ISendInvitation, IPopup, IFileData, IFeedback } from "common/interfaces";
import { Form, Input, SelectDropdown } from "common/form";
import { Schema, emailMaxChars, EitherEmailOrPhoneValidation, RequestedLoanAmountValidation } from "utils/validation/additionalValidation";
import { displayFeedback, getAPIErrorForTracking, getStatus, splitFormInputFieldList } from "utils/helpers";
import { applicationInvitesOnlyEmailTemplate, applicationInvitesTemplate } from "utils/bulkUploadTemplates";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import appActions from "reducers/AppReducer";
import popUpActions from "reducers/PopUpReducer";
import { CloseIcon, LocationSelector } from "common";
import { POPUPS } from "utils/constants";
import InvitationHandler from "actions/InvitationHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { useFlag } from "hooks/useFlag";
import { stripOutNonDigits } from "utils/formatters";
import NewPopup from "./NewPopup";
import { useBusinessModel } from "hooks/useBusinessModel";

const InviteApplicantPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const selectedLocation = useAppSelector(state => state.app.selectedLocation);
  const smsEnabled = useFlag("loan-application-invite-sms");
  const { locations, programs } = useAppSelector(state => state.merchant);
  const selectedBusinessModel = useBusinessModel();

  useEffect(() => {
    getStatus("Active")
      .then(id => {
        const activeLocations = locations?.filter(location => { return location.status === id }) || [];
        if (activeLocations.length === 1) {
          dispatch(appActions.selectLocation(activeLocations[0]));
        }
      });
  }, []);

  const handleSubmit = (data: ISendInvitation): void => {
    let payload = null;
    if (data.email && !data.phone_number) {
      payload = {
        email: data.email,
      }
    } else if (!data.email && data.phone_number) {
      payload = {
        phone_number: `${stripOutNonDigits(data.phone_number)}`
      }
    }
    payload = { ...payload, loan_program: selectedBusinessModel };
    if (selectedBusinessModel === "DTM") {
      payload = { ...payload, loan_amount: parseFloat(stripOutNonDigits(data?.requested_loan_amount)) };
    }

    InvitationHandler.sendApplicationInvite(payload, selectedLocation?.id)
      .then(() => {
        Analytics.track({ experience: "portal", screen: "invite_applicant", object: "single_invitation", action: "successful" } as ITracking, { invitation_type: payload["email"] ? "EMAIL" : "SMS" });
        displaySuccessMessage(payload["email"] ? "EMAIL" : "SMS");
      })
      .catch(error => {
        Analytics.track({ experience: "portal", screen: "invite_applicant", object: "single_invitation", action: "unsuccessful" } as ITracking, { invitation_type: payload["email"] ? "EMAIL" : "SMS", error_name: getAPIErrorForTracking(error) });
      });
  }

  const handleUpload = (data: IFileData): void => {
    InvitationHandler.bulkUploadApplicationInvites(data.file, selectedLocation?.id)
      .then(() => {
        Analytics.track({ experience: "portal", screen: "invite_applicant", object: "bulk_invitation", action: "successful" } as ITracking, { location_id: selectedLocation?.id });
        displaySuccessMessage("UNDEFINED");
      })
      .catch(error => {
        Analytics.track({ experience: "portal", screen: "invite_applicant", object: "bulk_invitation", action: "unsuccessful" } as ITracking, { location_id: selectedLocation?.id, error_name: getAPIErrorForTracking(error) });
      });
  }

  const displaySuccessMessage = (commType: "UNDEFINED" | "SMS" | "EMAIL") => {
    dispatch(popUpActions.closePopup());
    displayFeedback({
      title: `Success!`,
      body: `The contact(s) will get ${commType === "UNDEFINED" ? "an email or SMS" : (commType === "EMAIL" ? "an email" : "a SMS")} with instructions\nto fill out an application.`,
      type: "USER",
      buttons: [
        {
          id: "back_to_applications",
          label: "Back",
          action: () => {
            dispatch(popUpActions.closePopup());
          }
        }
      ]
    } as IFeedback);
  }

  const getSelectedBusinessModelText = () => {
    if (selectedBusinessModel === "DTC") {
      return "DTC (can be changed in the top bar)";
    } else if (selectedBusinessModel === "DTM") {
      return "DTM (can be changed in the top bar)";
    }
    return "";
  }

  const getInputFields = () => {
    const inputFieldList: Array<any> = [
      <Input type="email" name="email" id="inviteApplicantPopup_email" label="Email" maxLength={emailMaxChars} />
    ];
    if (smsEnabled) {
      inputFieldList.push(<p>or</p>);
      inputFieldList.push(<Input type="text" name="phone_number" id="inviteApplicantPopup_phone_number" label="Phone number" mask="phone" />);
    }
    inputFieldList.push(<SelectDropdown selectOptions={[]} name="business_model" id="inviteApplicantPopup_business_model" label="Choose program" disabled={true} placeholder={getSelectedBusinessModelText()} />);
    if (selectedBusinessModel === "DTM") {
      inputFieldList.push(<Input type="text" name="requested_loan_amount" id="inviteApplicantPopup_requested_loan_amount" label="Requested loan amount" mask="amount" inputPrefix="$" />);
    }
    const inputFields = splitFormInputFieldList(inputFieldList);
    return inputFields;
  }

  const getForm = () => {
    return (
      <Form
        id="inviteApplicantPopup"
        title=""
        submitBtnText="Send"
        onFormSubmit={handleSubmit}
        validationSchema={Schema({
          ...EitherEmailOrPhoneValidation,
          requested_loan_amount: selectedBusinessModel === "DTM" ? RequestedLoanAmountValidation : undefined,
        })}
        values={{
          email: "",
          phone_number: "",
          requested_loan_amount: "",
        }}
        buttonPosition={(smsEnabled && selectedBusinessModel === "DTM") || (!smsEnabled && (selectedBusinessModel !== "DTM")) ? "bottom right" : "side right"}
        inputFields={getInputFields()}
        isInsidePopup
      />
    );
  };

  return <>
    {!selectedLocation && <NewPopup style={{ maxWidth: "100rem" }}>
      <>
        <CloseIcon id="newApplication_locationSelector_close" color="dark" />
        <h2 style={{ marginBottom: 30 }}>First, select your location:</h2>
        <LocationSelector id="newApplication_locationSelector" onSelection={(location) => {
          dispatch(appActions.selectLocation(location))
        }}
          selectedLocationId={selectedLocation?.id} />
      </>
    </NewPopup>}
    {selectedLocation && <InvitePopup
      formTitle={`Send invitation link via email${smsEnabled ? " or text" : ""} - ${selectedLocation?.name}`}
      bulkUploadTitle={`Bulk invite via CSV file - ${selectedLocation?.name}`}
      disclaimerSend={`By clicking "Send", I affirm that I have received consent to email\n${smsEnabled ? "or text " : ""}the individual an invitation to inquire about financing\noptions.`}
      onBulkSendClick={handleUpload}
      disclaimerBulkSend={`By clicking "Upload", I affirm that I have received consent to\nemail${smsEnabled ? " or text" : ""} the individuals contained in the CSV file an\ninvitation to inquire about financing options.`}
      reducerName={POPUPS.INVITE_APPLICANT}
      form={getForm()}
      template={smsEnabled ? applicationInvitesTemplate : applicationInvitesOnlyEmailTemplate}
      onDownloadClick={() => {
        Analytics.track({ experience: "portal", screen: "invite_applicant", object: "bulk_import_file_download_link", action: "clicked" } as ITracking, { location_id: selectedLocation?.id });
      }}
      onCloseClick={() => {
        Analytics.track({ experience: "portal", screen: "invite_applicant", object: "close_modal", action: "clicked" } as ITracking);
      }}
      disableBulkInvitation={selectedBusinessModel === "DTM"}
    />}
  </>;
};

export default InviteApplicantPopup;
