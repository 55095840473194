import { Button, SVG } from "common";
import React, { CSSProperties, useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { FILTER_INITIAL_STATE } from "utils/constants";
import { isEqual } from "lodash";

interface IFilterButton {
  onClick: () => void;
  type: "MERCHANT" | "APPLICATION" | "MERCHANT_APPLICATION" | "PARTNER"
  id: string;
  style?: CSSProperties;
}

const FilterButton: React.FC<IFilterButton> = ({ onClick, type, id, style }) => {

  const applicationFilter = useAppSelector(state => state.app.tableFilters.application);
  const merchantFilter = useAppSelector(state => state.app.tableFilters.merchant);
  const merchantApplicationFilter = useAppSelector(state => state.app.tableFilters.merchantApplication);
  const partnerFilter = useAppSelector(state => state.app.tableFilters.partner);
  const [filterUsed, setFilterUsed] = useState(false);

  useEffect(() => {
    setFilterUsed(
      (type === "APPLICATION" && !isEqual(applicationFilter, Object.assign({}, FILTER_INITIAL_STATE))) ||
      (type === "MERCHANT" && !isEqual(merchantFilter, Object.assign({}, FILTER_INITIAL_STATE))) ||
      (type === "MERCHANT_APPLICATION" && !isEqual(merchantApplicationFilter, Object.assign({}, FILTER_INITIAL_STATE))) ||
      (type === "PARTNER" && !isEqual(partnerFilter, Object.assign({}, FILTER_INITIAL_STATE))));
  }, [applicationFilter, merchantFilter, merchantApplicationFilter, partnerFilter, type]);

  return <Button
    id={id.toLowerCase().includes("filter") ? id : `${id}_filter`}
    label="Filter"
    variant="secondary"
    onClick={onClick}
    icon={filterUsed ? <SVG src="/assets/images/filter-icon.svg" width={15} height={12} /> : null}
    style={style}
  />;
}

export default FilterButton;