import { IDTMApplication, IDTMOwner } from "common/interfaces";
import { ENABLE_FINANCING_API_INSTANCE } from "./ActionConstants";
import ErrorHandler from "./ErrorHandler";
import _ from "lodash";
import { UW_APP_STATUSES } from "utils/constants";
import { makeDTMApplicationAPIFriendly, makeDTMApplicationUIFriendly, makeDTMOwnerAPIFriendly, makeDTMOwnerUIFriendly } from "utils/formatters";
import store from "reducers/Store";
import dtmApplicationActions from "reducers/DTMApplicationReducer";
import Observer, { EVENTS } from "classes/Observer";
import { isNotNullNorUndefined } from "utils/helpers";

export const UnderwritingHandler = () => {
  /**
   * Asynchronously creates a Direct to Merchant (DTM) application for a specific merchant.
   * 
   * @param {IDTMApplication} application - The DTM application object to be submitted.
   * @returns {Promise<IDTMApplication>} A promise that resolves with the newly created DTM application data if successful, or rejects with an error if an error occurs.
   */
  const createDTMApplication = async (application: IDTMApplication): Promise<IDTMApplication> => {
    try {
      const payload = Object.assign({}, application);
      makeDTMApplicationAPIFriendly(payload);

      const response = await ENABLE_FINANCING_API_INSTANCE.post(`underwriting/dtm-applications/`, payload);
      const newApplication = { ...response.data };
      makeDTMApplicationUIFriendly(newApplication);

      return Promise.resolve(newApplication);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously retrieves a specific Direct to Merchant (DTM) application for a given merchant.
   * 
   * @returns {Promise<IDTMApplication>} A promise that resolves with an application.
   */
  const getDTMApplication = async (): Promise<IDTMApplication> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`underwriting/dtm-applications/`);
      const data = response.data;
      if (data.count > 0) {
        const application: IDTMApplication =
          _.find(data.results, (app: IDTMApplication) => app.underwriting_status === UW_APP_STATUSES.PENDING) ??
          _.find(data.results, (app: IDTMApplication) => [UW_APP_STATUSES.SUBMITTED, UW_APP_STATUSES.UNDER_REVIEW].includes(app.underwriting_status));

        makeDTMApplicationUIFriendly(application);
        return Promise.resolve(application);
      }
      return Promise.resolve(null);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously retrieves a declined Direct to Merchant (DTM) applications for a given merchant. Should never be more than 1 though.
   * 
   * @returns {Promise<IDTMApplication>} A promise that resolves with list of declined applications.
   */
  const getDeclinedDTMApplications = async (): Promise<Array<IDTMApplication>> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`underwriting/dtm-applications/?active=false`);
      const data = response.data;
      if (data.count > 0) {
        const applications: Array<IDTMApplication> = data.results;
        for (let i = 0; i < applications?.length; i++) {
          makeDTMApplicationUIFriendly(applications[i]);
        }
        return Promise.resolve(applications);
      }
      return Promise.resolve(null);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously updates a specific Direct to Merchant (DTM) application for a given merchant.
   * 
   * @param {string} application_id - The ID of the DTM application to update.
   * @param {IDTMApplication} application - The updated DTM application data.
   * @param {boolean} isFinalStep - Is this the final PATCH?
   * @returns {Promise<IDTMApplication>} A promise that resolves with the updated DTM application data if successful, or rejects with an error if an error occurs.
   */
  const updateDTMApplication = async (application_id: string, application: IDTMApplication, isFinalStep: boolean): Promise<IDTMApplication> => {
    try {
      const payload = { ...application };
      const body = makeDTMApplicationAPIFriendly(payload);
      if (isFinalStep) {
        body.append('underwriting_status', 'SUBMITTED');
      }
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`underwriting/dtm-applications/${application_id}/`, body);
      const newApplication: IDTMApplication = { ...response.data };
      makeDTMApplicationUIFriendly(newApplication);
      if ([UW_APP_STATUSES.SUBMITTED, UW_APP_STATUSES.UNDER_REVIEW].includes(newApplication?.underwriting_status)) {
        // When the app status changes to SUBMITTED or UNDER_REVIEW we want to remove the sidebar banner
        Observer.trigger(EVENTS.DTM_APPLICATION_FINALIZED);
      }
      return Promise.resolve(newApplication);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously deletes a specific Direct to Merchant (DTM) application for a given merchant.
   * 
   * @param {string} application_id - The ID of the DTM application to delete.
   * @returns {Promise<any>} A promise that resolves with the response data if the deletion is successful, or rejects with an error if an error occurs.
   */
  const deleteDTMApplication = async (application_id: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.delete(`underwriting/dtm-applications/${application_id}/`);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously creates a Direct to Merchant (DTM) owner for a specific application.
   * 
   * @param {string} application_id - DTM application id.
   * @param {IDTMOwner} owner - The DTM owner object to be submitted.
   * @param {File} doc_drivers_license - Drivers license file
   * @returns {Promise<IDTMOwner>} A promise that resolves with the newly created DTM owner data if successful, or rejects with an error if an error occurs.
   */
  const createDTMOwner = async (application_id: string, owner: IDTMOwner, doc_drivers_license: File): Promise<IDTMOwner> => {
    try {
      const payload = Object.assign({}, owner);
      const body = makeDTMOwnerAPIFriendly(payload);
      body.append("doc_drivers_license", doc_drivers_license);
      const response = await ENABLE_FINANCING_API_INSTANCE.post(`underwriting/dtm-applications/${application_id}/owners/`, body);
      const newOwner = { ...response.data };
      makeDTMOwnerUIFriendly(newOwner);

      // Save owner id to at the end it knows what owners to keep and what owners to remove.
      store.dispatch(dtmApplicationActions.keepOwnerID(newOwner?.id));

      return Promise.resolve(newOwner);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously retrieves a list of owners.
   * @param {string} application_id - The ID of the DTM application to update.
   * @returns {Promise<Array<IDTMOwner>>} A promise that resolves with a list of owners.
   */
  const getDTMOwners = async (application_id: string): Promise<Array<IDTMOwner>> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`underwriting/dtm-applications/${application_id}/owners/?limit=1000000`);
      const owners: Array<IDTMOwner> = [];
      response.data.results.forEach((owner: IDTMOwner) => {
        const friendlyOwner = { ...owner };
        makeDTMOwnerUIFriendly(friendlyOwner);
        owners.push(friendlyOwner);
      });
      return Promise.resolve(owners);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously updates a specific Direct to Merchant (DTM) owner.
   * 
   * @param {string} application_id - The ID of the DTM application to update.
   * @param {string} owner_id - The ID of the DTM owner to update.
   * @param {IDTMOwner} owner - The updated DTM owner data.
   * @param {File} doc_drivers_license - Drivers license file
   * @returns {Promise<IDTMOwner>} A promise that resolves with the updated DTM owner data if successful, or rejects with an error if an error occurs.
   */
  const updateDTMOwner = async (application_id: string, owner_id: string, owner: IDTMOwner, doc_drivers_license: File): Promise<IDTMOwner> => {
    try {
      const payload = { ...owner };
      const body = makeDTMOwnerAPIFriendly(payload);
      if (isNotNullNorUndefined(doc_drivers_license)) {
        body.append("doc_drivers_license", doc_drivers_license);
      }
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`underwriting/dtm-applications/${application_id}/owners/${owner_id}/`, body);
      const newOwner = { ...response.data };
      makeDTMOwnerUIFriendly(newOwner);

      // Save owner id to at the end it knows what owners to keep and what owners to remove.
      store.dispatch(dtmApplicationActions.keepOwnerID(owner_id));

      return Promise.resolve(newOwner);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously deletes a specific Direct to Merchant (DTM) owner for a given application.
   * 
   * @param {string} application_id - The ID of the DTM application where the owner is.
   * @param {string} owner_id -  The ID of the owner to delete.
   * @returns {Promise<any>} A promise that resolves with the response data if the deletion is successful, or rejects with an error if an error occurs.
   */
  const deleteDTMOwner = async (application_id: string, owner_id: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.delete(`underwriting/dtm-applications/${application_id}/owners/${owner_id}/`);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously uploads a document for a specific Direct to Merchant (DTM) application for a given merchant.
   * 
   * @param {string} application_id - The ID of the DTM application to update.
   * @param {string} property_name - The document property name.
   * @param {File} document - File to upload
   * @returns {Promise<IDTMApplication>} A promise that resolves with the updated DTM application data if successful, or rejects with an error if an error occurs.
   */
  const uploadDocument = async (application_id: string, property_name: string, document: File): Promise<IDTMApplication> => {
    try {
      const body = new FormData();
      body.append(property_name, document);
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`underwriting/dtm-applications/${application_id}/`, body);

      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * Asynchronously retrieves the a current version of attestation.
   * @returns {Promise<IDTMApplication>} A promise that resolves with an application.
  */
  const getAttestation = async (): Promise<IDTMApplication> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`/legal-content/merchant-attestation`);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  return {
    createDTMApplication, getDTMApplication, updateDTMApplication, deleteDTMApplication,
    getDTMOwners, updateDTMOwner, createDTMOwner, deleteDTMOwner, uploadDocument, getDeclinedDTMApplications,
    getAttestation
  }
}