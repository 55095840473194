import React from "react";

interface ILabel {
  text: string;
}

const Label: React.FC<ILabel> = ({ text }) => {
  return <label className="label">{text}</label>;
}

export default Label;