import React from "react";
import styles from "./InformationCardLine.module.scss";

interface IInformationCardLine {
    textLabel: string,
    textValue: string | number,
}

const InformationCardLine: React.FC<IInformationCardLine> = ({ textLabel, textValue }) => {
    return <>
        <div className={styles.informationCardLine}>
            <div>
                <p className={"menu"}>{textLabel}</p>
            </div>
            <div>
                <p className="fontTextMBig">{textValue}</p>
            </div>
        </div>
    </>;
}

export default InformationCardLine;