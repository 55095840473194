import { DeactivatedMerchantHandler } from 'actions/ErrorHandler';
import { MerchantHandler } from 'actions/MerchantHandler';
import { useAuth } from 'auth/useAuth';
import Observer, { EVENTS } from 'classes/Observer';
import TempStore from 'classes/TempStore';
import { IMerchant } from 'common/interfaces';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducers/Hooks';
import merchantActions from "reducers/MerchantReducer";
import { MERCHANT_RETRIEVING } from 'utils/constants';

const useMerchant = (merchantSlug?: string, forceReload?: boolean, callback?: () => void) => {
  const dispatch = useAppDispatch();
  const merchant = useAppSelector((state) => state.merchant);
  const user = useAuth()?.user;
  const slugFromUrl = useParams()?.merchantSlug;
  const [value, setValue] = React.useState(merchant);
  const observerMerchantUpdated = Observer.useObserver(EVENTS.MERCHANT_UPDATED);
  const observerMerchantRetreived = Observer.useObserver(EVENTS.MERCHANT_RETREIVED);

  useEffect(() => {
    if (observerMerchantUpdated > 1) {
      TempStore.remove(merchant?.id);
    }
  }, [observerMerchantUpdated])

  useEffect(() => {
    if (observerMerchantRetreived > 1) {
      TempStore.save<boolean>(MERCHANT_RETRIEVING, false);
    }
  }, [observerMerchantRetreived])

  useEffect(() => {
    const fetchData = async () => {
      if (merchantSlug || (user?.user_type === "MERCHANT" && user?.customer?.slug) || slugFromUrl) {

        const tempMerchant: IMerchant = TempStore.get<IMerchant>(merchant?.id);
        const merchantRetrieving: boolean = TempStore.get<boolean>(MERCHANT_RETRIEVING);
        if (!tempMerchant && !merchantRetrieving) {
          // call api
          TempStore.save<boolean>(MERCHANT_RETRIEVING, true);
          const response = await MerchantHandler(DeactivatedMerchantHandler).get(merchantSlug || (user?.user_type === "MERCHANT" && user?.customer?.slug) || slugFromUrl);
          if (merchant?.id) {
            TempStore.save<IMerchant>(merchant?.id, merchant, EVENTS.MERCHANT_RETREIVED);
          }
          dispatch(merchantActions.update(response));
          if (callback) {
            callback();
          }
        } else {
          setValue(tempMerchant);
        }
      }
    };

    let merchantNotMatchingTheSlug = false;
    if (slugFromUrl && (merchant?.slug !== slugFromUrl)) {
      merchantNotMatchingTheSlug = true;
    }

    const tempMerchant: IMerchant = TempStore.get<IMerchant>(merchant?.id);
    if (!merchant?.id || forceReload || merchantNotMatchingTheSlug || !tempMerchant) {
      fetchData();
    }

    setValue(merchant);
  }, [merchant, dispatch, merchantSlug, user, slugFromUrl]);

  return value;
};

export default useMerchant;
