import { SVG } from "common";
import React from "react";
import _ from "lodash";
import styles from "./Chart.module.scss";
import { parseChartData } from "utils/helpers";
import { LineBarChart, LoadingChart } from ".";

export interface IMerchantActivityChart {
  data: any;
  wrapped: boolean;
}

const MerchantActivityChart: React.FC<IMerchantActivityChart> = ({ data, wrapped }) => {

  return <div
    className={`${styles.graphContainer} ${wrapped ? styles.wrapped : ''}`}
    style={{ marginBottom: "20px" }}>
    {!wrapped && <h4>Merchant Activity (last 7 days)</h4>}
    {data && <div className={styles.graphInnerContainer}>
      {_.find(data, item => { return item.added > 0 || item.submitting > 0 }) && <LineBarChart
        data={parseChartData(data, "added", "submitting")}
        series1Label="Added"
        series2Label="Submitting" />}
      {_.find(data, item => { return item.added > 0 || item.submitting > 0 }) == null && <div className={styles.emptyImg}>
        <SVG
          src="/assets/dashboard/empty_merchant_activity.svg"
          title="Empty Merchant Activity"
          height={200}
        />
      </div>}
    </div>}
    {!data && <div className={styles.graphInnerContainer}>
      <div style={{ visibility: "hidden" }}>
        <LineBarChart
          data={parseChartData({ FRI: { submitting: 1, added: 1 } }, "added", "submitting")}
          series1Label="Added"
          series2Label="Submitting" />
      </div>
      <LoadingChart type="bar" />
    </div>
    }
  </div>;
}

export default MerchantActivityChart;