import React from "react";
import { useAppSelector } from "reducers/Hooks";
import styles from "./Agreements.module.scss";
import { Disclaimer } from "common";

const ESignAgreement: React.FC = () => {
  const whitelabelName = useAppSelector(state => state.whitelabel?.name);

  return <Disclaimer title="E-Sign Agreement">
    <div className={styles.agreement}>
      <p>
        Please read this information carefully and print a copy and/or retain this information for future reference. Introduction. You are submitting a request to be matched with one of our third party lenders and/or service providers. In order to offer you a loan, the third party lenders and/or service providers need your consent to use and accept electronic signatures, records, and disclosures (“E-Consent”). This form notifies you of your rights when receiving electronic disclosures, notices, and information. By clicking on the link assenting to our terms, you acknowledge that you received this E-Consent and that you consent to conduct transactions using electronic signatures, electronic disclosures, electronic records, and electronic contract documents (“Disclosures”).
      </p>
      <br />
      <br />
      <h2>Option for Paper or Non-Electronic Records</h2>
      <p>
        You may request any Disclosures in paper copy by contacting <a href="https://www.enablefinancing.com/">Enable, Inc.</a>, the third party lender and/or the service provider directly. Enable, Inc., the lenders and/or the service providers will provide paper copies at no charge. Enable, Inc., the lenders and/or service providers will retain all Disclosures as applicable law requires. To obtain a paper copy from Enable, Inc., please email us at <a href="mailto:support@enablefinancing.com">support@enablefinancing.com</a> with your request. In order to obtain a paper copy from a third party lender and/or service provider, please contact the third party lender and/or service provider directly.
      </p>
      <br />
      <br />
      <h2>Scope of Consent</h2>
      <p>
        This E-Consent applies to all interactions online concerning you, {whitelabelName}, Enable, Inc., and the third party lender and/or the service provider and includes those interactions engaged in on any mobile device, including phones, smart-phones, and tablets. By exercising this E-Consent, {whitelabelName}, Enable, Inc., the third party lender and/or the service provider may process your information and interact during all online interactions with you electronically. {whitelabelName}, Enable, Inc., the lender and/or the service provider may also send you notices electronically related to its interactions and transactions. Disclosures may be provided online at our or third party lenders’ and/or service providers’ websites, and may be provided by e-mail. Consenting to Do Business Electronically. Before you decide to do business electronically with {whitelabelName}, Enable, Inc., the third party lenders and/or the service providers, you should consider whether you have the required hardware and software capabilities described below.
      </p>
      <br />
      <br />
      <h2>Hardware and Software Requirements</h2>
      <p>
        To access and retain the Disclosures electronically, you will need to use the following computer software and hardware: A PC or MAC compatible computer or other device capable of accessing the Internet, access to an e-mail account, and an Internet Browser software program that supports at least 128 bit encryption, such as Microsoft® Internet Explorer, Netscape® or Mozilla Firefox®. To read some documents, you may need a PDF file reader like Adobe® Acrobat Reader X ® or Foxit®. You will need a printer or a long-term storage device, such as your computer’s disk drive, to retain a copy of the Disclosures for future reference. You may send any questions regarding the hardware and software requirements directly to Enable, Inc., the third party lenders and/or the service providers.
      </p>
      <br />
      <br />
      <h2>Withdrawing Consent</h2>
      <p>
        Your E-Consent for our matching service and for our third party lenders’ and/or service providers’ consideration of your matching request cannot be withdrawn because it is a one-time transaction. If you are matched with one or more third party lenders and/or service providers, you are free to withdraw your E-Consent with {whitelabelName}, Enable, Inc., those third party lenders and/or service providers at any time and at no charge. However, if you withdraw this E-Consent before receiving credit, you may be prevented from obtaining credit from the lender and/or service provider. Contact Enable, Inc., the third party lender and/or service provider directly if you wish to withdraw this E-Consent. If you decide to withdraw this E-Consent, the legal effectiveness, validity, and enforceability of prior electronic Disclosures will not be affected.
      </p>
      <br />
      <br />
      <h2>Change to Your Contact Information</h2>
      <p>
        You should keep {whitelabelName}, Enable, Inc., third party lenders and/or the service providers informed of any change in your electronic address or mailing address. You may update such information by logging into {whitelabelName}’s, Enable, Inc.’s, the third party lender’s and/or the service provider’s website or by sending Enable, Inc., the lender and/or the service provider a written update by mail.
      </p>
      <p>
        YOUR ABILITY TO ACCESS DISCLOSURES. BY CLICKING THE LINK, YOU ASSENT TO OUR TERMS. YOU ACKNOWLEDGE THAT YOU CAN ACCESS THE DISCLOSURES IN THE DESIGNATED FORMATS DESCRIBED ABOVE. CONSENT. BY CLICKING THE LINK, YOU ASSENT TO THE TERMS. YOU ACKNOWLEDGE YOU HAVE READ THIS INFORMATION ABOUT ELECTRONIC SIGNATURES, RECORDS, DISCLOSURES, AND DOING BUSINESS ELECTRONICALLY. YOU CONSENT TO USING ELECTRONIC SIGNATURES, HAVING ALL DISCLOSURES PROVIDED OR MADE AVAILABLE TO YOU IN ELECTRONIC FORM AND TO DOING BUSINESS WITH THE LENDER AND/OR SERVICE PROVIDER ELECTRONICALLY. YOU ACKNOWLEDGE THAT YOU MAY REQUEST A PAPER COPY OF THE ELECTRONIC RECORDS AND DISCLOSURES, WHICH WILL BE PROVIDED TO YOU AT NO CHARGE. IF YOU REFRAIN FROM PROCEEDING THEN YOU NEITHER WISH TO USE ELECTRONIC SIGNATURES NOR CONDUCT THIS TRANSACTION ELECTRONICALLY. YOU ALSO ACKNOWLEDGE THAT YOUR CONSENT TO ELECTRONIC DISCLOSURES IS REQUIRED TO RECEIVE SERVICES FROM THIRD PARTY LENDERS AND/OR SERVICE PROVIDERS OVER THE INTERNET.
      </p>
    </div>
  </Disclaimer>;
};

export default ESignAgreement;
