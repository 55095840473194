import React, { useEffect, useState } from "react";
import { Input, Form } from "common/form";
import { IContact, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/FlowsReducer";
import { ContactValidation, Schema } from "utils/validation/additionalValidation";
import LocalStorage from "classes/LocalStorage";
import { MerchantHandler } from "actions/MerchantHandler";
import { stripOutNonDigits } from "utils/formatters";
import { useLoadingBar } from "hooks/useLoadingBar";

const MainContactInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();
  const isRevision = useAppSelector(state => state.flows.isRevision);
  const mainContactInitialValues = useAppSelector(state => state.flows.merchantInvitation["mainContact"]);
  const vuid = LocalStorage.get<string>("vuid");
  const invitationId = LocalStorage.get<number>("invitationId");
  const [initialValues, setInitialValues] = useState(null);
  useLoadingBar(initialValues);

  useEffect(() => {
    if (mainContactInitialValues) {
      setInitialValues(mainContactInitialValues);
    }
  }, [mainContactInitialValues]);

  const handleSubmit = async (data: IContact) => {
    const payload = {
      ...data,
      main_contact_phone: `${stripOutNonDigits(data.main_contact_phone)}`
    };

    dispatch(actions.updateMerchantInvitation({ mainContact: data }));

    await MerchantHandler().saveMainContact(payload, invitationId, vuid, isRevision);
    onNext();
  }

  return <>
    {initialValues && <Form
      id="merchantInvitation_mainContactInformation"
      buttonPosition="bottom right"
      onFormSubmit={handleSubmit}
      title="Business main contact information"
      submitBtnText="Next"
      validationSchema={Schema({
        ...ContactValidation
      })}
      values={initialValues}
      inputFields={[
        [
          <Input type="text" name="main_contact_first_name" id="merchantInvitation_contact_first_name" label="First name*" />,
          <Input type="text" name="main_contact_last_name" id="merchantInvitation_contact_last_name" label="Last name*" />,
        ],
        [
          <Input readonly={isRevision} type="email" name="main_contact_email" id="merchantInvitation_contact_email" label="Email*" tooltip="This email address will receive an invitation to create a username and password once your account is approved." />,
          <Input type="text" name="main_contact_phone" id="merchantInvitation_contact_phone" label="Phone number*" mask="phone" />
        ]
      ]}
      onBackClick={onBack}
    />}
  </>;
};

export default MainContactInformation;
