import React from "react";
import styles from "./ProgressChartRow.module.scss";
import ProgressChart, { IProgressChart } from "./ProgressChart";
import { sanitizeString } from "utils/helpers";

interface IProgressChartRow {
  charts: Array<IProgressChart>;
  title: string;
}

const ProgressChartRow: React.FC<IProgressChartRow> = ({ charts, title }) => {

  return <div className={styles["progress-chart-row"]}>
    <h3 style={{ color: "var(--primaryVariationTextColor)" }}>
      {title}
    </h3>
    <div className={styles["progress-chart-items"]}>
      {charts.map((chart, index) => {
        return <ProgressChart {...chart} key={`progress-chart-${sanitizeString(title)}-${index}`} />;
      })}
    </div>
  </div>;
}

export default ProgressChartRow;