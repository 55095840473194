import React, { useState } from "react";
import { preventTextSelection } from "utils/helpers";
import styles from "./ButtonDropdown.module.scss";

interface IButton {
  id: string;
  label: string;
  options: Array<{ label: string, action: () => void }>;
}

const ButtonDropdown: React.FC<IButton> = ({ id, label, options }) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const selectOption = (action: () => void) => {
    setOpenDropdown(false);
    action();
  }

  return <div className={styles.buttonContainer}>
    <button
      className={styles.button}
      id={id}
      type="button"
      onClick={() => { setOpenDropdown(!openDropdown) }}
      onMouseDown={preventTextSelection}
      style={{ borderRadius: openDropdown ? "2rem  2rem 0px 0" : "4rem" }}>
      {label}
    </button>
    {openDropdown && <>
      <div className={styles.options}>
        {options.map(option => {
          return <div key={`user_group_${option.label}`} className={styles.option} onClick={() => selectOption(option.action)}>
          {option.label}
        </div>;
        })}
      </div>
    </>}
  </div>;
};

export default ButtonDropdown;
