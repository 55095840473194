// used in merchant settings - new view
import { MerchantHandler } from "actions/MerchantHandler";
import Observer, { EVENTS } from "classes/Observer";
import Landing from "common/Landing";
import useMerchant from "hooks/useMerchant";
import React, { useEffect } from "react";

// TODO: combine LandingPageContent and LandingPagePreview - LandingPageContent takes into account different industry templates - Landing page preview isn't used anymore
const LandingPageContent: React.FC = () => {
  const merchant = useMerchant();
  const observer = Observer.useObserver(EVENTS.MERCHANT_UPDATED);

  useEffect(() => {
    MerchantHandler().get(merchant?.slug);
  }, [observer]);

  return <>
    <div className="logoPreview" style={{ border: "1px solid var(--primaryColor20Solid)" }}>
      <Landing
        id="landingPreview"
        type="MERCHANT"
        preview={true}
        showOnlyAboveFold={false}
        merchantLogoPreview={null}
        merchantInitials={merchant?.name}
        hero={merchant?.industry_template?.hero_image || "/assets/images/merchantLandingBanner.png"}
        subtitle={merchant?.industry_template?.body || null}
        title={merchant?.industry_template?.title || null}
        {...merchant?.color_theme && { colors: [merchant?.color_theme?.primary_light, merchant?.color_theme?.primary_dark, merchant?.color_theme?.secondary] }}
        {...merchant?.primary_logo && { preview_logo: merchant?.primary_logo }}
        titleBelowFold="See how much you qualify for in just a few minutes with no impact to your credit score."
        feat1="Terms up to 144 months"
        feat2="Rates starting at 5.99%"
        feat3="Loans up to $100,000"
        feat4="Access to 20+ lenders"
        stepsTitle="Apply for your loan in 4 easy steps!"
        step1="Fill out a simple, mobile friendly application"
        step2="View your available loan offers"
        step3="Choose the offer that best fits your budget"
        step4="Finalize your application with your chosen lender"
      />
    </div>
  </>;
}

export default LandingPageContent;