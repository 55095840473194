import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import animationActions from "reducers/AnimationReducer";
import styles from "./MiniFeedback.module.scss";
import { Add } from "iconsax-react";

const MiniFeedback: React.FC = () => {
  const dispatch = useAppDispatch();
  const feedback = useAppSelector(state => state.animation.miniFeedback);

  useEffect(() => {
    if (feedback.open) {
      /* If the feedback message is an error, the automatic close time will be extended to 30 minutes. 
      This gives users ample time to capture screenshots and review error details without feeling rushed. */
      const timer = setTimeout(() => {
        dispatch(animationActions.hideMiniFeedback());
      }, feedback.isError ? 3600000 : feedback.closeTimer);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [feedback]);

  return <div className={`${styles.miniFeedback} ${feedback.open ? styles.show : ''} ${feedback.isError ? styles.error : styles.success}`}>
    <span>{feedback.message}</span>
    <Add className={styles.close} onClick={() => { dispatch(animationActions.hideMiniFeedback()); }} />
  </div>;
}

export default MiniFeedback;