import React, { ReactNode } from "react";

interface IPadding {
    all?: string;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    children?: ReactNode;
}

const Padding: React.FC<IPadding> = ({ all, top, right, bottom, left, children }) => {
    return (
        <div style={{ padding: all ? all : "50px", paddingLeft: left ? left : "50px", paddingRight: right ? right : "50px", paddingTop: top ? top : "50px", paddingBottom: bottom ? bottom : "50px" }}>
            {children}
        </div>
    );
};

export default Padding;
