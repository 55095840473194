import { Button, CloseIcon, SVG } from "common";
import { IAddEntity } from "common/interfaces";
import { useAppDispatch } from "reducers/Hooks";
import styles from "./DTMApplication.module.scss";
import popUpActions from "reducers/PopUpReducer";

const StartDTMApplication: React.FC<IAddEntity> = ({ onNext, ready }) => {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  return <>
    <div className={styles.step1}>
      <CloseIcon id="inviteUserPopup" color="dark" onClose={handleClose} />
      <h1>Apply for Direct-to-Merchant (DTM)</h1>
      <p>Want to have the funds for each loan go to you instead of your customer? If yes, you need our DTM program.</p>
      <p>Complete our DTM application by continuing below. Fill out all fields (both required and non-required) to be considered by all of our lenders.</p>
      {<div className={styles.svgWrapper}>
        <SVG
          src={"/assets/images/annual_amount.svg"}
          title={`Annual amount icon`}
          height={150}
        />
      </div>}
      <Button
        id="dtm_application_apply_dtm_continue"
        label="continue"
        style={{
          width: "170px",
          marginBottom: "5rem"
        }}
        onClick={() => { onNext(null) }}
        disabled={!ready}
      />
    </div>
  </>;
}

export default StartDTMApplication;