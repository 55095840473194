import { useAuth } from "auth/useAuth";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import React, { useEffect, useState } from "react";
import Dashboard2 from "./Dashboard2";
import Dashboard from "./Dashboard";
import useMerchant from 'hooks/useMerchant';
import { POPUPS } from "utils/constants";
import useUnderwritingApplications from "hooks/useUnderwritingApplications";
import UserHandler from "actions/UserHandler";
import DTMAppStatusEnum from "common/enums/DTMAppStatusEnum";

const NewDashboard: React.FC = () => {
  const user = useAuth()?.user;
  const merchant = useMerchant();
  const [ready, setReady] = useState(false);
  const dispatch = useAppDispatch();
  const [dtmAppStatus, verified, declined, buttonLabel] = useUnderwritingApplications();

  const render_dtm_modal = () => {
    const show_dtm_modal = user?.user_type === "MERCHANT"
      && merchant?.whitelabel?.direct_to_merchant
      && verified
      && !declined
      && ![DTMAppStatusEnum.NOT_ELIGIBLE, DTMAppStatusEnum.UNDER_REVIEW].includes(dtmAppStatus)
      ;
    console.log(`show_dtm_modal: ${show_dtm_modal}`);

    if (show_dtm_modal) {
      const response = UserHandler.getUserEvents();
      response.then((resp) => {
        if (resp.data["show-dtm-modal"] !== "INACTIVE") {
          dispatch(popUpActions.openPopup(POPUPS.DTM_NOW_AVAILABLE));
        }
      });
    }

  };

  useEffect(() => {
    if (!ready && user?.user_type) {
      setReady(true);
    }
  }, [user]);

  useEffect(() => {
    render_dtm_modal();
  }, [verified, declined, dtmAppStatus]);

  return <>
    {!ready && <></>}
    {ready && <>
      {(user?.user_type === "MERCHANT") || (user?.user_type === "WHITELABEL") ?
        <Dashboard2 /> : <Dashboard />}
    </>}
  </>;
}

export default NewDashboard;
