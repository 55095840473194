import { Button, CloseIcon, SearchBar, Table } from "common";
import React, { useEffect, useRef, useState } from "react";
import styles from "./TableWIthSearchInputPopup.module.scss";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IList, IPopup } from "common/interfaces";
import Popup from "./Popup";
import popUpActions from "reducers/PopUpReducer";
import { useParams } from "react-router-dom";
import { SearchBarRef } from "common/SearchBar";
import UserHandler from "actions/UserHandler";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { MerchantHandler } from "actions/MerchantHandler";
import { PartnerHandler } from "actions/PartnerHandler";
import { formatFullname } from "utils/formatters";

const EditUserAssociationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const { applicantId } = useParams();
  const message = useAppSelector(state => state.popUp.messagePassing);
  const [newUser, setNewUser] = React.useState<any>(null);
  const [keyword, setKeyword] = useState<string>("");

  const searchBarRef = useRef<SearchBarRef>(null);

  useEffect(() => {
    return () => {
      setNewUser(null);
    };
  }, []);

  const handleSelect = (user: any) => {
    if (user) {
      setNewUser(user.id);
    }
  }

  const parseUsers = (users: Array<any>, selected: number): Array<any> => {
    return users.map((user: any) => {
      return {
        id: user.id,
        name: formatFullname(user?.first_name, user?.last_name),
        email: user.email,
        selected: selected === user.id
      }
    });
  }

  const getData = async (next: string): Promise<IList> => {
    let response: IList = null;
    if (message.type === "APPLICATION") {
      response = await UserHandler.getAll(null, "MERCHANT", message.application.merchant?.id, null, 100000);
      response.results = parseUsers(response.results, message?.application?.created_by?.id);
    } else {
      response = await UserHandler.getAll(null, message.type === "MERCHANT" ? "WHITELABEL" : "PARTNER", null, null, 100000);
      response.results = parseUsers(response.results, message.type === "MERCHANT" ? message?.merchant?.created_by?.id : message?.partner?.created_by?.id);
    }
    return response;
  }

  const saveChange = () => {
    if (newUser) {
      searchBarRef.current.clearSearch();
      if (message.type === "APPLICATION") {
        LoanApplicationHandler.updateUserAssociation(newUser, applicantId)
          .then(() => {
            dispatch(popUpActions.closePopup());
          });
      } else if (message.type === "MERCHANT") {
        MerchantHandler().patch(message?.merchant?.slug, { created_by: newUser })
          .then(() => {
            dispatch(popUpActions.closePopup());
          });
      } else if (message.type === "PARTNER") {
        PartnerHandler().patch(message?.partner?.slug, { created_by: newUser })
          .then(() => {
            dispatch(popUpActions.closePopup());
          });
      }
    }
  }

  return (
    <Popup isInner={true} >
      <>
        <CloseIcon id="editUserAssociationInformationPopup" color="dark" />
        <h2>{`${message?.type === "MERCHANT" ? "Merchant" : (message?.type === "PARTNER" ? "Partner" : `Application ${applicantId}`)} • Edit user association`}</h2>
        <div>
          <SearchBar
            id="editUserAssociation_search"
            placeholder={"Search users"}
            onSearch={setKeyword}
            ref={searchBarRef} />
        </div>
        <div className={styles.tableDataContainer} style={{ overflowX: "auto" }}>
          <Table
            id="editUserAssociation_userAssociation"
            data={getData}
            tableBodyStyle={{ minWidth: 700 }}
            headers={[
              { label: "Name", value: "name", size: 11 },
              { label: "Email", value: "email", size: 14 },
              { label: "Select user", value: "actions", size: 6, preventSorting: true }
            ]}
            action={(selected: any) => { handleSelect(selected) }}
            keyword={keyword}
            maxHeight={273}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <Button
            id="save_user_association"
            label="Save"
            onClick={saveChange}
          />
        </div>
      </>
    </Popup>
  );
};

export default EditUserAssociationPopup;
