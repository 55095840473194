import { IFieldDefinition } from 'common/interfaces';
import * as Yup from 'yup';

// Define a function to create the validation schema dynamically
export const createValidationSchema = (fields: IFieldDefinition[]) => {
  const shape = {};
  fields.forEach((field: IFieldDefinition) => {
    if (field.validation) {
      shape[field.name] = field.validation;
    } else {
      shape[field.name] = Yup.string(); // default to string with no constraints
    }
  });
  return Yup.object().shape(shape);
};
