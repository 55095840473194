import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { useEffect, useState } from "react";
import { displayMiniFeedback, isPopupOpened } from "utils/helpers";
import { POPUPS } from "utils/constants";
import AddMerchantPopup from "content/popups/AddMerchantPopup";
import AddNewLocationPopup from "content/popups/AddNewLocationPopup";
import AddPartnerPopup from "content/popups/AddPartnerPopup";
import ApplicationHistoryPopup from "content/popups/ApplicationHistoryPopup";
import ApplicationStatusPopup from "content/popups/ApplicationStatusPopup";
import BusinessModelPopup from "content/popups/BusinessModelPopup";
import ConfirmationPopup from "content/popups/ConfirmationPopup";
import DeclineMerchantPopup from "content/popups/DeclineMerchantPopup";
import EditAccountAssociationPopup from "content/popups/EditAccountAssociationPopup";
import EditBusinessInformationPopup from "content/popups/EditBusinessInformationPopup";
import EditEstimatedSalesPopup from "content/popups/EditEstimatedSalesPopup";
import EditLenderGroupPopup from "content/popups/EditLenderGroupPopup";
import EditLocationAssociationPopup from "content/popups/EditLocationAssociationPopup";
import EditLocationPopup from "content/popups/EditLocationPopup";
import EditLogoPopup from "content/popups/EditLogoPopup";
import EditContactInformationPopup from "content/popups/EditMainContactInformationPopup";
import EditMerchantBusinessMainContactPopup from "content/popups/EditMerchantBusinessMainContactPopup";
import EditMerchantLandingPageTemplatePopup from "content/popups/EditMerchantLandingPageTemplatePopup";
import EditMerchantLandingPageTemplateSettingsPopup from "content/popups/EditMerchantLandingPageTemplateSettingsPopup";
import EditMerchantSlugPopup from "content/popups/EditMerchantSlugPopup";
import EditPartnerBusinessMainContactPopup from "content/popups/EditPartnerBusinessMainContactPopup";
import EditSupportPopup from "content/popups/EditSupportPopup";
import EditUserAssociationPopup from "content/popups/EditUserAssociationPopup";
import EditUserInformationPopup from "content/popups/EditUserInformationPopup";
import EditUserPopup from "content/popups/EditUserPopup";
import EditWhitelabelBusinessMainContactPopup from "content/popups/EditWhitelabelBusinessMainContactPopup";
import ExportReportPopup from "content/popups/ExportReportPopup";
import HelpPopup from "content/popups/HelpPopup";
import InstructionPopup from "content/popups/InstructionPopup";
import InvitationStatusPopup from "content/popups/InvitationStatusPopup";
import InviteApplicantPopup from "content/popups/InviteApplicantPopup";
import InviteMerchantPopup from "content/popups/InviteMerchantPopup";
import InviteUserPopup from "content/popups/InviteUserPopup";
import LandingPageLogoSettingsPopup from "content/popups/LandingPageLogoSettingsPopup";
import LandingPageSettingsPopup from "content/popups/LandingPageSettingsPopup";
import LandingPageUrlSettingsPopup from "content/popups/LandingPageUrlSettingsPopup";
import LocationPopup from "content/popups/LocationPopup";
import MerchantHistoryPopup from "content/popups/MerchantHistoryPopup";
import NewApplicationPopup from "content/popups/NewApplicationPopup";
import NotificationPopup from "content/popups/NotificationPopup";
import PartnerHistoryPopup from "content/popups/PartnerHistoryPopup";
import ReportEditFieldsPopup from "content/popups/ReportEditFieldsPopup";
import SaveReportPopup from "content/popups/SaveReportPopup";
import TableFilterPopup from "content/popups/TableFilterPopup";
import UpdatePasswordPopup from "content/popups/UpdatePasswordPopup";
import RequestRefundPopup from "content/popups/RequestRefundPopup";
import CancelLoanPopup from "content/popups/CancelLoanPopup";
import RejectOfferPopup from "content/popups/RejectOfferPopup";

const AllPopupsTest = () => {
    const dispatch = useAppDispatch();

    const popupNamesInner = useAppSelector(state => state.popUp.inner.names);
    const popupNamesFullscreen = useAppSelector(state => state.popUp.fullscreen.names);
    const closePopupTriggered = useAppSelector(state => state.popUp.closePopupTriggered);
    const popupNames = [...popupNamesFullscreen, ...popupNamesInner];

    const tableFilterPopup = useAppSelector(state => isPopupOpened(state, POPUPS.TABLE_FILTER));
    const editBusinessInformationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_BUSINESS));
    const confirmationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.CONFIRMATION_INNER));
    const declineMerchantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.DECLINE_MERCHANT));
    const editAccountAssociationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_ACCOUNT_ASSOCIATION));
    const editLenderGroupPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_LENDER_GROUP));
    const editLocationAssociationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_LOCATION_ASSOCIATION));
    const editLocationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_LOCATION));
    const editContactInformationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MAIN_CONTACT));
    const editUserAssociationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_USER_ASSOCIATION));
    const editUserInformationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_USER_INFORMATION));
    const editUserPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_USER));
    const exportReportPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EXPORT_REPORT));
    const invitationStatusPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INVITATION_STATUS));
    const inviteUserPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INVITE_USER));
    const landingPageLogoSettingsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.LANDING_PAGE_LOGO_SETTINGS));
    const landingPageSettingsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.LANDING_PAGE_SETTINGS));
    const landingPageUrlSettingsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.LANDING_PAGE_URL_SETTINGS));
    const locationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.LOCATION));
    const newApplicationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.NEW_APPLICATION));
    const notificationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.NOTIFICATION));
    const reportEditFieldsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.REPORT_EDIT_FIELDS));
    const saveReportPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.SAVE_REPORT));
    const updatePasswordPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.UPDATE_PASSWORD));
    const inviteApplicantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INVITE_APPLICANT));
    const inviteMerchantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INVITE_MERCHANT));
    const addNewLocationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.ADD_NEW_LOCATION));
    const addMerchantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.ADD_MERCHANT));
    const addPartnerPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.ADD_PARTNER));
    const feedbackPopup = useAppSelector(state => state.popUp.feedbackPopup.display);
    const helpPopup = useAppSelector(state => isPopupOpened(state, POPUPS.HELP));
    const instructionPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INSTRUCTIONS));
    const editSupportPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_SUPPORT));
    const applicationHistoryPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.APPLICATION_HISTORY));
    const applicationStatusPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.APPLICATION_STATUS));
    const businessModelPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.BUSINESS_MODEL));
    const editEstimatedSalesPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_ESTIMATED_SALES));
    const editLogoPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_LOGO));
    const editMerchantBusinessMainContactPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MERCHANT_BUSINESS_AND_MAIN_CONTACT));
    const editMerchantLandingPageTemplatePopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE));
    const editMerchantLandingPageTemplateSettingsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE_SETTINGS));
    const editMerchantSlugPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MERCHANT_SLUG));
    const editPartnerBusinessAndMainContactPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_PARTNER_BUSINESS_AND_MAIN_CONTACT));
    const editWhitelabelBusinessAndMainContactPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_WHITELABEL_BUSINESS_AND_MAIN_CONTACT));
    const merchantHistoryPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.MERCHANT_HISTORY));
    const partnerHistoryPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.PARTNER_HISTORY));
    const requestRefundPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.REQUEST_REFUND));
    const cancelLoanPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.CANCEL_LOAN));
    const rejectOfferPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.REJECT_OFFER));

    // this results in inf loop
    // const popupNames = useAppSelector(state => [...state.popUp.fullscreen.names, ...state.popUp.inner.names]);

    const [index, setIndex] = useState(0);

    const styles = {
        innerDivs: {
            position: 'absolute',
            height: "100%",
            width: "100%",
            border: "1px solid #f3f3f3",
            backgroundColor: '#f3f3f3',
            zIndex: 15// for fullscreen popups to work, you should disable PopupsManager or set zIndex above them
        }
    }

    const increaseIndex = () => {
        setIndex((index + 1) % popupNames.length);
    }

    useEffect(() => {
        dispatch(popUpActions.openPopup(popupNames[index]));
    }, [index]);

    useEffect(() => {
        if (closePopupTriggered > 1) {
            increaseIndex();
        }
    }, [closePopupTriggered]);

    useEffect(() => {
        displayMiniFeedback("This is a mini feedback message", false, 600000);
    }, []);

    return <>
        <div style={styles.innerDivs as React.CSSProperties}>
            <input type="button" onClick={() => increaseIndex()} value="Next" />
            <>
                {addMerchantPopup && <AddMerchantPopup />}
                {addNewLocationPopup && <AddNewLocationPopup />}
                {addPartnerPopup && <AddPartnerPopup />}
                {applicationHistoryPopup && <ApplicationHistoryPopup />}
                {applicationStatusPopup && <ApplicationStatusPopup />}
                {businessModelPopup && <BusinessModelPopup />}
                {confirmationPopup && <ConfirmationPopup inner={true} />}
                {declineMerchantPopup && <DeclineMerchantPopup />}
                {editAccountAssociationPopup && <EditAccountAssociationPopup />}
                {editBusinessInformationPopup && <EditBusinessInformationPopup />}
                {editEstimatedSalesPopup && <EditEstimatedSalesPopup />}
                {editLenderGroupPopup && <EditLenderGroupPopup />}
                {editLocationAssociationPopup && <EditLocationAssociationPopup />}
                {editLocationPopup && <EditLocationPopup />}
                {editLogoPopup && <EditLogoPopup />}
                {editContactInformationPopup && <EditContactInformationPopup />}
                {editMerchantBusinessMainContactPopup && <EditMerchantBusinessMainContactPopup />}
                {editMerchantLandingPageTemplatePopup && <EditMerchantLandingPageTemplatePopup />}
                {editMerchantLandingPageTemplateSettingsPopup && <EditMerchantLandingPageTemplateSettingsPopup />}
                {editMerchantSlugPopup && <EditMerchantSlugPopup />}
                {editPartnerBusinessAndMainContactPopup && <EditPartnerBusinessMainContactPopup />}
                {editSupportPopup && <EditSupportPopup />}
                {editUserAssociationPopup && <EditUserAssociationPopup />}
                {editUserInformationPopup && <EditUserInformationPopup />}
                {editUserPopup && <EditUserPopup />}
                {editWhitelabelBusinessAndMainContactPopup && <EditWhitelabelBusinessMainContactPopup />}
                {exportReportPopup && <ExportReportPopup />}
                {helpPopup && <HelpPopup />}
                {instructionPopup && <InstructionPopup />}
                {invitationStatusPopup && <InvitationStatusPopup />}
                {inviteApplicantPopup && <InviteApplicantPopup />}
                {inviteMerchantPopup && <InviteMerchantPopup />}
                {inviteUserPopup && <InviteUserPopup />}
                {landingPageLogoSettingsPopup && <LandingPageLogoSettingsPopup />}
                {landingPageSettingsPopup && <LandingPageSettingsPopup />}
                {landingPageUrlSettingsPopup && <LandingPageUrlSettingsPopup />}
                {locationPopup && <LocationPopup />}
                {merchantHistoryPopup && <MerchantHistoryPopup />}
                {newApplicationPopup && <NewApplicationPopup />}
                {notificationPopup && <NotificationPopup />}
                {partnerHistoryPopup && <PartnerHistoryPopup />}
                {reportEditFieldsPopup && <ReportEditFieldsPopup />}
                {requestRefundPopup && <RequestRefundPopup />}
                {saveReportPopup && <SaveReportPopup />}
                {tableFilterPopup && <TableFilterPopup />}
                {updatePasswordPopup && <UpdatePasswordPopup />}
                {cancelLoanPopup && <CancelLoanPopup />}
                {rejectOfferPopup && <RejectOfferPopup />}
            </>
        </div>
        <p style={{ fontSize: "30px", position: 'fixed', top: "10px", left: "10px", zIndex: 20 }}>{popupNames[index] + ": " + (index + 1) + " / " + popupNames.length}</p>
    </>;
}
export default AllPopupsTest;
