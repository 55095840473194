import styles from "./Settings.module.scss";
import { Sidebar, DashboardHeader, Aside } from "common";
import React from "react";
import InnerPopups from "content/popups/InnerPopups";
import PartnerSettingsView from "./settings/partner/PartnerSettingsView";

const PartnerSettings: React.FC = () => {
  return <main className={styles.settingsPageContainer}>
    <Sidebar />
    <Aside>
      <DashboardHeader />
      <InnerPopups />
      {<PartnerSettingsView />}
    </Aside>
  </main>;
};

export default PartnerSettings;
