import Analytics, { ITracking } from "classes/Analytics";
import { ColorPalleteDropdown } from "common";
import { CustomContent, DigitInput, EmptySpace, Input, InputDropdown, SelectDropdown, TextArea, UploadFile } from "common/form";
import TripleInput from "common/form/TripleInput";
import { IFilter, IList, ISidebar } from "common/interfaces";
import {
  GlobalEdit, Home, LampOn, MoneyRecive, SecurityUser, Setting2, Shop, Notification,
  Location, Grid3, Notepad2, Timer1, UserSquare, MedalStar, People
} from "iconsax-react";
import _ from "lodash";
import React from "react";

export const statesArray = _.sortBy([
  { label: "Alaska", value: "AK" },
  { label: "Texas", value: "TX" },
  { label: "California", value: "CA" },
  { label: "Montana", value: "MT" },
  { label: "New Mexico", value: "NM" },
  { label: "Arizona", value: "AZ" },
  { label: "Nevada", value: "NV" },
  { label: "Colorado", value: "CO" },
  { label: "Oregon", value: "OR" },
  { label: "Wyoming", value: "WY" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Utah", value: "UT" },
  { label: "Idaho", value: "ID" },
  { label: "Kansas", value: "KS" },
  { label: "Nebraska", value: "NE" },
  { label: "South Dakota", value: "SD" },
  { label: "Washington", value: "WA" },
  { label: "North Dakota", value: "ND" },
  { label: "Oklahoma", value: "OK" },
  { label: "Missouri", value: "MO" },
  { label: "Florida", value: "FL" },
  { label: "Wisconsin", value: "WI" },
  { label: "Georgia", value: "GA" },
  { label: "Illinois", value: "IL" },
  { label: "Iowa", value: "IA" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "Arkansas", value: "AR" },
  { label: "Alabama", value: "AL" },
  { label: "Louisiana", value: "LA" },
  { label: "Mississippi", value: "MS" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Ohio", value: "OH" },
  { label: "Virginia", value: "VA" },
  { label: "Tennessee", value: "TN" },
  { label: "Kentucky", value: "KY" },
  { label: "Indiana", value: "IN" },
  { label: "Maine", value: "ME" },
  { label: "South Carolina", value: "SC" },
  { label: "West Virginia", value: "WV" },
  { label: "Maryland", value: "MD" },
  { label: "Hawaii", value: "HI" },
  { label: "Massachusetts", value: "MA" },
  { label: "Vermont", value: "VT" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Rhode Island", value: "RI" },
  { label: "Washington, DC", value: "DC" }
], item => {
  return item.label;
});

export const REGEX_CHECKS = {
  requireDigit: /[0-9]/,
  requireNonAlphanumeric: /[^a-zA-Z0-9\s]/,
  requireUppercase: /[A-Z]/,
  requireLowercase: /[a-z]/,
};

export const sidebars: ISidebar = {
  MERCHANT: [
    {
      href: "/dashboard", icon: Home, label: "Dashboard", onClick: () => {
        Analytics.track({ experience: "portal", screen: "dashboard", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    // { href: "/reports", icon: Chart2, label: "Reports" },
    {
      href: "/applications", icon: MoneyRecive, label: "Applications", onClick: () => {
        Analytics.track({ experience: "portal", screen: "applications", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    {
      href: "/settings/accountInformation", icon: Setting2, label: "Settings", onClick: () => {
        Analytics.track({ experience: "portal", screen: "settings", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    {
      href: "/help", icon: LampOn, label: "Help", onClick: () => {
        Analytics.track({ experience: "portal", screen: "help", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
  ],
  WHITELABEL: [
    {
      href: "/dashboard", icon: Home, label: "Dashboard", onClick: () => {
        Analytics.track({ experience: "portal", screen: "dashboard", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    // { href: "/reports", icon: Chart2, label: "Reports" },
    {
      href: "/merchants", icon: Shop, label: "Merchants", onClick: () => {
        Analytics.track({ experience: "portal", screen: "merchants", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    {
      href: "/partners", icon: People, label: "Partners", onClick: () => {
        Analytics.track({ experience: "portal", screen: "partners", object: "menu", action: "clicked" } as ITracking);
      },
      visible: false
    },
    {
      href: "/applications", icon: MoneyRecive, label: "Applications", onClick: () => {
        Analytics.track({ experience: "portal", screen: "applications", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    {
      href: "/settings/accountInformation", icon: Setting2, label: "Settings", onClick: () => {
        Analytics.track({ experience: "portal", screen: "settings", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    {
      href: "/help", icon: LampOn, label: "Help", onClick: () => {
        Analytics.track({ experience: "portal", screen: "help", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
  ],
  PARTNER: [
    {
      href: "/dashboard", icon: Home, label: "Dashboard", onClick: () => {
        Analytics.track({ experience: "portal", screen: "dashboard", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    // { href: "/reports", icon: Chart2, label: "Reports" },
    {
      href: "/merchants", icon: Shop, label: "Merchants", onClick: () => {
        Analytics.track({ experience: "portal", screen: "merchants", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    {
      href: "/settings/accountInformation", icon: Setting2, label: "Settings", onClick: () => {
        Analytics.track({ experience: "portal", screen: "settings", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
    {
      href: "/help", icon: LampOn, label: "Help", onClick: () => {
        Analytics.track({ experience: "portal", screen: "help", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    },
  ],
  UNDEFINED: [
    {
      href: "/help", icon: LampOn, label: "Help", onClick: () => {
        Analytics.track({ experience: "portal", screen: "help", object: "menu", action: "clicked" } as ITracking);
      },
      visible: true
    }
  ],
};

export const merchantAdminSettingsMenu = [
  {
    label: "Account information",
    href: "accountInformation",
    icon: Home,
    id: "merchantSettings_accountInfo",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "settings_account_info", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Landing page settings",
    href: "landingPageSettings",
    icon: GlobalEdit,
    id: "merchantSettings_landingPageSettings",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "settings_landing_page", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Notifications",
    href: "notifications",
    icon: Notification,
    id: "merchantSettings_notifications",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "settings_notifications", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Users",
    href: "users",
    icon: SecurityUser,
    id: "merchantSettings_users",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "settings_users", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Locations",
    href: "locations",
    icon: Location,
    id: "merchantSettings_locations",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "settings_locations", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
];

export const partnerAdminSettingsMenu = [
  {
    label: "Account information",
    href: "accountInformation",
    icon: Home,
    id: "partnerSettings_accountInfo",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "settings_account_info", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Notifications",
    href: "notifications",
    icon: Notification,
    id: "partnerSettings_notifications",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "settings_notifications", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Users",
    href: "users",
    icon: SecurityUser,
    id: "partnerSettings_users",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "settings_users", object: "sub_menu", action: "clicked" } as ITracking);
    }
  }
];

export const merchantDetailsMenu = [
  {
    label: "Account information",
    href: "accountInformation",
    icon: Shop,
    id: "merchants_accountInfo",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "merchants_account_info", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Users",
    href: "users",
    icon: SecurityUser,
    id: "merchants_users",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "merchants_users", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Locations",
    href: "locations",
    icon: Location,
    id: "merchants_locations",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "merchants_locations", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Landing page settings",
    href: "landingPageSettings",
    icon: GlobalEdit,
    id: "merchants_landingPageSettings",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "merchants_landing_page", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Applications",
    href: "applications",
    icon: Notepad2,
    id: "merchants_applications",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "merchants_applications", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "History",
    href: "history",
    icon: Timer1,
    id: "merchants_history",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "merchants_history", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
]

export const partnerDetailsMenu = [
  {
    label: "Account information",
    href: "accountInformation",
    icon: Shop,
    id: "partner_accountInfo",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "partner_account_info", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Users",
    href: "users",
    icon: SecurityUser,
    id: "partner_users",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "partner_users", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Merchants",
    href: "merchants",
    icon: Shop,
    id: "partner_merchants",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "partner_merchants", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "History",
    href: "history",
    icon: Timer1,
    id: "partner_history",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "partner_history", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
]

export const viewApplicantMenu = [
  {
    label: "Application information",
    href: "application",
    icon: UserSquare,
    id: "merchantViewApplicant_application",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "view_application_account_info", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "Offers",
    href: "offers",
    icon: MedalStar,
    id: "merchantViewApplicant_offers",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "view_application_offers", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
  {
    label: "History",
    href: "history",
    icon: Timer1,
    id: "merchantViewApplicant_history",
    onClick: () => {
      Analytics.track({ experience: "portal", screen: "view_application_history", object: "sub_menu", action: "clicked" } as ITracking);
    }
  },
];

export const whiteLabelAdminSettingsMenu = [
  {
    label: "Account information",
    href: "accountInformation",
    icon: Home,
    id: "whiteLabelSettings_accountInfo",
  },
  {
    label: "Experience settings",
    href: "experienceSettings",
    icon: Grid3,
    id: "whiteLabelSettings_landingPageSettings",
  },
  {
    label: "Landing page settings",
    href: "landingPageSettings",
    icon: GlobalEdit,
    id: "whiteLabelSettings_landingPageSettings",
  },
  {
    label: "Notifications",
    href: "notifications",
    icon: Notification,
    id: "whiteLabelSettings_notifications",
  },
  {
    label: "Users",
    href: "users",
    icon: SecurityUser,
    id: "whiteLabelSettings_users",
  }
];

export const OFFER_TYPE = {
  PRE_APPROVED: "Pre-approved",
  PRE_QUALIFIED: "Pre-qualified"
}

export const POPUPS = {
  ADD_MERCHANT: "addMerchantPopup",
  ADD_PARTNER: "addPartnerPopup",
  ADD_NEW_LOCATION: "addNewLocationPopup",
  CONFIRMATION_INNER: "confirmationPopupInner",
  CONFIRMATION_FULL: "confirmationFullPopup",
  DECLINE_MERCHANT: "declineMerchantPopup",
  EDIT_BUSINESS: "editBusinessInformationPopup",
  EDIT_LOCATION: "editLocationPopup",
  EDIT_LOGO: "editLogoPopup",
  EDIT_MERCHANT_SLUG: "editMerchantSlugPopup",
  EDIT_LOCATION_ASSOCIATION: "editLocationAssociationPopup",
  EDIT_MAIN_CONTACT: "editMainContactInformationPopup",
  EDIT_USER: "editUserPopup",
  EDIT_ACCOUNT_ASSOCIATION: "editAccountAssociationPopup",
  EDIT_USER_ASSOCIATION: "editUserAssociationPopup",
  EDIT_USER_INFORMATION: "editUserInformationPopup",
  EXPORT_REPORT: "exportReportPopup",
  HELP: "helpPopup",
  INSTRUCTIONS: "instructionPopup",
  INVITATION_STATUS: "invitationStatusPopup",
  INVITE_APPLICANT: "inviteApplicantPopup",
  INVITE_MERCHANT: "inviteMerchantPopup",
  INVITE_USER: "inviteUserPopup",
  LANDING_PAGE_SETTINGS: "landingPageSettingsPopup",
  LANDING_PAGE_URL_SETTINGS: "landingPageUrlSettingsPopup",
  LANDING_PAGE_LOGO_SETTINGS: "landingPageLogoSettingsPopup",
  LOCATION: "locationPopup",
  NEW_APPLICATION: "newApplicationPopup",
  NOTIFICATION: "notificationPopup",
  REPORT_EDIT_FIELDS: "reportEditFieldsPopup",
  SAVE_REPORT: "saveReportPopup",
  TABLE_FILTER: "tableFilterPopup",
  UPDATE_PASSWORD: "updatePasswordPopup",
  EDIT_SUPPORT: "editSupportPopup",
  UNSUBSCRIBE: "unsubscribe",
  EDIT_LENDER_GROUP: "editLenderGroup",
  APPLICATION_HISTORY: "applicationHistoryPopup",
  BUSINESS_MODEL: "businessModelPopup",
  EDIT_MERCHANT_BUSINESS_AND_MAIN_CONTACT: "editMerchantBusinessAndMainContactPopup",
  EDIT_PARTNER_BUSINESS_AND_MAIN_CONTACT: "editPartnerBusinessAndMainContactPopup",
  EDIT_WHITELABEL_BUSINESS_AND_MAIN_CONTACT: "editWhitelabelBusinessAndMainContactPopup",
  EDIT_ESTIMATED_SALES: "editEstimatedSalesPopup",
  MERCHANT_HISTORY: "merchantHistoryPopup",
  PARTNER_HISTORY: "partnerHistoryPopup",
  EDIT_MERCHANT_LANDING_PAGE_TEMPLATE: "editMerchantLandingPageTemplate",
  EDIT_MERCHANT_LANDING_PAGE_TEMPLATE_SETTINGS: "editMerchantLandingPageTemplateSettings",
  DTM_PROCESS: "DTMProcessPopup",
  DTM_NOW_AVAILABLE: "DTMNowAvailablePopup",
  APPLICATION_STATUS: "applicationStatusPopup",
  REQUEST_REFUND: "requestRefundPopup",
  OPTIMIZED_ADD_MERCHANT: "optimizedAddMerchant",
  CANCEL_LOAN: "cancelLoanPopup",
  RECALCULATION: "recalculationPopup",
  REJECT_OFFER: "rejectOfferPopup",
}

export const DOMAIN_PREFIX = `${window.location.origin}/`;

export const DASHBOARD_SECTIONS = {
  FINANCE_ACTIVITY: "dashboards/finance-activity/?",
  APPLICATION_ACTIVITY: "dashboards/application-activity/?",
  ACTIVITY_FUNNEL: "dashboards/activity-funnel/?",
}

export const SUPPORT_INFO = {
  MERCHANT: "merchant-support",
  BORROWER: "borrower-support",
  WHITELABEL: "whitelabel-support",
  PARTNER: "partner-support",
  RESOURCES: "additional-resources",
}

export const FILTER_INITIAL_STATE = {
  state: null,
  status: null,
  merchant: null,
  partner: null,
  location: null,
  date_from: null,
  date_to: null,
  industry: null,
  annualSales: null,
  created_from: null,
  created_to: null,
  last_application_from: null,
  last_application_to: null,
  type: null
} as IFilter;

export const DEFAULT_MAX_LOAN_AMOUNT = 100000;

export const sortingMetrics: Array<any> = [
  {
    label: "APR: lowest to highest",
    value: "APR: lowest to highest",
  },
  // we might need this later
  // {
  //   label: "<span>Monthly payment:</span> lowest to highest",
  //   value: "Monthly payment: lowest to highest",
  // },
  {
    label: "Offer amount: highest to lowest",
    value: "Offer amount: highest to lowest",
  },
  {
    label: "Term: lowest to highest",
    value: "Term: lowest to highest",
  },
  {
    label: "Term: highest to lowest",
    value: "Term: highest to lowest",
  }
];

export const FORM_ELEMENT_TYPES = {
  input: React.createElement(Input).type,
  digitInput: React.createElement(DigitInput).type,
  selectDropdown: React.createElement(SelectDropdown).type,
  inputDropdown: React.createElement(InputDropdown).type,
  uploadFile: React.createElement(UploadFile).type,
  emptySpace: React.createElement(EmptySpace).type,
  customContent: React.createElement(CustomContent).type,
  colorPalleteDropdown: React.createElement(ColorPalleteDropdown).type,
  tripleInput: React.createElement(TripleInput).type,
  textArea: React.createElement(TextArea).type,
}

export const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

export const businessOwnership = [
  { label: "1 person", value: "1" },
  { label: "2 people", value: "2" },
  { label: "3 people", value: "3" },
  { label: "4 people", value: "4" },
];

export const UW_APP_STATUSES = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  SUBMITTED: "SUBMITTED",
  UNDER_REVIEW: "UNDER_REVIEW"
}

export const OWNER_TITLES = [
  { label: "Owner", value: "OWNER" },
  { label: "Principal", value: "PRINCIPAL" },
  { label: "Partner", value: "PARTNER" }
];

export const YES_NO = [
  { label: "Yes", value: "YES" },
  { label: "No", value: "NO" }
];

export const MERCHANT_RETRIEVING = "MERCHANT_RETRIEVING";

export const emptyIList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  originalUrl: null
} as IList;

export const BUSINESS_MODEL_OPTIONS = [
  { label: "DTC", value: "DTC" },
  { label: "DTM", value: "DTM" },
];

export const APPLICATION_SOURCE = {
  LANDING: "LANDING",// TODO: use this also - right now source is set to either null or INVITATION
  INVITATION: "INVITATION",
}

// some of those statuses are and will only ever be used in the backend
export const APP_STATUSES = {
  APPLICATION_STARTED: "Application Started",
  APPLICATION_SUBMITTED: "Application Submitted",
  RETRIEVING_OFFERS: "Retrieving Offers",
  NO_OFFERS: "No Offers",
  OFFERED: "Offered",
  CLICKED: "Clicked",
  PENDING_WITH_LENDER: "Pending with Lender",
  PENDING_WITH_MERCHANT: "Pending with Merchant",
  APPROVED_BY_LENDER: "Approved by Lender",
  ACCEPTED_BY_CONSUMER: "Accepted by Consumer",
  UNABLE_TO_VERIFY: "Unable to Verify",
  READY_FOR_FUNDING: "Ready for Funding",
  FUNDED: "Funded",
  DECLINED_BY_LENDER: "Declined by Lender",
  DECLINED_BY_CONSUMER: "Declined by Consumer",
  DECLINED_BY_MERCHANT: "Declined by Merchant",
  DELINQUENT: "Delinquent",
  CHARGE_OFF: "Charge Off",
  POTENTIAL_BUYBACK: "Potential Buyback",
  ACTIVE_DISPUTE: "Active Dispute",
  CLOSED: "Closed",
  BUYBACK: "Buyback",
  CANCELED: "Canceled",
  INVITED: "Invited",
  INVITATION_EXPIRED: "Invitation Expired",
  INVITATION_CANCELED: "Invitation Canceled",
  INVITAION_RESENT: "Invitation Resent",
  INVITATION_CLICKED: "Invitation Clicked",
}