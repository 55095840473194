import { InfoContainer } from "common";
import React from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch } from "reducers/Hooks";
import { POPUPS } from "utils/constants";
import { handleNull } from "utils/helpers";
import { SmsEdit } from "iconsax-react";
import { formatAPIPhone } from "utils/formatters";

interface IMainContactCard {
  cardTitle?: string;
  disabled?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const MainContactCard: React.FC<IMainContactCard> = ({ cardTitle, disabled, firstName, lastName, email, phone }) => {
  const dispatch = useAppDispatch();

  let rows = [
    { label: "First Name", value: handleNull(firstName) },
    { label: "Last Name", value: handleNull(lastName) },
    { label: "Email", value: handleNull(email) },
    { label: "Phone", value: handleNull(formatAPIPhone(phone)) },
  ];

  return <InfoContainer
    id="main_contact_card"
    title={cardTitle || "Main contact"}
    rows={rows}
    onClick={() => dispatch(popUpActions.openPopup(POPUPS.EDIT_MAIN_CONTACT))}
    disabled={disabled}
    icon={SmsEdit}
  />;
}

export default MainContactCard;