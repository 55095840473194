import { EmptySpace, Form, Input, SelectDropdown } from "common/form";
import React from "react";
import { Schema } from "utils/validation/additionalValidation";
import { statesArray } from "utils/constants";
import { IFormSection, IStepper } from "common/interfaces";
import { useConfirmation } from "./Confirmation.hooks";
import moment from "moment";

const Confirmation: React.FC<IStepper> = (props) => {
  const {
    initialValues, validationSchema, loanPurposeOptions, educationLevelOptions, handleSubmit, setEmploymentStatus,
    propertyStatusOptions, employmentStatusOptions, creditRatingOptions, payFrequencyOptions, lenderType,
    displayEmploymentStartDate, displayEmploymentEndDate, merchant
  } = useConfirmation(props);

  const getFields = () => {
    const employmentStartDateField = <Input name="employment_start_date" id="confirmation_employment_start_date" label="Employment start date" mask="date" />;
    const employmentEndDateField = <Input name="employment_end_date" id="confirmation_employment_end_date" label="Employment end date" mask="date" />;
    const firstPaymentDateField = <Input name="first_payment_date" id="confirmation_first_payment_date" label="Next paycheck date" mask="date" tooltip={`Date must be between ${moment().format('MM/DD/YYYY')} and ${moment().add(30, 'days').format('MM/DD/YYYY')}`} />;

    const dtmFields = [
      [
        <SelectDropdown label="Employment status" id="confirmation_employment_status" name="employment_status" selectOptions={employmentStatusOptions.current} placeholder="Select"
          onChange={(value: any) => {
            setEmploymentStatus(value);
          }}
        />,
        <SelectDropdown label="Pay frequency" id="confirmation_pay_frequency" name="pay_frequency" selectOptions={payFrequencyOptions.current} placeholder="Select" />,
      ],
      [
        <Input inputPrefix="$" name="annual_income" id="confirmation_annual_income" label="Annual income" mask="amount" />,
        <Input name="company_name" id="confirmation_company_name" label="Company name" />,
      ],
      [
        <Input name="supervisor_full_name" id="confirmation_supervisor_full_name" label="Supervisor full name" />,
        <EmptySpace />
      ]
    ];
    if (displayEmploymentStartDate && displayEmploymentEndDate) {
      dtmFields.push([employmentStartDateField, employmentEndDateField]);
      dtmFields.push([firstPaymentDateField, <EmptySpace />]);
    } else if (displayEmploymentStartDate && !displayEmploymentEndDate) {
      dtmFields.push([employmentStartDateField, firstPaymentDateField]);
    } else if (!displayEmploymentStartDate && !displayEmploymentEndDate) {
      dtmFields.push([firstPaymentDateField, <EmptySpace />]);
    }

    const inputFields: Array<IFormSection> = [
      {
        title: "Loan information",
        fields: [
          [
            <Input inputPrefix="$" name="loan_amount" id="confirmation_loan_amount" label="Amount" mask="amount" />,
            loanPurposeOptions.current.length > 1 && !merchant?.programs.includes("DTM") ? <SelectDropdown label="Purpose" id="confirmation_loan_purpose" name="loan_purpose" selectOptions={loanPurposeOptions.current} placeholder="Select" /> : <EmptySpace />
          ]
        ]
      },
      {
        title: "Personal information",
        fields: [
          [
            <Input name="first_name" id="confirmation_first_name" label="First name" />,
            <Input name="last_name" id="confirmation_last_name" label="Last name" />,
          ],
          [
            <Input name="date_of_birth" id="confirmation_dob" label="Date of birth" mask="date" />,
            <SelectDropdown label="Highest education level" id="confirmation_highest_education_level" name="education_level" selectOptions={educationLevelOptions.current} placeholder="Select" />,
          ]
        ]
      },
      {
        title: "Contact information",
        fields: [
          [
            <Input type="email" name="email" id="confirmation_email" label="Email" />,
            <Input name="phone_number" id="confirmation_phone_number" label="Phone number" mask="phone" />,
          ]
        ]
      },
      {
        title: "Address information",
        fields: [
          [
            <Input name="address1" id="confirmation_address1" label="Street address" />,
            <Input name="city" id="confirmation_city" label="City" />,
          ],
          [
            <SelectDropdown label="State" id="confirmation_state" name="state" selectOptions={statesArray} placeholder="Select" />,
            <Input name="postal_code" id="confirmation_postal_code" label="Postal code" maxLength={5} />,
          ],
          [
            <SelectDropdown label="Property status" id="confirmation_property_status" name="property_status" selectOptions={propertyStatusOptions.current} placeholder="Select" />,
            <EmptySpace />
          ]
        ]
      },
      lenderType === "DTC" ? {
        title: "Financial information",
        fields: [
          [
            <SelectDropdown label="Employment status" id="confirmation_employment_status" name="employment_status" selectOptions={employmentStatusOptions.current} placeholder="Select" />,
            <SelectDropdown label="Pay frequency" id="confirmation_pay_frequency" name="pay_frequency" selectOptions={payFrequencyOptions.current} placeholder="Select" />,
          ],
          [
            <Input inputPrefix="$" name="annual_income" id="confirmation_annual_income" label="Annual income" mask="amount" />,
            <SelectDropdown label="Credit rating" id="confirmation_credit_rating" name="credit_rating" selectOptions={creditRatingOptions.current} placeholder="Select" />,
          ]
        ]
      } : {
        title: "Financial information",
        fields: dtmFields
      },
      {
        title: "Social security number",
        fields: [
          [
            <Input type="password" name="ssn" id="confirmation_ssn" label="Social security number (SSN)" mask="ssn" placeholder="999-99-9999" />,
            <EmptySpace />
          ]
        ]
      }
    ].filter(Boolean);

    if (merchant?.programs.includes("DTM")) {
      // remove loan information
      inputFields.shift();
    }

    return inputFields;
  }

  return <>
    {initialValues && validationSchema && <Form
      id="borrowerExperience_confirmation"
      buttonPosition="side right"
      onFormSubmit={handleSubmit}
      title="Confirm your info"
      submitBtnText="Confirm and continue"
      validationSchema={Schema(validationSchema)}
      values={initialValues}
      onBackClick={props.onBack}
      inputFields={getFields()}
    />}
  </>;
}

export default Confirmation;