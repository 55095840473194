import { Skeleton } from "@mui/material";
import React from "react";

interface ILoadingChart {
  type: "bar" | "line";
}

const LoadingChart: React.FC<ILoadingChart> = ({ type }) => {

  return <div style={{ display: "flex", flexDirection: "column" }}>
    <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
      <Skeleton height={30} width={100} />
      <Skeleton height={30} width={100} />
      {type === "line" && <Skeleton height="18rem" width="100%" />}
    </div>
    {type === "bar" && <div style={{ display: "flex", flexDirection: "row", height: "18rem", justifyContent: "space-between" }}>
      <Skeleton height="100%" width="5%" style={{ alignSelf: "flex-end" }} />
      <Skeleton height="100%" width="5%" />
      <Skeleton height="100%" width="5%" />
      <Skeleton height="100%" width="5%" />
      <Skeleton height="100%" width="5%" />
      <Skeleton height="100%" width="5%" />
      <Skeleton height="100%" width="5%" />
    </div>}
  </div>;
}

export default LoadingChart;