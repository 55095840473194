import styles from "./EditUserPopup.module.scss";
import { CloseIcon, LocationSelector, SearchBar, Scrollable } from "common";
import { Input, Form } from "common/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { EmailValidation, PersonalInfoValidation, Schema } from "utils/validation/additionalValidation";
import popUpActions from "reducers/PopUpReducer";
import _ from "lodash";
import Observer, { EVENTS } from "classes/Observer";
import { useParams } from "react-router-dom";
import UserHandler from "actions/UserHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { useAuth } from "auth/useAuth";
import { displayMiniFeedback, isForMerchantGlobal } from "utils/helpers";
import { formatFullname } from "utils/formatters";
import usePartner from "hooks/usePartner";

const EditUserPopup: React.FC<IPopup> = () => {
  const { merchantSlug, partnerSlug } = useParams();
  const dispatch = useAppDispatch();
  const userEditing = useAppSelector(state => state.popUp.messagePassing);
  const user = useAuth()?.user;
  const partner = usePartner();

  const [primaryLocation, setPrimaryLocation] = useState<any>(null);
  const [keyword, setKeyword] = useState<string>("");

  let initialValues = {
    first_name: "",
    last_name: "",
    email: "",
  };

  if (userEditing) {
    initialValues = {
      first_name: userEditing.first_name,
      last_name: userEditing.last_name,
      email: userEditing.email,
    };
  };

  useEffect(() => {
    return () => {
      setPrimaryLocation(null);
      setKeyword("");
    };
  }, []);

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const getPartnerSlug = () => {
    if (user?.user_type === "MERCHANT") {
      return null;
    }
    return partnerSlug || partner?.slug;
  }

  const handleSubmit = async (data: any) => {
    const payload = {
      ...data,
      primary_location: primaryLocation?.id,
    };

    if (userEditing?.id) {
      try {
        await UserHandler.update(userEditing?.id, payload, merchantSlug, getPartnerSlug());
        Analytics.track({ experience: "portal", screen: user?.user_type === "WHITELABEL" && merchantSlug ? "merchants_users" : user?.user_type === "WHITELABEL" && getPartnerSlug() ? "partners_users" : "settings_users", object: "user_info", action: "updated" } as ITracking, { changes: { old: initialValues, new: data } });
        Observer.trigger(EVENTS.USER_UPDATED);
        displayMiniFeedback("User updated");
        handleClose();

      } catch (error) {
        console.error("Error:", error);
      }
    }
  };


  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="editUserPopup" color="dark" />
        <Scrollable>
          <Form
            id="editUserPopup"
            title={`${formatFullname(userEditing?.first_name, userEditing?.last_name)} • Edit user`}
            submitBtnText="Save"
            onFormSubmit={handleSubmit}
            validationSchema={Schema({
              first_name: PersonalInfoValidation.first_name,
              last_name: PersonalInfoValidation.last_name,
              email: EmailValidation,
              //user_role: SimpleRequireValidation
            })}
            values={initialValues}
            buttonPosition="side right"
            inputFields={[
              [
                <Input name="first_name" id="editUserPopup_first_name" label="First name" />,
                <Input name="last_name" id="editUserPopup_last_name" label="Last name" />
              ],
              [
                <Input type="email" name="email" id="editUserPopup_email" label="Email" />
              ]
            ]}
            isInsidePopup
          />
          {isForMerchant() && <>
            <div className={styles.searchBlock}>
              <SearchBar
                id="editUserPopup_search"
                placeholder={"Search location"}
                styleOverride={{
                  borderRadius: "4rem",
                  fontWeight: 600,
                  fontSize: "1.4rem",
                  color: "var(--darkTextColor)",
                  border: "none",
                }}
                onSearch={(keyword) => {
                  setKeyword(keyword);
                }} />
            </div>
            <LocationSelector
              id="editUserPopup_selector"
              onSelection={(location) => {
                setPrimaryLocation(location);
              }}
              keyword={keyword}
              selectedLocationId={primaryLocation?.id || userEditing?.primary_location_id}
            />
          </>}
        </Scrollable>
      </>
    </Popup>
  );
};

export default EditUserPopup;
