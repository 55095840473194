import React from "react";
import { HeaderWL, Container as InternalContainer, HelpButton } from "common";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Disclaimer.module.scss";

interface IDisclaimer {
  title: string;
  children: any;
}

const Disclaimer: React.FC<IDisclaimer> = ({ title, children }) => {
  return (
    <>
      <HeaderWL />
      <main className={styles.pageContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer}>
          <InternalContainer className={styles.invitationFormInner}>
            <h1>{title}</h1>
            <div className={styles.policyContent}>
              {children}
            </div>
          </InternalContainer>
        </div>
      </main>
    </>
  );
};

export default Disclaimer;
