import styles from "./ApplicationStatusPopup.module.scss";
import { Button, CloseIcon, Padding } from "common";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import Popup from "./Popup";
import { IPopup } from "common/interfaces";
import _ from "lodash";
import popUpActions from "reducers/PopUpReducer";
import { formatAmount } from "utils/formatters";
import { APP_STATUSES } from "utils/constants";

const ApplicationStatusPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const message = useAppSelector(state => state.popUp.messagePassing);

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  return (
    <Popup isInner={true} width={600} styleOverride={{ alignItems: "center" }}>
      <>
        <CloseIcon id="applicationStatusPopup" color="dark" onClose={handleClose} />
        {message?.status === APP_STATUSES.DELINQUENT && <>
          <h1 className={styles.title}>Loan for {message?.first_name} {message?.last_name} is delinquent</h1>
          <p className={styles.message}>Payments for ${formatAmount(message?.loan_amount)} loan for {message?.first_name} {message?.last_name} are not up-to-date, to resolve it please contact Special Financing at <span>(555) 123-4567</span> or <span>customers@sfc.com</span></p>
        </>}
        {message?.status === APP_STATUSES.ACTIVE_DISPUTE && <>
          <h1 className={styles.title}>Loan for {message?.first_name} {message?.last_name} is in dispute</h1>
          <p className={styles.message}>${formatAmount(message?.loan_amount)} loan for {message?.first_name} {message?.last_name} is in dispute, to resolve it please contact Special Financing at <span>(555) 123-4567</span> or <span>customers@sfc.com</span></p>
        </>}
        {message?.status === APP_STATUSES.UNABLE_TO_VERIFY && <>
          <h1 className={styles.title}>Applicant {message?.first_name} {message?.last_name} is not verified</h1>
          <p className={styles.message}>Please contact your applicant directly and ask them to call Special Financing. They need to verify with the customer that they wanted a loan with Special Financing.</p>
          <p className={styles.message}>Special financing can be reached at <span>(555) 123-4567</span> or email <span>customers@sfc.com</span></p>
        </>}
        {message?.status === APP_STATUSES.POTENTIAL_BUYBACK && <>
          <h1 className={styles.title}>Potential Merchant Buyback</h1>
          <p className={styles.message}>Due to recent developments, there is a possibility that this loan may be bought back. If you have any questions or need further guidance, please do not hesitate to contact the Special Financing support team. You can reach them by phone at  (757) 825-0550 or via email at andrea@sfc.us.</p>
        </>}
        <Button
          id="applicationStatusPopup_close_btn"
          label="Close"
          style={{
            width: "170px",
            textAlign: "center",
            marginBottom: "2rem",
          }}
          onClick={handleClose} />
      </>
    </Popup >
  );
};

export default ApplicationStatusPopup;
