import React, { useState, useCallback } from 'react';
import { createValidationSchema } from './OptimizedForm.validation';
import styles from './OptimizedForm.module.scss';
import * as Yup from 'yup';
import MemoizedField from './MemoizedField';
import { IFieldDefinition } from 'common/interfaces';

interface FormComponentProps {
  fields: IFieldDefinition[];
  onSubmit: (data: any) => void;
  title: string;
}

const OptimizedForm: React.FC<FormComponentProps> = ({ fields, onSubmit, title }) => {
  const initialValues = fields.reduce((acc, field) => {
    acc[field.name] = '';
    return acc;
  }, {});

  const validationSchema = createValidationSchema(fields);

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));

    try {
      const field = fields.find(item => { console.log(item); return item.name === name });
      await validationSchema.validateAt(name, { [name]: value });
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
      if (field?.onChange) {
        field.onChange(name, value);
      }

    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [name]: err.message
        }));
      }
    }
  }, [validationSchema]);

  const isDisabled = useCallback(() => {
    return Object.values(errors).some(val => val !== '');
  }, [errors]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await validationSchema.validate(values, { abortEarly: false });
      onSubmit(values);
      setErrors({}); // Clear errors on successful submission
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = {};
        err.inner.forEach(error => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      }
    }
  };

  return (
    <div className={styles.optimizedFormContainer}>
      <h1 className={styles.title}>{title}</h1>
      <form onSubmit={handleSubmit}>
        {fields.map(field => (
          <MemoizedField
            key={field.name}
            label={field.label}
            name={field.name}
            type={field.type}
            value={values[field.name]}
            onChange={handleChange}
            error={errors[field.name]}
            options={field.options}
          />
        ))}
        <div style={{ textAlign: "right" }}>
          <button
            type="submit"
            className={styles.button}
            disabled={isDisabled()}
            title={isDisabled ? "Please correct the errors above to proceed" : ""}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default OptimizedForm;
