import React, { ReactElement } from "react";
import styles from "./NewPopup.module.scss";

interface INewPopup {
  children: ReactElement;
  style?: any;
  isInner?: boolean;
  id?: string;
}

const NewPopup: React.FC<INewPopup> = ({ id, children, style, isInner }) => {
  return <div id={id || "modal_popup"} className={`${styles.popup} ${isInner ? styles.popupInner : ""}`} style={style}>
    {children}
  </div>;
}

export default NewPopup;
