import React, { useEffect } from "react";
import { useAppDispatch } from "reducers/Hooks";
import { displayFeedback } from "utils/helpers";

const NoPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    displayFeedback({
      title: "Oops! Something went wrong!",
      body: "We can't seem to find the page you are looking for.\nTry going back to the previous page or contact support.",
      type: "UFO"
    });
  }, [dispatch])

  return null;
}
export default NoPage;