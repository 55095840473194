import { Button, CloseIcon, Container } from "common";
import React, { ReactNode } from "react";
import styles from "./Confetti.module.scss";

interface IConfettiPopup {
  children: ReactNode;
  buttonText?: string;
  buttonAction?: () => void;
  isPopup: boolean;
  displayShadow?: boolean;
}

const Confetti: React.FC<IConfettiPopup> = ({ children, buttonText, buttonAction, isPopup, displayShadow }) => {

  const getContent = () => {
    const body = <>
      <p className={`${styles.thanksText} big`}>
        {children}
      </p>
      {buttonText && buttonAction && <div style={{ textAlign: "center" }}>
        <Button
          className={styles.confettiButton}
          id="confetti_btn"
          label={buttonText}
          onClick={buttonAction}
        />
      </div>}
    </>;
    if (isPopup) {
      return <>
        {/* <Container className={styles.thanksContainer}> */}
        {buttonText && buttonAction && <CloseIcon id="confetti_close_popup" color="dark" />}
        {body}
        {/* </Container> */}
      </>;
    } else {
      return <>
        <Container
          className={`${styles.confettiAnimation}`}
          style={{
            ...(displayShadow && {
              boxShadow: "0.8rem 0.8rem 2rem var(--primaryColor20)",
              borderRadius: "2rem"
            })
          }}>
          {body}
        </Container>
      </>
    }
  }

  return getContent();
}

export default Confetti;