import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IPartner } from 'common/interfaces';

const initialState: IPartner = {
  id: '',
  name: '',
  domain: '',
  website: '',
  slug: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal_code: '',
  phone: '',
  main_contact_first_name: '',
  main_contact_last_name: '',
  main_contact_email: '',
  main_contact_phone: '',
  primary_logo: '',
  secondary_logo: '',
  created_by: null,
  merchant_number: 0,
  type: 0,
  status: 0,
  external_id: '',
  submitted_count: 0,
  approved_count: 0,
  funded_count: 0,
  funded_total_amount: 0,
  approval_rate: 0,
  active_merchants: 0,
  enrolled_merchants: 0,
  conversion_rate: 0,
  created_at: '',
  active_merchants_rate: 0,
  last_referral_at: '',
  programs: ["DTC"],
};

const counterSlice = createSlice({
  name: 'partner',
  initialState: initialState,
  reducers: {
    update: (state, action: PayloadAction<any>) => {
      state = Object.assign(state, action.payload);
    },
    clear: (state) => {
      state = Object.assign({}, initialState);
    },
  }
})

export const partnerReducer = counterSlice.reducer;
export default counterSlice.actions;
