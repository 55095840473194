import { ReactNode } from "react";
import styles from "./Grid.module.scss";

interface IGrid {
  children: ReactNode;
  /** 
   * @summary Sets the type of grid (container - false or item - true).
   * @defaults false
   * */
  item?: boolean;
  /** 
   * @summary Sets the direction of the grid (horizontal - true or vertical - false).
   * @defaults false
   * */
  horizontal?: boolean;
  /** 
   * @summary Align internal elements to right position, Ignored when not a Grid item.
   * @defaults false
   * */
  alignRight?: boolean;
  /** 
   * @summary Style override
  */
  style?: any;
}

const Grid: React.FC<IGrid> = ({ children, style, item = false, horizontal = false, alignRight = false }) => {
  if (!item) {
    return <div
      className={styles.container}
      style={{ flexDirection: horizontal ? "row" : "column", ...style }}>
      {children}
    </div>;
  } else {
    return <div className={styles.item} style={{ textAlign: alignRight ? 'right' : 'inherit', ...style }}>
      {children}
    </div>;
  }
};

export default Grid;
