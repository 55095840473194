import BorrowerHandler from 'actions/BorrowerHandler';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'reducers/Hooks';
import borrowerActions from "reducers/BorrowerReducer";
import LocalStorage from 'classes/LocalStorage';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

/**
 * Trigger to start searching offers - for example: vuid
 */
const useGetOffers = (applicationId: number, trigger: any, vuid?: string) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [offersInterval, setOffersInterval] = React.useState(null);
  // offers that are saved to redux - if user refreshes page or gets to the offers after already getting them, use these instead of calling API again
  const offersSaved = useAppSelector(state => state.borrower.offers);
  const [progressStatus, setProgressStatus] = React.useState<number>(0);
  // how many times the api was called for the offers - limit it on 60 s
  const [offersLoads, setOffersLoads] = React.useState(0);
  const noOffers = LocalStorage.get<boolean>("no_offers");
  const [offersMatched, setOffersMatched] = useState<"WAITING" | "FALSE" | "TRUE">(noOffers ? "FALSE" : "WAITING");

  useEffect(() => {
    // if the user got to the offers after the displayApplicationLockedFeedbackPopup, the offers should be loaded from redux
    if (trigger) {
      setProgressStatus(0);
      getOffersLocal();
      if (!offersInterval) {
        setOffersInterval(setInterval(() => {
          getOffersLocal();
          setOffersLoads(offersLoads => {
            return offersLoads + 1;
          });
        }, 1000));
      }
    }

    // stop calling when moving to other page
    return () => clearIntervalCustom(offersInterval);
  }, [trigger]);

  useEffect(() => {
    // clear offers from redux when moving to other page (closing the popup), but only in applications tab - not on the borrower flow
    return () => {
      if (location?.pathname?.includes("applications")) {
        dispatch(borrowerActions.clearApplication())
      }
    };
  }, []);

  const clearIntervalCustom = (intervalId: any) => {
    clearInterval(intervalId);
    setOffersInterval(null);// after stopping interval, you still need to delete the reference
  }

  const getOffersLocal = () => {
    BorrowerHandler.getOffers(applicationId, vuid)
      .then(response => {
        if (response && response.results?.length > 0) {
          response.results = _.filter(response?.results, item => { return item.status !== "Canceled" });
          dispatch(borrowerActions.setOffers(response.results));
          dispatch(borrowerActions.setOffersList(response));
          setOffersMatched("TRUE");
          // Analytics.track(TRACKING_EVENTS.BORROWER_APPLICATION_OFFERS_RETURNED)
        } else if (response && response.results?.length === 0 && response.x_progress === 100) {
          setOffersMatched("FALSE");
          // Analytics.track(TRACKING_EVENTS.BORROWER_APPLICATION_NO_OFFERS_RETURNED)
        }
        setProgressStatus(response.x_progress);
      })
  };

  // clears interval for getting offers, when all offers are loaded
  useEffect(() => {
    if (progressStatus === 100) {
      clearIntervalCustom(offersInterval);
    }
  }, [progressStatus]);

  useEffect(() => {
    if (offersSaved && progressStatus !== 100) {
      // if offers are already saved to redux, progress bar will be reset after going back, so you need to set it again to hide the progress bar
      setProgressStatus(100);
    }
  }, []);

  // clears interval if offersMatched is FALSE - if user clicks browser's back button after no offers
  useEffect(() => {
    if (offersMatched === "FALSE") {
      setProgressStatus(100);
      clearIntervalCustom(offersInterval);
      // so the app doesn't try to receive offers again when user clicks browser's back button after no offers
      LocalStorage.save("no_offers", true);
    }
  }, [offersMatched]);

  // clears interval for getting offers after 60 s
  useEffect(() => {
    if (offersLoads >= 60) {
      clearIntervalCustom(offersInterval);
      setProgressStatus(100);
      if (offersSaved?.length === 0) {
        setOffersMatched("FALSE");
      }
    }
  }, [offersLoads]);

  return { offersMatched, offersLoads, progressStatus };
};

export default useGetOffers;
