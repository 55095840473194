import { IDataChart } from "common/interfaces";
import React from "react";
import { ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

const LineBarChart: React.FC<IDataChart> = ({ data, series1Label, series2Label }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={509}
        height={400}
        data={data}
        margin={{
          top: 0,
          right: 22,
          left: -40,
          bottom: 0,
        }}
      >
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <Legend
          verticalAlign="top"
          wrapperStyle={{ left: "2rem", width: "auto", paddingBottom: "1.5rem" }}
          layout={"vertical"}
          iconType={"circle"}
          iconSize={10}
        />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{
            fontWeight: "600",
            fontSize: "1.3rem",
            fill: "#000",
          }}
        />
        <YAxis axisLine={false} tickLine={false} tick={false} />
        <Tooltip
          wrapperStyle={{ outline: "none" }}
          cursor={{ strokeDasharray: "3 3" }}
          content={({ active, payload }: any) => {
            if (active && payload && payload.length) {
              return (
                <div className="customTooltip">
                  <p className="label">{`${series1Label}: ${payload[0].value}`}<br />{`${series2Label}: ${payload[1].value}`}</p>
                </div>
              );
            }
          }}
        />
        <Legend />
        <Bar
          dataKey="series1"
          name={series1Label}
          barSize={10}
          fill="var(--primaryTextColor)"
          radius={[10, 10, 10, 10]}
        />
        <Line
          type="monotone"
          name={series2Label}
          dataKey="series2"
          stroke="var(--secondaryColor)"
          dot={false}
          strokeWidth={3}
          activeDot={{
            fill: "var(--darkTextColor)",
            fontSize: "4rem",
            stroke: "none",
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default LineBarChart;
