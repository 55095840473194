import React from "react";
import styles from "./FeedbackPopup.module.scss";
import { SVG } from "common";

export const UserSVG: React.FC = () => {
  const height = 150;

  return <div
    className={styles.backgroundImage}
    style={{ height: height + 50, marginBottom: -20 }}>
    <SVG
      src={parseInt((Math.random() * 2).toString()) === 0 ? "/assets/feedback/boy.svg" : "/assets/feedback/girl.svg"}
      title="UserSVG"
      height={height}
    />
  </div>;
};

export default UserSVG;
