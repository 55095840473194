import styles from "./DeclineMerchantCancelLoan.module.scss";
import { Button, CloseIcon } from "common";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { IFeedback, IPopup } from "common/interfaces";
import Popup from "./Popup";
import { displayFeedback } from "utils/helpers";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import Observer, { EVENTS } from "classes/Observer";

const CancelLoanPopup: React.FC<IPopup> = () => {
  const message = useAppSelector(state => state.popUp.messagePassing);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    return () => {
      setReason("");
    };
  }, []);

  const handleCancel = async () => {
    if (!reason?.trim()) {
      setError("Reason is required");
    } else {
      const payload = {
        reason: reason.trim()
      };
      await LoanApplicationHandler.cancelApplicationInvite(message?.id, payload);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      displayFeedback({
        title: `You have successfully cancelled a loan for ${message?.first_name} ${message?.last_name}`,
        body: `They will get an email with all the details.`,
        type: "CANCEL",
      } as IFeedback, true);
    }
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="cancelLoanPopup" color="dark" />
        <p className={`${styles.p1} big`}>{`Are you sure you want to cancel a loan for ${message?.first_name} ${message?.last_name}?`}</p>
        <div className={styles.msgTextarea}>
          <label className="label">Reason*</label>
          <textarea
            id="message"
            name="message"
            className="textMedium"
            style={{
              padding: 10,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "var(--primaryVariationTextColor)"
            }}
            rows={4}
            cols={50}
            onChange={(e) => { setError(""); setReason(e.target.value); }}
            value={reason}
            maxLength={99} />
          {error && <p className={styles.errorText}>{error}</p>}
        </div>
        <div className={styles.btnContainer}>
          <Button id="cancelBtn" label="Confirm Cancelation" onClick={handleCancel} />
        </div>
        <div />
      </>
    </Popup >
  );
};

export default CancelLoanPopup;
