import GuestHandler from "actions/GuestHandler";
import LocalStorage from "classes/LocalStorage";
import { IFeedback } from "common/interfaces";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { displayFeedback } from "utils/helpers";
import { POPUPS } from "utils/constants";
import Analytics, { ITracking } from "classes/Analytics";

const Unsubscribed: React.FC = () => {
  const vuid = LocalStorage.get<string>("vuid");
  const params = useParams();
  const [successfullyUnsubscribed, setSuccessfullyUnsubscribed] = useState<boolean>(false);
  let notificationName = "";

  const handleUnsubscribe = async () => {
    if (!successfullyUnsubscribed) {
      const whitelabelId = params.whitelabelId;
      const notificationCodename = params.notification;

      if (vuid && whitelabelId && notificationCodename) {
        GuestHandler.unsubscribe(whitelabelId, vuid, notificationCodename)
          .then(response => {
            setSuccessfullyUnsubscribed(true);
            notificationName = response?.notification?.name;
            displayFeedback({
              title: `You have been successfully unsubscribed.`,
              body: " ",
              type: "BALOONS",
              subType: POPUPS.UNSUBSCRIBE,
              buttons: [
                // { label: "Close", id: "feedback_close_btn", action: dispatch(popUpActions.closePopup()) },
                { label: "Resubscribe", id: "feedback_close_btn", action: () => { handleSubscribe() } }
              ],
              hideCloseButton: true,
            } as IFeedback);
          }
          );
      }
    }
  };
  // { label: "Resubscribe", id: "feedback_close_btn", action: () => { handleSubscribe() } 

  const handleSubscribe = () => {
    // function handleSubscribe() {
    const whitelabelId = params.whitelabelId;
    const notificationCodename = params.notification;
    if (vuid && whitelabelId && notificationCodename) {
      GuestHandler.subscribe(whitelabelId, vuid, notificationCodename)
        .then(() => {
          displayFeedback({
            title: `You have been successfully resubscribed.`,
            body: " ",
            type: "SUCCESS",
            subType: POPUPS.UNSUBSCRIBE,
            buttons: [],
            hideCloseButton: true
          } as IFeedback, true);
        }
        );
    }
  };

  useEffect(() => {
    handleUnsubscribe();
    // }, [vuid, params])
  }, [])

  return null;
}
export default Unsubscribed;