import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "reducers/Hooks";

interface IBreadcrumbs {
  pageTitle: string;
}

const Breadcrumbs: React.FC<IBreadcrumbs> = ({ pageTitle }) => {
  const navigate = useNavigate();
  const breadcrumbs = useAppSelector(state => state.app.breadcrumbs);

  const redirect = (link: string) => {
    navigate(link);
  }

  return <>
    {breadcrumbs.length > 0 && <h2 style={{ userSelect: "none", color: "var(--primaryVariationTextColor)" }}>
      {breadcrumbs.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <> {`>`} </>}
          {item.url ? (
            <a href="#" onClick={() => { redirect(item.url) }} style={{ color: "var(--primaryVariationTextColor)" }}>{item.title}</a>
          ) : (
            <>{item.title}</>
          )}
          <>{` > ${pageTitle}`}</>
        </React.Fragment>
      ))}
    </h2>}
    {breadcrumbs.length === 0 && <h2 style={{ userSelect: "none", color: "var(--primaryVariationTextColor)" }}>{pageTitle}</h2>}
  </>;
}

export default Breadcrumbs;