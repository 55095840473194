import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IBreadcrumbItem, IFilter, IGeneralSupport, IMerchantLocation, ISupport } from "common/interfaces";
import { FILTER_INITIAL_STATE } from "utils/constants";
import { isSupport } from "utils/helpers";
import Observer from "classes/Observer";
import _ from "lodash";
import { formatAPIPhone } from "utils/formatters";

const counterSlice = createSlice({
  name: "app",
  initialState: {
    passwordRequirements: {
      requiredLength: 8,
      requiredUniqueChars: 1,
      requireNonAlphanumeric: true,
      requireLowercase: true,
      requireUppercase: true,
      requireDigit: true,
    },
    lastVisitedPages: [],
    selectedLocation: null,
    observers: Observer.generateReducerInitialValues(),
    support: {
      merchant: null,
      borrower: null,
      whitelabel: null,
      resources: [],
    },
    tableFilters: {
      merchant: null,
      application: null,
      merchantApplication: null,
      partner: null
    },
    merchantAppFromLandingPage: false,
    clientStorage: {},
    isWidget: false,
    borrowerVisitedSteps: [],
    breadcrumbs: [],
    sidePanelsHeight: 0
  },
  reducers: {
    initializeTableFilters: (state) => {
      state.tableFilters = {
        merchant: Object.assign({}, FILTER_INITIAL_STATE),
        application: Object.assign({}, FILTER_INITIAL_STATE),
        merchantApplication: Object.assign({}, FILTER_INITIAL_STATE),
        partner: Object.assign({}, FILTER_INITIAL_STATE),
      }
    },
    addVisitedStep: (state, action: PayloadAction<string>) => {
      state.borrowerVisitedSteps = [...state.borrowerVisitedSteps, action.payload];
    },
    setIsWidget: (state, action: PayloadAction<boolean>) => {
      state.isWidget = action.payload;
    },
    saveToClientStorage: (state, action: PayloadAction<{ key: string, value: any }>) => {
      const { key, value } = action.payload;
      state.clientStorage[key] = value;
    },
    removeFromClientStorage: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      delete state.clientStorage[key];
    },
    cleanClientStorage: (state) => {
      state.clientStorage = {};
    },
    updateSupport: (state, action: PayloadAction<IGeneralSupport>) => {
      let support: IGeneralSupport = {
        merchant: null,
        borrower: null,
        whitelabel: null,
        resources: [],
      };
      _.forOwn(action.payload, (value, key) => {
        if (value && isSupport(value)) {
          support[key] = {
            ...value,
            phone: formatAPIPhone(value?.phone)
          }
        }
      });
      _.forOwn(action.payload, (value, key) => {
        if (value && !isSupport(value)) {
          support[key] = {
            ...value,
          }
        }
      });

      state.support = support;
    },
    setLocation: (state, action: PayloadAction<string>) => {
      const lastVisitedPages = state.lastVisitedPages;
      const newVisitedPage = action.payload;

      const last5VisitedPages = lastVisitedPages.length > 4
        ? [...lastVisitedPages.slice(-4), newVisitedPage]
        : [...lastVisitedPages, newVisitedPage];

      state.lastVisitedPages = last5VisitedPages;
    },
    selectLocation: (state, action: PayloadAction<IMerchantLocation>) => {
      state.selectedLocation = action.payload;
    },
    clearLocation: (state) => {
      state.selectedLocation = null;
    },
    triggerEvent: (state, action: PayloadAction<string>) => {
      const event = action.payload;
      const updatedObservers = {
        ...state.observers,
        [event]: (state.observers[event] || 0) + 1,
      };
      return {
        ...state,
        observers: updatedObservers,
      };
    },
    updateMerchantFilter: (state, action: PayloadAction<IFilter>) => {
      state.tableFilters.merchant = action.payload;
    },
    updatePartnerFilter: (state, action: PayloadAction<IFilter>) => {
      state.tableFilters.partner = action.payload;
    },
    updateApplicationFilter: (state, action: PayloadAction<IFilter>) => {
      state.tableFilters.application = action.payload;
    },
    updateMerchantApplicationFilter: (state, action: PayloadAction<IFilter>) => {
      state.tableFilters.merchantApplication = action.payload;
    },
    resetMerchantFilter: (state) => {
      state.tableFilters.merchant = Object.assign({}, FILTER_INITIAL_STATE);
    },
    resetPartnerFilter: (state) => {
      state.tableFilters.partner = Object.assign({}, FILTER_INITIAL_STATE);
    },
    resetApplicationFilter: (state) => {
      state.tableFilters.application = Object.assign({}, FILTER_INITIAL_STATE);
    },
    resetMerchantApplicationFilter: (state) => {
      state.tableFilters.merchantApplication = Object.assign({}, FILTER_INITIAL_STATE);
    },
    updateMerchantAppComingFromLandingPage: (state, action: PayloadAction<boolean>) => {
      state.merchantAppFromLandingPage = action.payload;
    },
    setBreadcrumbs: (state, action: PayloadAction<Array<IBreadcrumbItem>>) => {
      state.breadcrumbs = action.payload;
    },
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
    },
    setSidePanelsHeight: (state, action: PayloadAction<number>) => {
      state.sidePanelsHeight = action.payload;
    }
  }
});

export const appReducer = counterSlice.reducer;
export default counterSlice.actions;
