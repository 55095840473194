import { useEffect } from 'react';

export const useVisitTime = (callback: (timeElapsedSeconds: number) => void) => {
  let timeStart;

  useEffect(() => {
    timeStart = Date.now();

    return () => {
      const timeElapsed = Date.now() - timeStart;
      callback(Math.floor(timeElapsed / 1000));
    }
  }, []);
};