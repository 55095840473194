import { Disclaimer } from "common";
import styles from "./Agreements.module.scss";
import React from "react";
import { useAppSelector } from "reducers/Hooks";

const PrivacyPolicy: React.FC = () => {
  const whitelabelName = useAppSelector(state => state.whitelabel?.name);
  const whitelabelSlug = useAppSelector(state => state.whitelabel?.slug);

  return <Disclaimer title="Privacy Policy">
    <div className={styles.agreement}>
      <p>Date of Last Revision: February 27, 2023</p>
      <br />
      <br />
      <h2>OUR POLICY</h2>
      <p>
        Welcome to {whitelabelName}, powered by Enable, Inc., ({whitelabelName}/Enable”, “{whitelabelName} & Enable”, “we”, “us”, “our”). This Privacy Policy is implemented to describe how we collect, use, and share your information in connection with our websites and platforms (the “Site”) and any other online content, tools, or services that we control and that link to this Privacy Policy. This Site is operated by {whitelabelName}/Enable and has been created to provide information to our visitors (“you,” “your”) about our company and our online content, tools and services, whether such content, tools and services are accessed on the Site or through third-party websites, mobile sites and/or applications (together with the Site, the “Services”). This Privacy Policy describes how we collect, use and disclose information from visitors to the Services, including personally identifiable data (“Personal Data”).
      </p>
      <br />
      <br />
      <h2>WHY THIS MATTERS</h2>
      <p>
        We want to build an indispensable platform to aid people in making smart comparisons and connecting them to companies for their financial needs. Knowing certain things will help us do that better. Please be sure to read this entire Privacy Policy before using or submitting information through the Services. By using or submitting information through the Services, you are indicating that you agree to the collection, transfer, manipulation, processing, storage, disclosure and other uses of your personal data as described in this Privacy Policy and to be bound by the terms of this Privacy Policy. This Privacy Policy is incorporated into our Terms of Service at <a href="/termsConditions">{whitelabelSlug}/termsConditions</a> and any other agreement entered into by and between you and us.
      </p>
      <br />
      <br />
      <h2>PERSONAL DATA THAT YOU PROVIDE THROUGH THE SERVICES</h2>
      <p>
        We collect Personal Data from you when you voluntarily provide such information, including but not limited to when you access, complete and/or use one of our online forms, tools or content items, contact us with inquiries, respond to one of our surveys, register for access to the Services or use certain Services. If you complete one of our online forms, you may be asked to provide Personal Data in order to provide the Services as described:
      </p><br />
      <table>
        <tr>
          <th>Category</th>
          <th>Examples</th>
        </tr>
        <tr>
          <td>Demographic Data</td>
          <td>Contact data such as your name, address (including postal code), email, postal code, date of birth, social security number and phone number.</td>
        </tr>
        <tr>
          <td>Personal Records</td>
          <td>Estimated credit score, payment history, employment status, ownership or rental history, income, education level and degrees, and other information.</td>
        </tr>
        <tr>
          <td>Account Registration</td>
          <td>If we have registration and/or a log-on experience, this would include the information we collect when you create an account such as name, email and/or other information to provide account related functionalities to our users.</td>
        </tr>
        <tr>
          <td>Feedback and Support</td>
          <td>If you provide us feedback or contact us for support, we may collect your name and e-mail address, as well as any other content that you send to us. We collect and use this data based upon our business need to receive and act upon your feedback or issues.</td>
        </tr>
        <tr>
          <td>Surveys</td>
          <td>If you voluntarily submit certain information to our services, such as filing out a survey about your user experience, we may collect the information you have provided. We may collect this information to understand your opinions, better market our services and to collect relevant information about our business.</td>
        </tr>
        <tr>
          <td>Other Information</td>
          <td>Information we may collect which is not specifically listed here is described in this Privacy Policy or is otherwise disclosed at time of collection.</td>
        </tr>
      </table><br />
      <p>
        For clarity, we collect Personal Data from you to, in part, provide you with personalized content and information related to financial products, but {whitelabelName}/Enable is not a lender, credit card issuer, bank or other similarly situated financial institution. Any loan or related product to which you may become a party is solely transacted between you and the applicable provider and is not a part of the Services. By voluntarily providing us with Personal Data, you are consenting to our use of your Personal Data in accordance with this Privacy Policy. You represent that the Personal Data you provide is true, accurate, current and complete, and that you have the authorization to provide it to us. If you provide Personal Data to the Services, you acknowledge and agree that such Personal Data may be transferred from your current location to the offices and servers of {whitelabelName}/Enable and the authorized third parties referred to herein.
      </p>
      <br />
      <br />
      <h2>INFORMATION AUTOMATICALLY COLLECTED</h2>
      <p>
        Whenever you interact with the Services, we may automatically receive and record information from your computer, browser and/or mobile device, which may include the following:
        <br />
        <br />
        <b>IP Addresses: </b>
        The requests you make to us may contain your IP address (the Internet address of your computer). We may use visitors’ IP addresses for various purposes, including to display customized content and analyze and report upon usage of the Services; to diagnose and prevent service or technology problems affecting the Services; and to monitor and prevent fraud and abuse.
        <br />
        <br />
        <b>Device IDs: </b>
        We may collect information on the type of device you are using, operating system, settings, browser and OS information, unique device identifiers, network information and other device-specific information. The information collected may depend on the type of device you use and its settings.
        <br />
        <br />
        <b>Location Data: </b>
        We may collect location data based on your IP address and the geolocation of your device. We collect this data to better tailor our services to you and to help provide service offerings available to you in your geographic area.
        <br />
        <br />
        <b>Usage Information: </b>
        We collect information about how you use our Services, such as the types and categories of content that you view or engage with, the features you use, the actions you take, the and the time, frequency and duration of your activities on the Site.
      </p>
      <br />
      <br />
      <h2>COOKIES, WEB BEACONS AND SIMILAR TECHNOLOGIES</h2>
      <p>
        In operating the Services, we may use cookies, web beacons and similar technologies. This Privacy Policy covers the use of cookies, web beacons and similar technologies by us only. We do not sell or share your cookies other than as described in this Privacy Policy. Uses of these technologies by third-party advertisers or websites linked from the Services, including third parties with which we partner, are governed by each such advertiser or website’s privacy policy.
        <br />
        <br />
        <b>Cookies & Analytics: </b>
        A cookie is a piece of information that the computer that hosts our Services gives to your browser when you access the Services. Cookies help provide additional functionality to the Services, customize users’ experiences with the Services and help us analyze Services usage more accurately for research, advertising and marketing purposes. On most web browsers, you will find a “help” section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. We recommend that you leave cookies turned on because they allow you to take advantage of some of the Services’ features.
        <br />
        <br />
        <b>Web beacons: </b>
        In addition to cookies, we may use web beacons (also known as “clear GIFs”) to measure traffic to or from or use of our online forms, tools or content items and related browsing behavior and to improve your experience when using the Services. We may also use customized links or other similar technologies to track hyperlinks that you click and associate that information with your Personal Data in order to provide you with more focused communications. Web beacons may be placed on the Service by third parties such as an analytics provider in order to support functionality described in this Privacy Policy. The information collected by these third parties in this manner is subject to that third party’s own privacy policies.
        <br />
        <br />
        <b>Aggregated Personal Data: </b>
        In an ongoing effort to better understand and serve the users of the Services, we often conduct research on consumer demographics, interests and behavior based on the Personal Data and other information provided to us. This research may be compiled and analyzed on an aggregate basis, and we may share this aggregate data with our affiliates, agents, service providers and other third parties with whom we do business. This includes, but is not limited to, information such as consumer financial trends, choices and comparisons. This aggregate information does not identify you personally. {whitelabelName}/Enable may also disclose aggregated user statistics in order to describe our services to current and prospective financial service providers, and to other third parties for other lawful purposes.
      </p>
      <br />
      <br />
      <h2>OUR USE OF YOUR PERSONAL DATA AND OTHER INFORMATION</h2>
      <p>
        {whitelabelName}/Enable uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. Generally, we may use information we collect in the following ways.
        <br />
        <br />
        <ul>
          <li>To identify and potentially personalize the Services when you visit our Site</li>
          <li>To provide products and services</li>
          <li>To allow the merchant you are conducting business with to identify your inquiry and provide support/respond to inquiries accordingly</li>
          <li>For fraud and information security</li>
          <li>To improve the Services</li>
          <li>To conduct analytics such as usage trends</li>
          <li>To respond to inquiries related to support or other requests</li>
          <li>To send marketing and promotional materials about the Services</li>
          <li>To manage our relationships</li>
          <li>To build aggregated user profiles and segments</li>
        </ul>
        For more clarity, if you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by email or provide feedback in any way such as to ask a question or report an issue with the Services, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to learn about certain products that may be available to you through third-party providers, we will use your Personal Data to provide you with information on such products and providers, to provide other personalized services, and/or to monitor your use of the Services.
        <br />
        <br />
        {whitelabelName}/Enable and our subsidiaries and affiliates (the “Our Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users and to improve the Services.
        <br />
        <br />
        <b>Partners and Third-Party Sources: </b>
        We may also obtain information about you from third-party sources, such as our partners. We may also, at your direction, receive information from third-party services that provide an ability to share information you have provided to the third-party through the use of an application program interface (API). If we combine or associate information from other sources with your information that we collect through the Services, we will treat the combined information in accordance with this Privacy Policy, the Terms, and applicable law.
        <br />
        <br />
        <b>Marketing: </b>
        {whitelabelName}/Enable and our financial providers with whom we share your information for product pricing may use your Personal Data to communicate with you by email, postal mail or by text message to provide you with:
        <br />
        <br />
        <ul>
          <li>Information regarding your application status, or transactional information relevant to your account.</li>
          <li>Opportunities, surveys and promotions related to your submitted request available through the services or products of our financial providers that we think may be of interest to you.</li>
          <li>Information or content – such as articles or white papers – that we believe would be of interest to you through email, text messages, or postal mail.</li>
        </ul>
        <br />
        Each marketing-related communication we send you will contain instructions permitting you to “opt-out” of receiving future marketing communications. If we intend on using any Personal Data in any manner that is not consistent with this Privacy Policy, you will be informed of such anticipated use prior to or at the time at which the Personal Data is collected.
        You may choose to unsubscribe from marketing emails or mail by emailing <a href="mailto:support@enablefinancing.com">support@enablefinancing.com</a> or at the address below and asking to be removed from any correspondence. Although we will remove you from our marketing lists you may still receive transactional messages related to the Services.
      </p>
      <br /><br />
      <h2>OUR DISCLOSURE OF YOUR PERSONAL DATA</h2>
      <p>
        {whitelabelName}/Enable is not in the business of selling your information to third parties. There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below:
      </p>
      <p>
        <b>Certain Business Partners:</b>
        When we partner with other businesses to provide content or services to you or to such business partners, we may share relevant Personal Data in connection with such content or services. For example, we will share your contact and other information with a lender and/or service provider if the information you provide in one of our online forms or tools matches certain criteria for loan products offered by such lender and/or loan originator.
      </p>
      <p>
        <b>Merchants Using the Platform:</b>
        When your use of the platform is recommended by a merchant, certain Personal Data, including the status of your inquiry, will be shared with the merchant so they are able to provide you with support as you complete the transaction.
      </p>
      <p>
        <b>Business Transfers: </b>
        As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets. Your Personal Data could also be shared with professional advisors and counterparties during such events.
      </p>
      <p>
        <b>Business Analytics: </b>
        When we partner with certain businesses in order to learn and improve user interface, content, site performance, and our offerings. For example, Information may be shared in order to improve your experience on partner sites as well as on our site.      </p>
      <p>
        <b>Related Companies: </b>
        We may also share your Personal Data with Our Related Companies for purposes consistent with this Privacy Policy.
      </p>
      <p>
        <b>Agents, Consultants and Related Third Parties: </b>
        {whitelabelName}/Enable, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases and providing marketing assistance. When we employ another entity to perform a function of this nature, we only provide them with the information that they need to perform their specific function.
      </p>
      <p>
        <b>Legal Requirements: </b>
        We may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, including responding to a subpoena, warrant, or other legal process, (ii) protect and defend the rights or property of {whitelabelName}/Enable, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability.
      </p>
      <br />
      <br />
      <h2>OTHER IMPORTANT INFORMATION</h2>
      <p>
        <b>Your Choices: </b>
        In general, you can visit the Services without providing Personal Data. If you choose not to provide any Personal Data, however, you may not be able to use certain features of the Services.
      </p>
      <p>
        <b>Registration/Account Information: </b>
        If we have registration and/or a log-on experience, this would include the information we collect when you create an account such as name, email and/or other information to provide account related functionalities to our users. If you choose to register and/or provide information, you agree to provide and maintain true, accurate, current and complete information about yourself.Please note that if you allow others to use your account (including others you permit to set up profiles under your account), they may be able to view information about your account or any of the profiles under your account. information about your account or any of the profiles under your account.
        <br />
        If you no longer what to use our Services, you can deactivate your account by contacting us at <a href="support@enablefinancing.com">support@enablefinancing.com</a> or at the address below.
        Due to regulatory and information retention requirements, we do not delete your information when you deactivate your account. We will, disable your account and cease sending any communications.
      </p>
      <p>
        <b>Access To Information: </b>
        We rely on you to ensure your information is accurate and up-to-date. To keep your Personal Data accurate, current and complete, please contact us as specified at the below address or <a href="mailto:support@enablefinancing.com">support@enablefinancing.com</a>. We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Services.
      </p>
      <p>
        <b>Exclusions: </b>
        This Privacy Policy does not apply to any Personal Data collected by {whitelabelName}/Enable other than Personal Data collected through the Services. This Privacy Policy shall not apply to any unsolicited information you provide to us through the Services or through any other means. This includes, but is not limited to, information posted to any public areas of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and we shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
      </p>
      <p>
        <b>Children’s Privacy Online: </b>
        We do not knowingly collect Personal Data from children under the age of 13. If you are under the age of 13, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on the Services without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to us through the Services, please contact us, and we will endeavor to delete that information from our databases.
      </p>
      <p>
        <b>Links To Other Websites: </b>
        This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by us (the “Third-Party Sites”). The policies and procedures we described here do not apply to the Third-Party Sites. The links from the Services do not imply that we endorse or have reviewed the Third-Party Sites. We suggest contacting those sites directly for information on their privacy policies.
      </p>
      <p>
        <b>Security: </b>
        We take reasonable steps to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via email. Please keep this in mind when disclosing any Personal Data to us via the Internet.
        <br />
        By using the Services or providing Personal Data to us, you agree that we may communicate with you electronically regarding security, privacy and administrative issues relating to your use of the Services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Services or by sending an email to you. You may have a legal right to receive this notice in writing. To receive free written notice of a security breach (or to withdraw your consent from receiving electronic notice), please notify us at <a href="mailto:support@enablefinancing.com">support@enablefinancing.com</a>.
        <br />
        If you are, or believe you have become, a victim of identity theft, you may visit the website of the Federal Trade Commission at <a href="https://www.identitytheft.gov">https://www.identitytheft.gov</a>  for free information to help guard against identity theft and guidance on the recovery steps you can take.
        International Users:
        <br />
        The Service is hosted and intended solely for consumers in the United States. All Personal Data is stored on servers in the United States and is subject to United States law. By choosing to use the Services or otherwise provide information to us, you agree that any dispute over privacy or the terms contained in this Privacy Policy will be governed by the law of the State of Texas and the adjudication of any disputes arising in connection with the Services will be in accordance with the Terms of Service at <a href="{/termsConditions">{whitelabelSlug}/termsConditions</a>.
        <br />
        If you are visiting the Services from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your information to the United States and the global processing of such information. By providing your information you consent to any transfer and processing in accordance with this Privacy Policy.
        <br />
        We rely on you to ensure your information is accurate and up-to-date. To keep your Personal Data accurate, current and complete, please contact us as specified at the below address or <a href="mailto:support@enablefinancing.com">support@enablefinancing.com</a>. We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Services.
      </p>
      <p>
        <b>Other Terms and Conditions: </b>
        Your access to and use of the Services is subject to the Terms of Service at <a href="/termsConditions">{whitelabelSlug}/termsConditions</a>.
      </p>
      <p>
        <b>Changes to {whitelabelName}/Enable Privacy Policy: </b>
        The Services and our business may change from time to time. As a result, at times it may be necessary for us to make changes to this Privacy Policy. We reserve the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before you provide any Personal Data. This Privacy Policy was last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
      </p>
      <p>
        <b>New Uses of Personal Data: </b>
        From time to time, we may desire to use Personal Data for uses not previously disclosed in our Privacy Policy. If our practices change regarding previously collected Personal Information in a way that would be materially less restrictive than stated in the version of this Privacy Policy in effect at the time we collected the information, we will make reasonable efforts to provide notice and obtain consent to any such uses as may be required by law.
      </p>
      <br /><br />
      <h2>YOUR CALIFORNIA PRIVACY RIGHTS</h2>
      <p>
        California Consumer Privacy Act (CCPA)
        <br /><br />
        The CCPA affords California residents certain rights over their personal information (subject to certain exceptions). This section applies only to “personal information” that is subject to the laws of the state of California. Please review our CCPA Privacy Statement for our privacy disclosures and information on how to exercise your rights.
      </p>
      <br />
      <br />
      <h2>CONTACTING US</h2>
      <p>
        To keep your Personal Data accurate, current and complete, please contact us as specified below. We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Services.
        <br /><br />
        Please also feel free to contact us if you have any questions about our Privacy Policy or the information practices of the Services.
        <br /><br />
        You may contact us as follows:
        <br /><br />
        <a href="mailto:support@enablefinancing.com">support@enablefinancing.com</a>
        <br />
        25132 Oakhurst Dr Suite 206
        <br />
        Spring, TX 77386
      </p>
      <br />
      <br />
      <h2>California Consumer Privacy Act (“CCPA”) Notice and Privacy Policy</h2>
      <p>
        February 2023
        <br /><br />
        The following CCPA Notice at Collection and Policy shall not apply to the collection, processing, sale or disclosure of any information (i) that a consumer provides to us to obtain a financial product or service, or (ii) about a consumer resulting from any transaction involving a financial product or service; or (iii) about a consumer resulting from a consumer’s express direction to disclose the consumer’s information; or (iv) we otherwise obtain about a consumer in connection with providing a financial product or service to that consumer.
        <br /><br />
        This NOTICE AT COLLECTION AND PRIVACY POLICY FOR CALIFORNIA RESIDENTS supplements the information contained in the Privacy Policy of {whitelabelName}/Enable (“we,” “us,” or “our”) and applies solely to consumers who reside in the State of California (“consumers” or “you”). We adopt this statement to comply with the California Consumer Privacy Act of 2018 (“CCPA”) and other California privacy laws. Any terms defined in the CCPA have the same meaning when used in this statement. For California residents, the provisions of this Notice at Collection and Privacy Policy prevail over any conﬂicting provisions of the Privacy Policy of {whitelabelName}/Enable.
      </p>
      <br />
      <br />
      <h2>Information We Collect</h2>
      <p>
        We collect information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household subject to the CCPA (“Personal Information”).
        <br /><br />
        In particular, we have collected the following categories of Personal Information from consumers within the last twelve (12) months:
      </p><br />
      <table>
        <tr>
          <th>Category</th>
          <th>Examples</th>
          <th>Collected</th>
        </tr>
        <tr>
          <td>A. Identifiers.</td>
          <td>A real name,postal address, unique personal identifier, online identifier, internet protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>B. Categories of Personal Information listed in the California Custome</td>
          <td>A name, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history,or any other financial information, medical information, or health insurance information.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Records statute (Cal. Civ. Code § 1798.80(e)).</td>
          <td>Some personal information included in this category may overlap with other categories.</td>
          <td> </td>
        </tr>
        <tr>
          <td>C. Protected classification characteristics under California or federal law.</td>
          <td>Age, citizenship, marital status, medical condition, sex, veteran or military status.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>D. Commercial information.</td>
          <td>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>F. Internet or other similar network activity.</td>
          <td>Browsing history, search history, information on a consumer's interaction with an internet website, application, or advertisement.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>G. Geolocation data.</td>
          <td>Physical location or movements.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>I. Professional or employment-related information.</td>
          <td>Current or past job history or education information.</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
          <td>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>K. Inferences drawn from other Personal Information.</td>
          <td>Profile reﬂecting a person's preferences, characteristics, behavior automatically derived from use of our services (e.g. through cookies) or from service providers</td>
          <td>YES</td>
        </tr>
      </table><br />
      <p>
        <b>Personal Information does not include: </b><br /><br />
        <ul>
          <li>Publicly available information from government records.</li>
          <li>De-identified or aggregate consumer information.</li>
          <li>Other information to the extent excluded from the CCPA's scope, like:</li>
          <li>Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (CalFIPA), and the Driver's Privacy Protection Act of 1994;</li>
          <li>Health or medical information that constitutes clinical trial data or that is otherwise covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA), the Health Information Technology for Economic and Clinical Health Act (HITECH), or the California Confidentiality of Medical Information Act (CMIA);</li>
          <li>Personal Information we may collect from a natural person (including, without limitation, emergency contact information for that natural person and such other Personal Information we may need in order to administer benefits for such natural person) in the course of the natural person applying for a job with us or otherwise in connection that natural person acting as our employee, owner, director, officer, medical staff member, or contractor; and</li>
          <li>Personal Information we may collect from a natural person who is acting as an employee, owner, director, officer, or contractor of another company with which company we are communicating or for which company we are otherwise evaluating or actually providing a product or service.</li>
        </ul>
        <br />
        <b>Sources of Personal Information: </b>
        With respect to each of the categories of Personal Information listed in the table above, we obtain such Personal Information from a variety of sources, including from:
        <br /><br />
        <ul>
          <li>our customers and consumers, with respect to both online and ofﬂine interactions they may have with us or our service providers and other entities with whom you transact;</li>
          <li>others with whom you maintain relationships who may deal with us on your behalf; credit bureaus;</li>
          <li>identify verification and fraud prevention services;</li>
          <li>marketing and analytics providers;</li>
          <li>public databases;</li>
          <li>social media platforms;</li>
          <li>advertising networks;</li>
          <li>government entities;</li>
          <li>internet service providers and the devices you use to access our websites, mobile applications, and online services;</li>
          <li>operating systems and platforms;</li>
          <li>third party through the use of an application program interface (API)</li>
          <li>data brokers</li>
        </ul>
      </p>
      <br />
      <h2>Use of Personal Information</h2>
      <p>
        With respect to each of the categories of Personal Information listed in the table above, we may use or disclose such Personal Information for any one or more of the following business purposes:
        <br />
        <br />
        <ul>
          <li>To fulfill or meet the reason for which the information is provided.</li>
          <li>To provide you with information, products or services that you request from us;</li>
          <li>To allow the merchant who has recommended you to use the platform to access your Personal Data and the result of your inquiry so they are able to provide you with support as you complete the transaction.</li>
          <li>To provide you with email alerts, event registrations and other statements concerning our products or services;</li>
          <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections;</li>
          <li>To detect and protect against security incidents, and malicious, deceptive, fraudulent or illegal activity, and prosecute the same;</li>
          <li>To debug to identify and repair errors in our systems;</li>
          <li>As otherwise necessary or appropriate to protect the rights, property or safety of us, our customers, consumers, or others;</li>
          <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations;</li>
          <li>For such purposes as may be necessary or appropriate in connection with audits and reporting relating to particular transactions and interactions, including online interactions, you may have with us or others on our behalf;</li>
          <li>To improve our website and apps and present their content to you;</li>
          <li>For testing, research, analysis and product development;</li>
          <li>For short-term, transient use including contextual customization of ads; and/or</li>
          <li>As otherwise described to you when collecting your Personal Information or as otherwise set forth in the CCPA.</li>
        </ul>
      </p>
      <br />
      <h2>Sharing Personal Information</h2>
      <p>
        With respect to each of the categories of Personal Information listed immediately below, we may disclose such Personal Information to a third party for any of the business purposes identified above.
      </p>
      <p>
        In addition, as is common practice among companies that operate online, we allow certain third parties, including non affiliated business partners, advertising networks, analytics providers, and other advertising providers, to directly collect information about your online activities on the Site and across your browsers and devices using cookies, web beacons, mobile advertising identifiers and other technologies. These third parties may use this information to display online advertisements tailored to your interests and preferences across your browsers and devices, to conduct ad campaign measurement and Site analytics, to detect, prevent and report fraud, or to carry out their own business and commercial purposes. For more information about these practices, please review the “Cookies, Web Beacons and Similar Technology” section of the {whitelabelName}/Enable Privacy Policy.
      </p>
      <p>
        In the preceding twelve (12) months, we have disclosed the above categories of Personal Information for each of the business purposes identified above.
      </p>
      <p>
        With respect to each of the categories of Personal Information listed immediately above, we may disclose such Personal Information for a business purpose to the following categories of third parties:
        <br />
        <br />
        <ul>
          <li>Our affiliates</li>
          <li>Service providers</li>
          <li>Government entities</li>
          <li>Marketing partners, such as those partners who you interact with that maintain a link to our services on their site, embeddable widget or our API</li>
          <li>Providers, such as third parties as our customers or consumers may direct us to disclose their personal information</li>
        </ul>
        We do not, and will not, sell (as that term is defined by the CCPA) any Personal Information that we collect.
      </p>
      <br />
      <br />
      <h2>Your Rights and Choices</h2>
      <p>
        The CCPA provides consumers (California residents) with specific rights regarding their Personal Information. This section describes your CCPA rights and explains how to exercise those rights.
      </p>
      <p>
        <b>Access to Specific Information and Data Portability Rights</b><br />
        You have the right to send us a request, no more than twice in a twelve-month period, for any of the following for the period that is twelve months prior to the request date:
        <br />
        <br />
        <ul>
          <li>The categories of personal information we have collected about you.</li>
          <li>The categories of sources from which we collected your personal information.</li>
          <li>The business or commercial purposes for our collecting or selling your personal information.</li>
          <li>The categories of third parties to whom we have shared your personal information.</li>
          <li>The specific pieces of personal information we have collected about you.</li>
          <li>A list of the categories of personal information disclosed for a business purpose in the prior 12 months, or that no disclosure occurred.</li>
          <li>A list of the categories of personal information sold about you in the prior 12 months, or that no sale occurred. If we sold your personal information, we will explain: • The categories of your personal information we have sold. • The categories of third parties to which we sold personal information, by categories of personal information sold for each third party.</li>
        </ul>
        You have the right to make or obtain a transportable copy, no more than twice in a twelve-month period, of your personal information that we have collected in the period that is 12 months prior to the request date and are maintaining.
      </p>
      <p>
        <b>Deletion Request Rights</b><br />
        You have the right to request that we delete any of your Personal Information that we have collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your Personal Information from our records, unless an exception applies.
        <br />
        We may deny your deletion request if retaining the Personal Information is necessary for us or our service providers to:
        <br />
        <br />
        <ol>
          <li>Complete the transaction for which we collected the Personal Information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
          <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
          <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
          <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
          <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 sec.)</li>
          <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</li>
          <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
          <li>Comply with a legal obligation.</li>
          <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
        </ol>
        <b>Exercising Access, Data Portability, and Deletion Rights</b><br />
        To make a request according to your rights under CCPA, email <a href="mailto:support@enablefinancing.com">support@enablefinancing.com</a> or mail your request for the attention of the Privacy / Legal Department to Enable, Inc., 25132 Oakhurst Dr Suite 206, Spring, TX 77386. California Consumers may exercise these rights via an authorized agent who meets the agency requirements of the CCPA. We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm that the personal information relates to you. Any request you submit to us is subject to an identification and residency verification process
        <br />
        <br />
        <b>The verifiable consumer request must: </b>
        <ul>
          <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or an authorized representative.</li>
          <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
        </ul>
        <i>Verifying Your Request</i>
        <br />
        --
      </p>
      <p>
        We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will only use Personal Information provided in a verifiable consumer request to verify your identity or authority to make the request. We may otherwise limit our response to your request as permitted under applicable law.
      </p>
      <p>
        Whenever feasible, we will match the identifying information provided by you to the Personal Information we maintain. Some personal information we maintain about Consumers is not sufficiently associated with enough personal information about the Consumer for us to be able to verify that it is a particular consumer's personal information (e.g., data tied only to a pseudonymous browser ID). As required by the CCPA, we do not include that personal information in response to verifiable consumer requests. If we cannot comply with a request, we will explain the reasons in our response.
      </p>
      <p>
        We will make commercially reasonable efforts to identify consumer personal information that we collect, process, store, disclose, and otherwise use and to respond to your California Consumer privacy rights requests. We will typically not charge a fee to fully respond to your requests, but we may charge a reasonable fee, or refuse to act upon a request, if your request is excessive, repetitive, unfounded, or overly burdensome.
      </p>
      <p>
        With respect to requests submitted on your behalf by your authorized agent, please note that we may deny a request from an authorized agent if the agent cannot provide your signed permission demonstrating that they have been authorized by you to act on your behalf.
      </p>
      <p>
        <i>Response Timing and Format</i>
        <br />
        --
      </p>
      <p>
        We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have an account with us, we will deliver our written response to that account.
      </p>
      <p>
        <i>Non-Discrimination</i>
        <br />
        --
      </p>
      <p>
        We will not discriminate against you for exercising any of your CCPA rights.
        <br />
        <br />
        Opt Out
        <br />
        <br />
        If third-party information collection on our sites constitutes the sale of personal information, such as the collection of certain information, including cookies and similar information stored on your browsers, advertising identifiers on your mobile devices, and/or the IP address of your devices when you visit our sites, you can exercise your right to opt out of these sales by:
        <br />
        <br />
        <ol>
          <li>Visit <a href="https://www.privacyrights.info/">privacyrights.info/</a> privacyrights.info to opt out from sales of this type of personal information by third-party businesses that participate in the DAA’s CCPA Opt-Out Tool, and</li>
          <li>Visit <a href="https://myaccount.google.com/data-and-personalization?pli=1">myaccount.google.com/data-and-personalization</a>, scroll down to the “Ad personalization” section, click “Go to ad settings” and toggle “Ad personalization” to OFF, and</li>
          <li>Follow the instructions in the “Third-Party Web Beacons and Third-Party Buttons” section in the {whitelabelName}/Enable Privacy Policy to opt out of interest-based advertising and other cookie-related information collection and processing.</li>
        </ol>
      </p>
      <p>
        <b>California “Shine the Light” law</b><br />
        California's "Shine the Light" law, Civil Code section 1798.83, requires certain businesses to respond to requests from California customers asking about the businesses' practices related to disclosing personal information to third parties for the third parties' direct marketing purposes. We do not currently engage in the type of sharing covered by that law and so no such list exists. We do not make any representations concerning third parties that do not collect personal information directly through our Services.
        <br />
        <br />
        <b>Changes to Our Privacy Statement</b><br />
        We reserve the right to amend this privacy policy at our discretion and at any time. When we make changes to this privacy policy, we will notify you through a statement on our website homepage.
        <br />
        <br />
        <b>Contact Information</b><br />
        If you have any questions or comments about this statement, the ways in which we collect and use your Personal Information, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:
        <br /><br />
        <a href="mailto:support@enablefinancing.com">support@enablefinancing.com</a>
        <br />
        25132 Oakhurst Dr Suite 206
        <br />
        Spring, TX 77386
      </p>
    </div>

  </Disclaimer>;
};

export default PrivacyPolicy;
