import { MD5 } from 'crypto-js';

export default class Hasher {

  /** 
   * @description This function takes a string as input and returns a hash of the string as output.
   * @param {string} data The string to be hashed.
   * @returns The hash of the input string.
   * */
  static convert = (data: string): string => {
    return MD5(data).toString();
  }
}
