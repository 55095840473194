import React from "react";

interface ReCaptchaDisclaimer {
  type: "DEFAULT" | "INVITATION_FLOWS" | "LANDING_PAGES"
}

const ReCaptchaDisclaimer: React.FC<ReCaptchaDisclaimer> = ({ type }) => {

  const getDisclaimer = () => {
    if (type === "INVITATION_FLOWS") {
      return <div style={{ padding: "0px 50px 50px 50px", backgroundColor: "var(--bgColor)", fontSize: "1.2rem", color: "var(--darkTextColor)", fontWeight: "500", textAlign: "center", marginTop: "-20px" }}>
        This site is protected by reCAPTCHA and the Google <a style={{ color: "var(--primaryVariationTextColor)", fontWeight: 600 }} href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a style={{ color: "var(--primaryVariationTextColor)", fontWeight: 600 }} href="https://policies.google.com/terms"> Terms of Service</a> apply.
      </div>
    } else if (type === "LANDING_PAGES") {
      return <div style={{ padding: "0px 50px 20px 50px", backgroundColor: "var(--primaryColor)", fontSize: "1.2rem", color: "var(--whiteTextColor)", fontWeight: "500", textAlign: "center" }}>
        This site is protected by reCAPTCHA and the Google <a style={{ color: "var(--whiteTextColor)", fontWeight: 600 }} href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a style={{ color: "var(--whiteTextColor)", fontWeight: 600 }} href="https://policies.google.com/terms"> Terms of Service</a> apply.
      </div>
    } else {
      return <div style={{ padding: "20px 45px 20px 0px", backgroundColor: "var(--bgColor)", fontSize: "1.2rem", color: "var(--darkTextColor)", fontWeight: "500", textAlign: "right" }}>
        This site is protected by reCAPTCHA and the Google <a style={{ color: "var(--primaryTextColor)", fontWeight: 600 }} href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a style={{ color: "var(--primaryColor)", fontWeight: 600 }} href="https://policies.google.com/terms"> Terms of Service</a> apply.
      </div>
    }
  }

  return <>
    {getDisclaimer()}
  </>;
}

export default ReCaptchaDisclaimer;