// this isn't used anywhere on 18/07/2023

import styles from "./NotificationPopup.module.scss";
import { Button, CloseIcon } from "common";
import React from "react";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";

const NotificationPopup: React.FC<IPopup> = () => {
  const [notRead, setNotRead] = React.useState<boolean>(true);
  const [notReadOne, setNotReadOne] = React.useState<boolean>(true);
  const [notReadTwo, setNotReadTwo] = React.useState<boolean>(true);

  return (
    <Popup isInner={true} >
      <>
        <CloseIcon id="notificationPopup" color="dark" />
        <h2>Notifications</h2>
        <div className={styles.notificationContainer}>
          <div
            className={`${styles.notification} ${notReadOne && notRead ? styles.notRead : null
              }`}
          >
            <p className="textMedium">
              12/50 Merchants have signed up after receiving invitation.
            </p>
            {notReadOne && notRead ? (
              <div className={styles.markReadContainer}>
                <Button
                  id="notificationPopup_markAsRead_1"
                  type="button"
                  label="Mark as read"
                  variant="secondary"
                  onClick={() => {
                    setNotReadOne(false);
                  }}
                />
              </div>
            ) : null}
          </div>
          <div
            className={`${styles.notification} ${notReadTwo && notRead ? styles.notRead : null
              }`}
          >
            <p className="textMedium">
              12/50 Merchants have signed up after receiving invitation.
            </p>
            {notReadTwo && notRead ? (
              <div className={styles.markReadContainer}>
                <Button
                  id="notificationPopup_markAsRead_2"
                  type="button"
                  label="Mark as read"
                  variant="secondary"
                  onClick={() => {
                    setNotReadTwo(false);
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.notification}>
            <p className="textMedium">
              Partner{" "}
              <span style={{ color: "var(--primaryTextColor)" }}>Dental Offices</span>{" "}
              has added 12 new merchants.
            </p>
          </div>
          <div className={styles.notification}>
            <p className="textMedium">
              12/50 Merchants have signed up after receiving invitation.
            </p>
          </div>
          <div className={styles.notification}>
            <p className="textMedium">
              12/50 Merchants have signed up after receiving invitation.
            </p>
          </div>
          <div className={styles.notification}>
            <p className="textMedium">
              12/50 Merchants have signed up after receiving invitation.
            </p>
          </div>
        </div>
        <Button
          id="notificationPopup_markAllAsRead"
          type="button"
          label="Mark all as read"
          variant="secondary"
          onClick={() => {
            setNotRead(false);
          }}
        />
      </>
    </Popup>
  );
};

export default NotificationPopup;
