import styles from "./HistoryPopup.module.scss";
import { CloseIcon, Scrollable, Table, TimeZoneMessage } from "common";
import React from "react";
import { IHeader, IList, IPopup } from "common/interfaces";
import Popup from "./Popup";
import { useParams } from "react-router-dom";
import { formatAPIDate } from "utils/formatters";
import usePartner from "hooks/usePartner";
import { PartnerHandler } from "actions/PartnerHandler";

const PartnerHistoryPopup: React.FC<IPopup> = () => {
  const { partnerSlug } = useParams();
  const partner = usePartner(partnerSlug);

  const getHistory = async (next: string): Promise<any> => {
    let history: IList = await PartnerHandler().getHistory(next, partnerSlug);
    history.results = history.results.map(result => {
      return {
        ...result,
        date_time: formatAPIDate(result.date_time, true, true)
      }
    });
    return history;
  }

  const historyHeaders: Array<IHeader> = [
    { label: "Event", value: "event", size: 10, preventSorting: true },
    { label: "Date / Time", value: "date_time", size: 9 },
    { label: "Account", value: "account", size: 13, preventSorting: true },
    { label: "User", value: "user", size: 13, preventSorting: true },
  ];

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="partnerHistoryPopup" color="dark" />
        <div className={styles.shadowFix}>
          <div className={styles.contentContainer}>
            <h2 className={styles.pageTitle}>
              {`${partner?.name} history`}
            </h2>
            <div className={styles.tableContainer}>
              <Table
                id="partner_history"
                data={getHistory}
                headers={historyHeaders}
                maxHeight={450}
                tableBodyStyle={{ minWidth: 690 }}
              />
            </div>
            <TimeZoneMessage timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone} />
          </div>
        </div>
      </>
    </Popup>
  );
};

export default PartnerHistoryPopup;
