import { Button } from "common";

interface IExportButton {
  id: string;
  onClick: () => void;
  exporting?: boolean;
  disabled?: boolean;
}

const ExportButton: React.FC<IExportButton> = ({ id, onClick, exporting, disabled }) => {
    return <Button
    id={`${id}_export_button`}
    label={exporting ? "Exporting..." : "Export CSV"}
    style={{ marginLeft: "auto", marginRight: "0", minWidth: "150px" }}
    onClick={onClick}
    disabled={exporting || disabled}
  />
};

export default ExportButton;
