import SystemHandler from "actions/SystemHandler";
import { Form, Input, SelectDropdown } from "common/form";
import { IAddEntity } from "common/interfaces";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { businessOwnership, statesArray } from "utils/constants";
import { containsOnlyAsterisks } from "utils/helpers";
import { BusinessValidation, getEINValidation, EmailValidation, PhoneValidation, Schema, SimpleRequireValidation, URLValidation } from "utils/validation/additionalValidation";
import { RequireDateValidation } from "utils/validation/dateValidation";

const BusinessInformation: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const dtmOwnersNumber = useAppSelector(state => state.dtmApplication.ownersNumber);
  const [businessTypes, setBusinessTypes] = useState([]);

  useEffect(() => {
    SystemHandler.getUWBusinessTypes()
      .then(_businessTypes => {
        setBusinessTypes(_businessTypes);
      });
  }, []);

  return <>
    <Form
      id="dtm_application_business_information"
      title="Business information"
      submitBtnText="Next"
      onFormSubmit={(data: any) => { onNext(data); }}
      validationSchema={Schema({
        business_name: BusinessValidation.name,
        business_dba_name: BusinessValidation.name,
        business_email: EmailValidation,
        business_phone: PhoneValidation,
        business_type: SimpleRequireValidation,
        business_website: URLValidation,
        business_start_date: RequireDateValidation,
        business_ein: getEINValidation(containsOnlyAsterisks(initialValues?.business_ein)),
        business_state_incorporated: SimpleRequireValidation,
        how_many_25_more: SimpleRequireValidation
      })}
      values={{ ...initialValues, how_many_25_more: dtmOwnersNumber.toString() }}
      buttonPosition="bottom right"
      inputFields={[
        [
          <Input name="business_name" id="dtm_application_business_name" label="Business corporate name*" />,
          <Input name="business_dba_name" id="dtm_application_business_dba_name" label="DBA name (Doing business as)*" />,
        ],
        [
          <Input name="business_email" id="dtm_application_business_email" label="Business email*" />,
          <Input name="business_phone" id="dtm_application_business_phone" label="Business phone*" mask="phone" />,
        ],
        [
          <SelectDropdown selectOptions={businessTypes} name="business_type" id="dtm_application_business_type" label="Business type*" />,
          <Input name="business_website" id="dtm_application_business_website" label="Website*" />,
        ],
        [
          <SelectDropdown selectOptions={statesArray} name="business_state_incorporated" id="dtm_application_business_state_incorporated" label="State of incorporation*" />,
          <Input name="business_start_date" id="dtm_application_business_start_date" label="Date business established**" mask="date" />,
        ],
        [
          <Input name="business_ein" id="dtm_application_business_ein" label="EIN number*" mask="ein" maxLength={10} />,
          <SelectDropdown selectOptions={businessOwnership} name="how_many_25_more" id="dtm_application_how_many_25_more" label="# owners that own ≥ 25% of business?" />,
        ],
      ]}
      isInsidePopup
    />;
  </>;
}

export default BusinessInformation;