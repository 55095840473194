import styles from "./OfferRejected.module.scss";
import { HeaderWL, WhiteFooter, ToolTip, Container as InternalContainer, HelpButton } from "common";
import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import { useAppSelector } from "reducers/Hooks";
import { useNavigate } from "react-router-dom";
import { IOfferCard } from "common/interfaces";
import strings from "localization/Strings";
import Analytics, { ITracking } from "classes/Analytics";
import { Cancel } from "./popups/feedback";

const OfferRejected: React.FC = () => {
  const whitelabel = useAppSelector(state => state.whitelabel);

  const handleAdvertiserDisclosureHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_offer_selected", object: "advertiser_discl_link", action: "hover" } as ITracking, null);
  }

  const handleHelpButtonClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_offer_selected", object: "help_link", action: "clicked" } as ITracking, null);
  }

  const handlePartnerGuaranteeDisclosureHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_offer_selected", object: "partner_guarantee_discl_link", action: "hover" } as ITracking, null);
  }

  const handleRepresentativeExampleHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_offer_selected", object: "rep_example_discl_link", action: "hover" } as ITracking, null);
  }

  const handlePrivacyPolicyClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_offer_selected", object: "privacy_policy_link", action: "clicked" } as ITracking, null);
  }

  const handleTermsOfServiceClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_offer_selected", object: "terms_service_link", action: "clicked" } as ITracking, null);
  }

  return (
    <>
      <HeaderWL />
      <main className={styles.offerSelectedPageContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" onClick={handleHelpButtonClick} />
            </Col>
            <Col className={styles.helpCol2}>
              <ToolTip
                textToolTip="Advertiser Disclosure"
                text={strings.advertiserDisclosure.replaceAll("{whitelabelName}", whitelabel?.name)}
                placement="bottom"
                onHover={handleAdvertiserDisclosureHover}
              />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer}>
          <InternalContainer className={styles.invitationFormInner}>
            <p className="big">The rejection was successfully sent to the merchant.</p>
            <Cancel />
          </InternalContainer>
        </div>
      </main>
      <WhiteFooter onPartnerGuaranteeDisclosureHover={handlePartnerGuaranteeDisclosureHover} onPrivacyPolicyClick={handlePrivacyPolicyClick} onRepresentativeExampleHover={handleRepresentativeExampleHover} onTermsOfServiceClick={handleTermsOfServiceClick} />
    </>
  );
};

export default OfferRejected;
