import { formatAmount, formatEINNumber, formatInputDate, formatPhoneNumber, formatSSNNumber, formatPercent } from "./formatters";

const handleCurrencyChange = (formik) => (e) => {
  formik.setFieldValue(e.target.name, formatAmount(e.target.value));
};

const handleDateChange = (formik) => (e) => {
  formik.setFieldValue(e.target.name, formatInputDate(e.target.value));
};

const handleSSNChange = (formik) => (e) => {
  formik.setFieldValue(e.target.name, formatSSNNumber(e.target.value));
};

const handleEINChange = (formik) => (e) => {
  formik.setFieldValue(e.target.name, formatEINNumber(e.target.value));
};

const handlePhoneChange = (formik) => (e) => {
  formik.setFieldValue(e.target.name, formatPhoneNumber(e.target.value));
};

const handlePercentChange = (formik) => (e) => {
  formik.setFieldValue(e.target.name, formatPercent(e.target.value));
};

export const getChangeHandler = (mask: string, formik: any) => {
  const types = ["amount", "date", "ssn", "phone", "ein", "percent"];
  const handlers = [
    handleCurrencyChange(formik),
    handleDateChange(formik),
    handleSSNChange(formik),
    handlePhoneChange(formik),
    handleEINChange(formik),
    handlePercentChange(formik),
  ];
  const position = types.findIndex(maskType => mask === maskType);
  return position > -1 ? handlers[position] : formik.handleChange;
}
