import React, { CSSProperties, ReactNode } from "react";
import styles from "./Aside.module.scss";
import { useAppSelector } from "reducers/Hooks";

interface IAside {
  children?: ReactNode;
  style?: CSSProperties;
}

const Aside: React.FC<IAside> = ({ children, style }) => {
  const innerPopupOpened = useAppSelector(state => state.popUp.showInnerPopup);
  const isPopupOpened = useAppSelector(state => state.popUp.showPopup);

  return (
    <aside className={(innerPopupOpened || isPopupOpened) ? styles.aside : ""} style={{ ...(style && { ...style }) }}>
      {children}
    </aside>
  );
}
export default Aside;