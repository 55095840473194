import { CloseIcon, Scrollable } from "common";
import { Input, Form, SelectDropdown } from "common/form";
import React from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { statesArray } from "utils/constants";
import { LocationValidation2, Schema } from "utils/validation/additionalValidation";
import { decypherApiErrorResponse, displayMiniFeedback, getStatus } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import LocationsHandler from "actions/LocationsHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { useParams } from "react-router-dom";

const EditLocationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const locationEditing = useAppSelector(state => state.popUp.messagePassing);
  const { merchantSlug } = useParams();

  let initialValues = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    status: null,
  };

  if (locationEditing) {
    initialValues = {
      name: locationEditing.name,
      address1: locationEditing.address1,
      address2: locationEditing.address2,
      city: locationEditing.city,
      state: locationEditing.state,
      postal_code: locationEditing.postal_code,
      status: locationEditing.status,
    };
  };

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const handleSubmit = async (data) => {
    const payload = {
      ...data,
      id: locationEditing.id,
      status: await getStatus("Active")
    };
    // TODO: ask backend to change "name" to "location_name"
    LocationsHandler.update(payload, locationEditing.id)
      .then(() => {
        Analytics.track({ experience: "portal", screen: merchantSlug ? "merchants_locations" : "settings_locations", object: "location", action: "updated" } as ITracking);
        handleClose();
      })
      .catch(error => {
        displayMiniFeedback(decypherApiErrorResponse(error), true);
      });
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="editLocationPopup" color="dark" />
        <Scrollable>
          <Form
            id="editLocationPopup"
            title="Edit location"
            submitBtnText="Save"
            onFormSubmit={handleSubmit}
            validationSchema={Schema({
              ...LocationValidation2
            })}
            values={initialValues}
            buttonPosition="side right"
            inputFields={[
              [
                <Input name="name" id="editLocationPopup_location_name" label="Location name" />,
                <Input name="address1" id="editLocationPopup_address1" label="Address" />
              ],
              [
                <Input name="city" id="editLocationPopup_city" label="City" />,
                <SelectDropdown selectOptions={statesArray} name="state" id="editLocationPopup_state" label="State" />
              ],
              [
                <Input name="postal_code" id="editLocationPopup_postal_code" maxLength={5} label="Postal code" />,
              ]
            ]}
            isInsidePopup
          />
        </Scrollable>
      </>
    </Popup>
  );
};

export default EditLocationPopup;
