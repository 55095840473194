
import { IUSPSAddress } from 'common/interfaces';
import { isNullOrUndefinedOrEmpty } from 'utils/helpers';
import { create } from 'xmlbuilder2';

const USER_ID = process.env.REACT_APP_USPS_USER_ID;

export const buildAddressXml = (address: IUSPSAddress): string => {
  const { Address1, Address2, City, State, Zip5 } = address;

  const root = create({ version: '1.0' })
    .ele('AddressValidateRequest', { USERID: USER_ID })
    .ele('Address')
    .ele('Address1').txt(Address1).up()
    .ele('Address2').txt(Address2).up()
    .ele('City').txt(City).up()
    .ele('State').txt(State).up()
    .ele('Zip5').txt(Zip5).up()
    .ele('Zip4').up()
    .up()
    .up();
  const xml = root.end({ prettyPrint: true });
  return xml;
};

/**
 * @description Moves addressLine2 to addressLine1, if addressLine1 is empty and addressLine2 is not
 * @param {IAddress} address can be missing addressLineOne property
 */
export const moveAddressLine2toline1 = (address: IUSPSAddress): IUSPSAddress => {
  if (isNullOrUndefinedOrEmpty(address?.Address1) && !isNullOrUndefinedOrEmpty(address?.Address2)) {
    return {
      ...address,
      Address1: address.Address2,
      Address2: address.Address1
    };
  } else if (!isNullOrUndefinedOrEmpty(address?.Address1) && !isNullOrUndefinedOrEmpty(address?.Address2)) {
    return {
      ...address,
      Address1: `${address.Address2} ${address.Address1}`,
      Address2: ""
    };
  }
  return address;
};

/**
 * @description Compare two addresses if they are equal
 * @param {IAddress} address2 can be missing addressLineOne property
 */
export const addressesAreEqual = (address1: IUSPSAddress, address2: IUSPSAddress) => {
  if (!addressLine1andAddressLine2equal(address1, address2)
    || address1?.Zip5?.toLowerCase() !== address2?.Zip5?.toLowerCase()
    || address1?.City?.toLowerCase() !== address2?.City?.toLowerCase()
    || address1?.State?.toLowerCase() !== address2?.State?.toLowerCase()) {
    return false;
  }
  return true;
}

const addressLine1andAddressLine2equal = (address1: IUSPSAddress, address2: IUSPSAddress): boolean => {

  if (address1?.Address1 && address2?.Address1 && address1?.Address2 && address2?.Address2) {
    return (address1?.Address1?.toLowerCase() === address2?.Address1?.toLowerCase()
      && address1?.Address2?.toLowerCase() === address2?.Address2?.toLowerCase())
      || (address1?.Address1?.toLowerCase() === address2?.Address2?.toLowerCase()
        && address1?.Address2?.toLowerCase() === address2?.Address1?.toLowerCase());
  }
  else if (address2?.Address1) {
    return address1?.Address1?.toLowerCase() === address2?.Address1?.toLowerCase()
      || address1?.Address2?.toLowerCase() === address2?.Address1?.toLowerCase();
  }
  else if (address2?.Address2) {
    return address1?.Address1?.toLowerCase() === address2?.Address2?.toLowerCase()
      || address1?.Address2?.toLowerCase() === address2?.Address2?.toLowerCase();
  }
  return false;
}

export const concatenateUSPSAddress = (address: IUSPSAddress): string => {
  return (address.Address1 ? address.Address1 + ", " : "") + (address.City ? address.City + ", " : "") + (address.State ? address.State + " " : "") + address.Zip5;
}
