import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { HeaderWL, Button, HelpButton } from "common";
import styles from "./SetupMerchant.module.scss";
import { useAppSelector } from "reducers/Hooks";

const SetupMerchant: React.FC = () => {
  const navigate = useNavigate();
  const whitelabel = useAppSelector(state => state.whitelabel);

  return (
    <>
      <HeaderWL />
      <main className={styles.setupMerchantContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="MERCHANT" />
            </Col>
          </Row>
        </Container>
        <div className={styles.contentContainer}>
          <div className={styles.colOne}>
            <h1>Already have an account?</h1>
            <h3>
              Already have an existing merchant account with {whitelabel?.name}? Continue
              by logging into the Client portal!
            </h3>
            <Button
              id="setupMerchant_login"
              type="button"
              label="Log into Client portal"
              variant="secondary"
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>
          <div className={styles.colTwo}>
            <h1>Become a merchant</h1>
            <h3>
              Follow a few easy steps, fill out the information and become a {whitelabel?.name} merchant today!
            </h3>
            <Button
              id="setupMerchant_startApplication"
              type="button"
              label="Start the application"
              onClick={() => {
                navigate("/merchantInvitation");
              }}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default SetupMerchant;
