import React from "react";
import { useAuth } from "auth/useAuth";
import { MerchantSettings, PartnerSettings, WhiteLabelSettings } from ".";

const Settings: React.FC = () => {
  const user = useAuth()?.user;

  return <>
    {user?.user_type === "MERCHANT" && <MerchantSettings />}
    {user?.user_type === "WHITELABEL" && <WhiteLabelSettings />}
    {user?.user_type === "PARTNER" && <PartnerSettings />}
  </>;
};

export default Settings;
