import { URLHandler } from "common";
import { NewUser } from "content/flows";
import { Route } from "react-router-dom";

const UserInvitationRoutes = () => {

  const getElement = () => {
    return <URLHandler
      requireParams={["invitationId"]}
      errorMessage="The link to your invitation has expired or is not valid. Please contact your referrer for a reinvite"
      redirectUrl={"/"}
      requireVisitorPass>
      <NewUser />
    </URLHandler>;
  }

  return <>
    <Route path="/newUser/userAccount" element={<NewUser />} />
    <Route path="/newUser/done" element={<NewUser />} />
    <Route path="/newUser/:invitationId" element={getElement()} />
    <Route path="/userInvitation/:invitationId" element={getElement()} />
  </>;
};

export default UserInvitationRoutes;