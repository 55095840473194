import { useAuth } from "auth/useAuth";
import Observer, { EVENTS } from "classes/Observer";
import { InfoContainer } from "common";
import strings from "localization/Strings";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { POPUPS } from "utils/constants";
import { Profile } from "iconsax-react";
import useMerchant from "hooks/useMerchant";

interface IUserAccountCard {
  cardTitle?: string;
}

const UserAccountCard: React.FC<IUserAccountCard> = ({ cardTitle }) => {
  const dispatch = useAppDispatch();
  const merchant = useMerchant();
  const user = useAuth()?.user;
  const [rows, setRows] = useState([]);
  const observer = Observer.useObserver(EVENTS.USER_ACCOUNT_UPDATED);

  useEffect(() => {
    if (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") {
      setRows([
        { label: "First name", value: user?.first_name },
        { label: "Last name", value: user?.last_name },
        { label: "Email", value: user.email },
        { label: "Update password", onClick: (): void => { dispatch(popUpActions.openPopup(POPUPS.UPDATE_PASSWORD)) } }
      ]);
    } else if (user?.user_type === "MERCHANT" && merchant) {
      const defaultLocation = _.find(merchant?.locations, location => { return location?.id === user?.primary_location });
      setRows([
        { label: "First name", value: user?.first_name },
        { label: "Last name", value: user?.last_name },
        { label: "Email", value: user?.email },
        { label: "Default location", value: defaultLocation?.name || strings.notAvailable },
        { label: "Update password", onClick: (): void => { dispatch(popUpActions.openPopup(POPUPS.UPDATE_PASSWORD)) } }
      ]);
    }
  }, [user, merchant, observer]);

  return <>
    {user && <InfoContainer
      id="user_account_card"
      title={cardTitle || "Your user account"}
      rows={rows}
      onClick={() => dispatch(popUpActions.openPopup(POPUPS.EDIT_USER_INFORMATION))}
      icon={Profile}
    />}
  </>;
}

export default UserAccountCard;