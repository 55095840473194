import React, { CSSProperties, ReactElement } from "react";
import styles from "./Popup.module.scss";

interface IPopupLocal {
  children: ReactElement;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  isInner?: boolean;
  showConfetti?: boolean;
  styleOverride?: CSSProperties;
}

const Popup: React.FC<IPopupLocal> = ({ children, maxWidth, isInner = false, minWidth, width, showConfetti, styleOverride }) => {
  return <>
    <div className={`${isInner ? styles.popupInner : styles.popup} ${showConfetti ? styles.confetti : ""}`}
      style={{
        maxWidth: maxWidth || "",
        minWidth: minWidth || "",
        zIndex: isInner ? 8 : 57,
        width: width || "",
        ...styleOverride
      }}>
      {children}
    </div>
  </>;
}

export default Popup;
