import { CloseIcon, BulkUploadSection } from "common";
import React from "react";
import { IFileData, IPopup } from "common/interfaces";
import { useAppSelector } from "reducers/Hooks";
import { isNullOrUndefined, isPopupOpened } from "utils/helpers";
import NewPopup from "./NewPopup";

interface IInvite extends IPopup {
  formTitle: string;
  bulkUploadTitle: string;
  disclaimerSend: string;
  disclaimerBulkSend?: string;
  form: object;
  reducerName?: string;
  template: Array<Array<string>>;
  onBulkSendClick: (data: IFileData) => void;
  onDownloadClick?: () => void;
  onCloseClick?: () => void;
  disableBulkInvitation?: boolean;
}

const InvitePopup: React.FC<IInvite> = ({ formTitle, disclaimerSend, bulkUploadTitle, onBulkSendClick,
  disclaimerBulkSend, form, reducerName, template, onDownloadClick, onCloseClick, disableBulkInvitation }) => {
  const displayModal = useAppSelector((state) => isPopupOpened(state, reducerName));

  return <>
    {displayModal && <NewPopup style={{ maxWidth: "90rem" }}>
      <>
        <CloseIcon
          id="invitePopup"
          color="dark"
          onClose={() => {
            if (onCloseClick) {
              onCloseClick();
            }
          }} />
        {/* TODO: style title */}
        <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{formTitle}</h2>
        {form}
        <p
          className="info"
          style={{
            textAlign: "right",
            maxWidth: "39rem",
            margin: disableBulkInvitation ? "0 0 1rem auto" : "3rem 0 1rem auto",
            color: "var(--darkTextColor)",
          }}>
          {disclaimerSend}
        </p>
        {(isNullOrUndefined(disableBulkInvitation) || !disableBulkInvitation) && <BulkUploadSection
          onSend={onBulkSendClick}
          template={template}
          title={bulkUploadTitle}
          disclaimerBulkSend={disclaimerBulkSend}
          onDownloadClick={onDownloadClick} />}
      </>
    </NewPopup>}
  </>;
};

export default InvitePopup;
