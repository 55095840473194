import { Form, Input } from "common/form";
import { IAddEntity } from "common/interfaces";
import React from "react";
import { EmailValidation, NameValidation, PhoneValidation, Schema } from "utils/validation/additionalValidation";

const MainContactInformation: React.FC<IAddEntity> = ({ onNext, initialValues }) => {

  return <>
    <Form
      id="dtm_application_main_contact_info"
      title="Business main contact information"
      submitBtnText="Next"
      onFormSubmit={(data: any) => { onNext(data); }}
      validationSchema={Schema({
        contact_first_name: NameValidation,
        contact_last_name: NameValidation,
        contact_email: EmailValidation,
        contact_phone: PhoneValidation
      })}
      values={initialValues}
      buttonPosition="bottom right"
      inputFields={[
        [
          <Input name="contact_first_name" id="dtm_application_contact_first_name" label="First name*" />,
          <Input name="contact_last_name" id="dtm_application_contact_last_name" label="Last name*" />,
        ],
        [
          <Input name="contact_email" id="dtm_application_contact_email" label="Email*" />,
          <Input name="contact_phone" id="dtm_application_contact_phone" label="Phone number*" mask="phone" />,
        ],
      ]}
      isInsidePopup
    />;
  </>;
}

export default MainContactInformation;