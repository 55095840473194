import { useAuth } from "auth/useAuth";
import styles from "./Settings.module.scss";
import { Breadcrumbs, Button, Table } from "common";
import React, { useEffect, useRef, useState } from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch } from "reducers/Hooks";
import { IList } from "common/interfaces";
import { useParams } from "react-router-dom";
import { POPUPS } from "utils/constants";
import {
  askForConfirmation,
  displayLoadingBar, displayMiniFeedback, getStatus, hideLoadingBar,
  isForMerchantGlobal, isForPartnerGlobal
} from "utils/helpers";
import { TableRef } from "common/Table";
import Observer, { EVENTS } from "classes/Observer";
import { MerchantHandler } from "actions/MerchantHandler";
import UserHandler from "actions/UserHandler";
import AuthHandler from "actions/AuthHandler";
import InvitationHandler from "actions/InvitationHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { PartnerHandler } from "actions/PartnerHandler";
import { formatAPIDate, formatFullname } from "utils/formatters";
import useMerchant from "hooks/useMerchant";
import usePartner from "hooks/usePartner";

const Users: React.FC = () => {
  const dispatch = useAppDispatch();
  const { merchantSlug, partnerSlug } = useParams();
  const user = useAuth()?.user;
  const merchant = useMerchant();
  const partner = usePartner();
  const observer = Observer.useObserver(EVENTS.USER_UPDATED);
  const observer2 = Observer.useObserver(EVENTS.INVITATION_UPDATED);

  const tableRef = useRef<TableRef>(null);
  const [headers, setHeaders] = useState([
    { label: "First Name", value: "first_name", size: 5 },
    { label: "Last Name", value: "last_name", size: 5 },
    { label: "Role", value: "group", size: 6 },
    { label: "Email", value: "email", size: 10 },
    { label: "Status", value: "status", size: 4 },
    { label: "Last login", value: "last_login", size: 5 },
    { label: "Actions", value: "actions", size: 7, preventSorting: true }
  ]);
  const [ready, setReady] = useState(false);
  const [isMerchantDeactivated, setIsMerchantDeactivated] = useState(false);
  const [isPartnerDeactivated, setIsPartnerDeactivated] = useState(false);

  useEffect(() => {
    if (merchant || partner) {
      Promise.all([getStatus("Deactivated"), getStatus("Declined")])
        .then(responses => {
          if (merchant) {
            setIsMerchantDeactivated(merchant?.status === responses[0] || merchant?.status === responses[1]);
          }
          if (partner) {
            setIsPartnerDeactivated(partner?.status === responses[0] || partner?.status === responses[1]);
          }
        });
    }
  }, [merchant, partner]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [observer, observer2]);

  useEffect(() => {
    if (user) {
      displayLoadingBar();
      if (!isForMerchant() && !isForPartner()) {
        setReady(true);
        hideLoadingBar();
      } else if (isForMerchant()) {
        MerchantHandler().get(merchantSlug || merchant?.slug)
          .then(() => {
            const headers: any = [
              { label: "First Name", value: "first_name", size: 5 },
              { label: "Last Name", value: "last_name", size: 5 },
              { label: "Role", value: "group", size: 4 },
              { label: "Email", value: "email", size: 11 },
              { label: "Status", value: "status", size: 4 },
              { label: "Locations", value: "primary_location", size: 5 },
              { label: "Last login", value: "last_login", size: 5 },
            ];
            if (user?.user_type !== "PARTNER") {
              headers.push({ label: "Actions", value: "actions", size: 7, preventSorting: true });
            }
            setHeaders(headers);
            setReady(true);
            hideLoadingBar();
          });
      } else if (isForPartner()) {
        PartnerHandler().get(partnerSlug || partner?.slug)
          .then(() => {
            setHeaders([
              { label: "First Name", value: "first_name", size: 5 },
              { label: "Last Name", value: "last_name", size: 5 },
              { label: "Role", value: "group", size: 4 },
              { label: "Email", value: "email", size: 11 },
              { label: "Status", value: "status", size: 4 },
              { label: "Last login", value: "last_login", size: 5 },
              { label: "Actions", value: "actions", size: 7, preventSorting: true }
            ])
            setReady(true);
            hideLoadingBar();
          });
      }
    }
  }, [user]);

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  const isForPartner = () => {
    return isForPartnerGlobal(user, partnerSlug, merchantSlug);
  }

  const getListUsers = async (next: string): Promise<IList> => {
    let list = await UserHandler.getAll(next,
      user?.user_type, merchant && merchantSlug ? merchant?.id : null,
      partner && partnerSlug ? partner?.id : null);
    list.results = list.results.map(result => {
      return {
        ...result,
        name: formatFullname(result?.first_name, result?.last_name),
        group: getRole(result.group),
        primary_location_id: result.primary_location?.id,
        primary_location: result.primary_location?.name,
        last_login: formatAPIDate(result.last_login)
      }

    });
    return list;
  }

  const getRole = (group: string): string => {
    return group?.replace('White Label ', '').replace('Merchant ', '').replace('Partner ', '') || '';
  }

  const resetPassword = async (data: any) => {
    await AuthHandler.resetPassword(data?.email);
    Analytics.track({ experience: "portal", screen: getScreenName(), object: "password_reset_email_action", action: "successful" } as ITracking);
    displayMiniFeedback("Password reset request sent");
    dispatch(popUpActions.closePopup());
  };

  const getScreenName = () => {
    if (!partnerSlug && !merchantSlug) {
      return "settings_users";
    } else if (partnerSlug) {
      return "partners_users"
    } else {
      return "merchants_users";
    }
  }

  const handleStatusChange = (data: any, newStatus: "Active" | "Deactivated") => {
    if (data.id) {
      if (isForMerchant()) {
        UserHandler.changeStatusMerchantUser(data.id, newStatus)
          .then(() => {
            Analytics.track({ experience: "portal", screen: getScreenName(), object: "user", action: newStatus === "Active" ? "activated" : "deactivated" } as ITracking);
            displayMiniFeedback(`User is now ${newStatus.toLowerCase()}`);
            dispatch(popUpActions.closePopup());
            Observer.trigger(EVENTS.USER_UPDATED);
          });
      } else if (isForPartner()) {
        UserHandler.changeStatusPartnerUser(data.id, newStatus, partner?.id)
          .then(() => {
            Analytics.track({ experience: "portal", screen: getScreenName(), object: "user", action: newStatus === "Active" ? "activated" : "deactivated" } as ITracking);
            displayMiniFeedback(`User is now ${newStatus.toLowerCase()}`);
            dispatch(popUpActions.closePopup());
            Observer.trigger(EVENTS.USER_UPDATED);
          });
      } else {
        UserHandler.changeStatusWhiteLabelUser(data.id, newStatus)
          .then(() => {
            Analytics.track({ experience: "portal", screen: getScreenName(), object: "user", action: newStatus === "Active" ? "activated" : "deactivated" } as ITracking);
            displayMiniFeedback(`User is now ${newStatus.toLowerCase()}`);
            dispatch(popUpActions.closePopup());
            Observer.trigger(EVENTS.USER_UPDATED);
          });
      }
    }
  };

  return <div className={styles.contentContainer}>
    <div className={styles.titleContainer}>
      {user?.user_type === "WHITELABEL" && merchantSlug && merchant && merchant?.name && <Breadcrumbs pageTitle={`${merchant?.name} users`} />}
      {user?.user_type === "WHITELABEL" && partnerSlug && partner && <h2 className={styles.pageTitle}>{`${partner?.name} users`}</h2>}
      <Button
        type="button"
        id="btn"
        label="Invite user"
        onClick={() => {
          Analytics.track({ experience: "portal", screen: getScreenName(), object: "invite_user_button", action: "clicked" } as ITracking);
          dispatch(popUpActions.openPopup(POPUPS.INVITE_USER));
        }}
      />
    </div>
    {ready && <div className={styles.tableContainer}>
      <Table
        maxHeight={450}
        data={getListUsers}
        headers={headers}
        id="userData"
        ref={tableRef}
        tableBodyStyle={{ minWidth: 1000 }}
        action={[
          {
            label: "Edit", value: (item) => {
              dispatch(popUpActions.openPopup({ name: POPUPS.EDIT_USER, message: item }));
              return Promise.resolve();
            },
            visible: (item: any) => {
              if (item.status !== "DEACTIVATED" &&
                ((isForMerchant() && !isMerchantDeactivated) ||
                  (isForPartner() && !isPartnerDeactivated) ||
                  (!isForMerchant() && !isForPartner()))) {
                return true;
              }
            }
          },
          {
            label: "Password reset", value: (item) => {
              askForConfirmation(`Are you sure you want to send a password reset email to ${formatFullname(item?.first_name, item?.last_name)}?`,
                { text: 'Yes, I do', action: () => { resetPassword(item); } },
                { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
              return Promise.resolve();
            },
            visible: (item: any) => {
              if ((item.status !== "INVITED" &&
                item.status !== "DEACTIVATED" &&
                item.status !== "CANCELED") &&
                ((isForMerchant() && !isMerchantDeactivated) ||
                  (isForPartner() && !isPartnerDeactivated) ||
                  (!isForMerchant() && !isForPartner()))) {
                return true;
              }
            }
          }, // TODO: create a popup
          // { label: "Log in as user", value: () => { } }, // TODO: Not MVP
          {
            label: "Deactivate", value: (item) => {
              askForConfirmation(`Are you sure you want to deactivate ${formatFullname(item?.first_name, item?.last_name)}?`,
                { text: 'Yes, deactivate', action: () => { handleStatusChange(item, "Deactivated"); } },
                { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
              return Promise.resolve();
            },
            visible: (item: any) => {
              if (item.status === "ACTIVE") {
                return true;
              }
            }
          },
          {
            label: "Activate",
            value: (item) => {
              askForConfirmation(`Are you sure you want to activate ${formatFullname(item?.first_name, item?.last_name)}?`,
                { text: 'Yes, I do', action: () => { handleStatusChange(item, "Active"); } },
                { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
              return Promise.resolve();
            },
            visible: (item: any) => {
              if (item.status === "DEACTIVATED") {
                return true;
              }
            }
          },
          {
            label: "Resend",
            value: (item: any) => {
              return InvitationHandler.resendOrCancelUserInvitation(user, item?.id, "Resend")
                .then(() => {
                  displayMiniFeedback("Invitation has been sent");
                });
            },
            visible: (item: any) => {
              if (item.status === "INVITED" || item.status === "CANCELED") {
                return true;
              }
            }
          },
          {
            label: "Cancel",
            value: (item) => {
              return InvitationHandler.resendOrCancelUserInvitation(user, item?.id, "Cancel")
                .then(() => {
                  Analytics.track({ experience: "portal", screen: getScreenName(), object: "invitation_cancel_action", action: "successful" } as ITracking);
                });
            },
            visible: (item: any) => {
              if (item.status === "INVITED") {
                return true;
              }
            }
          }
        ]}
        minDropdownWidth={140}// based on "Password reset"
      />
    </div>}
  </div>;
};

export default Users;
