import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { displayFullscreenLoading, getSubdomainIfAny, hideFullscreenLoading, isSmallScreen } from "utils/helpers";
import strings from "localization/Strings";
import SystemHandler from "actions/SystemHandler";
import popUpActions from "reducers/PopUpReducer";
import appActions from "reducers/AppReducer";
import merchantActions from "reducers/MerchantReducer";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import { colorOverlayFavicon } from "utils/colors";
import Log from "classes/Log";

export function useApp() {
  const dispatch = useAppDispatch();
  const [wlCalled, setWlCalled] = useState<boolean>(false); // prevents app from rendering with default colors for a split second
  const whitelabel = useAppSelector(state => state.whitelabel);
  const wlSubdomain = whitelabel?.slug;
  const wlColor = whitelabel?.primary_color;

  useEffect(() => {
    try {
      // find navigator language
      const navLanguage = navigator.language.split("-")[0];
      strings.setLanguage(navLanguage);
    } catch (error) {
      strings.setLanguage("en");
    }

    SystemHandler.getSupportInfo();
    dispatch(popUpActions.initializePopups());
    dispatch(merchantActions.initializeMaxLoanAmount());
    dispatch(appActions.initializeTableFilters());
    dispatch(popUpActions.closePopup());

    // display loading animation
    displayFullscreenLoading();

    // find subdomain
    const subdom = getSubdomainIfAny();
    if (subdom) {
      // Simulate API call to get colors
      WhiteLabelHandler.get(subdom, true)
        .then(wl => {
          window.document.title = wl?.name || "Affordable Financing";
          colorOverlayFavicon(`#${wl?.primary_color || "ffffff"}`);
          // Hide loading animation
          hideFullscreenLoading();
        })
        .catch((error) => {
          Log.error(error);
          hideFullscreenLoading();
        });
    } else {
      hideFullscreenLoading();
      setWlCalled(true);
    }
  }, [dispatch]);

  useEffect(() => {
    // on localhost etc.
    if (wlSubdomain === "") {
      setWlCalled(true);
    }
  }, [wlSubdomain]);

  useEffect(() => {
    if (wlSubdomain) {
      setWlCalled(true);
    }
    // eslint-disable-next-line
  }, [wlColor]);

  return { wlCalled };
}