// this isn't used anywhere on 18/07/2023

import styles from "./BasicInfoPopup.module.scss";
import { useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { CloseIcon } from "common";

const InstructionPopup: React.FC<IPopup> = () => {
  const whitelabel = useAppSelector(state => state.whitelabel);
  const logo = whitelabel?.primary_logo;
  const name = whitelabel?.name;

  return (
    <Popup>
      <div className={styles.popupContainer}>
        <div className={styles.popupHeader}>
          <div>
            <img src={logo} alt={name} />
            <h3>{`${name} instructions`}</h3>
          </div>
          <CloseIcon id="editUserAssociationPopup" color="light" />
        </div>
        <div className={styles.popupInfo}>
          <div className={styles.contactInfo}>
            <p>
              Please edit the location address and make sure that the postal code is
              correct. After that please resubmit.
            </p>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default InstructionPopup;
