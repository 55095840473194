import SystemHandler from "actions/SystemHandler";
import OptimizedForm from "common/form/OptimizedForm";
import { IAddEntity, IFieldDefinition } from "common/interfaces";
import React, { useEffect, useState } from "react";
import { BusinessValidation } from "utils/validation/additionalValidation";

const BusinessInformationForm: React.FC<IAddEntity> = ({ onNext, initialValues }) => {

  const [annualSalesOptions, setAnnualSalesOptions] = useState([]);
  const [monthlyFinanceOptions, setMonthlyFinanceOptions] = useState([]);
  const [averageTicketOptions, setAverageTicketOptions] = useState([]);
  const [industrySpecialtyOptions, setIndustrySpecialtyOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMonthlyFinanceVolume(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty()
    ]).then(responses => {
      setAnnualSalesOptions(responses[0]);
      setMonthlyFinanceOptions(responses[1]);
      setAverageTicketOptions(responses[2]);
      setIndustryOptions(responses[3]);
      setIndustrySpecialtyOptions(responses[4]);
    });
  }, []);

  const handleChange = (name: string, value: string) => {
    if (name === "industry") {
      if (!value) {
        setSpecialtyOptions([]);
      } else {
        setSpecialtyOptions(industrySpecialtyOptions.filter(specialty => { return specialty.industry === parseInt(value) }));
      }
    }
  }

  const fields: IFieldDefinition[] = [
    { label: 'Business name*', name: 'name', type: 'text', validation: BusinessValidation.name },
    { label: 'Website*', name: 'website', type: 'text', validation: BusinessValidation.website },
    { label: 'External ID', name: 'external_id', type: 'text' },
    { label: 'Annual sales', name: 'annual_sales', type: 'select', options: annualSalesOptions },
    { label: 'Est. monthly finance volume', name: 'monthly_financing_volume', type: 'select', options: monthlyFinanceOptions },
    { label: 'Average ticket', name: 'average_ticket', type: 'select', options: averageTicketOptions },
    { label: 'Industry', name: 'industry', type: 'select', options: industryOptions, onChange: handleChange },
    { label: 'Specialty', name: 'specialty', type: 'select', options: specialtyOptions },
  ];

  const handleSubmit = (data: any) => {
    onNext(data);
  }

  return <OptimizedForm fields={fields} onSubmit={handleSubmit} title="Add merchant • Business information" />;
}

export default BusinessInformationForm;