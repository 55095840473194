import Landing from 'common/Landing';
import { useEffect } from 'react';
import { useAppSelector } from 'reducers/Hooks';
import { useAuth } from 'auth/useAuth';
import { displayFeedback, getQueryString } from 'utils/helpers';
import { IFeedback } from 'common/interfaces';
import { useNavigate } from 'react-router-dom';

const WhiteLabelLanding: React.FC = () => {
  const navigate = useNavigate();
  const logout = useAuth()?.logout;
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);

  useEffect(() => {
    if (user?.id) {
      logout(false);
    }
  }, [useAuth]);

  useEffect(() => {
    const params = getQueryString(`${window.location.pathname}${window.location.search}`);
    if (params?.m) {
      displayFeedback({
        title: "Invitation expired",
        body: params.m,
        type: "USER"
      } as IFeedback);
      navigate(window.location.pathname, { replace: true });
    }
  }, []);

  return (
    <>
      {whitelabel && <Landing
        id="whitelabelLanding"
        title={`Give your customers the power of choice with ${whitelabel.name}`}
        subtitle="Don't let a lack of financing options hold your business back! We provide access to over 20 lenders to help you convert more customers."
        hero="/assets/images/whitelabel_default.png"
        ctaLink={`../merchantInvitation/businessInformation`}
        titleBelowFold="Unlock more sales with our flexible financing solutions"
        feat1="Terms up to 144 months"
        feat2="Rates starting at 5.99%"
        feat3="Loans up to $100,000"
        feat4="Access to 20+ lenders"
        stepsTitle="How it works"
        step1="Your customer fills out a simple, mobile-friendly application"
        step2="A real-time decision is made from 20+ lenders"
        step3="Your customer chooses the offer that best fits their budget"
        step4="Once completed, your customer will be funded directly"
        type="WHITELABEL"
      />}
    </>
  );
};

export default WhiteLabelLanding;