import { Form, Input, SelectDropdown } from "common/form";
import { IDTCFinancialInfo, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import { AnnualIncomeValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import { useEffect, useState } from "react";
import { askForConfirmation, scrollTop } from "utils/helpers";
import BorrowerHandler from "actions/BorrowerHandler";
import LocalStorage from "classes/LocalStorage";
import SystemHandler from "actions/SystemHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { stripOutNonDigits } from "utils/formatters";
import popUpActions from "reducers/PopUpReducer";
import useGetLoanApplication from "hooks/useGetLoanApplication";

const DTCFinancialInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();

  const [employmentStatusOptions, setEmploymentStatusOptions] = useState([]);
  const [payFrequencyOptions, setPayFrequencyOptions] = useState([]);
  const [creditRatingOptions, setCreditRatingOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    employment_status: "",
    pay_frequency: "",
    annual_income: 0,
    credit_rating: "",
  });

  useGetLoanApplication();

  useEffect(() => {
    Promise.all([
      SystemHandler.getEmploymentStatusOptions(),
      SystemHandler.getPayFrequencyOptions(),
      SystemHandler.getCreditRatingOptions()
    ]).then(responses => {
      setEmploymentStatusOptions(responses[0]);
      setPayFrequencyOptions(responses[1]);
      setCreditRatingOptions(responses[2]);
    });
    scrollTop();
  }, []);

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const applicationLocked = LocalStorage.get<boolean>("application_locked");

  const dtcFinancialInformation = useAppSelector(state => state.borrower.dtcFinancialInformation);
  useEffect(() => {
    if (dtcFinancialInformation) {
      setInitialValues(dtcFinancialInformation);
    }
  }, [dtcFinancialInformation]);

  const handleSubmit = async (data: IDTCFinancialInfo) => {
    data = {
      ...data,
      annual_income: parseInt(stripOutNonDigits((data.annual_income || 0).toString()))
    }

    if (data.annual_income < 15000) {
      askForConfirmation(`Are you sure that you have entered the annual (yearly) amount and not the monthly amount?`,
        { text: 'Yes, I am sure', action: () => { dispatch(popUpActions.closePopup()); goToNextScreen(data) } },
        { text: 'No, take me back', action: () => { dispatch(popUpActions.closePopup()) } }, true, null,
        `Looks like the annual amount is under $15,000`,
        "/assets/images/annual_amount.svg"
      );
    } else if (data.annual_income > 500000) {
      askForConfirmation(`Are you sure that you have entered the annual (yearly) amount?`,
        { text: 'Yes, I am sure', action: () => { dispatch(popUpActions.closePopup()); goToNextScreen(data) } },
        { text: 'No, take me back', action: () => { dispatch(popUpActions.closePopup()) } }, true, null,
        `Looks like the annual amount is over $500,000`,
        "/assets/images/annual_amount.svg"
      );
    } else {
      goToNextScreen(data);
    }
  }

  const goToNextScreen = async (data: IDTCFinancialInfo) => {
    if (applicationId && vuid) {
      const response = await BorrowerHandler.saveFinancialInformation(data, applicationId, vuid);
      if (response) {
        dispatch(actions.setDTCFinancialInformation(data));
        onNext();
      }
    }
  }

  const handleAnnualIncomeTooltipHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_financial_info", object: "income_info", action: "hover" } as ITracking, null);
  }

  return (
    <Form
      id="borrowerExperience_financialInformation"
      title="Financial information"
      submitBtnText="Next"
      onFormSubmit={handleSubmit}
      validationSchema={Schema({
        employment_status: SimpleRequireValidation,
        pay_frequency: SimpleRequireValidation,
        annual_income: AnnualIncomeValidation,
        credit_rating: SimpleRequireValidation,
      })}
      values={initialValues}
      overrideValues={dtcFinancialInformation}
      buttonPosition="bottom right"
      inputFields={[
        [
          <SelectDropdown
            id="borrowerExperience_employment_status"
            label="Employment status"
            name="employment_status"
            placeholder="Select"
            selectOptions={employmentStatusOptions}
          />,
          <SelectDropdown
            id="borrowerExperience_pay_frequency"
            label="Pay frequency"
            name="pay_frequency"
            placeholder="Select"
            selectOptions={payFrequencyOptions}
          />,
        ],
        [
          <Input
            type="text"
            name="annual_income"
            id="borrowerExperience_annual_income"
            label="Annual income"
            mask="amount"
            inputPrefix="$"
            tooltip="List your total available income including wages, retirement, investments, and rental properties. Alimony, child support or separate maintenance is optional and does not need to be included if you do not wish it to be considered as a basis for repaying the loan. Increase any non-taxable income or benefits by 25%."
            onTooltipHover={handleAnnualIncomeTooltipHover}
          />,
          < SelectDropdown
            id="borrowerExperience_credit_rating"
            label="Credit rating"
            name="credit_rating"
            placeholder="Select"
            selectOptions={creditRatingOptions}
          />
        ]
      ]}
      onBackClick={onBack}
      disabledInputFields={applicationLocked}
    />
  );
};

export default DTCFinancialInformation;
