import React from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { Switch } from "common";
import { formatAPICurrency } from "utils/formatters";
import { IDataChart } from "common/interfaces";

const DataChart: React.FC<IDataChart> = ({ importantInfo, data, series1Label, series2Label,
  onSwitchChange, isAmount, switchButtonTopMargin }) => {

  const style = {
    fontSize: "1.6rem",
    lineHeight: "2rem",
    fontWeight: "600",
    color: "var(--primaryVariationTextColor)"
  }

  return <>
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={509}
        height={400}
        data={data}
        margin={{
          top: 0,
          right: 22,
          left: -40,
          bottom: 0,
        }}
      >
        <Legend
          verticalAlign="top"
          wrapperStyle={{ left: "2rem", width: "auto", paddingBottom: "1.5rem" }}
          layout={"vertical"}
          iconType={"circle"}
          iconSize={10}
        />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{
            fontWeight: "600",
            fontSize: "1.3rem",
            fill: "var(--darkTextColor)",
          }}
        />
        <YAxis axisLine={false} tickLine={false} tick={false} />
        <Tooltip
          wrapperStyle={{ outline: "none" }}
          cursor={{ strokeDasharray: "3 3" }}
          content={({ active, payload }: any) => {
            if (active && payload && payload.length) {
              return (
                <div className="customTooltip">
                  <p className="label">{`${series1Label}: ${isAmount ? formatAPICurrency(payload[0].value) : payload[0].value}`}<br />{`${series2Label}: ${isAmount ? formatAPICurrency(payload[1].value) : payload[1].value}\n`}</p>
                </div>
              );
            }
          }}
        />
        <Area
          type="monotone"
          dataKey="series1"
          name={series1Label}
          stroke="var(--greenColor)"
          fill="var(--greenColor)"
          strokeOpacity={1}
          activeDot={{
            fill: "var(--darkTextColor20)",
            fontSize: "4rem",
            stroke: "none",
          }}
        />
        <Area
          type="monotone"
          dataKey="series2"
          name={series2Label}
          stroke="var(--primaryTextColor)"
          fill="none"
          strokeWidth={3}
          strokeOpacity={1}
          activeDot={{
            fill: "var(--darkTextColor)",
            fontSize: "4rem",
            stroke: "none",
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
    {importantInfo && <span style={{ color: "var(--darkTextColor)", fontWeight: 400, fontSize: "1.1rem" }}>* {importantInfo}</span>}
    <div style={{ position: "absolute", top: switchButtonTopMargin || 0, right: 30, display: "flex", gap: "1rem" }}>
      <span style={style}>#</span>
      <Switch checked={isAmount} onChange={(event: any, index: number) => { onSwitchChange(event) }} index={0} alwaysOn />
      <span style={style}>$</span>
    </div>
  </>;
};

export default DataChart;
