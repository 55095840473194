// this isn't used anywhere on 18/07/2023

import styles from "./LandingPageSettingsPopup.module.scss";
import { Button, CloseIcon, Grid } from "common";
import { Input, FormikError } from "common/form";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { displayMiniFeedback, getDomainFromHostname, isForMerchantGlobal, isForPartnerGlobal } from "utils/helpers";
import { DOMAIN_PREFIX } from "utils/constants";
import popUpActions from "reducers/PopUpReducer";
import { useNavigate, useParams } from "react-router-dom";
import { MerchantHandler } from "actions/MerchantHandler";
import { useAuth } from "auth/useAuth";
import { Schema, URLFriendlyText } from "utils/validation/additionalValidation";
import { PartnerHandler } from "actions/PartnerHandler";
import useMerchant from "hooks/useMerchant";

const LandingPageUrlSettingsPopup: React.FC<IPopup> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { merchantSlug, partnerSlug } = useParams();
  const user = useAuth()?.user;
  const merchant = useMerchant();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const [ready, setReady] = useState(false);

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  const isForPartner = () => {
    return isForPartnerGlobal(user, partnerSlug);
  }

  useEffect(() => {
    setReady(false);
    if (isForMerchant()) {
      MerchantHandler().get(merchantSlug)
        .then(() => {
          formik.setValues({
            domain: merchantSlug || merchant?.slug
          });
          setReady(true);
        });
    } else if (isForPartner()) {
      formik.setValues({
        domain: partnerSlug
      });
      setReady(true);
    } else if (user?.user_type === "WHITELABEL") {
      formik.setValues({
        domain: whitelabel?.slug
      });
      setReady(true);
    }
  }, [merchantSlug, partnerSlug]);

  const formik = useFormik({
    initialValues: {
      domain: ""
    },
    validationSchema: Schema({
      domain: URLFriendlyText
    }),
    async onSubmit(values) {
      if (isForMerchant()) {
        MerchantHandler().patch(merchantSlug || merchant?.slug, { slug: values.domain })
          .then(() => {
            dispatch(popUpActions.closePopup());
            displayMiniFeedback("Landing page URL was updated");
            if (user?.user_type === "WHITELABEL") {
              navigate(`/viewMerchant/${values.domain}/accountInformation`);
            } else {
              navigate(`/settings/landingPageSettings`);
            }
          });
      } else if (isForPartner()) {
        PartnerHandler().patch(partnerSlug, { slug: values.domain })
          .then(() => {
            dispatch(popUpActions.closePopup());
            displayMiniFeedback("Landing page URL was updated");
            if (user?.user_type === "WHITELABEL") {
              navigate(`/viewPartner/${values.domain}/accountInformation`);
            } else {
              // TODO: change that - means that partner is changing their settings
              navigate(`/settings/landingPageSettings`);
            }
          });
      }
    }
  });

  return (
    <Popup isInner={true}>
      {ready && <>
        {/* <div className={`${styles.popupContainer}`} style={{ width: isForMerchant() ? 800 : 600 }}> */}
        <CloseIcon id="landingPageSettingsPopup" color="dark" />
        <form onSubmit={formik.handleSubmit}>
          <h2>Landing page URL</h2>
          <div className={styles.inviteFormContainer}>
            <div className={styles.inviteFormRow}>
              <Grid horizontal style={{ gap: 0 }}>
                <Grid item style={{ width: 'auto', whiteSpace: 'nowrap', marginTop: 22 }}>
                  {(isForMerchant() || isForPartner()) && <span className={styles.domain} style={{ marginLeft: 0, marginRight: 4 }}>{`${DOMAIN_PREFIX}`}</span>}
                  {!isForMerchant() && !isForPartner() && <span className={styles.domain}>{`${getDomainFromHostname()}/merchants`}</span>}
                </Grid>
                <Grid item>
                  <Input
                    type="text"
                    name="domain"
                    id="landingPageSettingsPopup_domain"
                    onChange={(e: any) => {
                      let value = e.target.value;
                      formik.setFieldValue("domain", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.domain}
                  />
                  {!isForMerchant() && !isForPartner() && <span className={styles.domain}>{partnerSlug}</span>}
                  <FormikError propertyName="domain" formik={formik} />
                </Grid>
              </Grid>
            </div>
            <div className={styles.btnContainer}>
              <Button id="landingPageSettingsPopup_submit" type="submit" label="Save" />
            </div>
          </div>
        </form>
        {/* </div> */}
      </>}
    </Popup>
  );
};

export default LandingPageUrlSettingsPopup;
