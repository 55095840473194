import React from 'react';
import styles from './MemoizedField.module.scss';

interface IMemoizedField {
  label: string;
  name: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  error?: string;
  options?: any[];
}

const MemoizedField: React.FC<IMemoizedField> = ({ label, name, type, value, onChange, error, options }) => {
  return (
    <div className={styles.formGroup}>
      <label htmlFor={name}>{label}</label>
      {type === 'select' ? (
        <select
          name={name}
          value={value}
          onChange={onChange}
          className={`${styles["custom-select"]} ${error ? styles.error : ""}`}>
          <option value="">Select</option>
          {options?.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className={error ? styles.error : ''}
        />
      )}
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

export default React.memo(MemoizedField, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error &&
    prevProps.options === nextProps.options
  );
});
