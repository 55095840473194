import styles from "./Settings.module.scss";
import { Sidebar, DashboardHeader, Aside } from "common";
import React from "react";
import WhiteLabelSettingsView from "./settings/whiteLabel/WhiteLabelSettingsView";
import InnerPopups from "content/popups/InnerPopups";

const WhiteLabelSettings: React.FC = () => {

  return <main className={styles.settingsPageContainer}>
    <Sidebar />
    <Aside style={{ position: "relative", paddingTop: 0 }}>
      <DashboardHeader />
      <InnerPopups />
      {<WhiteLabelSettingsView />}
    </Aside>
  </main>;
};

export default WhiteLabelSettings;
