import { SVG } from "common";
import React from "react";
import _ from "lodash";
import styles from "./Chart.module.scss";
import { parseChartData } from "utils/helpers";
import { DataChart, LineBarChart, LoadingChart } from ".";

export interface IApplicationActivityChart {
  data: any;
  onSwitchChange: (isAmount: boolean) => void;
  isAmount: boolean;
  wrapped: boolean;
}

const ApplicationActivityChart: React.FC<IApplicationActivityChart> = ({ data, onSwitchChange, isAmount, wrapped }) => {

  const handleChange = (event: any) => {
    onSwitchChange(event.target.checked);
  }

  return <div className={`${styles.graphContainer} ${wrapped ? styles.wrapped : ''}`}
    style={{ marginBottom: "20px" }}
  >
    {!wrapped && <h4>Application Activity (last 7 days)</h4>}
    {data && <div className={styles.graphInnerContainer}>
      {_.find(data, item => { return item.submitted > 0 || item.funded > 0 }) && <DataChart
        data={parseChartData(data, "submitted", "funded")}
        series1Label="Submitted"
        series2Label="Funded"
        isAmount={isAmount}
        switchButtonTopMargin={wrapped ? 0 : -40}
        onSwitchChange={handleChange} />}
      {_.find(data, item => { return item.submitted > 0 || item.funded > 0 }) == null && <div className={styles.emptyImg}>
        <SVG
          src="/assets/dashboard/empty_application_activity.svg"
          title="Empty Application Activity"
          height={200}
        />
      </div>
      }
    </div>}
    {!data && <div className={styles.graphInnerContainer}>
      <div style={{ visibility: "hidden" }}>
        <LineBarChart
          data={parseChartData({ FRI: { submitting: 1, added: 1 } }, "added", "submitting")}
          series1Label="Added"
          series2Label="Submitting" />
      </div>
      <LoadingChart type="line" />
    </div>}
  </div >;
}

export default ApplicationActivityChart;