import { DeactivatedPartnerHandler } from 'actions/ErrorHandler';
import { PartnerHandler } from 'actions/PartnerHandler';
import { useAuth } from 'auth/useAuth';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducers/Hooks';
import partnerActions from "reducers/PartnerReducer";

const usePartner = (partnerSlug?: string, forceReload?: boolean, callback?: () => void) => {
  const dispatch = useAppDispatch();
  const partner = useAppSelector((state) => state.partner);
  const user = useAuth()?.user;
  const slugFromUrl = useParams()?.partnerSlug;
  const [value, setValue] = React.useState(partner);

  useEffect(() => {
    const fetchData = async () => {
      if (partnerSlug || (user?.user_type === "PARTNER" && user?.customer?.slug) || slugFromUrl) {
        const response = await PartnerHandler(DeactivatedPartnerHandler).get(partnerSlug || (user?.user_type === "PARTNER" && user?.customer?.slug) || slugFromUrl);
        dispatch(partnerActions.update(response));
        if (callback) {
          callback();
        }
      }
    };

    let partnerNotMatchingTheSlug = false;
    if (slugFromUrl && (partner?.slug !== slugFromUrl)) {
      partnerNotMatchingTheSlug = true;
    }

    if (!partner?.id || forceReload || partnerNotMatchingTheSlug) {
      fetchData();
    }

    setValue(partner);
  }, [partner, dispatch, partnerSlug, user, slugFromUrl]);

  return value;
};

export default usePartner;
