import { useEffect } from "react";
import { useAppDispatch } from "reducers/Hooks";
import animationActions from "reducers/AnimationReducer";

export const useLoadingBar = (display: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((display !== null && display !== undefined && display !== false) || display === true) {
      dispatch(animationActions.fadeOutLoadingBar());
    } else {
      dispatch(animationActions.fadeInLoadingBar());
    }
  }, [display]);
};