import Log from "classes/Log";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    Log.error(error, info.componentStack);
  }

  render() {
    if (this.state["hasError"]) {
      // You can render any custom fallback UI
      return <span>We are sorry! This component is not rendering properly.</span>;
    }

    return this.props["children"];
  }
}

export default ErrorBoundary;