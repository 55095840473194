import styles from "./Entities.module.scss";
import {
  Button, Table, Sidebar, DashboardHeader, Grid, ExportButton,
  Aside, SearchBar, FilterButton, TimeZoneMessage
} from "common";
import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import appActions from "reducers/AppReducer";
import { IHeader, IHighlight, IList, IFilter, IFeedback } from "common/interfaces";
import { POPUPS } from "utils/constants";
import moment from "moment";
import { SearchBarRef } from "common/SearchBar";
import { TableRef } from "common/Table";
import { useAuth } from "auth/useAuth";
import { useNavigate } from "react-router-dom";
import Observer, { EVENTS } from "classes/Observer";
import DashboardHandler from "actions/DashboardHandler";
import { DateRangeDropdownRef } from "common/DateRangeDropdown";
import Analytics, { ITracking } from "classes/Analytics";
import { EmptySpace } from "common/form";
import { formatAPICurrency, formatAPIDate } from "utils/formatters";
import MerchantInformationCard from "./MerchantInformationCard";
import { MerchantHandler } from "actions/MerchantHandler";
import { askForConfirmation, displayFeedback, displayMiniFeedback } from "utils/helpers";
import InnerPopups from "content/popups/InnerPopups";

const Merchants: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAuth()?.user;
  const observerMerchantUpdated = Observer.useObserver(EVENTS.MERCHANT_UPDATED);
  const tableFilterAppliedObserver = Observer.useObserver(EVENTS.TABLE_FILTER_APPLIED);
  const merchantFilter = useAppSelector(state => state.app.tableFilters.merchant);
  const dateRangeRef = useRef<DateRangeDropdownRef>(null);

  const [tableRows, setTableRows] = useState<Array<any>>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [preventPagination, setPreventPagination] = useState(false);

  const observerBusinessModel = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);

  const searchBarRef = useRef<SearchBarRef>(null);
  const tableRef = useRef<TableRef>(null);

  const [merchantSlug, setMerchantSlug] = useState<string>("");

  const merchantTableHeaders: Array<IHeader> = [
    { label: "Name", value: "name", size: 15 },
    { label: "Status", value: "status", size: 8 },
    { label: "Last app", value: "last_application_at", size: 8 },
    { label: "# Submited", value: "submitted_count", size: 7 },
    { label: "# Funded", value: "funded_count", size: 6 },
    { label: "$ Requested", value: "requested_total", size: 9 },
    { label: "$ Funded", value: "funded_total", size: 9 },
  ];

  useEffect(() => {
    if (observerBusinessModel > 1) {
      window.location.reload();
    }
  }, [observerBusinessModel]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [observerMerchantUpdated]);

  useEffect(() => {
    if (keyword) {
      Analytics.track({ experience: "portal", screen: "merchants", object: "search", action: "initiated" } as ITracking);
    }
  }, [keyword]);

  const handleShowSummary = (merchant: any) => {
    setMerchantSlug(merchant?.slug);
    Analytics.track({ experience: "portal", screen: "merchants", object: "merchant_record", action: "selected" } as ITracking, { merchant_id: merchant?.id });
  }

  const handlePendingStatus = (merchant: any) => {
    askForConfirmation(`Approve ${merchant?.name}?`,
      { text: "Approve", action: () => { dispatch(popUpActions.closePopup()); handleApprove(merchant) } },
      { text: "Decline", action: () => { handleDeclineApproval(merchant) } });
  }

  const handleApprove = (merchant) => {
    MerchantHandler().approve(merchant?.slug)
      .then(() => {
        MerchantHandler().get(merchant?.slug);
        displayFeedback({
          title: `You have successfully approved ${merchant?.name}!`,
          body: `Their account will now become active and the main contact will get an email to create a user account.`,
          type: "MERCHANT"
        } as IFeedback);
      });
  }

  const handleDeclineApproval = (merchant): void => {
    dispatch(popUpActions.openPopup({
      name: POPUPS.DECLINE_MERCHANT,
      message: {
        merchant: merchant,
        onSend: handleDecline
      }
    }))
  }

  const handleDecline = (reason: string, merchant): void => {
    MerchantHandler().decline(merchant?.slug, reason)
      .then(() => {
        MerchantHandler().get(merchant?.slug);
        displayMiniFeedback(`You have successfully declined ${merchant?.name}!`);
        dispatch(popUpActions.closePopup());
      });
  }

  const getData = async (next: string): Promise<IList> => {
    setIsLoading(true);
    let response = await DashboardHandler.getDashboardMerchants(next, merchantFilter, preventPagination);
    response.results = response.results.map(merchant => {
      return {
        ...merchant,
        name: <button className={styles.merchantPartnerName} onClick={() => { handleShowSummary(merchant) }}>{merchant?.name}</button>,
        status: (user?.user_type !== "PARTNER" && merchant?.status === "Pending") ?
          <Button
            onClick={() => { handlePendingStatus(merchant) }}
            label={merchant?.status}
            id={merchant?.id}
            small
          >{merchant?.status}</Button>
          : merchant?.status,
        created_at: formatAPIDate(merchant?.created_at),
        last_application_at: formatAPIDate(merchant?.last_application_at),
        requested_total: formatAPICurrency(merchant?.requested_total),
        funded_total: formatAPICurrency(merchant?.funded_total),
        highlight: { highlighted: merchant?.status === "Pending", property: "name" } as IHighlight,
      }
    });
    setIsLoading(false);
    return Promise.resolve(response);
  }

  useEffect(() => {
    if (user?.user_type === "MERCHANT") {
      navigate('/dashboard');
    }
  }, [user]);

  useEffect(() => {
    if (merchantFilter) {
      tableRef?.current?.reloadData();
    }
  }, [merchantFilter]);

  useEffect(() => {
    dateRangeRef?.current?.clearSelection();
  }, [tableFilterAppliedObserver]);

  const changeDateRange = (date_from: string): void => {
    let filterData: IFilter = Object.assign({}, merchantFilter);
    filterData.created_from = date_from ? moment(date_from).toDate() : null;
    filterData.created_to = moment().toDate();
    dispatch(appActions.updateMerchantFilter(filterData));
  }

  useEffect(() => {
    if (preventPagination) {
      tableRef?.current?.reloadData();
    }
  }, [preventPagination]);

  useEffect(() => {
    if (preventPagination) {
      window.DownloadCSV(tableRows, merchantTableHeaders as Array<any>, "Merchants.csv");
      setPreventPagination(false);
    }
  }, [tableRows]);

  const exportAction = () => {
    setPreventPagination(true);
  }

  const merchantSummaryRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (merchantSummaryRef.current && !merchantSummaryRef.current.contains(e.target)) {
      handleCloseMerchantSummary();
    }
  };

  const handleCloseMerchantSummary = () => {
    setMerchantSlug("");
  }

  return <>
    <main className={styles.pageContainer}>
      <Sidebar />
      <Aside>
        <DashboardHeader />
        <InnerPopups />
        <div className={styles.contentContainer}>
          <Container fluid>
            <Row>
              <Col >
                <div className={styles.btnContainer}>
                  <Button
                    id="merchants_addMerchant"
                    type="button"
                    label="Add merchant"
                    onClick={() => {
                      Analytics.track({ experience: "portal", screen: "merchants", object: "add_merchant_button", action: "clicked" } as ITracking);
                      dispatch(popUpActions.openPopup(POPUPS.ADD_MERCHANT));
                    }}
                  />
                  {(user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") && <>
                    <Button
                      id="merchants_inviteMerchant"
                      type="button"
                      label="Invite merchant"
                      onClick={() => {
                        Analytics.track({ experience: "portal", screen: "merchants", object: "invite_merchant_button", action: "clicked" } as ITracking);
                        dispatch(popUpActions.openPopup(POPUPS.INVITE_MERCHANT));
                      }}
                    />
                    <Button
                      id="merchants_invitationStatus"
                      type="button"
                      label="Invitation status"
                      variant="secondary"
                      onClick={() => {
                        Analytics.track({ experience: "portal", screen: "merchants", object: "invitation_status_button", action: "clicked" } as ITracking);
                        dispatch(popUpActions.openPopup({ name: POPUPS.INVITATION_STATUS, message: "MERCHANT" }));
                      }}
                    />
                  </>}
                  <ExportButton
                    id="merchants"
                    exporting={preventPagination}
                    disabled={isLoading}
                    onClick={() => {
                      exportAction();
                      Analytics.track({ experience: "portal", screen: "merchants", object: "export_button", action: "clicked" } as ITracking);
                    }} />
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className={styles.searchContainer}>
            <Row>
              <Col md={6} lg={6} className={styles.searchColOne}>
                <SearchBar
                  id="merchants_search"
                  placeholder={"Search merchants"}
                  onSearch={setKeyword}
                  ref={searchBarRef}
                  disabled={isLoading}
                  onClearClick={() => {
                    Analytics.track({ experience: "portal", screen: "merchants", object: "clear_search", action: "clicked" } as ITracking);
                  }} />
              </Col>
              <Col md={6} lg={6} className={styles.searchColTwo}>
                <Grid horizontal>
                  <Grid item>
                    <EmptySpace />
                    {/* <DateRangeDropdown
                      id="merchants_dateRange"
                      ref={dateRangeRef}
                      onChange={changeDateRange} /> */}
                  </Grid>
                  <Grid item>
                    <FilterButton
                      id="merchants_filter"
                      type="MERCHANT"
                      onClick={() => {
                        dispatch(popUpActions.openPopup({ name: POPUPS.TABLE_FILTER, message: { type: "MERCHANT" } }));
                      }}
                    />
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col>
                <Table
                  id="merchants_merchants"
                  data={getData}
                  tableBodyStyle={{ minWidth: 1100 }}
                  headers={merchantTableHeaders}
                  onUpdate={(rows: Array<any>) => setTableRows(rows)}
                  keyword={keyword}
                  ref={tableRef}
                  maxHeight={500}
                  onLoadingRows={() => {
                    Analytics.track({ experience: "portal", screen: "merchants", object: "table_load_more_rows", action: "initiated" } as ITracking);
                  }}
                  onSort={(field_sorted: string) => {
                    Analytics.track({ experience: "portal", screen: "merchants", object: "table_sort", action: "successful" } as ITracking, { field_sorted });
                  }}
                />
              </Col>
            </Row>
          </Container>
          <TimeZoneMessage />
        </div>
        <MerchantInformationCard variant="merchants" ref={merchantSummaryRef} merchantSlugProp={merchantSlug} closeMerchantSummary={handleCloseMerchantSummary} active={merchantSlug ? true : false} />
      </Aside>
    </main>
  </>;
};

export default Merchants;
