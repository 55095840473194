import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Table } from "common";
import _ from "lodash";
import { IList, IMerchantLocation } from "./interfaces";
import { Hidden } from "@mui/material";
import LocationsHandler from "actions/LocationsHandler";
import { useParams } from "react-router-dom";
import { concatenateAddress } from "utils/helpers";
import Observer, { EVENTS } from "classes/Observer";
import { TableRef } from "./Table";
import useMerchant from "hooks/useMerchant";

export interface LocationSelectorRef {
  reloadData: () => void;
}

interface ILocationSelector {
  id: string;
  onSelection: (location: IMerchantLocation) => void;
  keyword?: string;
  selectedLocationId: string;
  /**
   * @summary Only use this property on Location borrower coming from landing page.
   */
  locations?: Array<IMerchantLocation>;
  displayAll?: boolean;
}

const LocationSelector: React.ForwardRefRenderFunction<LocationSelectorRef, ILocationSelector> = ({ displayAll, id, onSelection, keyword, selectedLocationId, locations }: ILocationSelector, ref) => {
  const { merchantSlug } = useParams();
  const merchant = useMerchant();
  const observer = Observer.useObserver(EVENTS.LOCATION_UPDATED);
  const tableRef = useRef<TableRef>(null);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [selectedLocationId, keyword, observer, locations]);

  const getLocations = async (next: string): Promise<IList> => {
    if (locations) {
      return Promise.resolve({
        next: null,
        count: locations.length,
        previous: null,
        results: locations.map(location => {
          return {
            ...location,
            address: concatenateAddress({
              ...location
            }),
            selected: location.id === selectedLocationId
          }
        }),
        originalUrl: null
      });
    } else {
      const response = await LocationsHandler.getAll(null, merchantSlug || merchant?.slug, { key: "name", asc: true }, 50000, keyword);
      response.results = response.results.filter(location => { return location.status === "Active" });
      response.results = response.results.map(location => {
        return {
          id: location.id,
          name: location.name,
          address: concatenateAddress({
            ...location
          }),
          selected: location.id === selectedLocationId
        };
      });
      if (displayAll) {
        response.results = [{
          id: "all",
          name: "All locations",
          address: "(View all locations)",
          selected: !selectedLocationId
        }, ...response.results];
      }
      return Promise.resolve(response);
    }
  }

  const reloadData = () => {
    tableRef?.current?.reloadData();
  };

  // Expose the child function using the ref
  useImperativeHandle(ref, () => ({
    reloadData,
  }));

  return <>
    {merchant && <Table
      id={`${id}_location_selector`}
      data={getLocations}
      tableBodyStyle={{ minWidth: 740 }}
      headers={[
        { label: "Name", value: "name", size: 8, preventSorting: true },
        { label: "Address", value: "address", size: 17, preventSorting: true },
        { label: <Hidden mdDown>Select location</Hidden>, value: "actions", size: 6, preventSorting: true }
      ]}
      action={(selected: any) => {
        if (selected?.id === "all") {
          onSelection(null);
        } else {
          onSelection(selected);
        }
      }}
      supportMobile
      keyword={keyword}
      ref={tableRef}
      selectedItemId={selectedLocationId}
      maxHeight={273}
    />}
  </>;
};

export default forwardRef(LocationSelector);
