import Environment from "classes/Environment";
import LogRocket from 'logrocket';

export let segmentKey: string = "sa8Xk0kqFG73go162SCwHJCpS2CCDtGx";
export let gaKey: string = "G-R9DL4ZZWLR";
export let logRocketKey: string = "qiuraz/cloudsy-dev";

/** 
 * @description Load Segment script
 */
export function loadSegmentScript() {
  if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
    if (Environment.isProduction) {
      segmentKey = "NHq9YmSkGvBo7PCGBZCqauLDHDNqxLKI";
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${segmentKey}/analytics.min.js`;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("Segment is loaded");
    };
  }
}

export function loadLogRocket() {
  if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
    if (Environment.isProduction) {
      logRocketKey = "qiuraz/cloudsy";
    }
    LogRocket.init(logRocketKey);
  }
}