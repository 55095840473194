export default class Environment {
  /**
   * @description True if environment is production. That means it is not LOCAL, DEV, QA, or DEMO.
   */
  static isProduction: boolean = !(process.env.REACT_APP_ENV === "LOCAL"
    || process.env.REACT_APP_ENV === "DEV"
    || process.env.REACT_APP_ENV === "QA"
    || process.env.REACT_APP_ENV === "DEMO");

  /**
   * @description True only if environment is DEV.
   */
  static isDevelopment: boolean = process.env.REACT_APP_ENV === "DEV";

  /**
   * @description Return environment name. 
   * @description DO NOT USE for conditions. Use isProduction and isDevelopment instead.
   */
  static environmentName: string = process.env.REACT_APP_ENV;
}
