import React, { useEffect, useState } from "react";
import SettingsView from "../SettingsView";
import useBusinessAccount from "./useBusinessAccount";
import useYourSettings from "./useYourSettings";
import useExperienceSettings from "./useExperienceSettings";
import { useNavigate } from "react-router-dom";
import useViewIndex from "../useViewIndex";
import { ISettingsView } from "common/interfaces";

const WhiteLabelSettingsView: React.FC = ({ }) => {
  const navigate = useNavigate();
  const views = ["accountInformation", "yourSettings", "experienceSettings"];
  const activeView = useViewIndex(views);
  const businessAccount = useBusinessAccount();
  const yourSettings = useYourSettings();
  const experienceSettings = useExperienceSettings();
  const [activeTab, setActiveTab] = useState(0);
  const settingsArray: ISettingsView[] = [ businessAccount, yourSettings, experienceSettings ];
  
  useEffect(() => {
    navigate(`/settings/${views[activeTab]}`, { replace: true });
  }, [activeTab]);

  return <>
    {businessAccount && yourSettings && experienceSettings && <SettingsView
      panels={settingsArray[activeTab].panels}
      tabNavigation={{
        tabs: [
          { title: "Business account" },
          { title: "Your settings" },
          { title: "Experience settings" }
        ],
        contents: [
          businessAccount?.tabContent,
          yourSettings?.tabContent,
          experienceSettings?.tabContent
        ],
        onTabChange: (index: number) => setActiveTab(index),
        initialActiveTab: activeView
      }} />}
  </>;
}

export default WhiteLabelSettingsView;