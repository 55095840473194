import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import styles from "./BackdropOverlay.module.scss";
import popUpActions from "reducers/PopUpReducer";

const BackdropOverlayInner: React.FC = () => {
    const innerPopupOpened = useAppSelector(state => state.popUp.showInnerPopup);
    const dispatch = useAppDispatch();

    return <div className={styles.backDropOverlay} style={{ display: innerPopupOpened ? 'flex' : 'none' }} onClick={() => dispatch(popUpActions.closePopup())} />;
};

export default BackdropOverlayInner;