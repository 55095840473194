import { RefreshCircle } from "iconsax-react";
import React, { ReactNode, useEffect, useState } from "react";
import moment from "moment";
import styles from "./ComponentErrorWrapper.module.scss";

interface IComponentErrorWrapper {
  id: string;
  hasError: boolean;
  children: ReactNode;
  bottomPadding?: string | number;
  topPadding?: string | number;
  minutesToRetry?: number;
  onRetry: () => void;
}

const ComponentErrorWrapper: React.FC<IComponentErrorWrapper> = ({
  id, hasError, children, bottomPadding, topPadding, minutesToRetry, onRetry }) => {

  const [countdown, setCountdown] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [lastTrigger, setLastTrigger] = useState(null);

  useEffect(() => {
    if (hasError && !lastTrigger) {
      const localtime = moment();
      setLastTrigger(localtime.format('MM/DD/YY h:mm a'));
      setEndTime(localtime.add(minutesToRetry || 1, 'minute'));
    } else if (!hasError) {
      setLastTrigger(null);
      setEndTime(null);
      setCountdown(null);
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [hasError]);

  useEffect(() => {
    if (endTime) {
      const id = setInterval(() => {
        const currentTime = moment();
        const diff = moment.duration(endTime.diff(currentTime));

        const minutes = diff.minutes();
        const seconds = diff.seconds();

        setCountdown(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);

        if (minutes <= 0 && seconds <= 0) {
          clearInterval(id);
          setCountdown(null);
          setEndTime(null);
        }
      }, 1000);

      setIntervalId(id);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [endTime]);

  const retry = () => {
    onRetry();
    setEndTime(moment().add(minutesToRetry || 1, 'minute'));
    setLastTrigger(moment().format('MM/DD/YY h:mm a'));
  }

  return <div style={{ position: "relative" }}>
    {children}
    {hasError && <div
      className={styles.overlapMessage}
      style={{ bottom: bottomPadding || 0, top: topPadding || 0 }}>
      <p className={styles.textStyle}>We're having trouble loading this card. We'll try again shortly.</p>
      {!countdown && <p className={styles.textStyle} style={{ cursor: "pointer" }} onClick={retry}>
        Retry <RefreshCircle />
      </p>}
      {countdown && <p className={styles.textStyle}>
        {`Please wait ${countdown} to retry`}
      </p>}
    </div>
    }
  </div>;
}

export default ComponentErrorWrapper;