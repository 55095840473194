import styles from "./ReportEditFieldsPopup.module.scss";
import { Button, CloseIcon } from "common";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";

const ReportEditFieldsPopup: React.FC<IPopup> = () => {
  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="reportEditFieldsPopup" color="dark" />
        <h2>Edit Fields</h2>
        <p className="label">
          Arrange, drag and drop the fields you would like to see in your report.
        </p>
        <div className={styles.fieldsContainer}>
          <div className={styles.fieldsColOne}>
            <p className="label">Available fields</p>
            <div className={styles.fieldBlock}>
              <div>
                <p className="label">Application Activity</p>
                <ul>
                  <li className="textMedium"># Apps Offered - Full Approval</li>
                  <li className="textMedium">
                    # Apps Offered - Partial Approval
                  </li>
                </ul>
              </div>
              <div>
                <p className="label">Key Ratios</p>
                <ul>
                  <li className="textMedium">Full Approval %</li>
                  <li className="textMedium">App to Clicked %</li>
                  <li className="textMedium">Offers to Clicked %</li>
                  <li className="textMedium">Clicked to Funded %</li>
                </ul>
              </div>
              <div>
                <p className="label">Apps Submitted by Source</p>
                <ul>
                  <li className="textMedium"># Apps via Email Invite</li>
                  <li className="textMedium"># Apps via Text Invite</li>
                </ul>
              </div>
              <div>
                <p className="label">Apps Submitted by Source</p>
                <ul>
                  <li className="textMedium"># Apps via Email Invite</li>
                  <li className="textMedium"># Apps via Text Invite</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.fieldsColTwo}>
            <p className="label">Fields in report</p>
            <div className={styles.fieldBlock}>
              <ul>
                <li className="textMedium">Program Name</li>
                <li className="textMedium"># Apps Submitted</li>
                <li className="textMedium"># Apps Offered</li>
                <li className="textMedium"># Apps Clicked</li>
                <li className="textMedium"># Loans Funded</li>
                <li className="textMedium">Amt Requested</li>
                <li className="textMedium">Amt Funded</li>
                <li className="textMedium">App to Offers %</li>
                <li className="textMedium">App to Funded %</li>
                <li className="textMedium">Offers to Funded %</li>
                <li className="textMedium">Avg Amt Requested</li>
                <li className="textMedium">Program Name</li>
                <li className="textMedium"># Apps Submitted</li>
                <li className="textMedium"># Apps Offered</li>
                <li className="textMedium"># Apps Clicked</li>
                <li className="textMedium"># Loans Funded</li>
                <li className="textMedium">Amt Requested</li>
                <li className="textMedium">Amt Funded</li>
                <li className="textMedium">App to Offers %</li>
                <li className="textMedium">App to Funded %</li>
                <li className="textMedium">Offers to Funded %</li>
                <li className="textMedium">Avg Amt Requested</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button id="reportEditFieldsPopup_save" type="button" label="Save" />
        </div>
      </>
    </Popup>
  );
};

export default ReportEditFieldsPopup;
