import { BlankOrPastDateValidation, RequirePastDateValidation } from "./dateValidation";

/*
* Special financing validation matrix
*/
export const DTM_VALIDATION_MATRIX = {
  FULLTIME: {
    start_date: { visible: true, required: true },
    end_date: { visible: false, required: false }
  },
  PARTIME: {
    start_date: { visible: true, required: true },
    end_date: { visible: false, required: false }
  },
  MILITARY: {
    start_date: { visible: true, required: true },
    end_date: { visible: false, required: false }
  },
  NOT_EMPLOYED: {
    start_date: { visible: true, required: false },
    end_date: { visible: true, required: false }
  },
  SELF_EMPLOYED: {
    start_date: { visible: true, required: true },
    end_date: { visible: false, required: false }
  },
  RETIRED: {
    start_date: { visible: true, required: true },
    end_date: { visible: false, required: false }
  },
  OTHER: {
    start_date: { visible: true, required: true },
    end_date: { visible: true, required: false }
  }
};

export const EmploymentDateValidation = (status: string) => {
  if (!(Object.keys(DTM_VALIDATION_MATRIX) as Array<string>).includes(status)) {
    throw new Error(`EmploymentDateValidation failed: ${status} is not configured`);
  }

  const fieldsStatuses = DTM_VALIDATION_MATRIX[status];
  const validationSchema = {
    ...(fieldsStatuses.start_date.visible && { employment_start_date: fieldsStatuses.start_date.required ? RequirePastDateValidation : BlankOrPastDateValidation }),
    ...(fieldsStatuses.end_date.visible && { employment_end_date: fieldsStatuses.end_date.required ? RequirePastDateValidation : BlankOrPastDateValidation })
  }
  return validationSchema;
}