import { Disclaimer } from "common";
import React from "react";
import { useAppSelector } from "reducers/Hooks";


const CreditAuthorization: React.FC = () => {
  const whitelabelName = useAppSelector(state => state.whitelabel?.name);

  return <Disclaimer title="Credit Authorization Agreement">
    <p>
      You acknowledge, agree, and authorize that {whitelabelName}, powered by Enable, Inc., will send your information to its service providers in order to obtain consumer and related information about you from one or more consumer reporting agencies for the purposes of identifying the lenders and/or service providers who would be the best match for you. Your information may be sent to lenders and/or service providers on your behalf, and your information may be used in order to make sure you are who you say you are, and to determine how much debt you currently have in order to determine your debt-to-income ratio. Our affiliated lenders and/or service providers may obtain consumer reports and related information about you from one or more consumer reporting agencies, such as TransUnion, Experian and Equifax, and your information may be used by lenders and/or service providers to make prequalification and other credit decisions.
    </p>
  </Disclaimer>;
};

export default CreditAuthorization;
