import React, { useState } from "react";
import ApplicationActivityChart, { IApplicationActivityChart } from "./ApplicationActivityChart";
import MerchantActivityChart, { IMerchantActivityChart } from "./MerchantActivityChart";
import styles from "./CombinedActivityChart.module.scss";

interface ICombinedActivityChart {
  applicationActivityChart: IApplicationActivityChart;
  merchantActivityChart: IMerchantActivityChart;
}

const CombinedActivityChart: React.FC<ICombinedActivityChart> = ({ applicationActivityChart, merchantActivityChart }) => {
  const [index, setIndex] = useState(0);

  return <div className={styles["combined-activity-chart-shadown"]}>
    <div className={styles["combined-activity-chart-tabs"]}>
      <div onClick={() => setIndex(0)} className={`${styles["combined-activity-chart-tab"]} ${index === 0 ? styles.active : ''} ${styles.first}`}>Application Activity</div>
      <div onClick={() => setIndex(1)} className={`${styles["combined-activity-chart-tab"]} ${index === 1 ? styles.active : ''} ${styles.last}`}>Merchant Activity</div>
    </div>
    <div className={styles["combined-activity-chart"]}>
      {index === 0 && <ApplicationActivityChart {...applicationActivityChart} />}
      {index === 1 && <MerchantActivityChart {...merchantActivityChart} />}
    </div>
  </div>;
}

export default CombinedActivityChart;