import styles from "./BusinessModelPopup.module.scss";
import { CloseIcon } from "common";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import Popup from "./Popup";
import { IPopup } from "common/interfaces";
import _ from "lodash";
import Analytics, { ITracking } from "classes/Analytics";
import { BusinessModelType } from "common/types";
import popUpActions from "reducers/PopUpReducer";
import Observer, { EVENTS } from "classes/Observer";
import LocalStorage from "classes/LocalStorage";
import { useBusinessModel } from "hooks/useBusinessModel";

const BusinessModelPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const selectedBusinessModel = useBusinessModel();

  const changeBusinessModel = (businessModel: BusinessModelType) => {
    if (selectedBusinessModel !== businessModel) {
      LocalStorage.save('business_model', businessModel);
      Analytics.track({ experience: "portal", screen: "business_model_change", object: "business_model", action: "changed" } as ITracking, { business_model: businessModel });
      Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
      dispatch(popUpActions.closePopup());
    }
  }

  return (
    <Popup isInner={true} width={400} >
      <>
        <CloseIcon id="locationPopup" color="dark" />

        <div className={styles.businessModelWrapper}>
          <h2 className={styles.title}>Select your business model</h2>
          <div className={styles.businessModelRadio}>
            <input id={`business_model_dtm`} type="radio" value={"dtm"} name={"business_model"} onChange={e => changeBusinessModel("DTM")} defaultChecked={selectedBusinessModel === "DTM" ? true : false} />
            <p className="menu">Direct to merchant (DTM)</p>
          </div>
          <div className={styles.businessModelRadio}>
            <input id={`business_model_dtc`} type="radio" value={"dtc"} name={"business_model"} onChange={e => changeBusinessModel("DTC")} defaultChecked={selectedBusinessModel === "DTC" ? true : false} />
            <p className="menu">Direct to customer (DTC)</p>
          </div>
          <div className={styles.businessModelRadio}>
            <input id={`business_model_all`} type="radio" value={"all"} name={"business_model"} onChange={e => changeBusinessModel("All")} defaultChecked={!selectedBusinessModel || selectedBusinessModel === "All" ? true : false} />
            <p className="menu">Both models (All)</p>
          </div>
        </div>
      </>
    </Popup >
  );
};

export default BusinessModelPopup;
