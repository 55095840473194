import RequestHandler from "classes/RequestHandler";
import { IList, ILocation, IMerchantLocation, ISort } from "common/interfaces";
import { getAllStatuses, getStatus } from "utils/helpers";
import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from "./ActionConstants";
import Observer, { EVENTS } from "classes/Observer";
import ErrorHandler from "./ErrorHandler";
import { MerchantHandler } from "./MerchantHandler";
import ApiCache from "classes/ApiCache";

export default class LocationsHandler {
  /** 
   * @description Creates a new merchant location.
   * @param {IMerchantLocation} location New location.
   * @param {string} slug Merchant slug.
   * @returns Location details.
   * @emits EVENTS.LOCATION_CREATED
   * */
  static create = async (location: IMerchantLocation, slug: string): Promise<any> => {
    try {
      const payload = {
        ...location,
        status: await getStatus("Active")
      }
      if (slug) {
        payload["merchant"] = slug;
      }
      const response = await RequestHandler.makeRequest(`customer/location/create/`, "POST", payload);
      Observer.trigger(EVENTS.LOCATION_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /** 
   * @description Get all merchant locations.
   * @param {string} next Pagination.
   * @param {string} slug Merchant slug.
   * @param {ISort} sortBy Sorting info.
   * @param {number} limit Limit.
   * @param {string} keyword Limit.
   * @param {boolean} preventCache Prevent using cache results.
   * @returns List of locations.
   * */
  static getAll = async (next: string, slug?: string, sortBy?: ISort, limit?: number, keyword?: string, preventCache?: boolean): Promise<IList> => {

    try {
      let merchantId: string = null;
      if (slug) {
        const merchant = await MerchantHandler().get(slug);
        merchantId = merchant?.id;
      }

      let url = `customer/locations/?offset=0&limit=${limit || 30}`;
      url = next || url;
      url = url.AddQuerystring("merchant", merchantId).AddQuerystring("search", keyword).SortBy(sortBy);

      const statuses = await getAllStatuses();

      let response = null;
      if (preventCache) {
        const result = await ENABLE_FINANCING_API_INSTANCE.get(url);
        response = result.data;
      } else {
        response = await ApiCache.get(url);
      }
      response.results = response.results.map(item => {
        return {
          ...item,
          status: statuses.find(status => { return status.id === item.status })?.label || item.status
        };
      });
      return Promise.resolve({
        ...response,
        originalUrl: url
      } as IList);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /** 
   * @description Get a location details.
   * @param {string} locationId Location id.
   * @returns Location details.
   * */
  static get = async (locationId: string): Promise<IMerchantLocation> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`customer/location/${locationId}/`);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /** 
   * @description Delete a location.
   * @param {string} locationId Location id.
   * @returns API response.
   * @emits EVENTS.LOCATION_CHANGED
   * */
  static delete = async (locationId: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.delete(`customer/location/${locationId}/`);
      Observer.trigger(EVENTS.LOCATION_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /** 
   * @description Update location.
   * @param {any} data Location data to change.
   * @returns API response.
   * @emits EVENTS.LOCATION_CHANGED
   * */
  static update = async (data: any, locationId: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`customer/location/${locationId}/update/`, data);
      Observer.trigger(EVENTS.LOCATION_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /** 
   * @description Bulk upload locations.
   * @param {Blob} file_data File binary.
   * @param {string} slug Merchant slug.
   * @emits EVENTS.LOCATION_CHANGED
   * @returns API response.
   * */
  static bulkUpload = async (file_data: Blob, slug: string): Promise<any> => {
    try {
      const body = new FormData();
      body.append("file", file_data);

      const response = await ENABLE_FINANCING_API_INSTANCE.post(`customer/merchant/${slug}/locations/`, body);
      Observer.trigger(EVENTS.LOCATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
 * @description Add merchant location. For non-authenticated merchants.
 * @param {ILocation} location Location.  
 * @param {number} invitation_id Invitation id.
 * @param {string} vuid vuid sent on the invitation email.
 * @returns {Promise<any>} API response.
 */
  static createWithInvitation = async (location: ILocation, invitation_id: number, vuid: string): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post(`invitation/whitelabel/merchant-invite/${invitation_id}/location?vuid=${vuid}`, location);
      Observer.trigger(EVENTS.LOCATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Get merchant locations.
   * @param {number} invitation_id Invitation id.
   * @param {string} vuid vuid sent on the invitation email.
   * @param {boolean} preventCache Prevent using cache.
   * @returns {Promise<any>} API response.
   */
  static getAllWithInvitation = async (invitation_id: number, vuid: string, preventCache: boolean): Promise<any> => {
    try {
      if (!preventCache) {
        const response = await ApiCache.getAnonymous(`invitation/whitelabel/merchant-invite/${invitation_id}/location?vuid=${vuid}`);
        return Promise.resolve(response);
      } else {
        const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`invitation/whitelabel/merchant-invite/${invitation_id}/location?vuid=${vuid}`);
        return Promise.resolve(response.data);
      }
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Upload locations CSV.
   * @param {Blob} file_data file data.
   * @param {string} filename file name.
   * @param {number} invitation_id Invitation id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static bulkUploadWithInvitation = async (file_data: Blob, filename: string, invitation_id: number, vuid: string): Promise<any> => {

    try {
      const body = new FormData();
      body.append("file", file_data, filename);

      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post(`invitation/whitelabel/merchant-invite/${invitation_id}/location-upload?vuid=${vuid}`, body);
      Observer.trigger(EVENTS.BULK_LOCATIONS_UPLOAD);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Edit location.
   * @param {ILocation} location Location.  
   * @param {number} invitation_id Invitation id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static editWithInvitation = async (location: ILocation, invitation_id: number, vuid: string): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.put(`invitation/whitelabel/merchant-invite/${invitation_id}/location/${location.id}?vuid=${vuid}`, location);
      Observer.trigger(EVENTS.LOCATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Delete location.
   * @param {string} location_id Location id.  
   * @param {number} invitation_id Invitation id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static deleteWithInvitation = async (location_id: string, invitation_id: number, vuid: string): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.delete(`invitation/whitelabel/merchant-invite/${invitation_id}/location/${location_id}?vuid=${vuid}`);
      Observer.trigger(EVENTS.LOCATION_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }
}