import React from "react";
import PanelLine, { IPanelLine } from "./PanelLine";
import { sanitizeString } from "utils/helpers";
import AppIcon from "common/AppIcon";
import { Edit } from "iconsax-react";
import styles from "./Panel.module.scss";
import { IAction } from "common/interfaces";
import { Button } from "common";

export interface IPanel {
  title: string;
  lines?: Array<IPanelLine>;
  colorVariable?: string;
  editAction?: () => void;
  panelColor?: string;
  textColor?: string;
  customContent?: any;
  actions?: Array<IAction>;
  bottomBannerText?: string;
  last?: boolean;
}

const Panel: React.FC<IPanel> = ({ title, lines, colorVariable, editAction, panelColor, textColor, customContent, actions, bottomBannerText, last }) => {

  return <div className={styles.panel} style={{ backgroundColor: `var(--${panelColor || colorVariable || 'whiteColor'})`, paddingBottom: customContent ? 30 : 10, flex: bottomBannerText ? "1 1" : "unset", borderBottomLeftRadius: last ? "20px" : "unset" }}>
    {editAction && <AppIcon
      className={styles["edit-icon"]}
      icon={Edit}
      clickTrigger={{
        id: `${sanitizeString(title)}_edit_button`,
        onClick: editAction
      }}
      color="var(--primaryVariationTextColor)"
    />}
    <h2 className={styles["panel-title"]} style={{ ...(textColor && { color: `var(--${textColor})` }) }}>{title}</h2>
    {customContent && <div className={styles["custom-content"]}>{customContent}</div>}
    {!customContent && lines?.map((line, index) => {
      return <PanelLine key={`${sanitizeString(title)}_panel_line_${index}`} {...line} textColor={textColor} />;
    })}
    {actions?.length > 0 && <div style={{ textAlign: "center" }}>
      {actions.map((action, index) => {
        const key = `${sanitizeString(title)}_panel_action_${index}`;
        return <Button key={key}
          variant={action.color}
          type="button"
          id={key}
          label={action.label}
          onClick={action.onClick}
        />;
      })}
    </div>}
    {bottomBannerText && <div className={styles.bottomBanner}>
      <p>{bottomBannerText}</p>
    </div>}
  </div>
    ;
}

export default Panel;