import InvitationHandler from "actions/InvitationHandler";
import LocalStorage from "classes/LocalStorage";
import React, { useEffect } from "react";
import actions from "reducers/FlowsReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IBusinessInfo, IContact } from "common/interfaces";
import { useNavigate } from "react-router-dom";
import { displayFullscreenLoading, fillInitialValues, hideFullscreenLoading } from "utils/helpers";

const ReviewInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isRevision = useAppSelector(state => state.flows.isRevision);
  const invitationId = LocalStorage.get<number>("invitationId");
  const vuid = LocalStorage.get<string>("vuid");

  useEffect(() => {
    if (invitationId && vuid) {
      displayFullscreenLoading();
      InvitationHandler.getDeclinedMerchant(invitationId, vuid)
      .then(merchant => {
        let initialValues = {
          businessInformation: { name: "", website: "", annual_sales: "", monthly_financing_volume: "", average_ticket: "", industry: "", industry_specialty: "" },
          mainAddress: { address1: "", city: "", postal_code: "", state: "", manageMultipleLocations: "" },
          landingPage: { slug: "", primary_logo: null },
          additionalLocations: { location_name: "", address1: "", city: "", postal_code: "", state: "" },
          mainContact: { main_contact_first_name: "", main_contact_last_name: "", main_contact_email: "", main_contact_phone: "" }
        };
        const businessInformation: IBusinessInfo = fillInitialValues(initialValues.businessInformation, merchant);
        const mainAddress: any = fillInitialValues(initialValues.mainAddress, merchant);
        mainAddress["manageMultipleLocations"] = merchant?.locations.length > 1 ? 'yes' : 'no';
        const mainContact: IContact = fillInitialValues(initialValues.mainContact, merchant);
        const landingPage: any = { slug: merchant?.slug, primary_logo: merchant?.primary_logo || null };
        dispatch(actions.updateMerchantInvitation({ businessInformation, mainAddress, mainContact, landingPage }));
        dispatch(actions.setRevision(true));  
      });
    } else {
      navigate("/");
    }
  }, [invitationId, vuid]);

  useEffect(() => {
    if (isRevision) {
      hideFullscreenLoading();
      navigate('/merchantInvitation/businessInformation');
    }
  }, [isRevision]);

  return <></>;
}

export default ReviewInformation;