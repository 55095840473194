import styles from "./MerchantTabs.module.scss";
import React, { useEffect, useState } from "react";
import { Grid, LandingPagePreview } from "common";
import { useAuth } from "auth/useAuth";
import MerchantInformationCard from "./MerchantInformationCard";
import { MerchantTabType } from "common/types";
import { MerchantHouse } from "content/popups/feedback";
import { getAllStatuses } from "utils/helpers";
import { useLoadingBar } from "hooks/useLoadingBar";
import useMerchant from "hooks/useMerchant";
import Observer, { EVENTS } from "classes/Observer";
import { useNavigate, useParams } from "react-router-dom";
import { useBusinessModel } from "hooks/useBusinessModel";
import _ from "lodash";

interface IMerchantExperienceSettingsTab {
  variant: MerchantTabType;
}

const MerchantExperienceSettingsTab: React.FC<IMerchantExperienceSettingsTab> = ({ variant }) => {
  const user = useAuth()?.user;
  const merchant = useMerchant();

  useLoadingBar(merchant);
  const navigate = useNavigate();
  const merchantUpdated = Observer.useObserver(EVENTS.MERCHANT_UPDATED);
  const [originalSlug, setOriginalSlug] = useState<string>(null);
  const params = useParams();
  const { merchantSlug } = useParams();
  const selectedBusinessModel = useBusinessModel();

  const [merchantStatuses, setMerchantStatuses] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    getAllStatuses()
      .then(response => {
        setMerchantStatuses(response);
      });
  }, []);

  useEffect(() => {
    if (merchantSlug) {
      setOriginalSlug(merchantSlug);
    }
  }, [params]);

  useEffect(() => {
    if (window.location.pathname.includes("viewMerchant") && originalSlug !== merchant?.slug && merchant?.slug) {
      setOriginalSlug(merchant?.slug);
      navigate("/viewMerchant/" + merchant?.slug + "/experienceSettings");
    }
  }, [merchantUpdated]);

  useEffect(() => {
    console.log(user, merchant);
    if (user?.id && merchant?.id != "") {
      setReady(true);
    }
  }, [user, merchant])

  const getStatusId = (status: "Active" | "Pending" | "Deactivated" | "Declined"): number => {
    return _.find(merchantStatuses, item => { return item.name === status })?.id;
  }

  const renderMerchantHouse = () => {
    return (
      <>
        <div className={styles.approval}>
          <MerchantHouse />
          <h1>Merchant {merchant?.name} is pending approval</h1>
        </div>
      </>
    );
  }

  const renderLandingPagePreview = () => {
    return (
      <>
        <Grid>
          <Grid item>
            <h2>{merchant?.name} Landing Page Preview</h2>
          </Grid>
          <Grid item>
            <LandingPagePreview
              hero={merchant?.industry_template?.hero_image || "/assets/images/merchantLandingBanner.png"}
              subtitle={merchant?.industry_template?.body || null}
              title={merchant?.industry_template?.title || null}
              {...merchant?.color_theme && { colors: [merchant?.color_theme?.primary_light, merchant?.color_theme?.primary_dark, merchant?.color_theme?.secondary] }}
              {...merchant?.primary_logo && { preview_logo: merchant?.primary_logo }}
              forceMerchant={true}
              showOnlyAboveFold={false}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return <>
    <MerchantInformationCard variant={variant} />
    {ready && merchant && <div className={styles.contentContainer}>
      {
        merchant?.status === getStatusId("Pending") && user?.user_type === "PARTNER"
          ? renderMerchantHouse()
          : renderLandingPagePreview()
      }
    </div>}
  </>;
};

export default MerchantExperienceSettingsTab;

