import React from "react";
import styles from "./ApplicationProgressTracker.module.scss";
import { CardTick, Icon, Mouse, ReceiptEdit, UsdCoin } from "iconsax-react";

interface ICountTotal {
  count: number;
  total: number;
}

interface IApplicationProgressTracker {
  requested: ICountTotal;
  offered: ICountTotal;
  clicked: ICountTotal;
  funded: ICountTotal;
}

interface ITrackerStep {
  icon: Icon;
  title: string;
  counts: ICountTotal;
  lastChild?: boolean;
}

const TracketStep: React.FC<ITrackerStep> = ({ icon, title, counts, lastChild }) => {
  return <div className={styles["app-tracker-step"]}>
    <div className={lastChild ? styles["app-tracker-line-last"] : styles["app-tracker-line"]}></div>
    <div className={styles["app-tracker-step-icon"]}>
      {React.createElement(icon, { color: "var(--whiteTextColor)", size: 24 })}
    </div>
    <div className={styles["app-tracker-step-title"]}>
      {title}
    </div>
    <div className={styles["app-tracker-step-count"]}>
      {counts.count}
    </div>
    {counts.total && <div className={styles["app-tracker-step-more-info"]}>
      {`Total ${counts.total}`}
    </div>}
  </div>;
}

const ApplicationProgressTracker: React.FC<IApplicationProgressTracker> = ({ requested, offered, clicked, funded }) => {

  return <div className={styles["app-tracker-wrapper"]}>
    <h4 style={{ color: "var(--primaryVariationTextColor)" }}>Number of unique applications</h4>
    <div className={styles["app-tracker"]}>
      <TracketStep icon={ReceiptEdit} title="Requested" counts={requested} />
      <TracketStep icon={UsdCoin} title="Offered" counts={offered} />
      {clicked && <TracketStep icon={Mouse} title="Clicked" counts={clicked} />}
      <TracketStep icon={CardTick} title="Funded" counts={funded} lastChild />
    </div>
  </div>;
}

export default ApplicationProgressTracker;