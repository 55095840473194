import { Button } from "common";
import React from "react";
import styles from "./BulkUploadSection.module.scss";
import { IFileData } from "./interfaces";
import { generateCsvFile } from "utils/helpers";

interface IBulkUploadSection {
  title: string;
  disclaimerBulkSend?: string;
  template: Array<Array<string>>;
  onSend: (data: IFileData) => void;
  onDownloadClick?: () => void;
}

const BulkUploadSection: React.FC<IBulkUploadSection> = ({ title, disclaimerBulkSend, template, onSend, onDownloadClick }) => {
  const [filename, setFilename] = React.useState<string>("");
  const [uploadedFile, setUploadedFile] = React.useState<Blob>(null);

  return <>
    <div className={styles.gapContainer}>
      <div className={styles.orContainer}>
        <hr />
        <h2>or</h2>
        <hr />
      </div>
      <h2>{title}</h2>
      <div className={styles.bulkCsvContainer}>
        <div className={styles.uploadLogoContainer}>
          <div className={styles.labelcsvContainer}>
            <p>CSV file</p>
            <a href="#" target="_blank" className="menu" onClick={(e) => {
              e.preventDefault();
              generateCsvFile(template);
              if (onDownloadClick) {
                onDownloadClick();
              }
            }}>Download blank CSV</a>
          </div>
          <div className={styles.uploadContainer}>
            <input
              type="file"
              name="csv"
              id="actual-btn"
              hidden
              onChange={(e: any) => {
                setFilename(e.target.files[0]?.name);
                setUploadedFile(e.target.files[0]);
              }}
            />
            <label htmlFor="actual-btn">Choose a file</label>
            <span id={`fileChosen ${styles.choosenFile}`}>
              {filename}
            </span>
          </div>
        </div>
        <div className={`${styles.formBtnContainer}`}>
          <Button id="bulkSend" label="Upload" onClick={() => {
            onSend({ filename, file: uploadedFile } as IFileData);
          }} />
        </div>
      </div>
      {disclaimerBulkSend && <p
        className="info"
        style={{
          textAlign: "right",
          maxWidth: "39rem",
          margin: "0 0 0 auto",

          color: "var(--darkTextColor)",
        }}>
        {disclaimerBulkSend}
      </p>}
    </div>
  </>;
}

export default BulkUploadSection;