import React, { useEffect, useState } from "react";
import styles from "./ViewApplicant.module.scss";
import ApplicationProgeressCard from "./ApplicationProgressCard";
import ApplicationInformationCard from "./ApplicationInformationCard";
import OffersTable from "./OffersTable";

const ApplicationDetails: React.FC = () => {
  return <>
    <ApplicationInformationCard />
    <div className={styles.contentContainer}>
      <ApplicationProgeressCard />
      <OffersTable />
    </div>
  </>;
}

export default ApplicationDetails;