import { OptimizedForm } from "common/form";
import { IAddEntity, IFieldDefinition } from "common/interfaces";
import React, {  } from "react";
import { statesArray } from "utils/constants";
import { AddressValidation, SimpleRequireValidation } from "utils/validation/additionalValidation";

const BusinessMainAddressForm: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const fields: IFieldDefinition[] = [
    { label: 'Address*', name: 'address1', type: 'text', validation: AddressValidation.address1 },
    { label: 'City*', name: 'city', type: 'text', validation: AddressValidation.city },
    { label: 'State*', name: 'state', type: 'select', options: statesArray, validation: SimpleRequireValidation },
    { label: 'Postal code*', name: 'postal_code', type: 'text', validation: AddressValidation.postal_code },
    { label: 'Manage multiple locations?*', name: 'manage_multiple_locations', type: 'select', options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }], validation: SimpleRequireValidation }
  ];

  const handleSubmit = (data: any) => {
    onNext(data);
  }

  return <OptimizedForm fields={fields} onSubmit={handleSubmit} title="Add merchant • Business main address" />;
}

export default BusinessMainAddressForm;
