import { AddressValidator } from "common";
import { EmptySpace, Form, Input, SelectDropdown } from "common/form";
import { FormRef } from "common/form/Form";
import { IAddEntity, IDTMApplication, IFormSection, IUSPSAddress } from "common/interfaces";
import React, { useEffect, useRef, useState } from "react";
import { statesArray } from "utils/constants";
import { convertStringNullsToNull, IDTMApplicationLegalAddress2IUSPSAddress, IUSPSAddress2IDTMApplicationLegalAddress } from "utils/helpers";
import { AddressValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";

const MainAddress: React.FC<IAddEntity> = ({ onNext, initialValues }) => {
  const [typedAddress, setTypedAddress] = useState<any>(null);
  const [addressToVerify, setAddressToVerify] = useState<any>(null);
  const [verifyButtonEnabled, setVerifyButtonEnabled] = useState<boolean>(true);
  const formRef = useRef<FormRef>(null);

  const [addressIsTheSame, setAddressIsTheSame] = useState<boolean>(initialValues?.is_legal_business_address_same === "false" ? false : true);
  const businessAddress = {
    inputFields: [
      [
        <Input name="business_address1" id="dtm_application_business_address1" label="Address*" />,
        <Input name="business_city" id="dtm_application_business_city" label="City*" />,
      ],
      [
        <SelectDropdown name="business_state" id="dtm_application_business_state" label="State*" selectOptions={statesArray} />,
        <Input name="business_zip_code" id="dtm_application_business_zip_code" label="Zip code*" maxLength={5} />,
      ]
    ],
    requirements: {
      business_address1: AddressValidation.address1,
      business_city: AddressValidation.city,
      business_state: AddressValidation.state,
      business_zip_code: AddressValidation.postal_code
    }
  };

  const legalAddress = {
    inputFields: [
      [
        <Input name="legal_address1" id="dtm_application_legal_address1" label="Address*" />,
        <Input name="legal_city" id="dtm_application_legal_city" label="City*" />,
      ],
      [
        <SelectDropdown name="legal_state" id="dtm_application_legal_state" label="State*" selectOptions={statesArray} />,
        <Input name="legal_zip_code" id="dtm_application_legal_zip_code" label="Zip code*" maxLength={5} />,
      ]
    ],
    requirements: {
      legal_address1: AddressValidation.address1,
      legal_city: AddressValidation.city,
      legal_state: AddressValidation.state,
      legal_zip_code: AddressValidation.postal_code
    }
  };

  const getCommonFields = () => {
    let fields = [<SelectDropdown onChange={(value: string) => { setAddressIsTheSame(value === "true") }} name="is_legal_business_address_same" id="dtm_application_is_legal_business_address_same" label="Legal and business address is the same?*" selectOptions={[{ label: "No", value: "false" }, { label: "Yes", value: "true" }]} />];
    fields.push(<EmptySpace />);
    return [fields];
  }
  const common = {
    inputFields: getCommonFields(),
    requirements: {
      is_legal_business_address_same: SimpleRequireValidation
    }
  }

  const businessAddressSection: Array<IFormSection> = [{
    title: "Business address",
    fields: [...businessAddress.inputFields, ...common.inputFields]
  }];
  const legalAddressSection: Array<IFormSection> = [{
    title: "Legal address",
    fields: legalAddress.inputFields
  }];

  const [validationSchema, setValidationSchema] = useState<any>(Schema({ ...businessAddress.requirements, ...common.requirements, ...(initialValues?.is_legal_business_address_same === "false" && { ...legalAddress.requirements }) }));
  const [inputFields, setInputFields] = useState<Array<IFormSection>>([...businessAddressSection, ...(initialValues?.is_legal_business_address_same === "false" ? legalAddressSection : [])]);

  useEffect(() => {
    setInputFields([...businessAddressSection, ...(!addressIsTheSame ? [...legalAddressSection] : [])]);
    setValidationSchema(Schema({ ...businessAddress.requirements, ...common.requirements, ...(!addressIsTheSame && { ...legalAddress.requirements }) }));
  }, [addressIsTheSame]);

  const handleAddressVerification = (data: any) => {
    if (addressIsTheSame) {
      onNext(data);
    } else {
      const addressToVerify: IUSPSAddress = IDTMApplicationLegalAddress2IUSPSAddress(data);
      setAddressToVerify(addressToVerify);
    }
  }

  const handleNext = (address: IUSPSAddress) => {
    // combine IDTMApplication with selected address
    const values = formRef?.current?.getValues();
    const addressFormatted = IUSPSAddress2IDTMApplicationLegalAddress(address);
    const combinedDtmApplication = { ...values, ...addressFormatted };
    onNext(combinedDtmApplication);
  }

  const setAddressIsVerified = (verified: boolean) => {
    setVerifyButtonEnabled(!verified)
  }

  // so you can detect a change to enable / disable verify button
  const handleChange = (data: IDTMApplication) => {
    const typedAddress: IUSPSAddress = IDTMApplicationLegalAddress2IUSPSAddress(data);
    convertStringNullsToNull(typedAddress);
    setTypedAddress(typedAddress);
  }

  return <>
    <Form
      id="dtm_application_main_address"
      title="Business main address"
      submitBtnText={addressIsTheSame ? "Next" : "Verify"}
      onFormSubmit={handleAddressVerification}
      validationSchema={validationSchema}
      values={initialValues}
      buttonPosition={"bottom right"}
      inputFields={inputFields}
      isInsidePopup
      endOfFormJSX={addressToVerify && typedAddress && <AddressValidator setAddressIsVerified={setAddressIsVerified} addressToVerify={addressToVerify} typedAddress={typedAddress} handleNext={handleNext} />}
      buttonDisabled={!verifyButtonEnabled}
      onFormChange={handleChange}
      ref={formRef}
    />;
  </>;
}

export default MainAddress;