import { Button, CloseIcon, SVG } from "common";
import { IPopup } from "common/interfaces";
import NewPopup from "./NewPopup";
import { POPUPS } from "utils/constants";
import { useAppDispatch } from "reducers/Hooks";
import styles from "./DTMApplication/DTMApplication.module.scss";
import popUpActions from "reducers/PopUpReducer";
import { ENABLE_FINANCING_API_INSTANCE } from "../../actions/ActionConstants";

const USER_EVENT_SLUG = 'show-dtm-modal';

const DTMNowAvailable: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const acknowledgePopupClose = () => {
    ENABLE_FINANCING_API_INSTANCE.post(
      `/user-events/${USER_EVENT_SLUG}/acknowledge/`,
      {"status": "INACTIVE"}
    );
  }

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
    acknowledgePopupClose();
  }

  return (
    <NewPopup isInner={true} style={{
      maxWidth: "80rem",
      paddingBottom: 0,
      maxHeight: '80%'
    }}>
      <>
      <div className={styles.step1}>
        <CloseIcon id="openDTMNowAvailablePopup_closeIcon" color="dark" onClose={handleClose} />
        <h1>New Feature Alert<br/>Direct to Merchant (DTM) Now Available!</h1>
        <p>Great news! You can now receive funds for each loan directly to your account instead of your customer's. Apply today and start benefiting from Direct to Merchant.</p>
        <p>Minimum requirements: Annual sales last year must be over 1.2M</p>
        {<div className={styles.svgWrapper}>
          <SVG
            src={"/assets/images/annual_amount.svg"}
            title={`Annual amount icon`}
            height={150}
          />
        </div>}
        <Button
          id="dtm_application_apply_dtm_continue"
          label="Complete the DTM application now"
          style={{ marginBottom: "5rem" }}
          onClick={() => {
            dispatch(popUpActions.openPopup(POPUPS.DTM_PROCESS));
            acknowledgePopupClose();
          }} />
      </div>
    </>
  </NewPopup >);
}

export default DTMNowAvailable;