import { Link } from "common";
import React from "react";
import { useAppSelector } from "reducers/Hooks";

interface IPoweredByEnable {
  darkMode: boolean;
}

const PoweredByEnable: React.FC<IPoweredByEnable> = ({ darkMode }) => {
  const whitelabel = useAppSelector(state => state.whitelabel);

  if (!whitelabel?.display_powered_by_enable) {
    return <></>;
  }

  return <div style={{ textAlign: "center", marginTop: "2.5rem" }}>
    <Link id="footer_enableFinancing" href="https://enablefinancing.com/" openNewTab={true} rel="noreferrer" linkText="">
      <p style={{
        fontWeight: 500,
        fontSize: "1.3rem",
        lineHeight: "1.5rem",
        textAlign: "center",
        color: "var(--whiteTextColor)"
      }}>Powered by</p>
      <img src={darkMode ? "/assets/images/enableLogo.png" : "/assets/images/enableBlueLogo.png"} alt="Enable Logo" style={{
        width: "100%",
        maxWidth: 87
      }} />
    </Link>
  </div>;
}

export default PoweredByEnable;